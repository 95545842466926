import styled from 'styled-components/macro';

export const GlobalFlexRowWrapper = styled.div`
  display:flex;
  flex-direction:row;
  position:relative;
  align-items: center;
  margin-top: 20px;
`;
export const DetailsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  position:relative;
  align-items: baseline;
`;

export const HoverWrapper = styled.div`
  width: 100%;
  height: 101%;
  border-radius: 6px;
  border: 1px solid #FFC72C;
  transition: all ease-in-out 0.2s;
  background: var(--Brand_Scheme_bg_hover);
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  top: 0;
  z-index: 4;
  opacity: 0;

  svg path {
    stroke: var(--Brand_Scheme_fg_inverted);
  }
`;

export const CoverWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  position: relative;
  
  :hover ${HoverWrapper} {
    opacity: 1;
  }
`;

export const TextWrapperUPV = styled.div`
  color: #484343;
`;
export const ItemWrapperUPV = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`;
export const ItemWrapperCutHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #514E55;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
`;
export const FatDot = styled.div`
  color: #484343;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  line-height: 20px;
  margin-left: 4.5px;
  margin-right: 5px;
  margin-top: 3px;
`;
export const GreyItemsSplitter = styled.div`
  background: var(--Sheme_fg_disabled);
  width: 8px;
  height: 8px;
  border-radius: 10px;
  margin: 0 5px;
`;
export const AvatarWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .ava_popover {
    visibility: hidden;
  }

  &:hover {
    .ava_popover {
      visibility: visible;
    }
  }

  img {
    top: 0;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    border: 1.2px solid var(--uiui-10, #FCFDFF);
  }
  
  ${({ left }) => (left ? `left:-${left}px;` : '')}
  ${({ isReactions }) => (isReactions ? `
    img {
      width: 20px;
      height: 20px;
    }
    ` : '')}
`;

export const EndDateRed = styled.div`
  color: #D32F2F;
`;

export const AvatarGroupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  ${({ customWidth }) => (customWidth ? `width:${customWidth}px;` : '')}
  .avatar_border_hover{
    //border-radius: 28px;
    border: 1.2px solid var(--uiui-10, #FCFDFF);
  }
  div:first-child {

    ${({ onlyOne }) => (onlyOne ? '' : `
        img {
      position: relative;
      right: 0px;
      top: 0px;
    }
    `)}

  }

  ${({ isThirdRow }) => (isThirdRow ? `
  left:10px;
  bottom:6px;
  ` : '')}
  ${({ isUPV }) => (isUPV ? `
  bottom:4px;
  ` : '')}
`;

export const AvatarPopOver = styled.div`
  padding-left: 7px;
  padding-right: 7px;
  width: max-content;
  height: 24px;
  position: absolute;
  z-index: 1000;

  border-radius: 4px;
  background: var(--uiui-9, #FFF);
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #585858;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

    ${({ isReactions }) => (isReactions ? `
  top: 24px;
  right: -40px;
  background-color: #232225;
  color: #FCFCFD;
  white-space: pre-line;
  height: fit-content;
  ` : `
  border: 1px solid #FFC72C;
    top: -24px;
  left: 5px;`)}
`;

export const SettingsWrapper = styled.div`
    background: linear-gradient(317deg,#1FAE09 -2.91%,#5BDA48 138.5%);
    border: none;
    border-radius: 0 40px 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    cursor: pointer;
    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0.15) 100%),
        linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
    }
`;

export const ShareWrapper = styled.div`
  display: flex;
  align-items: end;
  position: relative;
  right: 0;
  margin-right: -2px;
  border-radius: 12px;

  ${({ forCutHeader }) => (forCutHeader
    ? `
      align-items: center;
      bottom: 0;

  `
    : '')}
  ${({ forCutHeader, moveLeft }) => (moveLeft && forCutHeader
    ? `
    right: 23.2%;
  `
    : '')}
  ${({ forCutHeader, isShowLibraryWidget }) => (isShowLibraryWidget && forCutHeader
    ? `
    right: 26.25%;
  `
    : '')}
`;
