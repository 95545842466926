import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button2023 from '../../../../../../components/Buttons/Button2023';
import Toggler from '../../../../../../components/Togglers/Toggler';
import { MakerOptionsWrapper, SettingsControlContainer, Text } from '../styled';
import { emptyArr, emptyCallback, openModalType } from '../../../../../../utils/constants';
import { actionEditPlaylistSingleStateUser } from '../../../../../../redux/playlists/action';
import DefaultOption from './DefaultOption';
import PublishOption from './PublishOption';
import ShareOption from './ShareOption';
import { optionType } from '../enums';
import ShareToWebOption from './ShareToWebOption';
import { actionCreator } from '../../../../../../shared/redux/actionHelper';
import SupportAction from '../../../../../../redux/support/types';
import { actionOpenModal } from '../../../../../../redux/user/action';
import { actionShowLibraryWidget } from '../../../../../../redux/currentPage/action';
import EditPlaylist from '../../../../../../redux/playlists/types';
import { shareType } from '../../../../../../components/DropDownOption/Share/helpers';


const Options = ({ currentPage, dataParent, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { myChannels } = useSelector((state) => state.channels);
  const [version, setVersion] = useState('');
  const [channels, setChannels] = useState([]);
  const [userEmail, setUserEmail] = useState('');
  const isPage = type === 'page';

  const {
    isPublish,
    hasModification,
    shareState = {},
    singleUserShareState = {},
    usedInChannels = {},
  } = currentPage;

  const { usersToSharing = emptyArr, availableTo, isShareToWeb } = shareState;

  const calcIndex = useMemo(() => {
    const currentCoEditors = (Object.values(singleUserShareState));
    return currentCoEditors.sort((a, b) => a.coEditorIndex - b.coEditorIndex).reduce((acc, cur, index) => {
      if ((cur.coEditorIndex !== acc || !cur.coEditorIndex) && (index + 1 <= currentCoEditors.length)) return acc;
      return acc + 1;
    }, 1);
  }, [singleUserShareState]);

  const calcVersion = () => {
    if (isPublish || isShareToWeb || !!usersToSharing.length) return t('sharedT');
    if (hasModification) return t('draftT');
    return t('draftT');
  };
  const removeChannel = (id) => {
    dispatch(actionCreator(EditPlaylist.RemoveChannel, { channelId: id }));
  };
  const enableShare = useCallback((preOpenOption) => () => {
    if (isPage) {
      dispatch(actionCreator(SupportAction.UpdateSharedModalAnchor, { sharedModalAnchor: Math.random() }));
    } else {
      dispatch(
        actionOpenModal(openModalType.Share, { itemType: 'playlist', isMakerStyles: true, preOpenOption }),
        // actionOpenModal(openModalType.ShareUnshare, { itemType: 'playlist', isMakerStyles: true, preOpenOption }),
      );
    }
    dispatch(actionShowLibraryWidget(false));
  }, [dispatch, isPage]);

  const editShareStateHandler = useCallback((state, field, email) => {
    dispatch(actionEditPlaylistSingleStateUser({
      state,
      field,
      email,
      coEditorIndex: calcIndex,
      // wrapperId:currentPage.wrapperId,
    }));
  }, [calcIndex, dispatch]);

  useEffect(() => {
    if (isPage) return;
    const idsInChannel = Object.values(usedInChannels).map(({ id }) => id);
    const usedChannels = Object.values(myChannels)
      .filter((channel) => idsInChannel.includes(channel.id))
      .map(channel => ({ ...usedInChannels[channel.id], ...channel }));

    setChannels(usedChannels);
  }, [myChannels, usedInChannels]);

  useEffect(() => {
    setVersion(calcVersion());
  }, [isPublish, hasModification]);

  return (
    <MakerOptionsWrapper data-parent={dataParent}>
      {!isPage
      && (
      <DefaultOption
        type={optionType.status}
        dataParent={dataParent}
      >
        <Text data-parent={dataParent}>{version}</Text>
      </DefaultOption>
      )}
      {!isPage && (
      <PublishOption
        items={channels}
        type={optionType.usedChannels}
        removeChannel={removeChannel}
        availableTo={availableTo}
        dataParent={dataParent}
        key={optionType.usedChannels.label}
      >
        <SettingsControlContainer data-parent={dataParent}>
          <Button2023
            handleButtonClick={enableShare(shareType.SHARE_TO_CHANNEL)}
            variant="secondaryWhite"
            height={28}
            text={t('addAChannelUpT')}
            noMargins
          />
        </SettingsControlContainer>
      </PublishOption>
      )}
      {/* {isShared && ( */}
      <ShareOption
        playlistId={currentPage.id}
        singleUserShareState={singleUserShareState}
        type={optionType.usersToSharing}
        items={Object.values(singleUserShareState || {})}
        dataParent={dataParent}
        coEditToggler={editShareStateHandler}
        key={optionType.usersToSharing.label}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        itemType={type}
      >
        <SettingsControlContainer data-parent={dataParent}>
          <Button2023
            handleButtonClick={enableShare(shareType.SHARE_TO_USER)}
            variant="secondaryWhite"
            height={28}
            text={t('addAShareUpT')}
            noMargins
          />
        </SettingsControlContainer>
      </ShareOption>
      { isShareToWeb && (
        <ShareToWebOption
          playlistId={currentPage.id}
          wrapperId={currentPage.wrapperId}
          shareState={shareState}
          type={optionType.sharingToWeb}
          itemType={type}
          dataParent={dataParent}
          setUserEmail={setUserEmail}
        />
      )}
      <DefaultOption
        type={optionType.comments}
        dataParent={dataParent}
      >
        <Text isOptionComments data-parent={dataParent}>
          {t('allowUsersToPostCommentsT')}
        </Text>
        <Toggler
          relative
          disabled
          isActive={false}
          callback={emptyCallback}
          dataParent={dataParent}
        />
      </DefaultOption>
    </MakerOptionsWrapper>
  );
};
export default Options;
