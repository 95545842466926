import React from 'react';
import SingleAvatarComponent from './SingleAvatarComponent';
import { AvatarGroupWrapper } from './styled';
import LastAvaPlaceholder from './LastAvaPlaceholder';


export const theLimit = 9;
export const AvatarGroupComponent = ({ users, isThirdRow, isUPV, isReactions, reactionText, yourId }) => {
  const isNeedToCutOff = users.length > theLimit;
  const customWidth = users.length
    ? (isReactions ? 5 : 16) + (isReactions ? 12 : 18) * Math.min(users.length, theLimit) : 0;
  return (
    <AvatarGroupWrapper
      customWidth={customWidth}
      onlyOne={users.length === 1}
      isThirdRow={isThirdRow}
      isUPV={isUPV}
    >
      {isNeedToCutOff
        ? (
          <>
            {users.slice(0, theLimit).map((user, index) => {
              return (
                <SingleAvatarComponent
                  reactionText={reactionText}
                  user={user}
                  yourId={yourId}
                  index={index}
                  key={user.email}
                  isReactions={isReactions}
                />
              );
            })}
            <LastAvaPlaceholder
              users={users.slice(theLimit, users.length)}
              index={theLimit}
              isReactions={isReactions}
            />
          </>
        )
        : users.map((user, index) => {
          return (
            <SingleAvatarComponent
              reactionText={reactionText}
              user={user}
              yourId={yourId}
              index={index}
              key={user?.email}
              isReactions={isReactions}
            />
          );
        })}
    </AvatarGroupWrapper>
  );
};
