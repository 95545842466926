import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginBackGround } from '../Login';
import {
  ButtonWrapper,
  Container,
  EmailInput,
  EyeContainer,
  ForgotWrapper,
  Logo,
  PasswordField,
  SignInReturn,
  Spinner,
  SubTitle,
  Title,
} from './ForgotView';
import { ReactComponent as EyeOn16Svg } from '../../../images/icons/eye_on.svg';
import { ReactComponent as EyeOff16Svg } from '../../../images/icons/eye_off.svg';
import { ReactComponent as SpinnerSvg } from '../../../images/icons/spinner.svg';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import { ReactComponent as LogoSvg } from '../../../images/2023/svg/Logo_black_text.svg';
import styles from '../Login.module.scss';
import LoginIntro from '../Intro';
import UI from '../../../UIComponents';

const ResetView = ({
  errorMsgPassword,
  isTouchedPassword,
  valuePassword,
  onValueChangePassword,
  onInputBlurPassword,
  onInputFocusPassword,
  onInputKeyPressPassword,
  errorMsgConfirmation,
  isTouchedConfirmation,
  valueConfirmation,
  onValueChangeConfirmation,
  onInputBlurConfirmation,
  onInputFocusConfirmation,
  onInputKeyPressConfirmation,
  generalError,
  showPassword,
  togglePasswordShow,
  onSubmit,
  toggleConfirmationShow,
  showConfirmation,
  onSignIn,
  requestSpinner,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.scroll_wrapper}>
      <div className={styles.scroll_balancer}>
        <LoginBackGround />
        <ForgotWrapper>
          <Container isResetPassword>
            <Logo isNotClickable isResetPassword>
              <LogoSvg />
            </Logo>
            <Title isResetPassword>{t('createNewPasswordT')}</Title>
            <SubTitle isResetPassword>
              {t('pleaseEnterNewPasswordT')}
            </SubTitle>
            <UI.Error error={generalError} isLogin />
            <EmailInput isResetPassword>
              <PasswordField hasError={!!errorMsgPassword && isTouchedPassword} isResetPassword>
                <input
                  type={showPassword}
                  value={valuePassword}
                  onChange={onValueChangePassword}
                  onBlur={onInputBlurPassword}
                  onFocus={onInputFocusPassword}
                  onKeyDown={onInputKeyPressPassword}
                  placeholder={t('newPasswordT')}
                />
                {!!valuePassword.length
                  && (
                    <div className={styles.eye}>
                      <EyeContainer onClick={togglePasswordShow} isDisabled={!valuePassword.length}>
                        {showPassword === 'password' && <EyeOff16Svg />}
                        {showPassword === 'text' && <EyeOn16Svg />}
                      </EyeContainer>
                    </div>
                  )}
              </PasswordField>
              <UI.Error error={errorMsgPassword} isLogin />
            </EmailInput>
            <EmailInput isResetPassword>
              <PasswordField
                hasError={!!errorMsgConfirmation && isTouchedConfirmation}
                isResetPassword
              >
                <input
                  type={showConfirmation}
                  value={valueConfirmation}
                  onChange={onValueChangeConfirmation}
                  onBlur={onInputBlurConfirmation}
                  onFocus={onInputFocusConfirmation}
                  onKeyDown={onInputKeyPressConfirmation}
                  placeholder={t('reEnterNewPasswordT')}
                />
                {!!valueConfirmation.length
                  && (
                    <div className={styles.eye}>
                      <EyeContainer onClick={toggleConfirmationShow} isDisabled={!valueConfirmation.length}>
                        {showConfirmation === 'password' && <EyeOff16Svg />}
                        {showConfirmation === 'text' && <EyeOn16Svg />}
                      </EyeContainer>
                    </div>
                  )}
              </PasswordField>
              <UI.Error error={errorMsgConfirmation} isLogin />
            </EmailInput>
            <ButtonWrapper onClick={onSubmit} isResetPassword>
              <ButtonComponent
                text={t('confirmNewPasswordT')}
                fullWidth
                height={48}
                isSignIn
              />
            </ButtonWrapper>
            {!!requestSpinner && requestSpinner !== 'init' && (
              <Spinner>
                <SpinnerSvg />
              </Spinner>
            )}
            <SignInReturn onClick={onSignIn} isForgotPassword>{t('backToSignInT')}</SignInReturn>
          </Container>
        </ForgotWrapper>
        <LoginIntro />
      </div>
    </div>
  );
};

export default ResetView;
