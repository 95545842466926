import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { sanitizeToLoad } from '../../../../utils/helpers';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { actionSwitchPage } from '../../../../redux/currentPage/action';
import AvatarPlaceholder from '../../../../components/Avatar';
import rawElemStyles from '../../MakerCardView/elements.module.scss';
import Tooltip from '../../../../shared/Tooltips/Tooltip';
import { CurrentPage } from '../../../../redux/currentPage/types';
import Image from '../../../../components/LibraryTableView/Image';
import { smartFileItemTypeCheck } from '../../../../shared/smartFile/constant';

const GreyStick = styled.div`
  width: 20px;
  height: 2px;
  background: rgba(29, 29, 29, 0.08);
  position: relative;
  top: 16px;
  right: 62px;
`;
const AvaWithStickWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  ${({ itemColor }) => `border: 2px solid ${itemColor};`} 

  img {
    display: block;
    ${({ isShareModal }) => (isShareModal
    ? `
        width: 32px;
        height: 32px;
      ` : `
        width: 28px;
        height: 28px;
      `)} 
    border-radius: 50% !important;
  }
`;

const BGImageAndTExtWrapper = styled.div`
  display: flex;

  > img {
    object-fit: cover;
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 2px;
  }
`;

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  width: 250px;
`;

const TextWithImageWrapper = styled.div`
  ${({ withImage }) => (withImage
    ? `
    ${TextWrapper} {
      padding-left:6px;
    }
      line-height: 30px;
`
    : '')}
`;


const cx = classNames.bind(rawElemStyles);
export const NameAndImageAva = ({
  avaURL,
  first_name,
  last_name,
  noStick = false,
  upvCard = false,
  itemColor,
  isOnlyAvatarsDisplay,
  calcDate,
  displayImage,
  isShareModal,
}) => {
  if (avaURL && displayImage !== 'GeneratedAvatar') {
    return (
      <AvaWithStickWrapper itemColor={itemColor} isShareModal={isShareModal}>
        <Tooltip
          text={isOnlyAvatarsDisplay ? calcDate : `${first_name} ${last_name}`}
          direction="none"
          place="avaUPVTable"
        >
          <img src={avaURL} alt="" />
        </Tooltip>

        {!noStick && <GreyStick />}
      </AvaWithStickWrapper>
    );
  }
  let abbrevation = '';
  if (first_name && first_name[0]) {
    abbrevation = first_name[0];
  }
  if (last_name && last_name[0]) {
    abbrevation += last_name[0];
  }
  if ((!avaURL || displayImage === 'GeneratedAvatar') && (first_name || last_name)) {
    return (
      <AvaWithStickWrapper
        className={upvCard ? cx('upv_card_ava_placeholder') : ''}
        itemColor={itemColor}
      >
        <Tooltip
          text={isOnlyAvatarsDisplay ? calcDate : `${first_name} ${last_name}`}
          direction="none"
          place="avaUPVTable"
        >
          <AvatarPlaceholder
            name={first_name}
            abbreviation={abbrevation}
            width={isShareModal ? 32 : 28}
          />
        </Tooltip>

        {!noStick && <GreyStick />}
      </AvaWithStickWrapper>
    );
  }

  return <></>;
};

const NameAndImage = ({ text, item, isViewer, openRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onClickHandler = (e) => {
    if (isViewer) return;
    e.stopPropagation();
    dispatch(
      actionCreator(CurrentPage.RenameEdit, { itemId: item?.id, isEdit: true }),
    );
    if (item?.textComponent?.id) {
      dispatch(actionSwitchPage({ newBlockId: item?.textComponent?.id }));
    }
  };

  const isTextBlock = smartFileItemTypeCheck.isRichText[item?.type] || item.type === 'elementText';

  const previewItem = item.libraryComponent?.type ? item.libraryComponent : item.textComponent;
  const isEmbed = item.libraryComponent?.type === 'embed_component';

  return (
    <BGImageAndTExtWrapper>
      {(!item?.libraryComponent?.isUpload || isEmbed) && (
      <div ref={openRef}>
        <Image item={previewItem} isLibrary isMaker />
      </div>
      )}
      {item?.libraryComponent?.isUpload && !isEmbed && <div className="loader_tini">{t('loadingT')}</div>}

      <TextWithImageWrapper onClick={onClickHandler} withImage>
        <TextWrapper isTextBlock={isTextBlock}>{sanitizeToLoad(text).replaceAll('\\n', ' ')}</TextWrapper>
      </TextWithImageWrapper>
    </BGImageAndTExtWrapper>
  );
};

export default NameAndImage;
