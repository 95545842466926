import styled from 'styled-components/macro';

export const MainHeaderShell = styled.div`
  display: flex;
  background-color: #f6f6f5;
  height: 66px;
  position: fixed;
  left: 0;
  position: relative;
  align-items: center;
  width: 100vw;
  padding-left: 256px;
  z-index: ${({ isSidebarHovered }) => (isSidebarHovered ? '102' : '103')};
`;

export const MainHeaderDivider = styled.div`
  height: 1px;
  width: calc(100vw - 360px);
  position: absolute;
  bottom: 0;
  left: 30px;
  background: rgba(29, 29, 29, 0.08);
`;
