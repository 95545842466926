import { actionOpenModal } from '../../redux/user/action';
import { openModalType, UiComponentTypes } from '../constants';
import { isEnd, isNotStart } from '../dateConvert';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import { actionPageWillDownload } from '../../redux/currentPage/action';

export const singleClickHandlerWrapper = ({
  event: e,
  props,
  currentPageId,
  item,
  toggleSelected,
  dispatch,
  history,
  unseenPagesManagerMap,
  noUPV,
  isNeedZoom,
}) => {
  e.stopPropagation();
  if (!noUPV) {
    const elemPosition = e.target?.getBoundingClientRect();
    dispatch(
      actionOpenModal(openModalType.UPVPreview, {
        id: item.id,
        index: props.index,
        mode: 'LIB',
        elemPosition,
        isNeedZoom,
      }),
    );
    return;
  }

  if (UiComponentTypes.component[item.type] && !props.isSimplifiedMultiselect) {
  } else if (UiComponentTypes.page[item.type] || item.type === 'sharedPage') {
    let url;

    if (item.id === currentPageId) return;

    if (props.isSimplifiedMultiselect) {
      toggleSelected(e);
    } else if (props.isChannelMiniPage) {
      url = `/libraryPage/${item.id}`;
    } else if (item.type === 'sharedPage') {
      if (isNotStart(item) || isEnd(item)) return;
      url = `/shared_page/${item.id}`;
    } else {
      url = `/libraryPage/${item.id}`;
    }

    if (unseenPagesManagerMap[item.libraryComponentId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPagesR, {
          pageId: item.libraryComponentId,
        }),
      );
    }
    if (url) {
      history.push(url);
      dispatch(actionPageWillDownload());
    }
  } else if (!props.isSimplifiedMultiselect) {
    history.push(`/libraryComponent/${item.id}`);
  }
};
