import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  OptionsWrapper,
} from '../../../../components/MiniPlaylist/NewPlaylist2023/styled';
import { THREE_DOTS_DD_OPTIONS_UPV_TABLE } from '../../../../components/MiniPlaylist/NewPlaylist2023/helpers/enums';
import PlaylistSelector from './PlaylistSelector';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import Playlist from '../../../../redux/playlists/types';
import { LibraryComponents } from '../../../../redux/library/types';
import { getItemName } from '../../../../utils/helpers';
import { ReactComponent as ArrowRightSVG } from '../../../../images/2023/svg/small/dropdown/arrow_right_6_10.svg';
import { actionShowMessage } from '../../../../redux/support/action';
import {
  Group,
  ITEM,
  MessageType,
  openModalType,
  PLAYLIST_TYPES_REVERT,
} from '../../../../utils/constants';
import { axiosAbortarium } from '../../../../utils/axiosAbortarium';
import { actionCloseModal, actionOpenModal } from '../../../../redux/user/action';
import { ServiceUser } from '../../../../redux/user/types';
import { CHANNEL_PERMISSIONS } from '../../../../utils/permissions';
import { Channels } from '../../../../redux/channels/types';
import SupportAction from '../../../../redux/support/types';
import { actionRemoveLinkPageFromPlaylist } from '../../../../redux/currentPage/action';
import { ThreeDotsDropWrapper } from './styled';


const ThreeDotsDropDownUPV = ({
  isMaker,
  isLib,
  forPreview,
  show,
  currentPage,
  play,
  refMenu,
  isWholePlaylistOperation,
  isForLib,
  isLinkOrComponent,
  isSharedToMePage,
  isTOC,
  isPlayer,
  positionDown,
  upvCard,
  isForSharedModeMaker,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [showPlaylistSelector, setShowPlaylistSelector] = useState(false);
  const { openModal } = useSelector((state) => state.user);
  const { type: typePlaylist, place, channelID = null } = useParams();
  const channels = useSelector((state) => state.channels.myChannels);

  const userID = useSelector((state) => state.user.id);
  const isPlaylistOwner = userID === currentPage?.owner?.id;
  const isPublished = currentPage?.status === 'publish';
  const isCoEdit = currentPage?.isCanCoEdit && !isPlaylistOwner;

  const isViewer = !isPlaylistOwner && !isCoEdit;
  const itemOwner = isLib ? true : userID === props?.item?.owner?.id;
  const isLocked = props?.item?.isRemixLocked;
  const isCanEdit = isCoEdit || itemOwner || isPlaylistOwner;
  const isEditable = props.item?.libraryComponent?.type === 'page'
    || props.item?.libraryComponent?.type === 'elementComponent';
  const isEditableInLibrary = props.item?.type === 'page' || props.item?.type === 'component';
  const removeEdit = (isViewer && !itemOwner && !isPlaylistOwner) || upvCard;
  const isCanRemix = itemOwner || !isLocked;
  const canUnsubscribe = typePlaylist === 'shared' && isWholePlaylistOperation;
  const type = props.item?.libraryComponent?.type;

  const canRemixMany = !!currentPage?.linkPages?.length && isWholePlaylistOperation;
  const isDownloadable = canRemixMany
    || (type && type !== 'page' && type !== 'text/html')
    || props.item?.textComponent?.id;
  const isCanBeAddToLibrary = !props.item?.textComponent?.id;

  const isCanDownload = canRemixMany || (isCanRemix && isDownloadable);
  const isCanAddToLibrary = canRemixMany || (isCanRemix && isCanBeAddToLibrary);
  const isCanDelete = itemOwner || (!channelID && isPlaylistOwner && isWholePlaylistOperation);

  const roleInChannel = channels[channelID]?.role;
  const isChannelAdmin = roleInChannel === 'admin';
  const isChannelCoAdmin = roleInChannel === 'co_admin';
  const roleOwnerPlaylist = currentPage?.owner?.roleInChannel;

  const isHasMoreRoleFromOwnerPlaylist = CHANNEL_PERMISSIONS[roleInChannel]?.whichContentCanRemove[
    roleOwnerPlaylist
  ];
  const isCanRemoveFromChannel = channelID
    && isWholePlaylistOperation
    && (isPlaylistOwner || isChannelAdmin || isHasMoreRoleFromOwnerPlaylist);
  const isShowNotActiveRemoveFromChannel = channelID
    && isWholePlaylistOperation
    && !isCanRemoveFromChannel
    && isChannelCoAdmin;

  const libraryComponentId = props.item?.libraryComponent?.id
    || props.item?.textComponent?.id
    || props.item?.id;
  const libraryComponentType = props.item?.libraryComponent?.type || props.item?.type;
  const libraryComponentTitle = props.item?.title || props.item?.libraryComponent?.title;

  const onAddItemsToLibraryClickHandler = (e) => {
    e.stopPropagation();
    if (isWholePlaylistOperation) {
      dispatch(actionCreator(Playlist.AddAllDownloadableItemsIntoLibrary));
      props.setIsActive(false);
      return;
    }
    if (
      props.item.type !== 'elementComponent'
      && props.item?.libraryComponent?.type !== 'page'
    ) return;
    props.setIsActive(false);
    dispatch(
      actionCreator(LibraryComponents.Player.DuplicateSharedComponent, {
        componentId: props.item?.libraryComponent?.id,
        linkPageId: props.item?.id,
        type: props.item?.libraryComponent?.type,
      }),
    );
    props.setIsActive(false);
  };

  const openComponent = (e) => {
    e.stopPropagation();
    props.setIsActive(false);
    if (!props.isOwner) return;
    if (props.item?.type === 'page') {
      history.push(`/libraryPage/${props.item?.id}`);
    }
  };
  const openGallery = (e) => {
    e.stopPropagation();
    props.setIsActive(false);
    if (props.item.textComponent?.id) {
      return;
    }
    if (props.item?.libraryComponent?.type === 'page') {
      history.push(`/libraryPage/${props.item?.libraryComponent?.id}`);
    } else {
      openModal && dispatch(actionCloseModal(ServiceUser.CloseModal));
      dispatch(
        actionOpenModal(openModalType.UPVPreview, { id: props.item?.id,
          index: props.index,
          mode: 'LIB' }),
      );
    }
  };

  const deleteLinkPageClickHandler = (e) => {
    e.stopPropagation();
    if (!props.isOwner && !props.isContentEditable) {
      return;
    }
    if (isWholePlaylistOperation) {
      if (isPublished) {
        return;
      }
      props.setIsActive(false);
      dispatch(
        actionOpenModal(openModalType.ConfirmModalUniversal, {
          title: t('deleteUpT'),
          subject: t('deleteThisSmartFileQuestionT'),
          description: t('deleteDescriptionT'),
          confirm: () => {
            dispatch(
              actionCreator(Playlist.updateMoveToTrash, {
                wrapperId: currentPage.wrapperId,
                state: true,
                id: currentPage.id,
                moveCallback: () => {
                  dispatch(
                    actionShowMessage({
                      type: MessageType.Regular,
                      text: t('smartFileDeletedT'),
                    }),
                  );
                  history.push(
                    `/content/smartfiles/${PLAYLIST_TYPES_REVERT[place]}`,
                  );
                },
              }),
            );
          },
          cancelText: t('cancelUpT'),
          okText: t('deleteUpT'),
        }),
      );
      return;
    }
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        props.socketId || props.playlistId,
        props.item.id,
        props.item.textComponent?.id || props.item.libraryComponent?.id,
      ),
    );
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    if (props.goNext) {
      props.goNext();
    }
  };

  const deleteLibComponent = (e) => {
    e.stopPropagation();
    if (!props.isOwner && !props.isContentEditable) {
      return;
    }
    if (props.setIsActive) {
      props.setIsActive(false);
    }
    dispatch(
      actionCreator(SupportAction.CheckIsInUse, {
        itemTitle: libraryComponentTitle,
        itemId: libraryComponentId,
      }),
    );
    if (props.goNext) {
      props.goNext();
    }
  };

  const unsubscribeHandler = (e) => {
    const unsubscribeType = 'playlist';
    e.stopPropagation();
    props.setIsActive(false);
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('removeUpT'),
        subject: t('removeThisSmartFileQuestionT'),
        description: `${t('removeFromFeedModalDesc1T')}${
          isCoEdit ? t('removeFromFeedModalDesc2T') : ''
        }`,
        confirm: () => {
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileRemovedT'),
            }),
          );
          dispatch(
            actionCreator(ServiceUser.unsubscribe, {
              type: unsubscribeType,
              id: currentPage.id,
              history,
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('removeUpT'),
      }),
    );
  };

  const removeFromChannel = (e) => {
    e.stopPropagation();
    if (isShowNotActiveRemoveFromChannel) return;
    props.setIsActive(false);
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('removeUpT'),
        subject: t('removeThisSmartFileQuestionT'),
        description: t('removeDescription1T'),
        confirm: () => {
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileRemovedT'),
            }),
          );
          dispatch(
            actionCreator(Channels.RemoveContentInSharedChannel, {
              contentId: currentPage.wrapperId,
              type: ITEM.Playlist,
              moveCallback: () => history.push(`/channel/${channelID}`),
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('removeUpT'),
      }),
    );
  };

  const downloadItemClickHandler = useCallback((e) => {
    e.stopPropagation();
    if (isWholePlaylistOperation) {
      dispatch(
        actionShowMessage({
          type: MessageType.DownloadWholePlaylist,
          itemName: 'playlist',
          mode: Group.processing,
          id: currentPage.id,
        }),
      );
      axiosAbortarium.generateNew(currentPage.id);
      dispatch(
        actionCreator(Playlist.DownloadAllDownloadablePlaylistItems, {
          playlistId: currentPage.id,
          playlistTitle: currentPage.title,
        }),
      );
      props.setIsActive(false);
      return;
    }

    dispatch(
      actionShowMessage({
        type: MessageType.DownloadOneItem,
        itemName: 'playlist',
        mode: Group.processing,
        id: libraryComponentId,
      }),
    );
    axiosAbortarium.generateNew(libraryComponentId);
    dispatch(
      actionCreator(Playlist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: libraryComponentTitle,
        elementType: libraryComponentType,
        isText: !!props.item.textComponent?.id,
        text: getItemName(props.item),
        owner: props.item.owner || { id: userID },
      }),
    );
    props.setIsActive(false);
  }, [currentPage.id, currentPage.title,
    isWholePlaylistOperation, libraryComponentId, libraryComponentTitle,
    libraryComponentType, props.item, userID]);

  useEffect(() => {}, [props.item?.id, props.item?.libraryComponent?.id]);

  if (!show) return <></>;

  if (isForLib) {
    return (
      <ThreeDotsDropWrapper
        isForLib
        forPreview={forPreview}
        data-parent="miniPage"
        ref={refMenu}
        isWholePlaylistOperation={isWholePlaylistOperation}
        isShort={isCanDelete}
      >
        <OptionsWrapper isFirst>
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.title)}
        </OptionsWrapper>
        {isEditableInLibrary && (
        <OptionsWrapper
          data-parent="miniPage"
          disabled={isSharedToMePage}
          onClick={openComponent}
        >
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.edit)}
        </OptionsWrapper>
        )}
        {(!forPreview || isEditableInLibrary) && <Divider />}

        <OptionsWrapper
          data-parent="miniPage"
          disabled={isLinkOrComponent || isSharedToMePage}
          onClick={
            isLinkOrComponent || isSharedToMePage
              ? (e) => e.stopPropagation()
              : downloadItemClickHandler
          }
        >
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.download)}
        </OptionsWrapper>
        <OptionsWrapper
          data-parent="miniPage"
          disabled={isSharedToMePage}

        >
          <ArrowRightSVG />
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.addToPlaylist)}
          {!isSharedToMePage && (
          <PlaylistSelector
            isForLib={isForLib}
            pageID={props.item?.id}
            lcID={props.item?.libraryComponent?.id}
            isWholePlaylistOperation={isWholePlaylistOperation}
            refMenu={refMenu}
          />
          )}
        </OptionsWrapper>
        {!isCanDelete && (
          <>
            <Divider />
            <OptionsWrapper
              isLast
              // disabled={!(props.isContentEditable || props.isOwner)}
              onClick={deleteLibComponent}
            >
              {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.delete)}
            </OptionsWrapper>
          </>
        )}
      </ThreeDotsDropWrapper>
    );
  }

  if (isTOC) {
    return (
      <ThreeDotsDropWrapper
        forPreview={forPreview}
        data-parent="miniPage"
        ref={refMenu}
        isWholePlaylistOperation={isWholePlaylistOperation}
        isShort={!isCanEdit}
        positionDown={positionDown}
      >
        <OptionsWrapper isFirst>
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.title)}
        </OptionsWrapper>
        <OptionsWrapper disabled={isCanDelete} data-parent="miniPage">
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.rename)}
        </OptionsWrapper>
        {isCanDelete && (
          <OptionsWrapper data-parent="miniPage" disabled>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.duplicate)}
          </OptionsWrapper>
        )}

        <OptionsWrapper
          data-parent="miniPage"
          disabled={!isCanRemix}
          onMouseEnter={
            !isCanRemix ? () => null : () => setShowPlaylistSelector(true)
          }
        >
          <ArrowRightSVG />
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.addToPlaylist)}
          <PlaylistSelector
            setIsDropDownVisible={props.setIsActive}
            pageID={props.item?.id}
            lcID={props.item?.libraryComponent?.id}
            isWholePlaylistOperation={isWholePlaylistOperation}
            refMenu={refMenu}
            show={showPlaylistSelector}
            hide={() => setShowPlaylistSelector(false)}
          />
        </OptionsWrapper>
        <OptionsWrapper
          data-parent="miniPage"
          disabled={!isCanDownload}
          onClick={
            !isCanDownload
              ? (e) => e.stopPropagation()
              : downloadItemClickHandler
          }
        >
          {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.download)}
        </OptionsWrapper>
        {isCanDelete && (
          <>
            <Divider />
            <OptionsWrapper
              isLast
              // disabled={!(props.isContentEditable || props.isOwner)}
              onClick={deleteLinkPageClickHandler}
            >
              {!isWholePlaylistOperation
                && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.remove)}
              {isWholePlaylistOperation
                && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.deletePlaylist)}
            </OptionsWrapper>
          </>
        )}
      </ThreeDotsDropWrapper>
    );
  }

  return (
    <ThreeDotsDropWrapper
      isMaker={isMaker}
      isPlayer={isPlayer}
      forPreview={forPreview}
      data-parent="miniPage"
      ref={refMenu}
      isWholePlaylistOperation={isWholePlaylistOperation}
      isShort={!isCanEdit}
      upvCard={upvCard}
    >
      <OptionsWrapper isFirst>
        {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.title)}
      </OptionsWrapper>
      {!isPlayer && (
        <>
          <OptionsWrapper data-parent="miniPage" onClick={play}>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.play)}
          </OptionsWrapper>
          {!isWholePlaylistOperation && !forPreview && (
          <OptionsWrapper data-parent="miniPage" onClick={openGallery}>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.open)}
          </OptionsWrapper>
          )}
          {(!removeEdit && isEditable && itemOwner) && (
          <OptionsWrapper
            data-parent="miniPage"
            disabled={!isCanEdit}
            onClick={isCanEdit ? props.goToItem : (e) => e.stopPropagation()}
          >
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.edit)}
          </OptionsWrapper>
          )}
          <Divider />
        </>
      )}

      <OptionsWrapper
        data-parent="miniPage"
        disabled={!isCanDownload || isForSharedModeMaker}
        onClick={
          !isCanDownload || isForSharedModeMaker ? (e) => e.stopPropagation() : downloadItemClickHandler
        }
      >
        {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.download)}
      </OptionsWrapper>
      <OptionsWrapper
        data-parent="miniPage"
        disabled={!isCanAddToLibrary || isForSharedModeMaker}
        onClick={
          !isCanAddToLibrary || isForSharedModeMaker ? () => null : onAddItemsToLibraryClickHandler
        }
      >
        {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.addToLibrary)}
      </OptionsWrapper>
      <OptionsWrapper
        data-parent="miniPage"
        disabled={!isCanRemix || isForSharedModeMaker}
        onMouseEnter={
          (!isCanRemix || isForSharedModeMaker) ? () => null : () => setShowPlaylistSelector(true)
        }
      >
        <ArrowRightSVG />
        {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.addToPlaylist)}
        {!isForSharedModeMaker && !(!isCanRemix || isForSharedModeMaker) && (
        <PlaylistSelector
          setIsDropDownVisible={props.setIsActive}
          pageID={props.item?.id}
          lcID={props.item?.libraryComponent?.id}
          isWholePlaylistOperation={isWholePlaylistOperation}
          refMenu={refMenu}
          show={showPlaylistSelector}
          hide={() => setShowPlaylistSelector(false)}
          upvCard={upvCard}
        />
        )}
      </OptionsWrapper>
      {isCanDelete && (
        <>
          <Divider />
          <OptionsWrapper
            isLast
            // disabled={!(props.isContentEditable || props.isOwner)}
            onClick={deleteLinkPageClickHandler}
          >
            {!isWholePlaylistOperation
              && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.remove)}
            {isWholePlaylistOperation
              && t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.deletePlaylist)}
          </OptionsWrapper>
        </>
      )}
      {canUnsubscribe && (
        <>
          <Divider />
          <OptionsWrapper isLast onClick={unsubscribeHandler}>
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.unsubscribe)}
          </OptionsWrapper>
        </>
      )}
      {(isCanRemoveFromChannel || isShowNotActiveRemoveFromChannel) && (
        <>
          <Divider />
          <OptionsWrapper
            disabled={isShowNotActiveRemoveFromChannel}
            isLast
            onClick={removeFromChannel}
          >
            {t(THREE_DOTS_DD_OPTIONS_UPV_TABLE.removeFromChannel)}
          </OptionsWrapper>
        </>
      )}
    </ThreeDotsDropWrapper>
  );
};

export default ThreeDotsDropDownUPV;
