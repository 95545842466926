import { Library, LibraryCollections, LibraryComponents } from './types';

export const actionSwitchLibrarySelector = (selector) => {
  return {
    type: Library.ChangeSideBarSelector,
    payload: selector,
  };
};
export const actionChangeSieveValue = (value, place) => {
  return {
    type: Library.ChangeSieveValue,
    payload: { value, place },
  };
};
export const actionAddLibraryComponent = (component) => {
  return {
    type: LibraryComponents.AddComponent,
    payload: { ...component },
  };
};
// export const actionCreateLibraryComponentFromBlocks = (
//   selectedBlocks,
//   name,
//   newLibraryComponentId,
//   tags,
//   blockId) => {
//   return {
//     type: LibraryComponents.CreateLibraryComponentFromBlocks,
//     payload: { selectedBlocks, name, newLibraryComponentId, tags, blockId },
//   };
// };
export const actionGetSharedLibraryComponent = ({
  playlistId,
  linkPageId,
  channelId,
  history,
}) => {
  return {
    type: LibraryComponents.GetSharedLibraryComponent,
    payload: { playlistId, linkPageId, channelId, history },
  };
};
export const actionToggleComponentFavorite = (
  componentId,
  collectionId,
  isFavorite,
  withCurrentPage,
  block,
  index,
) => {
  return {
    type: LibraryComponents.ToggleFavorite,
    payload: {
      componentId,
      collectionId,
      isFavorite,
      withCurrentPage,
      block,
      index,
    },
  };
};
export const actionCopyBlocksToPage = (
  pageId,
  componentsIds,
  index,
  isReplaceFirst,
  isLibraryCreate,
) => {
  return {
    type: LibraryComponents.CopyBlocksToPage,
    payload: { pageId, componentsIds, index, isReplaceFirst, isLibraryCreate },
  };
};
export const CopyBlockImageToPage = (blockId, index, libCompId) => {
  return {
    type: LibraryComponents.CopyBlockImageToPage,
    payload: { blockId, index, libCompId },
  };
};

export const CopyUploadToPage = (blockId, index, libCompId) => {
  return {
    type: LibraryComponents.CopyUploadToPage,
    payload: { blockId, index, libCompId },
  };
};


export const actionAddLibraryComponentReduxOnly = (component) => {
  return {
    type: LibraryComponents.actionAddLibraryComponentREDUX,
    payload: { ...component },
  };
};
export const actionDuplicateComponent = (
  collectionId,
  componentId,
  nextComponentId,
) => ({
  type: LibraryComponents.DuplicateComponent,
  payload: { componentId, nextComponentId, collectionId },
});
export const actionDuplicatePage = (folderId, pageId) => ({
  type: LibraryComponents.Pages.Duplicate,
  payload: { pageId, folderId },
});
export const actionDuplicateSharedComponent = (componentId, type) => ({
  type: LibraryComponents.DuplicateSharedComponent,
  payload: { componentId, type },
});
export const actionMoveComponentToTrash = (componentId, useCases) => {
  return {
    type: LibraryComponents.MoveComponentToTrash,
    payload: { componentId, useCases },
  };
};

export const actionRestoreComponentFromTrash = (componentData) => {
  return {
    type: LibraryComponents.RestoreComponentFromTrash,
    payload: componentData,
  };
};
export const actionUpdateLibraryComponent = (component, collectionId) => {
  return {
    type: LibraryComponents.UpdateLibraryComponent,
    payload: { ...component, collectionId },
  };
};

export const actionUnsubscribeManyLibraryEntity = (smartfileIdsObj, entityType) => {
  return {
    type: LibraryComponents.UnsubscribeManyLibraryEntity,
    payload: { smartfileIdsObj, entityType },
  };
};
export const actionUnsubscribeLibraryEntity = (id, entityType, itemID) => {
  return {
    type: LibraryComponents.UnsubscribeLibraryEntity,
    payload: { id, entityType, itemID },
  };
};

export const actionUpdateLibraryComponentInRedux = (component, isNotNeedUpdateDateGroup = false) => {
  return {
    type: LibraryComponents.UpdateLibraryComponentInRedux,
    payload: { ...component, isNotNeedUpdateDateGroup },
  };
};
export const actionAddComponentToDragState = (componentId, collectionId, wrapperId) => {
  return {
    type: LibraryComponents.AddComponentToDragState,
    payload: { componentId, collectionId, wrapperId },
  };
};
export const actionAddLinkPagesToDragState = (linkPages) => {
  return {
    type: LibraryComponents.AddLinkPagesToDragState,
    payload: { linkPages },
  };
};
export const actionBulkAddComponentsToDragState = (components) => {
  return {
    type: LibraryComponents.BulkAddComponentsToDragState,
    payload: components,
  };
};
export const actionClearDragState = () => {
  return {
    type: LibraryComponents.ClearDragState,
  };
};
export const actionRenameLibraryCollection = ({ id, name }) => {
  return {
    type: LibraryCollections.RenameLibraryCollection,
    payload: { id, name },
  };
};

export const actionRenameLibraryCollectionRedux = ({ id, name }) => {
  return {
    type: LibraryCollections.RenameLibraryCollectionRedux,
    payload: { id, name },
  };
};
// export const actionLoadFileToLibrary = (data) => {
//   return {
//     type: LibraryComponents.LoadFileToLibrary,
//     payload: { ...data },
//   };
// };

export const actionDeleteComponentReduxOnly = (id) => {
  return {
    type: LibraryComponents.DeleteComponentREDUX,
    payload: { id },
  };
};
export const actionDeleteComponentInCollectionReduxOnly = (id, folderId) => {
  return {
    type: LibraryComponents.DeleteComponentInCollectionREDUX,
    payload: { id, folderId },
  };
};

export const actionCreateLinkLibraryComponent = (
  urlText,
  imageDataTitle,
  respData,
  addedTags,
) => {
  return {
    type: LibraryComponents.CreateLinkLibraryComponent,
    payload: { urlText, imageDataTitle, respData, addedTags },
  };
};
export const actionCheckLinkForLibraryComponent = (
  urlText,
  setIsCreateLinkVisible,
  setLocalError,
  addedTags,
) => {
  return {
    type: LibraryComponents.CheckLinkForLibraryComponent,
    payload: { urlText, setIsCreateLinkVisible, setLocalError, addedTags },
  };
};

export const actionDetachComponent = (
  detachComponentId,
  createComponentAfterDetach,
) => {
  return {
    type: LibraryComponents.DetachComponent,
    payload: { detachComponentId, createComponentAfterDetach },
  };
};

export const actionSavePdfBlock = (
  pageId,
  relationId,
  nestedItemId,
  innerHtml,
  width,
  newHeight,
) => {
  return {
    type: LibraryComponents.SavePdfBlock,
    payload: { pageId, relationId, nestedItemId, innerHtml, width, newHeight },
  };
};

export const actionUpdatePdfRelation = (
  relationId,
  fieldsArr,
  valuesArr,
  nestedItemId,
  newInnerHtml,
  currentPageId,
  isNew,
) => {
  return {
    type: LibraryComponents.UpdatePdf,
    payload: {
      relationId,
      fieldsArr,
      valuesArr,
      nestedItemId,
      newInnerHtml,
      currentPageId,
      isNew,
    },
  };
};

export const actionAddPageIntoLibrary = (page, isAddToFavorite, isNew) => ({
  type: LibraryComponents.Pages.AddPageInLibraryRS,
  payload: { page, isAddToFavorite, isNew },
});

export const actionUpdateLibraryPageInLibraryField = (page) => ({
  type: LibraryComponents.Pages.UpdatePageInLibraryField,
  payload: { page },
});

export const actionAddLibraryPageComponent = (libraryComponent) => {
  return {
    type: LibraryComponents.Pages.AddLibraryPageComponentR,
    payload: { ...libraryComponent },
  };
};
export const actionAddLibraryPageMannyComponent = (libraryComponent) => {
  return {
    type: LibraryComponents.Pages.AddLibraryPageManyComponentR,
    payload: { ...libraryComponent },
  };
};
export const actionDeleteLibraryPageComponent = (libraryComponent) => {
  return {
    type: LibraryComponents.Pages.DeleteLibraryPageComponentR,
    payload: { ...libraryComponent },
  };
};
export const actionDeleteManyLibraryPageComponent = (
  currentPageId,
  pageComponents,
  inCollectionId,
) => {
  return {
    type: LibraryComponents.Pages.DeleteManyLibraryPageComponentR,
    payload: { currentPageId, pageComponents, inCollectionId },
  };
};
export const actionChangeManyLibraryPageComponentPosition = (
  currentPageId,
  pageComponents,
  inCollectionId,
) => {
  return {
    type: LibraryComponents.Pages.ChangeManyLibraryPageComponentPositionR,
    payload: { currentPageId, pageComponents, inCollectionId },
  };
};

export const actionSaveShareStateLibraryPage = (state, field) => {
  return {
    type: LibraryComponents.Pages.SaveShareStateLibraryPage,
    payload: { state, field },
  };
};


export const actionUpdateLibraryPageDescriptionShow = (
  libraryPage,
  inFolder,
) => ({
  type: LibraryComponents.Pages.updateLibraryPageDescription,
  payload: { libraryPage, inFolder },
});

export const actionToggleUploadingFilesState = (isUploading) => ({
  type: LibraryComponents.ToggleUploadingFilesState,
  payload: { isUploading },
});
