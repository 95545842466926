import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './index.module.scss';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';
import { actionLogout, actionOpenModal } from '../../redux/user/action';
import { ReactComponent as ChevronUpSvg } from '../../images/icons/chevron_up_12.svg';
import { openModalType } from '../../utils/constants';

const cn = classNames.bind(rawStyles);

const SelectorItem = ({ last, active, onClick, disabled, isHeader, isSubmenu, ...props }) => {
  return (
    <div className={cn('selector-wrapper', { isSubmenu })}>
      <div
        className={cn('selector', { last, active, disabled })}
        onClick={onClick}
      >
        <div className={cn('text', { disabled, isHeader })}>{props.children}</div>
      </div>
    </div>
  );
};

const SettingsSideBar = ({ setActiveSection, activeSection }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isProfileCollapsed, setProfileCollapsed] = useState(true);
  const [isPreferencesCollapsed, setPreferencesCollapsed] = useState(false);

  const redirectTo = (place) => {
    if (place === activeSection) return;
    if (
      place !== 'profile'
      && place !== 'preferences'
      && place !== 'shortcuts'
      && place !== 'upgrade'
      && place !== 'activity'
      // && place !== 'language'
      && place !== 'emojis'
    ) {
      return;
    }
    if (history.location.pathname.includes(place)) return;
    dispatch(actionCreator(Settings.LoadingData));
    setActiveSection(place);
  };

  const onLogoutClick = () => {
    dispatch(
      actionOpenModal(openModalType.ConfirmModal, {
        subject: t('leavingT'),
        description: t('signOutQuestionT'),
        confirm: () => {
          dispatch(actionLogout());
          history.push('/logout');
        },
        cancelText: t('cancelUpT'),
        okText: t('signOutUpT'),
        isSignOut: true,
      }),
    );
  };
  return (
    <div className={cn('side_bar_wrapper')}>
      <div className={cn('title')}>{t('accoutSettingsT')}</div>
      <SelectorItem
        onClick={() => setProfileCollapsed(!isProfileCollapsed)}
        isHeader
      >
        {t('profileT')}
        <ChevronUpSvg className={cn('chevron', { chevronOpen: isProfileCollapsed })} />
      </SelectorItem>
      <div className={cn('submenu-items',
        { anyListIsHidden: !isProfileCollapsed, profileIsVisible: isProfileCollapsed })}
      >
        <SelectorItem
          active={activeSection === 'profile'}
          onClick={(e) => redirectTo('profile', e)}
          isSubmenu
        >
          {t('infosT')}
        </SelectorItem>
        <SelectorItem
          active={activeSection === 'activity'}
          onClick={(e) => redirectTo('activity', e)}
          isSubmenu
        >
          {t('channelsT')}
        </SelectorItem>
      </div>
      <SelectorItem
        onClick={() => setPreferencesCollapsed(!isPreferencesCollapsed)}
        isHeader
      >
        {t('preferencesT')}
        <ChevronUpSvg className={cn('chevron', { chevronOpen: isPreferencesCollapsed })} />
      </SelectorItem>
      <div className={cn('submenu-items',
        { anyListIsHidden: !isPreferencesCollapsed, preferencesIsVisible: isPreferencesCollapsed })}
      >
        <SelectorItem
          active={activeSection === 'preferences'}
          onClick={(e) => redirectTo('preferences', e)}
          isSubmenu
        >
          {t('generalT')}
        </SelectorItem>
        {/* <SelectorItem
          active={activeSection === 'language'}
          onClick={(e) => redirectTo('language', e)}
          isSubmenu
        >
          {t('languageT')}
        </SelectorItem> */}
        <SelectorItem
          active={activeSection === 'emojis'}
          onClick={(e) => redirectTo('emojis', e)}
          isSubmenu
        >
          {t('emojisT')}
        </SelectorItem>
      </div>
      <SelectorItem
        active={activeSection === 'notification'}
        onClick={(e) => redirectTo('notification', e)}
        disabled
      >
        {t('notificationsT')}
      </SelectorItem>
      <SelectorItem
        active={activeSection === 'upgrade'}
        onClick={(e) => redirectTo('upgrade', e)}
      >
        {t('upgradeT')}
      </SelectorItem>
      <SelectorItem
        active={activeSection === 'shortcuts'}
        onClick={(e) => redirectTo('shortcuts', e)}
      >
        {t('shortcutsT')}
      </SelectorItem>
      <SelectorItem onClick={onLogoutClick} last>
        <div className={cn('with_icon_wrapper')}>
          {t('logoutT')}
        </div>
      </SelectorItem>
    </div>
  );
};

export default SettingsSideBar;
