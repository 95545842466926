import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../images/icons/icon_20/plus2.svg';

import { ReactComponent as ChevronDownSvg } from '../../../images/icons/chevron_down_12.svg';
import { ReactComponent as SFIcon } from '../../../images/2023/svg/small/left-main-menu/fill_icons/smartfiles.svg';
import { MainNavigate } from '../../../navigate/navigate';
import {
  Counter,
  IconPlace,
  Info,
  SectionChevronIconWrapper,
  MajorSelector,
  SelectorWrapper,
  MajorText,
  Selector,
  CollapsingSectionWrapper, PlusWrapperCircle,
} from '../styled';
import { DATA_PARENTS } from '../../../utils/constants.tsx';
import { convertToSmartNumbers, calcSelectorForSmartfiles } from '../../../utils/helpers';
import { sortOptionChooser } from '../../../utils/sort/sortTypeChooser';
import { actionSwitchContentSortType } from '../../../redux/content/actions';
import Tooltip from '../../../shared/Tooltips/Tooltip';


/* The function has no use after implementing LanguageContext
const calculateDisplayedSelector = (itemType) => {
  if (itemType === 'shared') return 'Shared To Me';
  if (itemType === 'sharedByMe') return sharedByMe;
  if (itemType === 'recently_viewed') return recently_viewed;
  return itemType;
};
*/

const SmartfilesSection = ({
  createNewPlaylist,
  sortOptions,
  isQuickAccess,
}) => {
  const sieveOptions = isQuickAccess ? MainNavigate.smartfiles?.selectorsOrder.slice(0, 2)
    : MainNavigate.smartfiles?.selectorsOrder.slice(2, 4);
  const itemHeight = 33;
  const smartItemsHeight = `${(sieveOptions.length + 1) * itemHeight}px`;

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const [dragOverItem, setDragOverItem] = useState(null);
  const [isSmartfilesCollapsed, setSmartfilesCollapsed] = useState(!isQuickAccess);
  const isContent = history.location.pathname.startsWith('/content');

  const {
    activeNavSlider: place = 'smartfiles',
    selectorType,
    counters,
  } = useSelector((state) => state.content);

  const convert = (itemType, customPlace) => {
    if (counters[`${customPlace ?? place}/${itemType}`]) {
      if (itemType === 'sharedByMe') {
        return counters[`${customPlace ?? place}/${itemType}`] + counters[`${customPlace ?? place}/shared`];
      }
      return counters[`${customPlace ?? place}/${itemType}`];
    }
    return 0;
  };


  const setSieve = (value) => {
    const sortName = sortOptionChooser('smartfiles', value);
    if (sortName && sortOptions[sortName]) {
      dispatch(actionSwitchContentSortType(sortOptions[sortName]));
    }
    history.push(`/content/smartfiles/${value}`);
  };

  const activeSFOption = sieveOptions.find(type => history
    .location.pathname.includes(`smartfiles/${calcSelectorForSmartfiles(type)}`));

  const isShowPlaylistField = isSmartfilesCollapsed && isContent && activeSFOption;

  const onDragOver = (e, currentHover) => {
    e.stopPropagation();
    e.preventDefault();
    if (!dragOverItem) setDragOverItem(currentHover);
    e.dataTransfer.dropEffect = 'none';
  };

  const onDragEnd = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOverItem(null);
  };

  const handleClickTitle = () => {
    if (!isQuickAccess) {
      setSmartfilesCollapsed(!isSmartfilesCollapsed);
    }
  };
  return (
    <>
      {!isQuickAccess && (
        <MajorSelector
          isMargin={!isQuickAccess}
          onClick={handleClickTitle}
          isCollapsed={isSmartfilesCollapsed}
        >
          <SelectorWrapper>
            <SectionChevronIconWrapper
              isCollapsed={isSmartfilesCollapsed}
            >
              <ChevronDownSvg className="chevron" />
              <SFIcon className="icon" />
            </SectionChevronIconWrapper>
            <MajorText>{t('smartfilesT')}</MajorText>
          </SelectorWrapper>
          <Tooltip
            text="Add"
            direction="down"
            place="sidebarPlus"
          >
            <PlusWrapperCircle onClick={createNewPlaylist}>
              <PlusIcon />
            </PlusWrapperCircle>
          </Tooltip>
        </MajorSelector>
      )}
      <CollapsingSectionWrapper
        isCollapsed={isSmartfilesCollapsed}
        height={smartItemsHeight}
        time="0.3"
      >
        {sieveOptions.map((itemType) => (
          <Selector
            data-cy={itemType}
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
            key={`${itemType}`}
            active={history.location.pathname.includes(`smartfiles/${calcSelectorForSmartfiles(itemType)}`)}
            onClick={() => {
              setSieve(itemType);
            }}
            isPlusDisabled
            isDragOver={dragOverItem === itemType}
            onDragOver={(e) => onDragOver(e, itemType)}
            onDragLeave={onDragEnd}
            isMain={isQuickAccess}
          >
            <Info>
              <IconPlace className="fill-icon-hover">
                {MainNavigate.smartfiles.sidebarSelectors[itemType].icon}
                {t(MainNavigate.smartfiles.sidebarSelectors[itemType].textID)}
              </IconPlace>
            </Info>
            <Counter
              active={
                (isContent && itemType === selectorType)
              }
              isHidden={!history.location.pathname.includes(`smartfiles/${calcSelectorForSmartfiles(itemType)}`)}
            >
              {convertToSmartNumbers(convert(itemType, 'smartfiles')) || '??'}
            </Counter>
          </Selector>
        ))}
      </CollapsingSectionWrapper>
    </>
  );
};

export default SmartfilesSection;
