import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as UploadSvg } from '../../images/2023/svg/small/Upload_Svg_18_14.svg';
import { ReactComponent as EditSvg } from '../../images/2023/svg/small/upv/edit_13_14.svg';
import { ReactComponent as DownloadArrow } from '../../images/2023/svg/preview/download_16_20.svg';
import { ReactComponent as Share } from '../../images/icons/batchActions/share_all.svg';
import { ReactComponent as VisitLinkSVG } from '../../images/2023/svg/preview/visit_link_22_22.svg';

const Btn = styled.button`
  @keyframes ellipsis {
    to {
      width: 15px;
    }
  }

  display: block;
  width: max-content;
  width: ${(props) => `${props.width}px;`};
  ${(props) => (props.withMinWidth ? `min-width: ${props.width}px;` : '')};
  height: ${(props) => `${props.height}px;`};
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.05em;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 #7a69691a;
  border-radius: 6px;
  margin-right: 12px;
  margin-left: 12px;
  text-transform: uppercase;
  ${({ isDisabled }) => (isDisabled
    ? `
    opacity:20%;
    cursor: no-drop;
    pointer-events: none;

  `
    : '')}
  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return `
          color: #484343;
          background-color: #FFFFFF;
          border: none;
          box-shadow: none`;
      case 'third':
        return `
          color: #534B4F;
          background-color: #FFFFFF;
          border: 0.6px solid #ECE2E2;
            &:hover {
              color:#484343;
              border: 0.6px solid #FFC72C;
              // background-color:#FFEEBF;
          }`;
      case 'secondarySharedPlaylistDisabled':
        return `
          border: 0.6px solid rgba(31, 174, 9, 0.70);
          background: rgba(31, 174, 9, 0.50);
          box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
          cursor: no-drop;
          width: 111px;
          color: #fff;
          &:after {
            overflow: hidden;
            vertical-align: bottom;  
            animation: ellipsis steps(4,end) 1s infinite;
            content: "\\2026";
            width: 0px;
          }`;
      case 'secondaryFitContent':
        return `width: fit-content;
        color: #FFFFFF;
          background-color: #534B4F;
          border: 0.6px solid transparent;
          &:hover {
              color:#FFC72C;
              border: 0.6px solid #FFC72C;
          }
        `;
      case 'secondaryWhite':
        return `color: #484343;
          background-color: #FFFFFF;
          border: 0.6px solid #ECE2E2;
          font-family: RobotoFlex, sans-serif;
          &:hover {
            color:#484343;
            border: 0.6px solid #FFC72C;
            background-color:#FFEEBF;
          }

          &:active {
            background: #FFE9AD;
          }
        `;
      case 'secondaryWhiteAuto':
        return `color: #484343;
          background-color: #FFFFFF;
          padding: 0 9px;
          border: 0.6px solid #ECE2E2;
          font-family: RobotoFlex, sans-serif;
          &:hover {
            color:#484343;
            border: 0.6px solid #FFC72C;
            background-color:#FFEEBF;
          }

          &:active {
            background: #FFE9AD;
          }
        `;
      case 'secondaryGradientGreenSquare':
        return `color: #D32F2F;
          border: 0.6px solid #D32F2F;
          background: #FFF;

          &:hover {
            background: #FEE;
          }

        `;
      case 'gradientGreenSquare':
        return `color: #FFF;
          background: linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
          border: none;
          padding: 0 10px;

          &:hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%,
             rgba(255, 255, 255, 0.15) 100%),
             linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
          }
        `;
      case 'gradientGreen':
        return `color: #FFF;
          width: 140px;
          border-radius: 22px;
          border: none;
          background: linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
          box-shadow: 0px 1px 4px 0px rgba(217, 0, 0, 0.15);

          font-family: RobotoFlex, sans-serif;
          &:hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%,
             rgba(255, 255, 255, 0.15) 100%),
             linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
          }

          &:active {
            background: #FFE9AD;
          }
        `;
      case 'transparentGreen':
        return `
          width: 156px;
          height: 32px;
          border-radius: 6px;
          border: 0.6px solid #1FAE09;
          background: #F5FFF3;
          margin-right: 19px;
          box-shadow: 0px 1px 4px 0px rgba(122, 105, 105, 0.10);
          color: #1FAE09;
        `;
      default:
        return `
          color: #FFF;
          background-color: #1FAE09;
          border: 0.6px solid #1FAE09;
          &:hover {
            border: 0.6px solid #41C82C;
            background: #41C82C;
          }`;
    }
  }};
  ${({ paddingLeft }) => (paddingLeft
    ? `
    padding-left:${paddingLeft}px;
  `
    : '')}
  ${({ maxWidth }) => (maxWidth
    ? `
    white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width:${maxWidth}px;`
    : '')}
  ${({ isActive }) => (isActive
    ? `
  background: #FFEEBF;
  border: 0.6px solid #FFC72C;`
    : '')}
  ${({ height }) => (height === 28
    ? `
    margin-right: -3px;
  `
    : '')}
  ${({ noMargins }) => (noMargins
    ? `
  margin: 0px 0px 0px 0px;
  `
  : '')}
    ${({ isShare }) => (isShare
    ? `
      margin: 0;
      border-radius: 40px;
      width: 180px;
      font-size: 16px;
      text-transform: none;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
  `
    : '')}
  ${({ isCut }) => (isCut
    ? `
      margin-right: 0;
      border-radius: 40px 0 0 40px;
      font-size: 16px;
      width: 144px;
  `
    : '')}
`;

export const IconTypesBtn2023 = {
  upload: 'upload',
  edit: 'edit',
  previewDownload: 'previewDownload',
  visitLink: 'visitLink',
  shareSF: 'shareSF',
};

const IcoWrapper = styled.div`
  display: block;
  position: absolute;
  left: 9px;
  top: 8px;
  ${({ height }) => (height === 28
    ? `
    top: 6px;
  `
    : '')}
  ${({ IcoType }) => (IcoType === IconTypesBtn2023.previewDownload
    ? `
    left: 11px;
    top: 6px;
  `
    : IcoType === IconTypesBtn2023.visitLink
      ? `
    left: 7px;
    top: 5px;
  `
      : '')}
}
${({ isShare }) => (isShare
  ? `
    position: static;
    ` : '')}
& svg path {
  fill: ${(props) => props.IcoColor}
}
`;

const getIcon = (type) => {
  switch (type) {
    case IconTypesBtn2023.upload:
      return <UploadSvg />;
    case IconTypesBtn2023.edit:
      return <EditSvg />;
    case IconTypesBtn2023.previewDownload:
      return <DownloadArrow />;
    case IconTypesBtn2023.shareSF:
      return <Share />;
    case IconTypesBtn2023.visitLink:
      return <VisitLinkSVG />;
  }
};
const Button2023 = React.memo(function Button2023({
  children,
  text,
  width,
  height,
  derivedParentData,
  IcoType,
  IcoColor,
  variant,
  handleButtonClick,
  dataCy,
  maxWidth,
  isDisabled = false,
  noMargins = false,
  paddingLeft,
  isActive,
  isCut,
  isShare,
}) {
  const memoHandleButtonClick = useCallback((e) => {
    if (isDisabled) return;
    handleButtonClick(e);
  }, [handleButtonClick, isDisabled,
  ]);

  if (IcoType) {
    return (
      <Btn
        maxWidth={maxWidth}
        paddingLeft={paddingLeft}
        data-cy={dataCy}
        data-parent={derivedParentData}
        width={width}
        height={height}
        variant={variant}
        onClick={memoHandleButtonClick}
        isDisabled={isDisabled}
        noMargins={noMargins}
        isActive={isActive}
        isCut={isCut}
        isShare={isShare}
      >
        <IcoWrapper IcoColor={IcoColor} IcoType={IcoType} height={height} isShare={isShare}>
          {getIcon(IcoType)}
        </IcoWrapper>
        {text}
      </Btn>
    );
  }
  return (
    <>
      <Btn
        maxWidth={maxWidth}
        paddingLeft={paddingLeft}
        data-cy={dataCy}
        variant={variant}
        width={width}
        height={height}
        onClick={memoHandleButtonClick}
        isDisabled={isDisabled}
        noMargins={noMargins}
        isActive={isActive}
        data-parent={derivedParentData}
        isCut={isCut}
        isShare={isShare}
      >
        {text}
      </Btn>
      {children}
    </>
  );
});

export default Button2023;
