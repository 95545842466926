import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from 'draft-js';
import { all, delay, select } from '@redux-saga/core/effects';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import axios from 'axios';
import {
  actionChangeStateButtonREDUX,
  actionCurrentEditTitle, actionSetGlobalShareStateToSingleUserState, actionSetSingleUserShareState,
  actionSwitchPage,
  actionTryPublishPlaylist,
  actionUpdateBlock,
  actionUpdateLayers,
  actionUpdatePlaylistLastModified,
  actionUpdateShareState,
} from '../redux/currentPage/action';
import {
  requestCreateComponent,
  requestCreateContactContact,
  requestCreateLibraryComponentPlacement,
  requestDeleteComponent,
  requestDeleteLibraryComponent,
  requestGetLibComponent,
  requestGetSomeUserContent,
  requestSendEmailToSharing,
  requestSendEmailToSharingMany,
  requestUpdateComponent,
  requestUpdateLibraryComponent,
  requestUpdatePlaylist,
  requestUpdateSingleUserSharePageOptions,
  requestUpdateUserPlacement,
} from '../utils/request';
import { EditPage } from '../redux/pages/types';
import {
  CurrentPage,
  GET_CURRENT_PAGE,
  GO_TO_VIEW_PAGE,
} from '../redux/currentPage/types';
import {
  APIGetPageSharing,
  BlockTypes,
  colorsApprovedButton,
  DEFAULT_PAGE_PATH,
  DEFAULT_POSITION_STEP,
  MessageType,
  TYPE,
  UiComponentTypes,
} from '../utils/constants';
import {
  calculateIndex,
  contentImageTemplate,
  innerHtmlApproveButtonTemplate,
  innerHtmlDropboxEmbedTemplate,
  innerHtmlGoogleEmbedTemplate,
  innerHtmlLineSeparatorTemplate,
  innerHtmlPageLink,
  innerHtmlWebSiteTemplate,
  innerHtmlWebTextTemplate,
  parseContentForUsage,
  replaceDoubleQuotes,
  sanitizeToLoad,
  sanitizeToSave,
  stringifyInnerHtml,
  stringifyStateToContent,
} from '../utils/helpers';
import {
  calculateUpdatePlaylistDurationTime,
  getBlocks,
  getBlocksPlaylist,
  getCurrentPage,
  getDraggableBlocks,
  getLessons,
  getLibraryComponent,
  getPages,
  getSelectedPage,
  getSupportBlocks,
  getUser,
  sendEventUpdate,
  showErrorMessage,
} from './sagasUtils';

import {
  actionGetMiniPage,
  actionSaveMiniPage,
} from '../redux/miniPages/action';
import { MiniPages } from '../redux/miniPages/types';
import {
  actionJumpTo,
  actionSaveComponentDescriptionData,
  actionShowMessage,
  actionUpdateSupportBlocks,
  actionUpdateSupportLayers,
} from '../redux/support/action';
import { DefaultDraftBlockRenderMap1 } from '../utils/draftConstants';
import SupportAction from '../redux/support/types';
import draftDataConvertToLoad from '../utils/draftDataConvert';
import {
  actionAddLibraryPageComponent,
  actionAddLibraryPageMannyComponent,
  actionChangeManyLibraryPageComponentPosition,
  actionDeleteLibraryPageComponent,
  actionDeleteManyLibraryPageComponent,
} from '../redux/library/actions';
import ActionDraggable from '../redux/dragable/action';
import { LibraryComponents } from '../redux/library/types';
import downloadStatus from '../utils/dataUtil';
import { toggleAllBlocksType } from '../utils/draftJsHelpers';
import { actionRemoveAllSelected } from '../redux/selectedPage/action';
import { sendMessage } from './SocketCluster/action';
import { ContentActionType } from '../redux/content/contentTypes';
import Contacts from '../redux/contacts/types';
import {
  createAddComponentPlaylistContent,
  createAddManyComponentsBody,
  createChangeComponentTypeBody,
  createUpdateComponentBody,
  generateQueryWithBlockStateToAddToPageNew,
  queryCreateContact,
} from '../utils/query/helper';
import { placementEnum } from '../utils/query/queryEnum';
import { DateRangeMark4SharedPlaylist } from '../components/DatePicker/helpers/enum';
import { FileUpload } from '../redux/filesUpload/types';
import { OutboxAction } from '../redux/outbox/types';
import { getLayersForPage } from '../shared/page/utils';
import { setServiceWorkerSharedJwt } from '../utils/registerServiceWorker';

const actionCreator = (type, value, place) => ({
  type,
  payload: { ...value },
  place,
});

const rawContent = (type, content) => {
  const text = content ?? '';
  let blocks;
  if (
    type === BlockTypes.unordered_list_item
    || type === BlockTypes.ordered_list_item
  ) {
    blocks = text.split(/\n/).reduce((acc, i) => {
      acc.push({
        text: i,
        type,
        entityRanges: [{ offset: 0, length: 0, key: 'first' }],
      });
      return acc;
    }, []);
  } else {
    blocks = [
      {
        text,
        type,
        entityRanges: [{ offset: 0, length: 0, key: 'first' }],
      },
    ];
  }
  return {
    blocks,
    entityMap: {
      first: {
        type,
        mutability: 'MUTABLE',
      },
    },
  };
};

export const blocks = (type = BlockTypes.text, content) => convertFromRaw(rawContent(type, content));

function* changeTypeBlock(action) {
  try {
    const {
      payload: { id, type, playlistField },
    } = action;
    let editableBlocks;
    if (!playlistField) {
      editableBlocks = yield select(getBlocks);
    } else {
      const editableBlocks1 = yield select(getBlocksPlaylist);
      editableBlocks = editableBlocks1[playlistField];
    }
    const newEditableBlocks = [...editableBlocks];
    newEditableBlocks[id] = { ...newEditableBlocks[id], type };

    yield put(actionUpdateLayers(getLayersForPage(newEditableBlocks, playlistField)));

    if (!playlistField) {
      yield put(actionSwitchPage({ blocks: newEditableBlocks }));
    } else {
      const old = yield select(getBlocksPlaylist);
      old[playlistField] = newEditableBlocks;
      yield put(actionSwitchPage({ additionalInformation: old }));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}


// TODO check usage
function* goToViewPage(action) {
  try {
    const {
      payload: { idViewPage, history, indexViewLesson, playlistId, isPreview },
    } = action;
    const currentPage = yield select(getCurrentPage);
    const lessons = yield select(getLessons) || [];
    // yield put(actionWipeCurrentPageData());
    yield put(actionSwitchPage({ indexViewLesson }));

    if (idViewPage) {
      history.push(
        `/${
          isPreview ? 'preview_pages' : 'read_pages'
        }/${playlistId}/${indexViewLesson}`,
      );
    }

    const linkPage = lessons[indexViewLesson];
    const currentIdViewPage = linkPage?.page && linkPage?.page[0]?.id;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const { data } = yield requestGetLibComponent([currentIdViewPage]);
    const parentTitle = currentPage.parentTitle;
    const uploadedContent = data.Page && data.Page[0];
    let libraryCompBlocks = [];
    if (
      uploadedContent.hasLibraryComponent
      && !!uploadedContent.hasLibraryComponent.length
      && !!uploadedContent.libraryComponents[0]
    ) {
      let componentsFromLibComponent = [];
      libraryCompBlocks = uploadedContent.hasLibraryComponent
        .filter((item) => !!item?.hasLibraryComponent.length)
        .map((item) => {
          const libraryComponent = item;
          if (!libraryComponent?.hasLibraryComponent.length) return;

          const firstElement = libraryComponent.hasLibraryComponent[0];
          if (firstElement.type === 'application/pdf') {
            componentsFromLibComponent = [
              {
                id: libraryComponent.id,
                type: BlockTypes.pdf,
                state: {
                  data: {
                    nestedItemId: firstElement.id,
                    width: libraryComponent.width,
                    representationState: libraryComponent.representationState,
                    title: libraryComponent.hasLibraryComponent[0].title,
                    size: libraryComponent.hasLibraryComponent[0].size,
                    urlFile: libraryComponent.hasLibraryComponent[0].urlFile,
                  },
                },
              },
            ];
          } else {
            const currentLibComponentComponents = libraryComponent.hasLibraryComponent[0].components.map(
              (component) => {
                const content = {};
                if (component.type === BlockTypes.page) {
                  content.title = (component.page
                        && component.page[0]
                        && sanitizeToLoad(component.page[0].title))
                      || 'The functional logic has been updated.';
                  content.nestedItemId = component.page
                      && component.page[0]
                      && component.page[0].id;
                } else if (
                  component.type === BlockTypes.approveButton
                    || component.type === BlockTypes.actionButton
                    || component.type === BlockTypes.shortText
                ) {
                  content.state = JSON.parse(
                    sanitizeToLoad(component.content),
                  );
                  // } else if (item.type === 'webSite') {
                } else if (
                  component.type === BlockTypes.webSite
                    || component.type === BlockTypes.lineSeparator
                ) {
                  const tmpContent = parseContentForUsage(component.content);
                  content.state = tmpContent || '';
                } else {
                  content.state = draftDataConvertToLoad(component.content);
                }

                return {
                  id: component.id,
                  type: component.type,
                  innerHtml: component.innerHtml,
                  position: component.position,
                  isHidden: component.isHidden,
                  width: component.width,
                  ...content,
                };
              },
            );

            componentsFromLibComponent = [
              {
                id: libraryComponent.id,
                type: 'libComponent',
                state: {
                  data: {
                    nestedItemId: firstElement.id,
                    width: libraryComponent.width,
                    representationState: libraryComponent.representationState,
                    title: libraryComponent.hasLibraryComponent[0].title,
                    size: libraryComponent.hasLibraryComponent[0].size,
                    urlFile: libraryComponent.hasLibraryComponent[0].urlFile,
                  },
                },
                components: currentLibComponentComponents,
              },
            ];
          }
          return {
            position: item.position,
            id: item.id,
            type:
              item.hasLibraryComponent[0].type === 'application/pdf'
                ? item.hasLibraryComponent[0].type
                : 'libComponent',
            contentType: item.hasLibraryComponent[0].type,
            isHidden: item.isHidden,
            nestedItemId: libraryComponent.hasLibraryComponent[0].id,
            ...componentsFromLibComponent[0],
          };
        });
    }
    let localBlocks = [];
    if (uploadedContent) {
      const {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isPublish,
        isReused,
        description,
      } = uploadedContent;
      const shareState = {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isPublish,
        isReused,
      };
      const { id, title, movedToTrash } = uploadedContent;
      yield put(
        actionCurrentEditTitle(sanitizeToLoad(title)),
        null,
        null,
        lastModifiedDate,
      );

      if (uploadedContent.components && !!uploadedContent.components.length) {
        localBlocks = uploadedContent.components.map((item) => {
          const content = {};
          if (item.type === BlockTypes.page) {
            content.title = (item.page
                && item.page[0]
                && sanitizeToLoad(item.page[0].title))
              || 'The functional logic has been updated.';
            content.nestedItemId = item.page && item.page[0] && item.page[0].id;
          } else if (
            item.type === BlockTypes.approveButton
            || item.type === BlockTypes.actionButton
            || item.type === BlockTypes.shortText
          ) {
            content.state = JSON.parse(sanitizeToLoad(item.content));
          } else if (item.type === BlockTypes.webSite) {
            content.state = parseContentForUsage(item.content);
          } else if (item.type === BlockTypes.lineSeparator) {
            content.state = parseContentForUsage(item.content);
          } else if (item.type === BlockTypes.webSite) {
            if (item.content) {
              content.state = parseContentForUsage(item.content);
            } else {
              const sourceComponent = item.libraryComponents[0];
              content.state = {
                data: {
                  url: sourceComponent.linkUrl,
                  title: sourceComponent.title,
                  description: sanitizeToLoad(sourceComponent.description),
                  images: [sourceComponent.urlFile],
                },
              };
            }
          } else if (item.type === 'application/pdf') {
            const sourceComponent = item.libraryComponents[0];
            content.type = 'component';
            content.contentType = 'application/pdf';
            content.components = [
              {
                type: BlockTypes.pdf,
                state: {
                  data: {
                    nestedItemId: sourceComponent.id,
                    width: sourceComponent.width || 700,
                    representationState: 'attachment',
                    title: sourceComponent.title,
                    size: sourceComponent.size || 700,
                    urlFile: sourceComponent.urlFile,
                  },
                },
              },
            ];
          } else if (item.type === 'component') {
            content.nestedItemId = item.libraryComponents[0]?.id;
            content.components = item.libraryComponents[0].components.map(
              (component) => {
                const localContent = {};
                if (component.type === BlockTypes.page) {
                  const [libraryPage] = component.libraryComponents;
                  localContent.title = (libraryPage && sanitizeToLoad(libraryPage.title))
                    || 'The functional logic has been updated.';
                  localContent.nestedItemId = libraryPage && libraryPage.id;
                } else if (
                  component.type === BlockTypes.approveButton
                  || component.type === BlockTypes.actionButton
                  || component.type === BlockTypes.shortText
                ) {
                  localContent.state = JSON.parse(
                    sanitizeToLoad(component.content),
                  );
                } else if (
                  component.type === BlockTypes.webSite
                  || component.type === BlockTypes.lineSeparator
                ) {
                  const tmpContent = parseContentForUsage(component.content);
                  localContent.state = tmpContent || '';
                } else {
                  localContent.state = draftDataConvertToLoad(
                    component.content,
                  );
                }
                return {
                  id: component.id,
                  type: component.type,
                  innerHtml: component.innerHtml,
                  position: component.position,
                  isHidden: component.isHidden,
                  width: component.width,
                  ...localContent,
                };
              },
            );
          } else if (
            (item.type === BlockTypes.image
              || item.type === BlockTypes.video)
            && item.libraryComponents[0]
          ) {
            // content.state = draftDataConvertToLoad(item.content);
            //
            content.urlFile = item.libraryComponents[0].urlFile;
            content.urlSmallImage = item.libraryComponents[0].urlSmallImage;
            content.urlVerySmallImage = item.libraryComponents[0].urlVerySmallImage;
            content.state = draftDataConvertToLoad(
              contentImageTemplate(
                item.libraryComponents[0].urlSmallImage
                  || item.libraryComponents[0].urlFile,
              ),
            );
          } else {
            content.state = draftDataConvertToLoad(item.content);
          }
          return {
            id: item.id,
            type: item.type,
            position: item.position,
            isHidden: item.isHidden,
            ...content,
          };
        });
      }

      const resultBlocks = [...localBlocks, ...libraryCompBlocks].sort(
        (a, b) => a.position - b.position,
      );
      const layers = getLayersForPage(resultBlocks);

      yield put(
        actionSwitchPage({
          id,
          title: sanitizeToLoad(title),
          layers,
          type: TYPE.PAGE,
          parentTitle: sanitizeToLoad(parentTitle),
          movedToTrash,
          shareState,
          description: sanitizeToLoad(description),
          indexViewLesson,
          blocks: resultBlocks,
        }),
      );

      yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
      if (!linkPage.isRead && !isPreview) {
        const { playlistId } = yield select(getCurrentPage);
        yield call(
          requestUpdateUserPlacement(placementEnum.queryReadLinkPages),
          {
            linkPageId: linkPage.id,
            playlistId,
          },
        );
        // yield put(actionReadLesson(indexViewLesson - 1));
      }
      yield put(
        actionSwitchPage({
          description: sanitizeToLoad(description),
        }),
      );
    } else {
      yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* dragManyBlockRedux(action) {
  try {
    const {
      payload: { dragHandlerId },
    } = action;
    const editableBlocks = yield select(getSupportBlocks);
    const draggableBlocks = yield select(getDraggableBlocks);

    let blockState;
    let movedBlockUpdatePosition;
    if (dragHandlerId === 'last') {
      const previousPosition = editableBlocks[editableBlocks.length - 1]?.position
        || DEFAULT_POSITION_STEP;
      const step = DEFAULT_POSITION_STEP;
      const notMovedBlock = editableBlocks.filter(
        (item) => !draggableBlocks.includes(item.id),
      );
      const movedBlock = editableBlocks.filter((item) => draggableBlocks.includes(item.id),
      );
      movedBlockUpdatePosition = movedBlock.map((item, index) => ({
        ...item,
        position: previousPosition + step * (index + 1),
      }));
      blockState = [...notMovedBlock, ...movedBlockUpdatePosition];
    } else {
      const previousIndex = editableBlocks.findIndex(
        (item) => item.id === dragHandlerId,
      );
      const previousPosition = editableBlocks[previousIndex - 1]?.position ?? 0;
      const nextPosition = editableBlocks[previousIndex]?.position;
      let step = DEFAULT_POSITION_STEP;
      if (nextPosition) {
        step = (nextPosition - previousPosition) / (draggableBlocks.length + 1);
      }
      const notMovedBlock = editableBlocks.filter(
        (item) => !draggableBlocks.includes(item.id),
      );
      const newIndex = notMovedBlock.findIndex(
        (block) => block.id === dragHandlerId,
      );
      const movedBlock = editableBlocks.filter((item) => draggableBlocks.includes(item.id),
      );
      movedBlockUpdatePosition = movedBlock.map((item, index) => ({
        ...item,
        position: previousPosition + step * (index + 1),
      }));
      blockState = [
        ...notMovedBlock.slice(0, newIndex),
        ...movedBlockUpdatePosition,
        ...notMovedBlock.slice(newIndex),
      ];
    }

    yield put(actionUpdateSupportBlocks({ blocks: blockState }));
    yield put(actionUpdateSupportLayers({ layers: getLayersForPage(blockState) }));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* dragManyBlock(action) {
  try {
    const {
      payload: { dragHandlerId, draggableBlocks },
    } = action;
    const editableBlocks = yield select(getBlocks);
    const currentPage = yield select(getCurrentPage);
    let blockState;
    let movedBlockUpdatePosition;
    if (dragHandlerId === 'last') {
      const previousPosition = editableBlocks[editableBlocks.length - 1].position
        || DEFAULT_POSITION_STEP;
      const step = DEFAULT_POSITION_STEP;
      const notMovedBlock = editableBlocks.filter(
        (item) => !draggableBlocks.includes(item.id),
      );
      const movedBlock = editableBlocks.filter((item) => draggableBlocks.includes(item.id),
      );
      movedBlockUpdatePosition = movedBlock.map((item, index) => ({
        ...item,
        position:
          previousPosition + step * (index + 1)
          || DEFAULT_POSITION_STEP * (index + 1),
      }));
      blockState = [...notMovedBlock, ...movedBlockUpdatePosition];
    } else {
      const previousIndex = editableBlocks.findIndex(
        (item) => item.id === dragHandlerId,
      );
      const previousPosition = editableBlocks[previousIndex - 1]?.position || 0;
      const nextPosition = editableBlocks[previousIndex]?.position;
      let step = DEFAULT_POSITION_STEP;
      if (nextPosition) {
        step = (nextPosition - previousPosition) / (draggableBlocks.length + 1);
      }
      const notMovedBlock = editableBlocks.filter(
        (item) => !draggableBlocks.includes(item.id),
      );
      const newIndex = notMovedBlock.findIndex(
        (block) => block.id === dragHandlerId,
      );
      const movedBlock = editableBlocks.filter((item) => draggableBlocks.includes(item.id),
      );
      movedBlockUpdatePosition = movedBlock.map((item, index) => ({
        ...item,
        position:
          previousPosition + step * (index + 1)
          || DEFAULT_POSITION_STEP * (index + 1),
      }));
      blockState = [
        ...notMovedBlock.slice(0, newIndex),
        ...movedBlockUpdatePosition,
        ...notMovedBlock.slice(newIndex),
      ];
    }
    yield put(
      actionSwitchPage({ blocks: blockState, layers: getLayersForPage(blockState) }),
    );

    yield call(requestUpdateLibraryComponent(), {
      place: 'queryUpdatePositionManyComponents',
      id: currentPage.id,
      updateNested: {
        components: movedBlockUpdatePosition.map((block) => ({
          id: block.id,
          field: { position: block.position },
        })),
      },
    });
    yield put(
      actionChangeManyLibraryPageComponentPosition(
        currentPage.id,
        movedBlockUpdatePosition,
        currentPage?.inCollection?.id,
      ),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* deleteEditableBlock(action) {
  try {
    const {
      payload: { id },
    } = action;
    const editableBlocks = yield select(getBlocks);

    const currentPage = yield select(getCurrentPage);
    const index = editableBlocks.findIndex((block) => block.id === id);
    const blockState = [
      ...editableBlocks.slice(0, index),
      ...editableBlocks.slice(index + 1),
    ];
    yield put(actionSwitchPage({ blocks: blockState }));

    yield put(actionUpdateLayers(getLayersForPage(blockState)));

    if (editableBlocks[index]?.type === 'libComponent') {
      // TODO: Research and Delete if not used
      yield call(
        requestDeleteLibraryComponent(placementEnum.queryDeleteLibComponent),
        {
          componentId: editableBlocks[index].id,
          pageId: currentPage.id,
        },
      );
    } else {
      yield call(requestDeleteComponent(placementEnum.queryDeleteComponent), {
        componentId: id,
      });
    }
    yield put(
      actionDeleteLibraryPageComponent({
        id: currentPage.id,
        deleteComponentId: id,
      }),
    );

    if (
      editableBlocks[index]?.type === 'component'
      && editableBlocks[index].nestedItemId
    ) {
      yield put(
        actionSaveComponentDescriptionData(editableBlocks[index].nestedItemId),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* deleteManyBlocks(action) {
  try {
    const {
      payload: { ids },
    } = action;
    const currentPage = yield select(getCurrentPage);

    const editableBlocks = yield select(getBlocks);

    const blockState = editableBlocks.filter((item) => !ids.includes(item.id));
    const deleteState = editableBlocks.filter((item) => ids.includes(item.id));
    yield put(actionSwitchPage({ blocks: blockState }));

    yield put(actionUpdateLayers(getLayersForPage(blockState)));
    yield call(
      requestDeleteLibraryComponent(placementEnum.queryDeleteManyLibComponent),
      {
        componentsId: deleteState.map((i) => i.id),
        pageId: currentPage.id,
      },
    );
    yield put(
      actionDeleteManyLibraryPageComponent(
        currentPage.id,
        deleteState,
        currentPage?.inCollection?.id,
      ),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* addManyEditableBlock(action) {
  try {
    const {
      payload: {
        id,
        blocks: localBlocks,
        pageId,
        firstEmpty,
        playlistField,
        isLibraryCreate,
      },
    } = action;
    const { id: userId } = yield select(getUser);

    // export const actionAddEditableBlock = (id, type, pageId, isClone) => {
    const currentPage = yield select(getCurrentPage);
    const libraryItems = yield select(getLibraryComponent);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const filterPersonalItem = localBlocks.filter((item) => {
      if (item.type === 'component') return libraryItems[item.nestedItemId];
      if (item.type === 'page') return libraryItems[item.pageId];
      return true;
    });
    let editableBlocks;
    if (isLibraryCreate) {
      editableBlocks = yield select(getSupportBlocks);
      editableBlocks = editableBlocks.sort((a, b) => a.position - b.position);
    } else if (!playlistField) {
      editableBlocks = yield select(getBlocks);
      editableBlocks = editableBlocks.sort((a, b) => a.position - b.position);
    } else {
      const editableBlocks1 = yield select(getBlocksPlaylist);
      editableBlocks = editableBlocks1[playlistField];
      editableBlocks = editableBlocks.sort((a, b) => a.position - b.position);
    }

    let step = DEFAULT_POSITION_STEP;
    let index = 0;
    let unNextPos = 0;

    if (!isLibraryCreate) {
      if (id) {
        index = editableBlocks.findIndex((block) => block.id === id);
        if (!(index + 1) && editableBlocks.length) {
          index = editableBlocks.length;
        }
      } else index = editableBlocks.length;

      unNextPos = editableBlocks[index - 1]?.position || 0;
      const nextPos = editableBlocks[index]?.position || unNextPos + DEFAULT_POSITION_STEP;

      step = (nextPos
        ? Math.abs(nextPos - unNextPos) / (filterPersonalItem.length + 1)
        : DEFAULT_POSITION_STEP + unNextPos) || DEFAULT_POSITION_STEP;
    }
    const update = filterPersonalItem.map((item, idx) => {
      const position = unNextPos + step * (idx + 1);
      if (item.type === 'component') return { ...libraryItems[item.nestedItemId], ...item, position };
      return { ...item, position };
    });
    const sliceCounter = firstEmpty ? 0 : 1;
    const blockState = [
      ...editableBlocks.slice(0, index + sliceCounter),
      ...update,
      ...editableBlocks.slice(index + 1),
    ].sort((a, b) => a.position - b.position);

    if (!isLibraryCreate) {
      yield put(actionSwitchPage({ blocks: blockState }));
      yield put(actionUpdateLayers(getLayersForPage(blockState), playlistField));
      const createSimpleBlock = [];
      const requests = update.map((newBlock, i) => {
        if (BlockTypes[newBlock.type] === BlockTypes.upload) return null;
        const newId = uuidv4();
        const isNewBlock = !(i === 0 && firstEmpty);
        if (newBlock.newUrl) {
          return put(actionCreator(FileUpload.CreateImageUploadByLink,
            { url: newBlock.newUrl, blockId: newBlock.id, newId, isNewBlock, position: newBlock.position },
          ));
        }
        if (i === 0 && firstEmpty) {
          return call(
            requestUpdateLibraryComponent(),
            createUpdateComponentBody(
              newBlock,
              pageId,
              playlistField,
              null,
              null,
              lastModifiedDate,
            ),
          );
        }
        const queryWithBlockStateToAddToPage = generateQueryWithBlockStateToAddToPageNew(newBlock, userId);
        createSimpleBlock.push(queryWithBlockStateToAddToPage);
        return null;
      }).filter(i => i);

      requests.push(call(requestUpdateLibraryComponent(), {
        place: 'queryAddComponent',
        id: currentPage.id,
        create: {
          components: createSimpleBlock,
        },
      }));
      yield all(requests);
    }
    if (isLibraryCreate) {
      yield put(actionUpdateSupportBlocks({ blocks: blockState }));
      yield put(actionUpdateSupportLayers(getLayersForPage(blockState)));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
  // if (playlistField) yield calculateUpdatePlaylistDurationTime();
}

function* addEditableBlock(action) {
  try {
    const {
      payload: {
        id,
        type,
        pageId,
        isClone,
        isWidget,
        eraseContent,
        playlistField,
        title,
        text,
      },
    } = action;
    const { id: userId } = yield select(getUser);
    const isPlaylist = !!playlistField;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    let editableBlocks;
    let currentPage;
    let old;
    if (isPlaylist) {
      currentPage = yield select(getCurrentPage);
      old = yield select(getBlocksPlaylist);
      editableBlocks = old[playlistField] || [];
    } else {
      editableBlocks = yield select(getBlocks);
      currentPage = yield select(getCurrentPage);
    }

    if (type === 'new' || type === 'new_before' || type.newType) {
      const content = {};
      let index = editableBlocks.findIndex((block) => block.id === id);
      if (type === 'new_before') {
        index = --index;
      }
      content.state = EditorState.createWithContent(
        blocks(type.newType || 'text', pageId),
      );
      let position;
      if (id) {
        const nextPos = editableBlocks[index + 1] && editableBlocks[index + 1].position;
        position = calculateIndex(editableBlocks[index]?.position, nextPos);
      } else {
        position = DEFAULT_POSITION_STEP;
      }
      const newBlockId = uuid();
      const newBlock = {
        id: newBlockId,
        ...content,
        type: type.newType || 'text',
        // isHidden: editableBlocks[index]?.isHidden,
        position,
        isNew: true,
      };

      const blockState = [
        ...editableBlocks.slice(0, index + 1),
        newBlock,
        ...editableBlocks.slice(index + 1),
      ];
      if (!isPlaylist) {
        yield put(
          actionSwitchPage({
            blocks: blockState,
            newBlockId,
            layers: getLayersForPage(blockState),
            lastModifiedDate,
          }),
        );
      } else {
        old[playlistField] = blockState;
        yield call(
          requestCreateLibraryComponentPlacement(
            placementEnum.queryAddComponentPlaylist,
          ),
          {
            currentPageId: currentPage.id,
            component: newBlock,
            pageIdToAdd: pageId,
            playlistField,
            lastModifiedDate,
            content: createAddComponentPlaylistContent(
              currentPage.id,
              newBlock,
            ),
          },
        );
        yield put(
          actionUpdatePlaylistLastModified({
            id: currentPage.id,
            lastModifiedDate,
          }),
        );
        yield put(actionSwitchPage({ additionalInformation: old, newBlockId }));
      }

      yield put(actionUpdateLayers(getLayersForPage(blockState), playlistField));
      if (!isWidget && !isPlaylist) {
        yield call(requestUpdateLibraryComponent(), {
          place: 'addEditableBlock',
          id: currentPage.id,
          create: {
            components: [
              {
                id: newBlock.id,
                content: stringifyStateToContent(newBlock.state),
                type: type?.newType || 'text',
                position,
                owner: userId,
              },
            ],
          },
        });
      }
      if (playlistField) yield calculateUpdatePlaylistDurationTime();

      yield put(
        actionAddLibraryPageComponent({
          id: currentPage.id,
          newComponent: newBlock,
        }),
      );

      return;
    }
    if (isClone) {
      let editableBlocksLocal;
      if (!playlistField) {
        editableBlocksLocal = yield select(getBlocks);
      } else {
        const editableBlocks1 = yield select(getBlocksPlaylist);
        editableBlocksLocal = editableBlocks1[playlistField];
      }
      const index = editableBlocksLocal.findIndex((block) => block.id === id);
      const nextPos = editableBlocksLocal[index + 1]
        && editableBlocksLocal[index + 1].position;
      const position = calculateIndex(
        editableBlocksLocal[index].position,
        nextPos,
      );
      const newBlockId = uuid();
      const newBlock = {
        ...editableBlocksLocal[index],
        position,
        id: newBlockId,
        isNew: true,
      };
      const blockState = [
        ...editableBlocksLocal.slice(0, index + 1),
        newBlock,
        ...editableBlocksLocal.slice(index + 1),
      ];
      if (!isWidget && !isPlaylist) {
        if (newBlock.type === 'component') {
          yield call(
            requestUpdateLibraryComponent(
              placementEnum.queryDuplicateLibraryComponentInPage,
            ),
            {
              nestedItemId: newBlock.nestedItemId,
              pageId: currentPage.id,
              positionInPage: position,
              libraryComponentInPageId: newBlock.id,
              viewMode:
                newBlock.components[0]?.state?.data?.representationState,
              width: newBlock.components[0]?.state?.data?.width,
            },
          );
        } else {
          const queryWithBlockStateToAddToPage = generateQueryWithBlockStateToAddToPageNew(newBlock, userId);
          yield call(requestUpdateLibraryComponent(), {
            place: 'queryAddComponent',
            id: currentPage.id,
            create: {
              components: [queryWithBlockStateToAddToPage],
            },
          });
        }
      }
      if (!isPlaylist) {
        yield put(
          actionSwitchPage({
            blocks: blockState,
            newBlockId,
            layers: getLayersForPage(blockState),
          }),
        );
      } else {
        yield call(
          requestCreateLibraryComponentPlacement(
            placementEnum.queryAddComponentPlaylist,
          ),
          {
            currentPageId: currentPage.id,
            component: newBlock,
            pageIdToAdd: pageId,
            playlistField,
            lastModifiedDate,
            content: createAddComponentPlaylistContent(
              currentPage.id,
              newBlock,
            ),
          },
        );
        yield put(
          actionUpdatePlaylistLastModified({
            id: currentPage.id,
            lastModifiedDate,
          }),
        );
        old[playlistField] = blockState;
        yield put(actionUpdateLayers(getLayersForPage(blockState), playlistField));

        yield put(actionSwitchPage({ additionalInformation: old, newBlockId }));
      }
      yield put(ActionDraggable.BlocksOnPage([]));
      yield put(ActionDraggable.SetHasDraggable(false));
      if (playlistField) yield calculateUpdatePlaylistDurationTime();

      yield put(
        actionAddLibraryPageComponent({
          id: currentPage.id,
          newComponent: newBlock,
          inCollectionId: currentPage?.inCollection?.id,
        }),
      );

      return;
    }
    const index = editableBlocks.findIndex((block) => block.id === id);
    const position = editableBlocks[index]?.position;
    const content = {};
    if (
      editableBlocks[index].type === type
      || editableBlocks[index].type === BlockTypes.image
      || editableBlocks[index].type === BlockTypes.video
    ) return;
    if (type === BlockTypes.page) {
      content.title = sanitizeToLoad(title);
      content.nestedItemId = pageId;
      content.innerHtml = innerHtmlPageLink(title);
    } else if (type === BlockTypes.approveButton) {
      content.state = {
        defaultName: 'Approve',
        clickedName: 'Approved',
        buttonId: 'Approve Button',
        isClicked: false,
        color: colorsApprovedButton[0],
        questionName: 'Question name',
      };
      content.innerHtml = innerHtmlApproveButtonTemplate;
    } else if (type === BlockTypes.actionButton) {
      content.state = {
        buttonName: 'read more',
        color: colorsApprovedButton[5],
      };
    } else if (type === BlockTypes.lineSeparator) {
      content.state = {
        data: {
          lineType: 'single',
        },
      };
      content.innerHtml = innerHtmlLineSeparatorTemplate;
    } else if (type === BlockTypes.shortText) {
      content.state = {
        questionName: 'Question Name',
        hintText: '',
        placeholderText: '',
        isHasLimit: false,
        limit: 50,
        isRequired: false,
      };
    } else if (type === BlockTypes.embed) {
      content.state = {
        data: {
          text,
          isPreview: true,
        },
      };
    } else if (eraseContent) {
      const newEmptyState = EditorState.createWithText(
        '',
        DefaultDraftBlockRenderMap1[type],
      );
      content.state = RichUtils.toggleBlockType(newEmptyState, type);
    } else {
      content.state = toggleAllBlocksType(editableBlocks[index].state, type);
    }

    if (type === BlockTypes.embed) {
      const newId = uuid();
      yield put(
        actionCreator(LibraryComponents.CreateEmbedLibraryComponent, {
          iframeText: content?.state?.data?.text,
          newId,
        }),
      );
      content.nestedItemId = newId;
    }

    const newBlock = {
      id: editableBlocks[index].id,
      ...content,
      type,
      position,
      isHidden: editableBlocks[index]?.isHidden,
      isNew: true,
    };

    let blockState;
    if (editableBlocks?.length) {
      const targetIndex = editableBlocks.findIndex((block) => block.id === id);
      blockState = [
        ...editableBlocks.slice(0, targetIndex),
        newBlock,
        ...editableBlocks.slice(targetIndex + 1),
      ];
    } else {
      blockState = [newBlock];
    }
    if (!isPlaylist) {
      yield put(
        actionSwitchPage({
          blocks: blockState,
          newBlockId: editableBlocks[index].id,
        }),
      );
    } else {
      old[playlistField] = blockState;
      yield put(
        actionSwitchPage({
          additionalInformation: old,
          newBlockId: editableBlocks[index].id,
        }),
      );
    }
    yield put(actionUpdateLayers(getLayersForPage(blockState), playlistField));
    if (!isWidget) {
      const reqPayload = createChangeComponentTypeBody(
        newBlock,
        currentPage.id,
        playlistField,
      );
      if (reqPayload) {
        yield call(requestUpdateLibraryComponent(), reqPayload);
      }
    }
    if (playlistField) yield calculateUpdatePlaylistDurationTime();

    yield put(
      actionAddLibraryPageComponent({
        id: currentPage.id,
        newComponent: { ...newBlock, nestedItemId: null },
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* addNewEmptyEditableBlock(action) {
  try {
    const {
      payload: { targetBlockId, newId, order, type },
    } = action;
    const { id: userId } = yield select(getUser);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const editableBlocks = yield select(getBlocks);
    const currentPage = yield select(getCurrentPage);

    const content = {};
    let index = editableBlocks.findIndex((block) => block.id === targetBlockId);
    if (order === 'new_before') {
      index = --index;
    }
    content.state = EditorState.createWithContent(
      blocks(type?.newType || 'text'),
    );
    let position;
    if (targetBlockId) {
      const nextPos = editableBlocks[index + 1] && editableBlocks[index + 1].position;
      position = calculateIndex(editableBlocks[index]?.position, nextPos);
    } else {
      position = DEFAULT_POSITION_STEP;
    }
    const newBlock = {
      id: newId,
      ...content,
      type: type?.newType || 'text',
      position,
      isNew: true,
    };

    const blockState = [
      ...editableBlocks.slice(0, index + 1),
      newBlock,
      ...editableBlocks.slice(index + 1),
    ];
    yield put(
      actionSwitchPage({
        blocks: blockState,
        newBlockId: newId,
        layers: getLayersForPage(blockState),
        lastModifiedDate,
      }),
    );
    yield requestUpdateLibraryComponent()({
      place: 'addNewEmptyEditableBlock',
      id: currentPage.id,
      create: {
        components: [
          {
            id: newBlock.id,
            content: stringifyStateToContent(newBlock.state),
            type: type?.newType || 'text',
            position,
            owner: userId,
          },
        ],
      },
    });

    yield put(
      actionAddLibraryPageComponent({
        id: currentPage.id,
        newComponent: newBlock,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* addDuplicateBlock(action) {
  try {
    const {
      payload: { id, newBlockId },
    } = action;
    const { id: userId } = yield select(getUser);
    const currentPage = yield select(getCurrentPage);
    const editableBlocksLocal = yield select(getBlocks);

    const index = editableBlocksLocal.findIndex((block) => block.id === id);
    const nextPos = editableBlocksLocal[index + 1] && editableBlocksLocal[index + 1].position;
    const position = calculateIndex(
      editableBlocksLocal[index].position,
      nextPos,
    );
    const newBlock = {
      ...editableBlocksLocal[index],
      position,
      id: newBlockId,
      isNew: true,
    };
    const blockState = [
      ...editableBlocksLocal.slice(0, index + 1),
      newBlock,
      ...editableBlocksLocal.slice(index + 1),
    ];

    yield put(
      actionSwitchPage({
        blocks: blockState,
        newBlockId,
        layers: getLayersForPage(blockState),
      }),
    );
    const queryWithBlockStateToAddToPage = generateQueryWithBlockStateToAddToPageNew(newBlock, userId);
    yield call(requestUpdateLibraryComponent(), {
      place: 'queryAddComponent',
      id: currentPage.id,
      create: {
        components: [queryWithBlockStateToAddToPage],
      },
    });
    yield put(
      actionAddLibraryPageComponent({
        id: currentPage.id,
        newComponent: newBlock,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* multipleDuplicateBlock(action) {
  try {
    const {
      payload: { selectedIds, newBlockIds },
    } = action;
    const { id: userId } = yield select(getUser);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const currentPage = yield select(getCurrentPage);
    const editableBlocksLocal = yield select(getBlocks);
    const lastIndex = editableBlocksLocal.findIndex(
      (block) => block.id === selectedIds[selectedIds.length - 1],
    );

    const prewPos = editableBlocksLocal[lastIndex] && editableBlocksLocal[lastIndex].position;
    const nextPos = (editableBlocksLocal[lastIndex + 1]
        && editableBlocksLocal[lastIndex + 1].position)
      || prewPos + DEFAULT_POSITION_STEP * selectedIds.length;
    const step = (nextPos - prewPos) / (selectedIds.length + 2);

    const newBlocks = [];
    selectedIds.forEach((id, index) => {
      const copyBlockIndex = editableBlocksLocal.findIndex(
        (block) => block.id === id,
      );
      const position = prewPos + step * (index + 1);

      const newBlock = {
        ...editableBlocksLocal[copyBlockIndex],
        position,
        id: newBlockIds[index],
        isNew: true,
      };
      newBlocks.push(newBlock);
    });
    const blockState = [
      ...editableBlocksLocal.slice(0, lastIndex + 1),
      ...newBlocks,
      ...editableBlocksLocal.slice(lastIndex + 1),
    ];

    yield put(
      actionSwitchPage({ blocks: blockState, layers: getLayersForPage(blockState) }),
    );
    yield call(
      requestCreateComponent(placementEnum.queryAddManyComponents),
      createAddManyComponentsBody(
        lastModifiedDate,
        currentPage.id,
        userId,
        newBlocks,
      ),
    );
    yield put(
      actionAddLibraryPageMannyComponent({
        id: currentPage.id,
        newComponents: newBlocks,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* restoreAfterDeleteEditableBlock(action) {
  try {
    const {
      payload: { currentBlock, index },
    } = action;
    const { id: userId } = yield select(getUser);
    const currentPage = yield select(getCurrentPage);
    const editableBlocksLocal = yield select(getBlocks);

    const blockState = [
      ...editableBlocksLocal.slice(0, index),
      currentBlock,
      ...editableBlocksLocal.slice(index),
    ];

    yield put(
      actionSwitchPage({
        blocks: blockState,
        newBlockId: currentBlock.id,
        layers: getLayersForPage(blockState),
      }),
    );
    const queryWithBlockStateToAddToPage = generateQueryWithBlockStateToAddToPageNew(currentBlock, userId);
    yield call(requestUpdateLibraryComponent(), {
      place: 'queryAddComponent',
      id: currentPage.id,
      create: {
        components: [queryWithBlockStateToAddToPage],
      },
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* restoreAfterDeleteManyEditableBlock(action) {
  try {
    const {
      payload: { deleteState },
    } = action;
    const { id: userId } = yield select(getUser);
    const currentPage = yield select(getCurrentPage);
    const editableBlocksLocal = yield select(getBlocks);

    const blockState = [...editableBlocksLocal, ...deleteState].sort(
      (i, b) => i.position - b.position,
    );

    yield put(
      actionSwitchPage({ blocks: blockState, layers: getLayersForPage(blockState) }),
    );

    yield all(
      deleteState.map((currentBlock) => {
        const queryWithBlockStateToAddToPage = generateQueryWithBlockStateToAddToPageNew(currentBlock, userId);
        return call(requestUpdateLibraryComponent(), {
          place: 'queryAddComponent',
          id: currentPage.id,
          create: {
            components: [queryWithBlockStateToAddToPage],
          },
        });
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* resizeImageInBlock(action) {
  try {
    const {
      payload: { id, width, html, state, position },
    } = action;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const currentPage = yield select(getCurrentPage);
    yield put(actionUpdateBlock(id, state, width, html));

    yield call(
      requestUpdateLibraryComponent(),
      createUpdateComponentBody(
        {
          id,
          state,
          position,
          innerHtml: html,
        },
        currentPage.id,
        width,
        null,
        null,
        lastModifiedDate,
      ),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* ChangeTypeEditableBlock(action) {
  try {
    const {
      payload: { id, type, isClear, addedPage, textParseLink, newContentState },
    } = action;
    const editableBlocks = yield select(getBlocks);
    const currentPage = yield select(getCurrentPage);

    const index = editableBlocks.findIndex((block) => block.id === id);
    const position = editableBlocks[index]?.position;
    const content = {};
    const textState = newContentState || editableBlocks[index].state;
    if (type === BlockTypes.page) {
      const { title, id: pageId } = addedPage;
      content.title = sanitizeToLoad(title);
      content.nestedItemId = pageId;
      content.innerHtml = innerHtmlPageLink(title);
    } else if (type === BlockTypes.approveButton) {
      content.state = {
        defaultName: 'Approve',
        clickedName: 'Approved',
        buttonId: 'Approve Button',
        isClicked: false,
        color: colorsApprovedButton[0],
        questionName: 'Question name',
      };
      content.innerHtml = innerHtmlApproveButtonTemplate;
    } else if (type === BlockTypes.actionButton) {
      content.state = {
        buttonName: 'read more',
        color: colorsApprovedButton[5],
      };
    } else if (type === BlockTypes.lineSeparator) {
      content.state = {
        data: {
          lineType: 'single',
        },
      };
      content.innerHtml = innerHtmlLineSeparatorTemplate;
    } else if (type === BlockTypes.shortText) {
      content.state = {
        questionName: 'Question Name',
        hintText: '',
        placeholderText: '',
        isHasLimit: false,
        limit: 50,
        isRequired: false,
      };
    } else if (type === BlockTypes.embed) {
      content.state = {
        data: {
          text: textParseLink,
          isPreview: true,
        },
      };
    } else if (isClear) {
      const newEmptyState = EditorState.createWithText(
        '',
        DefaultDraftBlockRenderMap1[type],
      );
      content.state = RichUtils.toggleBlockType(newEmptyState, type);
    } else if (
      editableBlocks[index]?.state
      && !editableBlocks[index].state.data
      && DefaultDraftBlockRenderMap1[editableBlocks[index].type]
    ) {
      content.state = toggleAllBlocksType(textState, type);
    } else if (type === BlockTypes.embed) {
      const newId = uuid();
      yield put(
        actionCreator(LibraryComponents.CreateEmbedLibraryComponent, {
          iframeText: content?.state?.data?.text,
          newId,
        }),
      );
      content.nestedItemId = newId;
    } else if (type === BlockTypes.image) {
      content.state = '';
      content.isNew = true;
    } else if (textState) {
      content.state = toggleAllBlocksType(textState, type);
    } else {
      const newEmptyState = EditorState.createWithText('');
      content.state = RichUtils.toggleBlockType(newEmptyState, type);
    }

    const newBlock = {
      id: editableBlocks[index].id,
      ...content,
      type,
      position,
      isHidden: editableBlocks[index]?.isHidden,
      isNew: true,
    };

    const blockState = [
      ...editableBlocks.slice(0, index),
      newBlock,
      ...editableBlocks.slice(index + 1),
    ];

    yield put(
      actionSwitchPage({
        blocks: blockState,
        newBlockId: editableBlocks[index].id,
      }),
    );
    yield put(actionUpdateLayers(getLayersForPage(blockState)));
    const reqPayload = createChangeComponentTypeBody(newBlock, currentPage.id);
    if (reqPayload) yield call(requestUpdateLibraryComponent(), reqPayload);
    yield put(
      actionAddLibraryPageComponent({
        id: currentPage.id,
        newComponent: { ...newBlock, nestedItemId: null },
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* addEditableBlockRedux(action) {
  try {
    const {
      payload: { id, type, pageId, isClone, contentText, eraseContent },
    } = action;
    const editableBlocks = yield select(getSupportBlocks) || [];
    if (contentText) {
      const content = {};
      const index = 0;
      content.state = EditorState.createWithContent(
        blocks(type.newType || 'text', contentText),
      );
      let position;
      if (id) {
        const nextPos = editableBlocks[index + 1] && editableBlocks[index + 1]?.position;
        position = calculateIndex(editableBlocks[index]?.position, nextPos);
      } else {
        position = DEFAULT_POSITION_STEP;
      }
      const newBlock = {
        id,
        ...content,
        type: type.newType || 'text',
        position,
        innerHtml: innerHtmlWebTextTemplate(contentText).trim(),
        isNew: true,
      };
      if (newBlock.type === 'image') {
        newBlock.isNew = true;
      }
      const blockState = [
        ...editableBlocks.slice(0, index + 1),
        newBlock,
        ...editableBlocks.slice(index + 1),
      ];
      yield put(actionUpdateSupportBlocks({ blocks: blockState, id }));
      yield put(actionUpdateSupportLayers(getLayersForPage(blockState)));
      return;
    }

    if (type === 'new' || type === 'new_before' || type.newType) {
      const content = {};
      let index = editableBlocks.findIndex((block) => block.id === id);
      if (type === 'new_before') {
        index = --index;
      }

      content.state = EditorState.createWithContent(
        blocks(type.newType || 'text', pageId),
      );
      let position;
      if (id) {
        const nextPos = editableBlocks[index + 1] && editableBlocks[index + 1]?.position;
        position = calculateIndex(editableBlocks[index]?.position, nextPos);
      } else {
        position = DEFAULT_POSITION_STEP;
      }
      const newBlockId = uuid();
      const newBlock = {
        id: newBlockId,
        ...content,
        type: type.newType || 'text',
        position,
        isNew: true,
      };

      const blockState = [
        ...editableBlocks.slice(0, index + 1),
        newBlock,
        ...editableBlocks.slice(index + 1),
      ];
      yield put(actionUpdateSupportBlocks({ blocks: blockState, newBlockId }));
      yield put(actionUpdateSupportLayers(getLayersForPage(blockState)));
      return;
    }

    if (isClone) {
      const index = editableBlocks.findIndex((block) => block.id === id);
      const nextPos = editableBlocks[index + 1] && editableBlocks[index + 1]?.position;
      const position = calculateIndex(editableBlocks[index]?.position, nextPos);
      const newBlockId = uuid();
      const newBlock = {
        ...editableBlocks[index],
        position,
        id: newBlockId,
        isNew: true,
      };
      const blockState = [
        ...editableBlocks.slice(0, index + 1),
        newBlock,
        ...editableBlocks.slice(index + 1),
      ];
      yield put(actionUpdateSupportBlocks({ blocks: blockState, newBlockId }));
      yield put(actionUpdateSupportLayers(getLayersForPage(blockState)));
      return;
    }

    const index = editableBlocks.findIndex((block) => block.id === id);
    const position = editableBlocks[index]?.position;
    const content = {};
    if (
      editableBlocks[index].type === type
      || editableBlocks[index].type === BlockTypes.image
      || editableBlocks[index].type === BlockTypes.video
    ) return;
    if (type === BlockTypes.page) {
      content.title = sanitizeToLoad(pageId.title);
      content.nestedItemId = pageId.id;
    } else if (type === BlockTypes.approveButton) {
      content.state = {
        defaultName: 'Approve',
        clickedName: 'Approved',
        isClicked: false,
        buttonId: 'Approve Button',
        color: colorsApprovedButton[0],
        questionName: 'Question name',
      };
      content.innerHtml = innerHtmlApproveButtonTemplate;
    } else if (type === BlockTypes.actionButton) {
      content.state = {
        buttonName: 'read more',
        color: colorsApprovedButton[5],
      };
    } else if (type === BlockTypes.lineSeparator) {
      content.state = {
        data: {
          lineType: 'single',
        },
      };
      content.innerHtml = innerHtmlLineSeparatorTemplate;
    } else if (type === BlockTypes.shortText) {
      content.state = {
        questionName: 'Question Name',
        hintText: '',
        placeholderText: '',
        isHasLimit: false,
        limit: 50,
        isRequired: false,
      };
    } else if (type === BlockTypes.embed) {
      // content.isNeedUpdate = true;
    } else if (eraseContent) {
      const newEmptyState = EditorState.createWithText(
        '',
        DefaultDraftBlockRenderMap1[type],
      );
      content.state = RichUtils.toggleBlockType(newEmptyState, type);
    } else {
      try {
        content.state = RichUtils.toggleBlockType(
          editableBlocks[index].state,
          type,
        );
      } catch (err) {
        console.error(err.message);
      }
    }

    const newBlock = {
      id: editableBlocks[index].id,
      ...content,
      type,
      position,
      isNew: true,
    };

    let blockState;
    if (editableBlocks?.length) {
      const targetIndex = editableBlocks.findIndex((block) => block.id === id);
      blockState = [
        ...editableBlocks.slice(0, targetIndex),
        newBlock,
        ...editableBlocks.slice(targetIndex + 1),
      ];
    } else {
      blockState = [newBlock];
    }
    yield put(
      actionUpdateSupportBlocks({
        blocks: blockState,
        newBlockId: editableBlocks[index].id,
      }),
    );
    yield put(actionUpdateSupportLayers(getLayersForPage(blockState)));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* changeEditableBlock(action) {
  try {
    const {
      payload: { id, newState, innerHtml, playlistField, blockType },
    } = action;
    let editableBlocks;
    if (!playlistField) {
      editableBlocks = yield select(getBlocks);
    } else {
      const editableBlocks1 = yield select(getBlocksPlaylist);
      editableBlocks = editableBlocks1[playlistField];
    }

    const currentPage = yield select(getCurrentPage);
    const oldBlock = editableBlocks.find((innerBlock) => innerBlock.id === id) || {};
    const block = { ...oldBlock };
    if (newState?.data?.lineType) {
      block.state = { ...newState };
      block.type = BlockTypes.lineSeparator;
      block.innerHtml = innerHtml;
    } else if (blockType === BlockTypes.pdf) {
      block.state = { ...newState };
      block.type = BlockTypes.pdf;
      block.innerHtml = innerHtml;
    } else if (blockType === BlockTypes.googeEmbed) {
      block.state = { ...newState };
      block.type = BlockTypes.googeEmbed;
      block.innerHtml = innerHtml;
    } else if (newState?.data?.mediaType) {
      block.state = { ...newState };
      block.type = BlockTypes.webSite;
      block.innerHtml = innerHtmlWebSiteTemplate(block.state.data);
    } else if (blockType === BlockTypes.embed) {
      block.state = { ...newState };
      block.type = BlockTypes.embed;
      block.state = newState;
      if (innerHtml) {
        block.innerHtml = innerHtml.replaceAll("'", '').replaceAll('"', "'");
      }
      // block.innerHtml = innerHtmlWebSiteTemplate(block.state.data);
    } else {
      block.state = newState;
      if (innerHtml) {
        block.innerHtml = innerHtml.replaceAll("'", '').replaceAll('"', "'");
      }
    }

    const index = editableBlocks.findIndex(
      (innerBlock) => innerBlock.id === id,
    );

    const blockState = [
      ...editableBlocks.slice(0, index),
      block,
      ...editableBlocks.slice(index + 1),
    ];
    if (action.metod === 'UNDO') {
      yield put(
        actionSwitchPage({
          blocks: blockState,
          newBlockId: editableBlocks[index].id,
        }),
      );
    } else {
      // yield put(actionSwitchPage({ blocks: blockState }));
    }
    if (block.position && block.id) {
      const newBlockState = block.state.getCurrentContent
        ? { ...convertToRaw(block.state.getCurrentContent()) }
        : {};
      newBlockState?.blocks?.forEach((block) => {
        block.text = sanitizeToSave(block.text);
      });
      const field = {
        content: replaceDoubleQuotes(JSON.stringify(newBlockState)),
      };
      if (
        block.type === BlockTypes.embed
        || block.type === BlockTypes.webSite
      ) {
        field.content = `"${sanitizeToSave(JSON.stringify(block.state))}"`;
        field.type = `"${block.type}"`;
      }
      if (block.innerHtml && innerHtml) {
        field.innerHtml = stringifyInnerHtml(innerHtml);
      }

      yield call(requestUpdateLibraryComponent(), {
        place: 'queryUpdateComponentText',
        id: currentPage.id,
        updateNested: {
          components: [{ id: block.id, field }],
        },
      });

      if (block.type === BlockTypes.embed) {
        block.nestedItemId = null;
      }
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* separateEditableBlockWithState(action) {
  try {
    const {
      payload: {
        id,
        newState,
        replaceState,
        pageId,
        newBlockId,
        isWidget,
        playlistField,
      },
    } = action;
    const { id: userId } = yield select(getUser);

    const lastModifiedDate = Math.floor(Date.now() / 1000);
    let isCreateInLibrary = false;
    if (!pageId) isCreateInLibrary = true;

    let editableBlocks;
    if (isCreateInLibrary) {
      editableBlocks = yield select(getSupportBlocks);
    } else if (!playlistField) {
      editableBlocks = yield select(getBlocks);
    }
    const index = editableBlocks.findIndex((block) => block.id === id);

    const oldBlock = editableBlocks[index];
    const separateBlock = { ...oldBlock };
    separateBlock.state = replaceState;

    const nextPos = editableBlocks[index + 1] && editableBlocks[index + 1]?.position;
    const position = calculateIndex(editableBlocks[index]?.position, nextPos);
    const newBlock = {
      state: newState,
      type: editableBlocks[index]?.type,
      position,
      id: newBlockId,
    };

    const blockState = [
      ...editableBlocks.slice(0, index),
      separateBlock,
      newBlock,
      ...editableBlocks.slice(index + 1),
    ];
    yield put(actionUpdateLayers(getLayersForPage(blockState), playlistField));

    if (!isCreateInLibrary || !isWidget) {
      yield put(actionSwitchPage({ blocks: blockState }));
      const queryWithBlockStateToAddToPage = generateQueryWithBlockStateToAddToPageNew(newBlock, userId);
      yield call(requestUpdateLibraryComponent(), {
        place: 'queryAddComponent',
        id: pageId,
        create: {
          components: [queryWithBlockStateToAddToPage],
        },
      });
      yield call(
        requestUpdateLibraryComponent(),
        createUpdateComponentBody(
          separateBlock,
          pageId,
          null,
          null,
          null,
          lastModifiedDate,
        ),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* createEditableBlockWithStateRedux(action) {
  try {
    const {
      payload: { id, newState },
    } = action;
    const editableBlocks = yield select(getSupportBlocks);
    const index = editableBlocks.findIndex((block) => block.id === id);
    const nextPos = editableBlocks[index + 1] && editableBlocks[index + 1]?.position;
    const position = calculateIndex(editableBlocks[index]?.position, nextPos);
    const newBlockId = uuid();
    const newBlock = {
      state: newState,
      type: editableBlocks[index]?.type,
      position,
      id: newBlockId,
    };

    const blockState = [
      ...editableBlocks.slice(0, index + 1),
      newBlock,
      ...editableBlocks.slice(index + 1),
    ];

    yield put(actionUpdateSupportBlocks({ blocks: blockState }));
    yield put(actionUpdateSupportLayers(getLayersForPage(blockState)));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
//
// function* getShareLink(action) {
//   try {
//     const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
//     const {
//       payload: { id, callback, itemType },
//     } = action;
//     const url = itemType === 'page' ? APIGetSharedUrl : APIGetSharedPlaylistUrl;
//     const { data: dataLink } = yield call(axios, {
//       method: 'get',
//       url: `${url}/${id}`,
//       params: { pageId: id },
//       headers: {
//         token: token ? `${token}` : '',
//       },
//     });
//     const link = dataLink.link;
//     const shareState = { link };
//     yield put(actionSwitchPage({ shareState }));
//     if (callback) callback();
//   } catch (e) {
//     yield showErrorMessage(e, action);
//   }
// }

function* downloadCurrentPage(action) {
  try {
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: true } });
    const {
      payload: { id, isSharedMod, history },
    } = action;
    const { data } = yield requestGetLibComponent([id]);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const upload = data.LibraryComponent;
    let notAvailable = false;
    const [uploadedContent] = upload;

    if (uploadedContent) {
      const {
        isAnyCanComment,
        isAnyCanEdit,
        category,
        isMakeCollaborate,
        isPublish,
        isReused,
        reference,
        description,
        isShowDescription,
        availableFrom,
        availableTo,
        isAvailableRange,
        isShareToWeb,
        isNeedAccess,
        accessCode,
        usersToSharing,
        usersInviteToSharing,
        dateRangeMark,
      } = uploadedContent;

      const singleUserShareState = {};
      usersToSharing.edges?.forEach(i => singleUserShareState[i.node.email] = i.node);

      const { id: pageId, title, movedToTrash } = uploadedContent;

      const shareState = {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isPublish,
        isReused,
        isShareToWeb,
        isNeedAccess,
        accessCode,
        availableTo,
        dateRangeMark,
        availableFrom,
        isAvailableRange,
        link: id,
        usersToSharing: [
          ...usersToSharing.edges.map((i) => ({
            ...i.node,
            send: i.send ? downloadStatus.success : '',
          })),
          ...usersInviteToSharing.edges.map((i) => ({
            ...i.node,
            send: i.send ? downloadStatus.success : '',
          })),
        ],
      };

      if (isSharedMod && isShareToWeb) {
        const nowSec = Math.floor(Date.now() / 1000);
        if (availableTo < nowSec) notAvailable = true;
        if (isAvailableRange && availableFrom > nowSec) notAvailable = true;
        if (notAvailable) {
          yield put(
            actionSwitchPage({
              id: pageId,
              shareState,
              notAvailable,
            }),
          );
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }
      } else if (isSharedMod && !isShareToWeb) {
        history.push(DEFAULT_PAGE_PATH);
      }

      const inputs = {
        category,
        isAnyCanComment,
        isAnyCanEdit,
        reference,
        availableFrom,
        availableTo,
        isAvailableRange,
      };

      yield put(
        actionCurrentEditTitle(
          sanitizeToLoad(uploadedContent.title),
          null,
          null,
          lastModifiedDate,
        ),
      );

      const notDescriptionAdded = !isShowDescription;
      let libraryCompBlocks = [];
      let localBlocks = [];

      const hashtags = [];
      const tags = uploadedContent.contentTags.map((item) => ({
        type: 'tags',
        ...item,
      }));

      if (
        uploadedContent.libraryComponents
        && !!uploadedContent.libraryComponents.length
        && !!uploadedContent.libraryComponents[0]
      ) {
        libraryCompBlocks = uploadedContent.libraryComponents.map((item) => {
          const libraryComponent = item;
          if (!libraryComponent?.hasLibraryComponent.length) return;
          if (libraryComponent?.hasLibraryComponent[0].movedToTrash) return;

          let componentsFromLibComponent;
          const firstElement = libraryComponent.hasLibraryComponent[0];
          if (
            firstElement.type === 'application/pdf'
            || UiComponentTypes.pp[firstElement.type]
            || UiComponentTypes.presentation[firstElement.type]
          ) {
            componentsFromLibComponent = [
              {
                type: BlockTypes.pdf,
                state: {
                  data: {
                    nestedItemId: firstElement.id,
                    width: libraryComponent.width,
                    representationState: libraryComponent.representationState,
                    title: libraryComponent.hasLibraryComponent[0].title,
                    size: libraryComponent.hasLibraryComponent[0].size,
                    urlFile: libraryComponent.hasLibraryComponent[0].urlFile,
                  },
                },
              },
            ];
          } else {
            componentsFromLibComponent = libraryComponent.hasLibraryComponent[0].components.map(
              (innerItem) => {
                const content = {};
                if (innerItem.type === 'page') {
                  const [libraryPage] = innerItem.libraryComponents;
                  content.title = (libraryPage && sanitizeToLoad(libraryPage.title))
                      || 'The functional logic has been updated.';
                  content.nestedItemId = libraryPage && libraryPage.id;
                } else if (
                  innerItem.type === BlockTypes.approveButton
                    || innerItem.type === BlockTypes.actionButton
                    || innerItem.type === BlockTypes.shortText
                ) {
                  content.state = JSON.parse(
                    sanitizeToLoad(innerItem.content),
                  );
                  // } else if (item.type === 'webSite') {
                } else if (
                  innerItem.type === BlockTypes.webSite
                    || innerItem.type === BlockTypes.lineSeparator
                ) {
                  const tmpContent = parseContentForUsage(innerItem.content);
                  content.state = tmpContent || '';
                } else if (
                  innerItem.type === BlockTypes.googleEmbed
                    || innerItem.type === BlockTypes.dropboxEmbed
                ) {
                  const innerLibraryComponent = innerItem.libraryComponents[0];
                  content.state = {
                    data: {
                      url: innerLibraryComponent?.linkUrl,
                      images: innerLibraryComponent?.urlFile,
                      title: innerLibraryComponent?.title,
                      description: innerLibraryComponent?.description,
                      foreignFileId: innerLibraryComponent.foreignFileId,
                      linkedAccountId: innerLibraryComponent.linkedAccountId,
                      libraryComponentId: innerLibraryComponent.id,
                      foreignLastModifiedDate:
                          innerLibraryComponent.foreignLastModifiedDate,
                      foreignLastModifiedUserName:
                          innerLibraryComponent.foreignLastModifiedUserName,
                    },
                  };
                } else {
                  content.state = draftDataConvertToLoad(innerItem.content);
                }

                return {
                  id: innerItem.id,
                  type: innerItem.type,
                  innerHtml: innerItem.innerHtml,
                  position: innerItem.position,
                  isHidden: innerItem.isHidden,
                  width: innerItem.width,
                  ...content,
                };
              },
            );
          }

          return {
            position: item.position,
            id: item.id,
            type: 'component',
            contentType: item.hasLibraryComponent[0].type,
            isHidden: item.isHidden,
            nestedItemId: libraryComponent.hasLibraryComponent[0].id,
            components: componentsFromLibComponent,
          };
        });
      }

      if (uploadedContent.components && !!uploadedContent.components.length) {
        localBlocks = uploadedContent.components.map((item) => {
          if (item.libraryComponents[0]?.movedToTrash) return;
          const content = {};

          if (item.libraryComponents[0]?.id) {
            content.nestedItemId = item.libraryComponents[0].id;
          }
          if (item.type === BlockTypes.page) {
            const [libraryPage] = item.libraryComponents;
            content.innerHtml = sanitizeToLoad(item.innerHtml);
            content.title = sanitizeToLoad(libraryPage?.title) || 'default title';
            content.nestedItemId = libraryPage && libraryPage.id;
          } else if (
            item.type === BlockTypes.approveButton
            || item.type === BlockTypes.actionButton
            || item.type === BlockTypes.shortText
          ) {
            try {
              content.state = JSON.parse(sanitizeToLoad(item.content));
              content.innerHtml = sanitizeToLoad(item.innerHtml);
            } catch (err) {
              content.state = '';
            }
          } else if (item.type === BlockTypes.lineSeparator) {
            content.state = parseContentForUsage(item.content);
          } else if (item.type === BlockTypes.embed) {
            if (item.libraryComponents[0]) {
              content.state = { data: {
                text: sanitizeToLoad(item.libraryComponents[0].linkUrl),
                isPreview: true,
              } };
            } else {
              content.state = parseContentForUsage(item.content);
            }
          } else if (
            item.type === BlockTypes.googleEmbed
            || item.type === BlockTypes.dropboxEmbed
          ) {
            const innerLibraryComponent = item.libraryComponents[0];
            content.state = {
              data: {
                url: innerLibraryComponent?.linkUrl,
                images: innerLibraryComponent?.urlFile,
                title: innerLibraryComponent?.title,
                description: innerLibraryComponent?.description,
                foreignFileId: innerLibraryComponent?.foreignFileId,
                linkedAccountId: innerLibraryComponent?.linkedAccountId,
                libraryComponentId: innerLibraryComponent?.id,
                foreignLastModifiedDate:
                  innerLibraryComponent?.foreignLastModifiedDate,
                foreignLastModifiedUserName:
                  innerLibraryComponent?.foreignLastModifiedUserName,
              },
            };
            if (item.type === BlockTypes.googleEmbed) {
              content.innerHtml = content.state?.data
                && innerHtmlGoogleEmbedTemplate(content.state?.data);
            } else {
              content.innerHtml = content.state?.data
                && innerHtmlDropboxEmbedTemplate(content.state?.data);
            }
          } else if (item.type === BlockTypes.webSite) {
            const innerLibraryComponent = item.libraryComponents[0];
            content.state = innerLibraryComponent
              ? {
                data: {
                  url: innerLibraryComponent?.linkUrl,
                  images: innerLibraryComponent?.urlFile,
                  title: innerLibraryComponent?.title,
                  description: innerLibraryComponent?.description,
                },
              }
              : parseContentForUsage(item.content);
            content.innerHtml = content.state?.data
              && innerHtmlWebSiteTemplate(content.state?.data);
          } else if (item.type === 'application/pdf') {
            const sourceComponent = item.libraryComponents[0];
            content.type = 'application/pdf';
            content.contentType = 'application/pdf';
            if (sourceComponent) {
              content.state = {
                data: {
                  nestedItemId: sourceComponent.id,
                  width: item.width || sourceComponent.width || 700,
                  representationState: item.content,
                  title: sourceComponent.title,
                  size: sourceComponent.size || 700,
                  urlFile: sourceComponent.urlFile,
                },
              };
            }
          } else if (
            UiComponentTypes.ms[item?.type]
            || UiComponentTypes.xls[item?.type]
            || UiComponentTypes.pp[item?.type]
            || UiComponentTypes.presentation[item?.type]
          ) {
            const sourceComponent = item.libraryComponents[0];

            content.type = 'application/pdf';
            content.contentType = item?.type;
            if (sourceComponent) {
              content.state = {
                data: {
                  nestedItemId: sourceComponent.id,
                  width: item.width || 700,
                  representationState: item.content,
                  title: sourceComponent.title,
                  size: sourceComponent.size || 700,
                  pdfPreviewUrl: sourceComponent.pdfPreviewUrl,
                  urlFile: sourceComponent.urlFile,
                },
              };
            }
          } else if (item.type === 'component') {
            content.nestedItemId = item.libraryComponents[0]?.id;
            content.title = item.libraryComponents[0]?.title;
            content.components = item.libraryComponents[0]?.components.map(
              (innerItem) => {
                const localContent = {};

                if (innerItem.type === BlockTypes.page) {
                  const [libraryPage] = innerItem.libraryComponents;
                  localContent.title = (libraryPage && sanitizeToLoad(libraryPage.title))
                    || 'The functional logic has been updated.';
                  localContent.nestedItemId = libraryPage && libraryPage.id;
                  localContent.innerHtml = sanitizeToLoad(innerItem.innerHtml);
                } else if (innerItem.type === BlockTypes.embed) {
                  localContent.nestedLibComponent = innerItem.libraryComponents[0]?.id;
                  localContent.state = parseContentForUsage(innerItem.content);
                } else if (
                  innerItem.type === BlockTypes.approveButton
                  || innerItem.type === BlockTypes.actionButton
                  || innerItem.type === BlockTypes.shortText
                ) {
                  localContent.state = JSON.parse(
                    sanitizeToLoad(innerItem.content),
                  );
                } else if (innerItem.type === BlockTypes.webSite) {
                  const innerLibraryComponent = innerItem.libraryComponents[0];
                  localContent.state = innerLibraryComponent
                    ? {
                      data: {
                        url: innerLibraryComponent?.linkUrl,
                        images: innerLibraryComponent?.urlFile,
                        title: innerLibraryComponent?.title,
                        description: innerLibraryComponent?.description,
                      },
                    }
                    : parseContentForUsage(innerItem.content);
                  localContent.innerHtml = localContent.state?.data
                    && innerHtmlWebSiteTemplate(localContent.state?.data);
                } else if (
                  innerItem.type === BlockTypes.googleEmbed
                  || innerItem.type === BlockTypes.dropboxEmbed
                ) {
                  const innerLibraryComponent = innerItem.libraryComponents[0];
                  localContent.nestedItemId = innerItem.libraryComponents[0]?.id;
                  localContent.state = {
                    data: {
                      url: innerLibraryComponent?.linkUrl,
                      images: innerLibraryComponent?.urlFile,
                      title: innerLibraryComponent?.title,
                      description: innerLibraryComponent?.description,
                      foreignFileId: innerLibraryComponent?.foreignFileId,
                      linkedAccountId: innerLibraryComponent?.linkedAccountId,
                      foreignLastModifiedDate:
                        innerLibraryComponent?.foreignLastModifiedDate,
                      foreignLastModifiedUserName:
                        innerLibraryComponent?.foreignLastModifiedUserName,
                    },
                  };
                  if (innerItem.type === BlockTypes.googleEmbed) {
                    localContent.innerHtml = localContent.state?.data
                      && innerHtmlGoogleEmbedTemplate(localContent.state?.data);
                  } else {
                    localContent.innerHtml = localContent.state?.data
                      && innerHtmlDropboxEmbedTemplate(localContent.state?.data);
                  }
                } else if (innerItem.type === BlockTypes.lineSeparator) {
                  const tmpContent = parseContentForUsage(innerItem.content);
                  localContent.state = tmpContent || '';
                  localContent.innerHtml = sanitizeToLoad(innerItem.innerHtml);
                } else if (
                  (innerItem.type === BlockTypes.image
                    || innerItem.type === BlockTypes.video)
                  && innerItem.libraryComponents[0]
                ) {
                  localContent.state = draftDataConvertToLoad(
                    contentImageTemplate(
                      item.libraryComponents[0].urlSmallImage
                        || innerItem.libraryComponents[0].urlFile,
                    ),
                  );
                  localContent.innerHtml = sanitizeToLoad(innerItem.innerHtml);
                  localContent.urlFile = innerItem.libraryComponents[0].urlFile;
                  localContent.urlSmallImage = innerItem.libraryComponents[0].urlSmallImage;
                  localContent.urlVerySmallImage = innerItem.libraryComponents[0].urlVerySmallImage;
                } else if (
                  (innerItem.type === BlockTypes.image
                    || innerItem.type === BlockTypes.video)
                  && !innerItem.libraryComponents[0]
                ) {
                  localContent.state = '""';
                  // old await to delete
                } else if (innerItem.type === 'application/pdf') {
                  const sourceComponent = innerItem.libraryComponents[0];
                  localContent.type = 'application/pdf';
                  localContent.contentType = 'application/pdf';
                  localContent.state = {
                    data: {
                      nestedItemId: sourceComponent.id,
                      width: innerItem.width || sourceComponent.width || 700,
                      representationState: innerItem.content,
                      title: sourceComponent.title,
                      size: sourceComponent.size || 700,
                      urlFile: sourceComponent.urlFile,
                    },
                  };
                } else if (
                  UiComponentTypes.ms[innerItem?.type]
                  || UiComponentTypes.xls[innerItem?.type]
                ) {
                  const sourceComponent = innerItem.libraryComponents[0];
                  localContent.type = 'application/pdf';
                  localContent.contentType = innerItem?.type;
                  localContent.components = [
                    {
                      type: 'application/pdf',

                      state: {
                        data: {
                          nestedItemId: sourceComponent.id,
                          width: innerItem.width || 700,
                          representationState: innerItem.content,
                          title: sourceComponent.title,
                          size: sourceComponent.size || 700,
                          pdfPreviewUrl: sourceComponent.pdfPreviewUrl,
                          urlFile: sourceComponent.urlFile,
                        },
                      },
                    },
                  ];
                } else {
                  localContent.state = draftDataConvertToLoad(
                    innerItem.content,
                  );
                }
                return {
                  id: innerItem.id,
                  type: innerItem.type,
                  innerHtml: sanitizeToLoad(innerItem.innerHtml),
                  position: innerItem.position,
                  isHidden: innerItem.isHidden,
                  width: innerItem.width,
                  ...localContent,
                };
              },
            );
          } else if (
            (item.type === BlockTypes.image
              || item.type === BlockTypes.video)
            && item.libraryComponents[0]
          ) {
            content.urlFile = item.libraryComponents[0].urlFile;
            content.urlSmallImage = item.libraryComponents[0].urlSmallImage;
            content.urlVerySmallImage = item.libraryComponents[0].urlVerySmallImage;
            content.state = draftDataConvertToLoad(
              contentImageTemplate(
                item.libraryComponents[0].urlSmallImage
                  || item.libraryComponents[0].urlFile,
              ),
            );
            content.innerHtml = sanitizeToLoad(item.innerHtml);
          } else if (
            (item.type === BlockTypes.image
              || item.type === BlockTypes.video)
            && !item.libraryComponents[0]
          ) {
            content.state = draftDataConvertToLoad(item.content);
            // old await to delete
          } else {
            content.state = draftDataConvertToLoad(item.content);
            content.innerHtml = sanitizeToLoad(item.innerHtml);
          }
          return {
            id: item.id,
            type: item.type,
            innerHtml: sanitizeToLoad(item.innerHtml),
            position: item.position,
            isHidden: item.isHidden,
            width: item.width,
            ...content,
          };
        });
      }
      const blocksF = localBlocks.filter((i) => i);
      const libraryCompBlocksF = libraryCompBlocks.filter((i) => i);
      const mergedBlocks = [...blocksF, ...libraryCompBlocksF];
      const newORDER = mergedBlocks.sort(
        (first, last) => first.position - last.position,
      );
      const layers = getLayersForPage(newORDER);

      yield put(
        actionSwitchPage({
          id,
          title: sanitizeToLoad(title),
          layers,
          type: TYPE.LIBRARY_COMPONENT,
          shareState,
          singleUserShareState,
          inputs,
          notAvailable,
          hashtags,
          tags,
          movedToTrash,
          notDescriptionAdded,
          isShowDescription: isShowDescription || false,
          description: sanitizeToLoad(description) || '',
          blocks: newORDER || [],
          inCollection: null,
          currentPageId: uploadedContent.id,
          owner: uploadedContent?.users && uploadedContent?.users[0],
          lastModifiedDate: uploadedContent.lastModifiedDate,
        }),
      );
      const filtered = mergedBlocks.filter(
        (item) => item.type === 'component' && item.nestedItemId,
      );
      const uniqueById = [
        ...new Set(filtered.map((item) => item.nestedItemId)),
      ];
      yield all(
        filtered.map((item) => {
          if (uniqueById.includes(item.nestedItemId)) {
            return put(actionSaveComponentDescriptionData(item.nestedItemId));
          }
          return () => {};
        }),
      );
    } else {
      const items = yield select(getPages);
      if (items[id]) {
        let position;
        if (!items[id].position) {
          position = Math.max(
            ...Object.values(items || {}).map((i) => i.position || 0),
          ) + DEFAULT_POSITION_STEP;
        } else {
          position = items[id].position + DEFAULT_POSITION_STEP;
        }
        yield put(
          actionSwitchPage({
            id,
            title: '',
            layers: ['text'],
            type: TYPE.LIBRARY_COMPONENT,
            blocks: [],
            shareState: { link: id },
            notDescriptionAdded: true,
            currentPageId: id,
            owner: uploadedContent?.users && uploadedContent.users[0],
          }),
        );

        yield call(
          requestCreateLibraryComponentPlacement(placementEnum.queryCreatePage),
          {
            title: '',
            pageId: id,
            position,
          },
        );
      } else {
        yield put(
          actionSwitchPage({
            shareState: { link: id },
          }),
        );
      }
    }
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* downloadSharedCurrentPage(action) {
  try {
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: true } });
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const {
      payload: { id, accessCode, isShowByWeb },
    } = action;

    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    let data;
    try {
      const response = yield call(axios, {
        method: 'get',
        url: `${APIGetPageSharing}/${id}`,
        headers: {
          accessCode: accessCode ? `${accessCode}` : '',
          token: token ? `${token}` : '',
          isbyweb: isShowByWeb ? `${isShowByWeb}` : '',
        },
      });
      data = response.data;
      yield setServiceWorkerSharedJwt(response.data.sharedJwt);
    } catch (e) {
      if (e.response?.status === 403) {
        if (e.response?.data?.message === 'need accessCode') {
          yield put(actionSwitchPage({ shareState: { needAccessCode: true } }));
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === 'wrong accessCode') {
          yield put(
            actionSwitchPage({
              shareState: { needAccessCode: true, wrongAccessCode: true },
            }),
          );
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === 'need login') {
          yield put(actionSwitchPage({ shareState: { needLogin: true } }));
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === 'wrong user') {
          yield put(actionSwitchPage({ shareState: { wrongUser: true } }));
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }

        if (e.response?.data?.message === 'wrong time') {
          const { availableTo, isAvailableRange, availableFrom, readId } = e.response?.data;
          const shareState = {
            availableTo,
            availableFrom,
            isAvailableRange,
          };
          yield put(
            actionSwitchPage({
              id,
              shareState,
              notAvailable: true,
            }),
          );
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          yield put(
            actionCreator(SupportAction.ReadOneUnseenPage, { id: readId }),
          );

          return;
        }
      }
      return;
    }
    const upload = data.LibraryComponent;
    const socketId = data.socketId;
    const [uploadedContent] = upload;
    if (uploadedContent) {
      const {
        isAnyCanComment,
        isAnyCanEdit,
        category,
        isMakeCollaborate,
        isPublish,
        isReused,
        reference,
        description,
        isShowDescription,
        availableFrom,
        availableTo,
        isAvailableRange,
        isShareToWeb,
        usersToSharing,
      } = uploadedContent;
      const { id: pageId, title, movedToTrash } = uploadedContent;
      const { sharedAvailableTo, sharedAvailableFrom } = usersToSharing.edges[0] || {};

      const shareState = {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isPublish,
        isReused,
        isShareToWeb,
        isAvailableRange,
      };
      if (isShowByWeb) {
        shareState.availableTo = availableTo;
        shareState.availableFrom = availableFrom;
      } else {
        shareState.availableTo = sharedAvailableTo;
        shareState.availableFrom = sharedAvailableFrom;
      }
      const inputs = {
        category,
        isAnyCanComment,
        isAnyCanEdit,
        reference,
        availableFrom,
        availableTo,
        isAvailableRange,
      };

      yield put(
        actionCurrentEditTitle(
          sanitizeToLoad(uploadedContent.title),
          null,
          null,
          lastModifiedDate,
        ),
      );
      const notDescriptionAdded = !isShowDescription;
      let libraryCompBlocks = [];
      let localBlocks = [];
      const hashtags = [];
      const tags = uploadedContent.contentTags.map((item) => ({
        type: 'tags',
        ...item,
      }));
      if (
        uploadedContent.libraryComponents
        && !!uploadedContent.libraryComponents.length
        && !!uploadedContent.libraryComponents[0]
      ) {
        libraryCompBlocks = uploadedContent.libraryComponents.map((item) => {
          const libraryComponent = item;
          if (!libraryComponent?.hasLibraryComponent.length) return;
          if (libraryComponent?.hasLibraryComponent[0].movedToTrash) return;

          let componentsFromLibComponent;
          const firstElement = libraryComponent.hasLibraryComponent[0];
          if (firstElement.type === 'application/pdf') {
            componentsFromLibComponent = [
              {
                type: BlockTypes.pdf,
                state: {
                  data: {
                    nestedItemId: firstElement.id,
                    width: libraryComponent.width,
                    representationState: libraryComponent.representationState,
                    title: libraryComponent.hasLibraryComponent[0].title,
                    size: libraryComponent.hasLibraryComponent[0].size,
                    urlFile: libraryComponent.hasLibraryComponent[0].urlFile,
                  },
                },
              },
            ];
          } else {
            componentsFromLibComponent = libraryComponent.hasLibraryComponent[0].components.map(
              (innerItem) => {
                const content = {};
                if (innerItem.type === 'page') {
                  const [libraryPage] = innerItem.libraryComponents;
                  content.title = (libraryPage && sanitizeToLoad(libraryPage.title))
                      || 'The functional logic has been updated.';
                  content.nestedItemId = libraryPage && libraryPage.id;
                } else if (
                  innerItem.type === BlockTypes.approveButton
                    || innerItem.type === BlockTypes.actionButton
                    || innerItem.type === BlockTypes.shortText
                ) {
                  content.state = JSON.parse(
                    sanitizeToLoad(innerItem.content),
                  );
                  // } else if (item.type === 'webSite') {
                } else if (
                  innerItem.type === BlockTypes.webSite
                    || innerItem.type === BlockTypes.lineSeparator
                ) {
                  const tmpContent = parseContentForUsage(innerItem.content);
                  content.state = tmpContent || '';
                } else if (
                  innerItem.type === BlockTypes.googleEmbed
                    || innerItem.type === BlockTypes.dropboxEmbed
                ) {
                  const innerLibraryComponent = innerItem.libraryComponents[0];
                  content.nestedItemId = innerItem.libraryComponents[0].id;
                  content.state = {
                    data: {
                      url: innerLibraryComponent?.linkUrl,
                      images: innerLibraryComponent?.urlFile,
                      title: innerLibraryComponent?.title,
                      description: innerLibraryComponent?.description,
                      foreignFileId: innerLibraryComponent?.foreignFileId,
                      linkedAccountId: innerLibraryComponent?.linkedAccountId,
                      foreignLastModifiedDate:
                          innerLibraryComponent?.foreignLastModifiedDate,
                      foreignLastModifiedUserName:
                          innerLibraryComponent?.foreignLastModifiedUserName,
                    },
                  };
                  if (innerItem.type === BlockTypes.googleEmbed) {
                    content.innerHtml = content.state?.data
                        && innerHtmlGoogleEmbedTemplate(content.state?.data);
                  } else {
                    content.innerHtml = content.state?.data
                        && innerHtmlDropboxEmbedTemplate(content.state?.data);
                  }
                } else {
                  content.state = draftDataConvertToLoad(innerItem.content);
                }

                return {
                  id: innerItem.id,
                  type: innerItem.type,
                  innerHtml: innerItem.innerHtml,
                  position: innerItem.position,
                  isHidden: innerItem.isHidden,
                  width: innerItem.width,
                  ...content,
                };
              },
            );
          }

          return {
            position: item.position,
            id: item.id,
            type: 'component',
            contentType: item.hasLibraryComponent[0].type,
            isHidden: item.isHidden,
            nestedItemId: libraryComponent.hasLibraryComponent[0].id,
            components: componentsFromLibComponent,
          };
        });
      }
      if (uploadedContent.components && !!uploadedContent.components.length) {
        localBlocks = uploadedContent.components.map((item) => {
          if (item.libraryComponents[0]?.movedToTrash) return;
          const content = {};
          if (item.type === BlockTypes.page) {
            const [libraryPage] = item.libraryComponents;
            content.innerHtml = sanitizeToLoad(item.innerHtml);
            content.title = sanitizeToLoad(libraryPage?.title) || 'default title';
            content.nestedItemId = libraryPage && libraryPage.id;
          } else if (
            item.type === BlockTypes.approveButton
            || item.type === BlockTypes.actionButton
            || item.type === BlockTypes.shortText
          ) {
            try {
              content.state = JSON.parse(sanitizeToLoad(item.content));
              content.innerHtml = sanitizeToLoad(item.innerHtml);
            } catch (err) {
              content.state = '';
            }
          } else if (item.type === BlockTypes.lineSeparator) {
            content.state = parseContentForUsage(item.content);
          } else if (item.type === BlockTypes.embed) {
            content.state = parseContentForUsage(item.content);
          } else if (item.type === BlockTypes.webSite) {
            const innerLibraryComponent = item.libraryComponents[0];
            content.state = innerLibraryComponent
              ? {
                data: {
                  url: innerLibraryComponent?.linkUrl,
                  images: innerLibraryComponent?.urlFile,
                  title: innerLibraryComponent?.title,
                  description: innerLibraryComponent?.description,
                },
              }
              : parseContentForUsage(item.content);
            content.innerHtml = content.state?.data
              && innerHtmlWebSiteTemplate(content.state?.data);
          } else if (
            item.type === BlockTypes.googleEmbed
            || item.type === BlockTypes.dropboxEmbed
          ) {
            const innerLibraryComponent = item.libraryComponents[0];
            content.state = {
              data: {
                url: innerLibraryComponent?.linkUrl,
                images: innerLibraryComponent?.urlFile,
                title: innerLibraryComponent?.title,
                description: innerLibraryComponent?.description,
                foreignFileId: innerLibraryComponent.foreignFileId,
                linkedAccountId: innerLibraryComponent.linkedAccountId,
                libraryComponentId: innerLibraryComponent.id,
                foreignLastModifiedDate:
                  innerLibraryComponent.foreignLastModifiedDate,
                foreignLastModifiedUserName:
                  innerLibraryComponent.foreignLastModifiedUserName,
              },
            };
            if (item.type === BlockTypes.googleEmbed) {
              content.innerHtml = content.state?.data
                && innerHtmlGoogleEmbedTemplate(content.state?.data);
            } else {
              content.innerHtml = content.state?.data
                && innerHtmlDropboxEmbedTemplate(content.state?.data);
            }
          } else if (item.type === 'application/pdf') {
            const sourceComponent = item.libraryComponents[0];
            content.type = 'application/pdf';
            content.contentType = 'application/pdf';
            if (sourceComponent) {
              content.state = {
                data: {
                  nestedItemId: sourceComponent.id,
                  width: item.width || sourceComponent.width || 700,
                  representationState: item.content,
                  title: sourceComponent.title,
                  size: sourceComponent.size || 700,
                  urlFile: sourceComponent.urlFile,
                },
              };
            }
          } else if (
            UiComponentTypes.ms[item?.type]
            || UiComponentTypes.xls[item?.type]
          ) {
            const sourceComponent = item.libraryComponents[0];

            content.type = 'application/pdf';
            content.contentType = item?.type;
            if (sourceComponent) {
              content.state = {
                data: {
                  nestedItemId: sourceComponent.id,
                  width: item.width || 700,
                  representationState: item.content,
                  title: sourceComponent.title,
                  size: sourceComponent.size || 700,
                  pdfPreviewUrl: sourceComponent.pdfPreviewUrl,
                  urlFile: sourceComponent.urlFile,
                },
              };
            }
          } else if (item.type === 'component') {
            content.nestedItemId = item.libraryComponents[0]?.id;
            content.title = item.libraryComponents[0]?.title;
            content.components = item.libraryComponents[0]?.components.map(
              (innerItem) => {
                const localContent = {};

                if (innerItem.type === BlockTypes.page) {
                  const [libraryPage] = innerItem.libraryComponents;
                  localContent.title = (libraryPage && sanitizeToLoad(libraryPage.title))
                    || 'The functional logic has been updated.';
                  localContent.nestedItemId = libraryPage && libraryPage.id;
                  localContent.innerHtml = sanitizeToLoad(innerItem.innerHtml);
                } else if (innerItem.type === BlockTypes.embed) {
                  localContent.nestedLibComponent = innerItem.libraryComponents[0]?.id;
                  localContent.state = parseContentForUsage(innerItem.content);
                } else if (
                  innerItem.type === BlockTypes.approveButton
                  || innerItem.type === BlockTypes.actionButton
                  || innerItem.type === BlockTypes.shortText
                ) {
                  localContent.state = JSON.parse(
                    sanitizeToLoad(innerItem.content),
                  );
                } else if (innerItem.type === BlockTypes.webSite) {
                  const innerLibraryComponent = innerItem.libraryComponents[0];
                  localContent.state = innerLibraryComponent
                    ? {
                      data: {
                        url: innerLibraryComponent?.linkUrl,
                        images: innerLibraryComponent?.urlFile,
                        title: innerLibraryComponent?.title,
                        description: innerLibraryComponent?.description,
                      },
                    }
                    : parseContentForUsage(innerItem.content);
                  localContent.innerHtml = localContent.state?.data
                    && innerHtmlWebSiteTemplate(localContent.state?.data);
                } else if (
                  innerItem.type === BlockTypes.googleEmbed
                  || innerItem.type === BlockTypes.dropboxEmbed
                ) {
                  const innerLibraryComponent = innerItem.libraryComponents[0];
                  localContent.nestedItemId = innerItem.libraryComponents[0].id;
                  localContent.state = {
                    data: {
                      url: innerLibraryComponent?.linkUrl,
                      images: innerLibraryComponent?.urlFile,
                      title: innerLibraryComponent?.title,
                      description: innerLibraryComponent?.description,
                      foreignFileId: innerLibraryComponent.foreignFileId,
                      linkedAccountId: innerLibraryComponent.linkedAccountId,
                      libraryComponentId: innerLibraryComponent.id,
                      foreignLastModifiedDate:
                        innerLibraryComponent.foreignLastModifiedDate,
                      foreignLastModifiedUserName:
                        innerLibraryComponent.foreignLastModifiedUserName,
                    },
                  };
                  if (innerItem.type === BlockTypes.googleEmbed) {
                    localContent.innerHtml = localContent.state?.data
                      && innerHtmlGoogleEmbedTemplate(localContent.state?.data);
                  } else {
                    localContent.innerHtml = localContent.state?.data
                      && innerHtmlDropboxEmbedTemplate(localContent.state?.data);
                  }
                } else if (innerItem.type === BlockTypes.lineSeparator) {
                  const tmpContent = parseContentForUsage(innerItem.content);
                  localContent.state = tmpContent || '';
                  localContent.innerHtml = sanitizeToLoad(innerItem.innerHtml);
                } else if (
                  (innerItem.type === BlockTypes.image
                    || innerItem.type === BlockTypes.video)
                  && innerItem.libraryComponents[0]
                ) {
                  localContent.state = draftDataConvertToLoad(
                    contentImageTemplate(
                      innerItem.libraryComponents[0].urlSmallImage
                        || innerItem.libraryComponents[0].urlFile,
                    ),
                  );
                  localContent.innerHtml = sanitizeToLoad(innerItem.innerHtml);
                  localContent.urlFile = innerItem.libraryComponents[0].urlFile;
                  localContent.urlSmallImage = innerItem.libraryComponents[0].urlSmallImage;
                  localContent.urlVerySmallImage = innerItem.libraryComponents[0].urlVerySmallImage;
                } else if (
                  (innerItem.type === BlockTypes.image
                    || innerItem.type === BlockTypes.video)
                  && !innerItem.libraryComponents[0]
                ) {
                  localContent.state = draftDataConvertToLoad(
                    innerItem.content,
                  );
                  // old await to delete
                } else if (innerItem.type === 'application/pdf') {
                  const sourceComponent = innerItem.libraryComponents[0];
                  localContent.type = 'application/pdf';
                  localContent.contentType = 'application/pdf';
                  localContent.state = {
                    data: {
                      nestedItemId: sourceComponent.id,
                      width: innerItem.width || sourceComponent.width || 700,
                      representationState: innerItem.content,
                      title: sourceComponent.title,
                      size: sourceComponent.size || 700,
                      urlFile: sourceComponent.urlFile,
                    },
                  };
                } else if (
                  UiComponentTypes.ms[innerItem?.type]
                  || UiComponentTypes.xls[innerItem?.type]
                ) {
                  const sourceComponent = innerItem.libraryComponents[0];
                  localContent.type = 'application/pdf';
                  localContent.contentType = innerItem?.type;
                  localContent.components = [
                    {
                      type: 'application/pdf',

                      state: {
                        data: {
                          nestedItemId: sourceComponent.id,
                          width: innerItem.width || 700,
                          representationState: innerItem.content,
                          title: sourceComponent.title,
                          size: sourceComponent.size || 700,
                          pdfPreviewUrl: sourceComponent.pdfPreviewUrl,
                          urlFile: sourceComponent.urlFile,
                        },
                      },
                    },
                  ];
                } else {
                  localContent.state = draftDataConvertToLoad(
                    innerItem.content,
                  );
                }
                return {
                  id: innerItem.id,
                  type: innerItem.type,
                  innerHtml: sanitizeToLoad(innerItem.innerHtml),
                  position: innerItem.position,
                  isHidden: innerItem.isHidden,
                  width: innerItem.width,
                  ...localContent,
                };
              },
            );
          } else if (
            (item.type === BlockTypes.image
              || item.type === BlockTypes.video)
            && item.libraryComponents[0]
          ) {
            // content.state = draftDataConvertToLoad(item.content);
            //

            content.state = draftDataConvertToLoad(
              contentImageTemplate(
                item.libraryComponents[0].urlSmallImage
                  || item.libraryComponents[0].urlFile,
              ),
            );
            content.innerHtml = sanitizeToLoad(item.innerHtml);
            content.urlFile = item.libraryComponents[0].urlFile;
            content.urlSmallImage = item.libraryComponents[0].urlSmallImage;
            content.urlVerySmallImage = item.libraryComponents[0].urlVerySmallImage;
          } else if (
            (item.type === BlockTypes.image
              || item.type === BlockTypes.video)
            && !item.libraryComponents[0]
          ) {
            content.state = draftDataConvertToLoad(item.content);
            // old await to delete
          } else {
            content.state = draftDataConvertToLoad(item.content);
            content.innerHtml = sanitizeToLoad(item.innerHtml);
          }
          return {
            id: item.id,
            type: item.type,
            innerHtml: sanitizeToLoad(item.innerHtml),
            position: item.position,
            isHidden: item.isHidden,
            width: item.width,
            ...content,
          };
        });
      }
      const blocksF = localBlocks.filter((i) => i);
      const libraryCompBlocksF = libraryCompBlocks.filter((i) => i);
      const mergedBlocks = [...blocksF, ...libraryCompBlocksF];
      const newORDER = mergedBlocks.sort(
        (first, last) => first.position - last.position,
      );
      const layers = getLayersForPage(newORDER);

      yield put(
        actionSwitchPage({
          id: pageId,
          title: sanitizeToLoad(title),
          layers,
          type: TYPE.LIBRARY_COMPONENT,
          shareState,
          inputs,
          socketId,
          hashtags,
          tags,
          movedToTrash,
          notDescriptionAdded,
          isShowDescription: isShowDescription || false,
          description: sanitizeToLoad(description) || '',
          blocks: newORDER || [],
          inCollection: null,
          currentPageId: uploadedContent.id,
          owner: uploadedContent?.users && uploadedContent?.users[0],
          lastModifiedDate: uploadedContent.lastModifiedDate,
        }),
      );
      const filtered = mergedBlocks.filter(
        (item) => item.type === 'component' && item.nestedItemId,
      );
      const uniqueById = [
        ...new Set(filtered.map((item) => item.nestedItemId)),
      ];
      yield all(
        filtered.map((item) => {
          if (uniqueById.includes(item.nestedItemId)) {
            return put(actionSaveComponentDescriptionData(item.nestedItemId));
          }
          return () => {};
        }),
      );
    } else {
      const items = yield select(getPages);
      if (items[id]) {
        let position;
        if (!items[id].position) {
          position = Math.max(
            ...Object.values(items || {}).map((i) => i.position || 0),
          ) + DEFAULT_POSITION_STEP;
        } else {
          position = items[id].position + DEFAULT_POSITION_STEP;
        }
        yield put(
          actionSwitchPage({
            id,
            title: '',
            layers: ['text'],
            type: TYPE.LIBRARY_COMPONENT,
            blocks: [],
            notDescriptionAdded: true,
            currentPageId: id,
            owner: uploadedContent?.users && uploadedContent.users[0],
          }),
        );
        yield call(
          requestCreateLibraryComponentPlacement(placementEnum.queryCreatePage),
          {
            title: '',
            pageId: id,
            position,
          },
        );
      }
    }
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
    yield put(actionCreator(SupportAction.ReadOneUnseenPage, { id: socketId }));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* downloadMiniPage(action) {
  try {
    const {
      payload: { id, isForBuilder },
    } = action;
    yield put(actionGetMiniPage(id));
    const { data } = yield requestGetLibComponent(['mini', id]);
    if (data.Page && data.Page[0]) {
      const components = data.Page[0]?.components.map(
        ({ id: innerId, position, innerHtml, type, page }) => ({
          id: innerId,
          position,
          type,
          page,
          innerHtml:
            innerHtml
            && innerHtml
              .replaceAll('"', '\\"')
              .replaceAll("'", '"')
              .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
              .replaceAll('$@quote', "'"),
        }),
      );
      const componentsFromLibrary = data.Page[0]?.libraryComponents.reduce(
        (acc, outerItem) => {
          outerItem.hasComponent.forEach(
            ({
              id: libraryComponentId,
              type,
              components: innerComponents,
              urlFile,
              title,
              size,
            }) => acc.push({
              id: libraryComponentId,
              width: outerItem.width,
              height: outerItem.height,
              innerHtml: sanitizeToLoad(outerItem.innerHtml),
              position: outerItem.position,
              mode: outerItem.representationState,
              urlFile,
              type,
              title,
              size,
              parentId: outerItem.id,
              components: innerComponents.map((nestedItem) => ({
                ...nestedItem,
                innerHtml: sanitizeToLoad(nestedItem.innerHtml),
              })),
            }),
          );
          return acc;
        },
        [],
      );
      const { isShowDescription, description, createDate, lastModifiedDate } = data.Page[0];
      yield put(
        actionSaveMiniPage({
          id,
          components: [...components, ...componentsFromLibrary].sort(
            (a, b) => a.position - b.position,
          ),
          isShowDescription,
          description: sanitizeToLoad(description),
          createDate,
          lastModifiedDate,
          isForBuilder,
        }),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

// function* downloadMiniPages(action) {
//   try {
//     const { payload: { pagesArray } } = action;
//     const miniPages = yield select(getMiniPages);
//     const notDownloadPages = Object.values(pagesArray || {})
//       .filter(i => !miniPages[i.id] && !i.movedToTrash && !i.isFolder && !i.isForBuilder);
//     if (notDownloadPages.length) {
//       yield all(notDownloadPages.slice(0, 20).map(item => downloadMiniPage({ payload: { id: item.id } }),
//       ));
//       yield put(actionLazyLoading(false));
//     }
//   } catch (e) {
//     yield showErrorMessage(e, action);
//   }
// }

function* saveMiniPagesForBuilder(action) {
  try {
    const {
      payload: { id },
    } = action;
    yield downloadMiniPage({ payload: { id, isForBuilder: true } });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* toggleComponentIsHidden(action) {
  try {
    const { id, value } = action.payload;
    const { id: pageId } = yield select(getCurrentPage);

    yield call(requestUpdateLibraryComponent(), {
      place: 'queryToggleComponentIsHidden',
      id: pageId,
      updateNested: {
        components: [{ id, field: { isHidden: value } }],
      },
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* changeStateComponentButton(action) {
  try {
    const { buttonState } = action.payload;
    const currentPage = yield select(getCurrentPage);
    yield put(actionChangeStateButtonREDUX(buttonState));
    const reqPayload = createChangeComponentTypeBody(
      { ...buttonState, pageId: currentPage.id },
      currentPage.id,
    );
    if (reqPayload) yield call(requestUpdateLibraryComponent(), reqPayload);
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* bulkChangeToggleComponentIsHidden(action) {
  try {
    const { idsValuesArray } = action.payload;
    const { id: pageId } = yield select(getCurrentPage);

    yield all(
      idsValuesArray.map((item) => {
        if (item.type === 'component') {
          return call(
            requestUpdateLibraryComponent(
              placementEnum.queryToggleLibComponentIsHidden,
            ),
            {
              id: item.id,
              value: item.value,
            },
          );
        }
        return call(requestUpdateLibraryComponent(), {
          place: 'queryToggleComponentIsHidden',
          id: pageId,
          updateNested: {
            components: [{ id: item.id, field: { isHidden: item.isHidden } }],
          },
        });
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* setAvailabilityDate(action) {
  try {
    const { availableFrom, availableTo } = action.payload;
    const { id } = yield select(getCurrentPage);
    const selectedPage = yield select(getSelectedPage);
    const playlistId = id || Object.keys(selectedPage)[0];
    const dataObject = {};

    dataObject.availableFrom = ` ${
      availableFrom ? Math.floor(availableFrom.getTime() / 1000) : 0
    } `;
    dataObject.availableTo = ` ${
      availableTo ? Math.floor(availableTo.getTime() / 1000) : 0
    }  `;
    yield call(requestUpdatePlaylist(), {
      id: playlistId,
      fieldsUpdateObg: dataObject,
    });
    yield put(actionRemoveAllSelected());
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* jumpToPageTitle() {
  try {
    yield delay(100);
    yield put(actionTryPublishPlaylist());
    yield put(actionJumpTo('pageTitleInput'));
    yield delay(100);
    yield put(actionJumpTo(''));
  } catch (err) {
    yield showErrorMessage(err, { type: 'jumpToPageTitle' });
  }
}

function* loadPageSettingsData(action) {
  try {
    const { id } = action.payload;
    const {
      data: { LibraryComponent },
    } = yield requestGetLibComponent([id]);
    const [lc] = LibraryComponent;

    const {
      isAnyCanComment,
      isAnyCanEdit,
      category,
      isMakeCollaborate,
      isPublish,
      isReused,
      reference,
      description,
      isShowDescription,
      availableFrom,
      availableTo,
      isAvailableRange,
      notDescriptionAdded,
    } = lc;
    const inputs = {
      category,
      isAnyCanComment,
      isAnyCanEdit,
      reference,
      availableFrom,
      availableTo,
      isAvailableRange,
    };
    const shareState = {
      isAnyCanComment,
      isAnyCanEdit,
      isMakeCollaborate,
      isPublish,
      isReused,
    };

    const hashtags = [];
    const tags = lc.contentTags.map((item) => ({ type: 'tags', ...item }));
    const updatedComponent = {
      id,
      title: sanitizeToLoad(lc.title),
      inputs,
      hashtags,
      tags,
      notDescriptionAdded,
      isShowDescription: isShowDescription || false,
      description: sanitizeToLoad(description) || '',
      inCollection: null,
      currentPageId: lc.id,
      shareState,
      owner: lc?.users && lc?.users[0],
      lastModifiedDate: lc.lastModifiedDate,
    };
    yield put(actionSwitchPage(updatedComponent));
    yield put(
      actionCreator(
        LibraryComponents.UpdateLibraryComponentInRedux,
        updatedComponent,
      ),
    );
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

// function* pageChannelPublishManagement (action) {
//   try {
//     const { channels, page } = action.payload;
//     const addPageToChannelArr = [];
//     const removePageFromChannelArr = [];
//
//     const { data } = yield wrapperRequestNeo4j( queryGetPageForChannels(page.id));
//
//     const arrayFromServer = data.Page[0].inChannel.reduce((acc, item) => {
//       acc[item.id] = { ...item };
//       return acc;
//     }, {});
//
//     const arrOfUniq = new Set(Object.values({ ...arrayFromServer, ...channels }));
//
//     arrOfUniq.forEach(item => {
//       if (arrayFromServer[item.id] && !channels[item.id]) removePageFromChannelArr.push(item);
//       if (!arrayFromServer[item.id] && channels[item.id]) addPageToChannelArr.push(item);
//     });
//
//     if (!!addPageToChannelArr.length || !!removePageFromChannelArr.length) {
//       yield call(requestNeo4j,
//         { query: queryManagePageChannelsAttachment(
//           addPageToChannelArr,
//           removePageFromChannelArr,
//           page,
//           'Page') });
//
//       yield all(addPageToChannelArr
//         .map(channel => {
//           return put(actionAddPageToChannelR(channel, { ...data.Page[0] }));
//         }));
//
//       yield all(removePageFromChannelArr
//         .map(channel => put(actionRemovePageFromChannelR(channel, { ...data.Page[0] }))));
//     }
//   } catch (err) {
//     yield showErrorMessage(err, action);
//   }
// }

function* AddUserToSharing(action) {
  try {
    const owner = yield select(getUser);

    const {
      payload: { pageId, user },
    } = action;
    const {
      data: { UserExist, UserInvite, ContactUser },
    } = yield requestGetSomeUserContent(['email', user.email]);
    const newUuid = uuidv4();
    const newContactUuid = uuidv4();

    const isExistInContact = !!ContactUser?.length || user.email === owner.email;
    const { shareState } = yield select(getCurrentPage);
    const updateAvailableTo = shareState.sharedAvailableTo || shareState.availableTo;
    const updateAvailableFrom = shareState.sharedAvailableFrom || shareState.availableFrom;
    if (UserExist[0]) {
      const fieldsUpdateObg = { isShareToWeb: false };
      if (updateAvailableTo) fieldsUpdateObg.availableTo = updateAvailableTo;
      if (updateAvailableFrom) fieldsUpdateObg.availableFrom = updateAvailableFrom;
      yield requestUpdateLibraryComponent()({
        place: 'queryAddUsersToSharing',
        id: pageId,
        connect: { usersToSharing: [UserExist[0].id] },
        fieldsUpdateObg,
      });
    } else if (UserInvite[0]) {
      const fieldsUpdateObg = { isShareToWeb: false };
      if (updateAvailableTo) fieldsUpdateObg.availableTo = updateAvailableTo;
      if (updateAvailableFrom) fieldsUpdateObg.availableFrom = updateAvailableFrom;
      yield requestUpdateLibraryComponent()({
        place: 'queryAddUsersInvitedToSharing',
        id: pageId,
        connect: { usersInviteToSharing: [UserInvite[0].id] },
        fieldsUpdateObg,
      });
    } else {
      const fieldsUpdateObg = { isShareToWeb: false };
      if (updateAvailableTo) fieldsUpdateObg.availableTo = updateAvailableTo;
      if (updateAvailableFrom) fieldsUpdateObg.availableFrom = updateAvailableFrom;

      yield requestUpdateLibraryComponent()({
        place: 'queryCreateAndAddUsersInvitedToSharing',
        id: pageId,
        create: { usersInviteToSharing: [{ id: newUuid, email: user.email }] },
        fieldsUpdateObg,
      });
    }
    if (!isExistInContact) {
      const creatingContactData = {
        id: newContactUuid,
        email: user.email,
        createDate: Math.floor(Date.now() / 1000),
        userId: owner.id,
        userForContact: {
          id: UserExist[0]?.id || newUuid,
          userType: UserExist[0] ? '' : 'InviteUser',
        },
      };

      yield requestCreateContactContact(queryCreateContact(creatingContactData));
      yield put(
        actionCreator(Contacts.AddSingleToContactMap, { creatingContactData }),
      );
    }

    if (updateAvailableTo) yield put(actionUpdateShareState({ availableTo: updateAvailableTo }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* RemoveUserToSharing(action) {
  try {
    const {
      payload: { pageId, user },
    } = action;
    const {
      data: { UserExist, UserInvite },
    } = yield requestGetSomeUserContent(['email', user.email]);
    const disconnect = {};

    if (UserExist[0]) disconnect.usersToSharing = [UserExist[0].id];
    if (UserInvite[0]) disconnect.usersInviteToSharing = [UserInvite[0].id];
    yield requestUpdateLibraryComponent()({
      place: 'queryRemoveUsersToSharing',
      id: pageId,
      disconnect,
    });
    // TODO check socket
    yield put(
      sendMessage({
        closePage: pageId,
        closeForUser: UserExist[0]?.id,
      }),
    );
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* SendEmailToSharing(action) {
  try {
    const {
      payload: {
        users,
        pageId,
        update,
      },
    } = action;

    const usersEmail = users.map(i => i.email).filter(i => i);
    const newShareState = { };
    if (update.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
      newShareState.sharedAvailableFrom = 0;
      newShareState.sharedAvailableTo = 0;
    } else {
      const {
        availableFrom,
        availableTo,
      } = update;

      newShareState.sharedAvailableFrom = availableFrom ? Math.floor(
        availableFrom?.getTime() / 1000,
      ) : 0;
      newShareState.sharedAvailableTo = availableFrom ? Math.floor(
        availableTo?.getTime() / 1000,
      ) : 0;
    }
    //
    //
    //
    // if (!emailsToInvite.length) return;
    // yield put(
    //   actionCreator(EditPage.SendingOnEmail, {
    //     emailsToInvite,
    //     pageId,
    //     sendStatus: downloadStatus.pending,
    //   }),
    // );

    // try {
    yield call(requestSendEmailToSharing, { pageId,
      emailsToUpdate: usersEmail,
      update: newShareState,
    });
    yield put(actionCreator(CurrentPage.setManyUserShareState, { users }));

    yield put(
      actionShowMessage({
        type: MessageType.InviteUserToSharedPage,
      }),
    );
    yield put(
      actionCreator(ContentActionType.ShareMultipleR, {
        ids: [pageId],

        shareState: {
          usersToSharing: users,
          availableFrom: update.availableFrom,
          availableTo: update.availableTo,
          isShareToWeb: update.isShareToWeb,
        },
      }),
    );
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* clearBlockWithFile(action) {
  try {
    const {
      payload: { blockId, oldType: type },
    } = action;
    const editableBlocks = yield select(getBlocks);
    const currentPage = yield select(getCurrentPage);

    const index = editableBlocks.findIndex((block) => block.id === blockId);
    const position = editableBlocks[index]?.position;
    const content = {};
    if (editableBlocks[index]?.state && !editableBlocks[index].state.data) {
      content.state = RichUtils.toggleBlockType(
        editableBlocks[index].state,
        type,
      );
    } else {
      const newEmptyState = EditorState.createWithText(
        '',
        DefaultDraftBlockRenderMap1[type],
      );
      content.state = RichUtils.toggleBlockType(newEmptyState, type);
    }

    const newBlock = {
      id: editableBlocks[index].id,
      ...content,
      type,
      position,
      isHidden: editableBlocks[index]?.isHidden,
      isNew: true,
    };

    const blockState = [
      ...editableBlocks.slice(0, index),
      newBlock,
      ...editableBlocks.slice(index + 1),
    ];

    yield put(
      actionSwitchPage({
        blocks: blockState,
        newBlockId: editableBlocks[index].id,
      }),
    );

    yield put(actionUpdateLayers(getLayersForPage(blockState)));
    yield call(requestUpdateLibraryComponent(placementEnum.deleteRelation), {
      blockId: newBlock,
      libComponentId: currentPage.id,
    });
    const reqPayload = createChangeComponentTypeBody(newBlock, currentPage.id);
    if (reqPayload) yield call(requestUpdateLibraryComponent(), reqPayload);
    yield put(
      actionAddLibraryPageComponent({
        id: currentPage.id,
        newComponent: { ...newBlock, nestedItemId: null },
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* ConvertBlockIntoEmbed(action) {
  try {
    const { block } = action.payload;
    yield requestUpdateComponent(placementEnum.mutationAddMediaIntoEmbedBlock)({
      component: block,
      nestedComponentId: block.state.data.libraryComponentId,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* ShareMultiplePages(action) {
  try {
    const {
      payload: {
        users,
        update,
      },
    } = action;

    const newShareState = { };
    if (update.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
      newShareState.sharedAvailableFrom = 0;
      newShareState.sharedAvailableTo = 0;
    } else {
      const {
        availableFrom,
        availableTo,
      } = update;

      newShareState.sharedAvailableFrom = availableFrom ? Math.floor(
        availableFrom?.getTime() / 1000,
      ) : 0;
      newShareState.sharedAvailableTo = availableFrom ? Math.floor(
        availableTo?.getTime() / 1000,
      ) : 0;
    }

    const usersEmail = users.map(i => i.email).filter(i => i);

    const selectedPages = yield select(getSelectedPage);


    const pagesIds = Object.keys(selectedPages);

    try {
      const response = yield call(requestSendEmailToSharingMany, {
        pagesIds,
        emailsToInvite: usersEmail,
        update: newShareState,
      });
      // const response = yield call(requestSendEmailToSharingMany, {
      //   pagesIds,
      //   emailsToInvite,
      //   fields: {
      //     ...shareState,
      //   },
      // });

      const { message } = response.data;

      yield put(
        actionShowMessage({
          type: MessageType.MultipleShareSuccess,
          itemType: 'SmartPages',
          isSingle: Object.keys(selectedPages).length === 1,
          message,
        }),
      );


      yield put(
        actionCreator(ContentActionType.ShareMultipleR, {
          ids: Object.keys(selectedPages),

          shareState: {
            usersToSharing: users,
            availableFrom: update.availableFrom,
            availableTo: update.availableTo,
            isShareToWeb: update.isShareToWeb,
          },
        }),
      );
      yield put(actionCreator(OutboxAction.UpdateCounterS));
    } catch (err) {
      const status = err?.response?.status;
      if (!status) {
        yield showErrorMessage(err);
        return;
      }
      const { message } = err.response.data;

      if (status === 500) {
        yield put(
          actionShowMessage({
            type: MessageType.MultipleShareError,
            itemType: 'SmartPage',
            message,
          }),
        );
      } else {
        yield put(
          actionShowMessage({
            type: MessageType.MultipleShareWarning,
            itemType: 'SmartPages',
            message,
          }),
        );
      }
    }
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* editShareStatePageSingleUser(action) {
  try {
    const {
      payload: {
        // state,
        // field,
        availableFrom,
        availableTo,
        email,
      },
    } = action;
    const { id } = yield select(getCurrentPage);

    const sharedAvailableFrom = availableFrom ? Math.floor(
      availableFrom?.getTime() / 1000,
    ) : 0;
    const sharedAvailableTo = availableFrom ? Math.floor(
      availableTo?.getTime() / 1000,
    ) : 0;
    const newShareState = {
      sharedAvailableFrom,
      sharedAvailableTo,
    };

    yield put(actionSetSingleUserShareState({ ...newShareState, email }));
    // TODO SOCKET CLOSE PAGE FOR CERTAIN USER

    yield call(requestUpdateSingleUserSharePageOptions(id, email), newShareState);
  } catch (err) {
    yield showErrorMessage(err);
  }
}


function* updateShareToWebStatePage(action) {
  const { id } = yield select(getCurrentPage);
  try {
    const {
      payload: {
        updateTime,
        isShareToWeb,
        accessCode,
        isNeedAccess,
        playlistId = id,
      },
    } = action;
    const newShareState = { accessCode: `"${accessCode}"`, isNeedAccess };
    if (updateTime) {
      if (updateTime.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
        newShareState.availableFrom = 0;
        newShareState.availableTo = 0;
        newShareState.isShareToWeb = true;
      } else {
        const {
          availableFrom,
          availableTo,
        } = updateTime;

        newShareState.availableFrom = availableFrom ? Math.floor(
          availableFrom?.getTime() / 1000,
        ) : 0;
        newShareState.availableTo = availableFrom ? Math.floor(
          availableTo?.getTime() / 1000,
        ) : 0;
        newShareState.isShareToWeb = true;
      }
    } else {
      newShareState.isShareToWeb = isShareToWeb;
    }

    yield put(actionUpdateShareState(newShareState));

    yield call(requestUpdateLibraryComponent(), {
      id: playlistId,
      fieldsUpdateObg: newShareState,
    });
    yield delay(200);
    yield put(actionSetGlobalShareStateToSingleUserState(newShareState));
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* sendEventUpdatePage(action) {
  try {
    const { payload: { users = [] } } = action;
    yield call(sendEventUpdate, ({
      type: 'page',
      users,
    }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* pageSaga() {
  yield takeEvery(EditPage.ChangeEditableBlock, changeEditableBlock);
  yield takeEvery(EditPage.AddManyEditableBlock, addManyEditableBlock);
  yield takeEvery(
    SupportAction.AddSupportEditableBlockRedux,
    addEditableBlockRedux,
  );
  yield takeEvery(EditPage.AddEditableBlock, addEditableBlock);
  yield takeEvery(EditPage.addDuplicateBlock, addDuplicateBlock);
  yield takeEvery(EditPage.multipleDuplicateBlock, multipleDuplicateBlock);
  yield takeEvery(
    EditPage.RestoreAfterDeleteEditableBlock,
    restoreAfterDeleteEditableBlock,
  );
  yield takeEvery(
    EditPage.RestoreAfterDeleteManyEditableBlock,
    restoreAfterDeleteManyEditableBlock,
  );
  yield takeEvery(EditPage.ResizeImageInBlock, resizeImageInBlock);
  yield takeEvery(EditPage.ChangeTypeEditableBlock, ChangeTypeEditableBlock);
  yield takeEvery(EditPage.DeleteEditableBlock, deleteEditableBlock);
  yield takeEvery(EditPage.DeleteManyBlocks, deleteManyBlocks);
  yield takeEvery(EditPage.DragManyBlock, dragManyBlock);
  yield takeEvery(EditPage.AddNewEmptyEditableBlock, addNewEmptyEditableBlock);
  yield takeEvery(SupportAction.DragManySupportBlocksRedux, dragManyBlockRedux);
  yield takeEvery(CurrentPage.ChangeTypeBlock, changeTypeBlock);
  yield takeLatest(GO_TO_VIEW_PAGE, goToViewPage);
  yield takeLatest(GET_CURRENT_PAGE, downloadCurrentPage);
  // yield takeLatest(MiniPages.getMiniPages, downloadMiniPages);
  // yield takeLatest(MiniPages.getMiniPagesClear, downloadMiniPages);
  yield takeEvery(MiniPages.saveMiniPagesForBuilder, saveMiniPagesForBuilder);
  yield takeEvery(EditPage.SeparateBlock, separateEditableBlockWithState);
  yield takeEvery(
    SupportAction.CreateSupportEditableBlockWithState,
    createEditableBlockWithStateRedux,
  );
  yield takeEvery(CurrentPage.ToggleComponentIsHidden, toggleComponentIsHidden);
  yield takeEvery(CurrentPage.ClearBlockWithFile, clearBlockWithFile);
  yield takeEvery(
    CurrentPage.ChangeStateComponentButton,
    changeStateComponentButton,
  );
  yield takeEvery(
    CurrentPage.BulkToggleComponentIsHidden,
    bulkChangeToggleComponentIsHidden,
  );
  yield takeLatest(CurrentPage.SetAvailabilityDate, setAvailabilityDate);
  // yield takeLatest(CurrentPage.GetShareLink, getShareLink);
  yield takeLatest(SupportAction.JumpToPageTitle, jumpToPageTitle);
  yield takeEvery(SupportAction.LoadPageSettings, loadPageSettingsData);
  yield takeEvery(EditPage.AddUserToSharing, AddUserToSharing);
  yield takeEvery(EditPage.RemoveUserToSharing, RemoveUserToSharing);
  yield takeLatest(EditPage.SendEmailToSharingS, SendEmailToSharing);
  yield takeLatest(
    CurrentPage.Share.GET_CURRENT_SHARED_PAGE,
    downloadSharedCurrentPage,
  );

  yield takeEvery(EditPage.ConvertBlockIntoEmbed, ConvertBlockIntoEmbed);
  yield takeEvery(EditPage.ShareMultiplePagesS, ShareMultiplePages);
  yield takeEvery(EditPage.editShareStatePageSingleUser, editShareStatePageSingleUser);
  yield takeEvery(EditPage.editShareStateToWeb, updateShareToWebStatePage);
  yield takeEvery(EditPage.sendEventUpdatePage, sendEventUpdatePage);

  // yield takeEvery(ServiceUser.ResetShowOnlyNotNestedPages, toggleShowOnlyNotNestedPages);
  // yield takeEvery(EditPage.MovePageToFolder, movePageToFolder);
  // yield takeEvery(EditPage.RenamePageToFolder, renamePageToFolder);
  // yield takeEvery(EditPage.DeleteFolder, deleteFolder);
  // yield takeEvery(EditPage.MoveFolderToTrash, MoveFolderToTrash);
  // yield takeEvery(EditPage.MoveFolderFromTrash, MoveFolderFromTrash);
  // yield takeEvery(EditPage.DeletePage, deletePage);
  // yield takeEvery(EditPage.MovedToTrashPage, MovedToTrashPage);
  // yield takeEvery(EditPage.MovedFromTrashPage, MovedFromTrashPage);
  // yield takeEvery(EditPage.ToggleFavorite, toggleFavorite);
  // yield takeEvery(EditPage.MovePage, movePage);
  // yield takeEvery(EditPage.DuplicatePage, duplicatePage);
  // yield takeEvery(EditPage.DuplicateSharedPage, duplicateSharedPage);
  // yield takeEvery(EditPage.TogglePublishPage, editShareStatePage);
  // yield takeEvery(EditPage.RenameFolder, renameFolder);
  // yield takeEvery(EditPage.UpdatePages, updatePages);
  // yield takeLatest(EditPage.CreateNewPageInFolder, createNewPageInFolder);
  // yield takeLatest(CurrentPage.GetViewPage, downloadViewPage);
  // yield takeEvery(MiniPages.getMiniPageForce, downloadMiniPage);
  // yield takeLatest(EditPage.PageChannelPublishManagement, pageChannelPublishManagement);
}
