import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronSVG } from '../../../../images/2023/svg/small/complete/chevron_10_6.svg';
import { ReactComponent as ChannelsSvg } from '../../../../images/icons/shareModal/channels.svg';

import styles from '../../index.module.scss';

const cx = classNames.bind(styles);
interface IChannelsTab {
    setCurrentMode: (key: string) => void,
    channelsList: { name: string, id: string }[],
    mixedSharedToChannel: number,
    isMultiple: boolean,
}
export const ChannelsTab: React.FC<IChannelsTab> = ({
  setCurrentMode,
  channelsList,
  isMultiple,
  mixedSharedToChannel,
}) => {
  const { t } = useTranslation();
  const onClickHandler = () => {
    if (!isMultiple) setCurrentMode('channels');
  };

  return (
    <div className={cx('shared_option')} onClick={onClickHandler}>
      <ChannelsSvg className={cx('shared_option_icon')} />
      <div className={cx('shared_option_content')}>
        <div className={cx('option_title', 'text_unified-base')}>{t('channelsT')}</div>
        {!isMultiple && !!channelsList.length && (
          channelsList.map((channel: { name: string, id: string }, index: number) => (
            <span key={channel.id} className={cx('option_description', 'text_unified-sm')}>
              {channel.name}{index < channelsList.length - 1 ? ', ' : ''}
            </span>
          ))
        )}
        {isMultiple && !!mixedSharedToChannel && (
          <span className={cx('option_description', 'text_unified-sm')}>
            Mixed, {mixedSharedToChannel} channels
          </span>
        )}
        {!channelsList.length && !mixedSharedToChannel && (
          <div className={cx('option_description', 'text_unified-sm')}>{t('noChannelsT')} </div>
        )}
      </div>
      {!isMultiple && <ChevronSVG className={cx('shared_option_chevron')} />}
    </div>

  );
};
