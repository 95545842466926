import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ResetView from './ResetView';
import { actionSendResetPasswordRequest } from '../../../redux/support/action';

const ResetPassword = ({}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { requestSpinner } = useSelector((state) => state.support);
  const params = useParams();

  const [generalError, setGeneralError] = useState('');

  const [errorMsgPassword, setErrorMsgPassword] = useState('');
  const [isTouchedPassword, setIsTouchedPassword] = useState(false);
  const [valuePassword, setValuePassword] = useState('');
  const [showPassword, setShowPassword] = useState('password');
  const [showConfirmation, setShowConfirmation] = useState('password');

  const [errorMsgConfirmation, setErrorMsgConfirmation] = useState('');
  const [isTouchedConfirmation, setIsTouchedConfirmation] = useState(false);
  const [valueConfirmation, setValueConfirmation] = useState('');

  const onSubmit = () => {
    if (!valuePassword) setErrorMsgPassword('Password can not be blank');
    if (valuePassword.length < 6) setErrorMsgPassword('Password must be 6 symbols at least');
    if (!valueConfirmation) setErrorMsgConfirmation('Confirmation can not be blank');
    if (valueConfirmation.length < 6) setErrorMsgConfirmation('Confirmation must be 6 symbols at least');
    if (valueConfirmation !== valuePassword) setGeneralError('Password doesnt match');
    if (
      !!valuePassword
      && valuePassword.length >= 6
      && !!valueConfirmation
      && valueConfirmation.length >= 6
      && valueConfirmation === valuePassword
    ) {
      setValuePassword('');
      setValueConfirmation('');
      dispatch(actionSendResetPasswordRequest(valuePassword, history, params));
    }
  };

  const onValueChangePassword = (e) => {
    setErrorMsgPassword('');
    setGeneralError('');
    setValuePassword(e.target.value);
  };
  const onInputBlurPassword = () => {
    if (!valuePassword) setErrorMsgPassword('This field is required');
  };
  const onInputFocusPassword = () => {
    if (!isTouchedPassword) setIsTouchedPassword(true);
  };
  const onInputKeyPressPassword = (e) => {
    if (e.code === 'Enter') onSubmit();
  };
  const togglePasswordShow = () => {
    if (showPassword === 'password') setShowPassword('text');
    else setShowPassword('password');
  };

  const toggleConfirmationShow = () => {
    if (showConfirmation === 'password') setShowConfirmation('text');
    else setShowConfirmation('password');
  };

  const onValueChangeConfirmation = (e) => {
    setErrorMsgConfirmation('');
    setGeneralError('');
    setValueConfirmation(e.target.value);
  };
  const onInputBlurConfirmation = () => {
    if (!valueConfirmation) setErrorMsgConfirmation('This field is required');
  };
  const onInputFocusConfirmation = () => {
    if (!isTouchedConfirmation) setIsTouchedConfirmation(true);
  };
  const onInputKeyPressConfirmation = (e) => {
    if (e.code === 'Enter') onSubmit();
  };
  const goToSignIn = () => {
    history.push('/login');
  };
  return (
    <ResetView
      errorMsgPassword={errorMsgPassword}
      isTouchedPassword={isTouchedPassword}
      valuePassword={valuePassword}
      showPassword={showPassword}
      onValueChangePassword={onValueChangePassword}
      onInputBlurPassword={onInputBlurPassword}
      onInputFocusPassword={onInputFocusPassword}
      onInputKeyPressPassword={onInputKeyPressPassword}
      errorMsgConfirmation={errorMsgConfirmation}
      isTouchedConfirmation={isTouchedConfirmation}
      valueConfirmation={valueConfirmation}
      onValueChangeConfirmation={onValueChangeConfirmation}
      onInputBlurConfirmation={onInputBlurConfirmation}
      onInputFocusConfirmation={onInputFocusConfirmation}
      onInputKeyPressConfirmation={onInputKeyPressConfirmation}
      generalError={generalError}
      toggleConfirmationShow={toggleConfirmationShow}
      togglePasswordShow={togglePasswordShow}
      showConfirmation={showConfirmation}
      onSubmit={onSubmit}
      requestSpinner={requestSpinner}
      onSignIn={goToSignIn}
    />
  );
};

export default ResetPassword;
