import { centerCrop, makeAspectCrop } from 'react-image-crop';

export const defaultCrop = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  unit: 'px',
};

export function convertToPixelCrop(crop, containerWidth, containerHeight) {
  if (!crop.unit) {
    return { ...defaultCrop, ...crop, unit: 'px' };
  }

  if (crop.unit === 'px') {
    return { ...defaultCrop, ...crop, unit: 'px' };
  }

  return {
    unit: 'px',
    x: crop.x ? (crop.x * containerWidth) / 100 : 0,
    y: crop.y ? (crop.y * containerHeight) / 100 : 0,
    width: crop.width ? (crop.width * containerWidth) / 100 : 0,
    height: crop.height ? (crop.height * containerHeight) / 100 : 0,
  };
}

export const calcNewCropStyles = ({ width, height }, sliderPosition, crop) => {
  if (crop) {
    const aspect = 1;

    const centerX = crop.x + crop.width / 2;
    const centerY = crop.y + crop.height / 2;
    const maxSide = width > height ? 'height' : 'width';
    const newCrop = makeAspectCrop(
      {
        unit: '%',
        [maxSide]: sliderPosition,
      },
      aspect,
      width,
      height,
    );

    newCrop.x = centerX - newCrop.width / 2;
    newCrop.y = centerY - newCrop.height / 2;

    newCrop.x = Math.max(0, Math.min(newCrop.x, 100 - newCrop.width));
    newCrop.y = Math.max(0, Math.min(newCrop.y, 100 - newCrop.height));

    return newCrop;
  }
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: sliderPosition,
        height: sliderPosition,
      },
      1,
      width,
      height,
    ),
    width,
    height,
  );
};
export const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};
