import React from 'react';
import { useTranslation } from 'react-i18next';
import { CutModeImageAndNameWrapper, ImgWrap, Title } from './styled';
import { ReactComponent as HoverIconSvg } from '../../images/2023/svg/hover_pencil.svg';
import FolderImage from './FolderImage';
import FolderIconLayout from './FolderIconLayout';
import {
  GreyItemsSplitter,
  ItemWrapperCutHeader,
  TextWrapperUPV,
  GlobalFlexRowWrapper,
  DetailsWrapper,
  HoverWrapper,
  CoverWrapper,
} from './components/Info/styled';
import { timeSince } from '../../utils/dateConvert';

const ImageAndName = React.memo(
  function ImageAndName({
    src,
    title,
    isWithProgressBar,
    small,
    color,
    isShowLibraryWidget,
    itemId,
    elemsCount,
    size,
    coeditors,
    lastModified,
    setIsActive,
    coverRef,
    isSmall,
  }) {
    const { t } = useTranslation();
    const onClickHandler = (e) => {
      e.stopPropagation();
      setIsActive(true);
    };
    return (
      <CutModeImageAndNameWrapper
        small={small}
        isWithProgressBar={isWithProgressBar}
      >
        <CoverWrapper onClick={onClickHandler} ref={coverRef}>
          {src ? (
            <>
              <ImgWrap src={src} />
              {!isSmall && <FolderIconLayout isOverCoverImage />}
            </>
          ) : (
            <FolderImage itemId={itemId} isSmall currentColor={color} />
          )}
          <HoverWrapper>
            <HoverIconSvg />
          </HoverWrapper>
        </CoverWrapper>
        <DetailsWrapper>
          <Title
            small={small}
            isShowLibraryWidget={isShowLibraryWidget}
            isWithProgressBar={isWithProgressBar}
          >
            {title || t('unnamedSmartFileT')}
          </Title>
          <GlobalFlexRowWrapper>
            {!!coeditors?.length && (
              <ItemWrapperCutHeader>
                <TextWrapperUPV>{coeditors?.length} {t('co-editors')}</TextWrapperUPV>
                <GreyItemsSplitter />
              </ItemWrapperCutHeader>
            )}
            <ItemWrapperCutHeader>
              <TextWrapperUPV>{elemsCount || 0} {t('itemsLowT')}</TextWrapperUPV>
            </ItemWrapperCutHeader>
            <ItemWrapperCutHeader>
              <GreyItemsSplitter />
              <TextWrapperUPV>{size}</TextWrapperUPV>
            </ItemWrapperCutHeader>
            <ItemWrapperCutHeader>
              <GreyItemsSplitter />
              <TextWrapperUPV>{timeSince(lastModified * 1000)}</TextWrapperUPV>
            </ItemWrapperCutHeader>
          </GlobalFlexRowWrapper>
        </DetailsWrapper>
      </CutModeImageAndNameWrapper>
    );
  });

export default ImageAndName;
