import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';
import rawElemStyles from './elements.module.scss';
import { ReactComponent as SixDotsSvg } from '../../../images/icons/six_dots.svg';
import { ReactComponent as None } from '../../../images/icons/icon-colour-none.svg';
import { ReactComponent as Add16Svg } from '../../../images/icons/plus_16.svg';
import { isRoleInPlaylist } from '../../../utils/permissions';
import { getItemName } from '../../../utils/helpers';
import { UserAvatarWrapper } from '../styled';
import { BatchActionsPopup } from '../BatchActionsPopup';
import { actionRemoveLinkPageFromPlaylist } from '../../../redux/currentPage/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { CurrentPage } from '../../../redux/currentPage/types';
import { axiosAbortarium } from '../../../utils/axiosAbortarium';

import Playlist from '../../../redux/playlists/types';
import { actionShowMessage } from '../../../redux/support/action';
import { empty, Group, MessageType, openModalType } from '../../../utils/constants';
import { LibraryComponents } from '../../../redux/library/types';
import CheckBoxLinkPage from '../../../entities/CheckBoxLinkPage';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { actionOpenModal } from '../../../redux/user/action';
import i18n from '../../../i18n';

const cx = classNames.bind(rawElemStyles);

const SixDotsAnalogWrap = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 6px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  position: sticky;
  top: 114px;
  background: #ffc72c;
  z-index: 31;
  transform: translate(0%, -50%);
    & svg{
        width: 22px;
        height: 22px;
    }
`;

const SixDotsAnalogIndentWrap = styled.div`
    position: absolute;
    left: -15px;
    height: inherit;
`;

const SixDotsOverlayWrap = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
`;
//
// const clearLineStyle = (ref) => {
//   if (ref?.current) {
//     ref.current.style.height = '';
//     ref.current.style.background = '';
//     ref.current.style['border-width'] = '';
//     ref.current.style['border-color'] = '';
//     setTimeout(() => {
//       if (ref.current?.style) {
//         ref.current.style.color = '';
//       }
//     }, 200);
//   }
// };
//
// const setLineStyle = (ref) => {
//   if (ref?.current) {
//     ref.current.style.height = '1px';
//     ref.current.style['border-width'] = '1px';
//     ref.current.style['border-color'] = '#DDA20E';
//     ref.current.style.background = '#DDA20E';
//     ref.current.style.color = '#DDA20E';
//   }
// };
//

const SixDotsItem = ({
  menuRef,
  isActive,
  setIsActive,
  itemStylesObject,
  otherOwner,
  duplicateHandler,
  refToGet,
  refToGetNext,
  currentRole,
  refHoverForPositionAction,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedItems } = useSelector(state => state.currentPage);
  const { isViewer: isViewerEx, isCoEdit: isCoEditEx } = isRoleInPlaylist;

  const isViewer = isViewerEx[currentRole];
  const isCoEdit = isCoEditEx[currentRole];
  const isTitle = itemStylesObject?.isTitle;
  const isDisabled = (isViewer || isCoEdit && otherOwner) && props.item.isRemixLocked;
  const { id } = useSelector((state) => state.currentPage);
  const user = useSelector((state) => state.user || empty);
  const clickHandler = (e) => {
    e && e.stopPropagation();
    if (isViewer) return;
    props.clear();
    setIsActive(!isActive);
  };
  const buttonRef = useRef(null);

  // const [positionPlus, setPositionPlus] = useState(0);
  // const [showPlus, setShowPlus] = useState(false);
  // const [isTop, setIsTop] = useState(false);
  // const [isLineVisible, setIsLineVisible] = useState(false);

  // const handleMouseMove = (event) => {
  // if (isActive) return;
  //
  // const elementB = refHoverForPositionAction?.current;
  // if (elementB) {
  //   const rect = elementB.getBoundingClientRect();
  //
  //   const y = event.clientY - rect.top;
  //   const mousePositionElement = event.clientY - rect.top;
  //
  //   const IsHidden = mousePositionElement < 0 || mousePositionElement > rect.height;
  //   setShowPlus(!IsHidden);
  //   setPositionPlus(y - 35);
  //   const isTop = y < (rect.height / 2 + 20);
  //   const showLine = !IsHidden && isLineVisible;
  //   setIsTop(isTop);
  //   if (!showLine) {
  //     clearLineStyle(refToGetNext);
  //     clearLineStyle(refToGet);
  //     return;
  //   }
  //   if (isTop) {
  //     setLineStyle(refToGet);
  //     clearLineStyle(refToGetNext);
  //   } else {
  //     clearLineStyle(refToGet);
  //     setLineStyle(refToGetNext);
  //   }
  // }
  // };

  // useEffect(() => {
  //   const elementB = refHoverForPositionAction?.current;
  //   const handlemouseLeave = () => {
  //     setShowPlus(false);
  //     setIsLineVisible(false);
  //   };
  //   if (elementB) {
  //     elementB.addEventListener('mousemove', handleMouseMove);
  //     elementB.addEventListener('mouseleave', handlemouseLeave);
  //   }
  //
  //   return () => {
  //     if (elementB) {
  //       elementB.removeEventListener('mousemove', handleMouseMove);
  //       elementB.removeEventListener('mouseleave', handlemouseLeave);
  //     }
  //   };
  // }, [isActive, isTop, isLineVisible]);

  const deleteLinkPageClickHandler = (e) => {
    e.stopPropagation();
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        props.socketId || props.playlistId,
        props.item.id,
        props.item.textComponent?.id || props.item.libraryComponent?.id,
      ),
    );
    if (props.setShow) {
      props.setShow(false);
    }
    if (props.goNext) {
      props.goNext();
    }
  };

  const summarizeHandler = () => {
    if (isTitle) {
      dispatch(actionShowMessage({
        type: MessageType.NeutralRegular,
        text: i18n.t('ActionNotSupportedT'),
      }));
    } else {
      dispatch(
        actionCreator(CurrentPage.Summarize, {
          linkPages: [props.item.id],
        }),
      );
    }
  };

  const translateHandler = () => {
    dispatch(
      actionOpenModal(openModalType.BatchActionsUniversal,
        { type: 'translate', element: props.item }),
    );
  };

  const onLockButtonClickHandler = (e) => {
    e.stopPropagation();
    if (props.isDisabled) {
      return;
    }

    dispatch(
      actionCreator(CurrentPage.UpdateRemixState, {
        linkPageId: props.item.id,
        playlistId: id,
        value: !props.item.isRemixLocked,
      }),
    );
  };

  const isOwner = user.id === props.item?.owner?.id;
  const isDownloadDisabled = !isOwner && props.item.isRemixLocked;
  const onDownloadButtonClickHandler = (e) => {
    e.stopPropagation();

    const libraryComponentId = props.item?.libraryComponent?.id
      || props.item?.textComponent?.id;
    if (props.item.isRemixLocked && isDownloadDisabled) {
      return;
    }
    axiosAbortarium.generateNew(props.item.id);
    dispatch(
      actionShowMessage({
        type: MessageType.DownloadOneItem,
        itemName: 'playlist',
        mode: Group.processing,
        id: libraryComponentId,
      }),
    );

    dispatch(
      actionCreator(Playlist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: props.item?.title || props.item?.libraryComponent?.title,
        elementType: props.item?.libraryComponent?.type || props.item?.type,
        isText: !!props.item.textComponent?.id,
        text: getItemName(props.item),
        owner: props.item.owner || { id: user.id },
      }),
    );
  };

  const onAddItemsToLibraryClickHandler = (e) => {
    e.stopPropagation();
    if (
      props.item.type !== 'elementComponent'
      && props.item?.libraryComponent?.type !== 'page'
    ) return;
    dispatch(
      actionCreator(LibraryComponents.Player.DuplicateSharedComponent, {
        componentId: props.item?.libraryComponent?.id,
        linkPageId: props.item?.id,
        type: props.item?.libraryComponent?.type,
      }),
    );
  };
  const handleAddToSF = () => {
    dispatch(
      actionOpenModal(openModalType.BatchActionsUniversal,
        { element: props.item, type: 'AddToSF' }),
    );
  };

  const SixDotsPortal = () => {
    const sixDotsBtnPos = buttonRef.current?.getBoundingClientRect();
    const { left, top } = sixDotsBtnPos;
    const { innerHeight: height } = window;
    const topMaxByTop = Math.max(top, 200)
    const topMinByBottom = Math.min(topMaxByTop, height - 245)
    return (
      <SixDotsOverlayWrap>
        <BatchActionsPopup
          popupRef={menuRef}
          item={props.item}
          selectedItems={selectedItems}
          setClosed={setIsActive}
          downloadHandler={onDownloadButtonClickHandler}
          duplicateHandler={duplicateHandler}
          deleteHandler={deleteLinkPageClickHandler}
          lockHandler={onLockButtonClickHandler}
          summarizeHandler={summarizeHandler}
          translateHandler={translateHandler}
          addToSmartfileHandler={handleAddToSF}
          addToLibraryHandler={onAddItemsToLibraryClickHandler}
          isSixDots
          left={left}
          top={topMinByBottom}
        />
      </SixDotsOverlayWrap>
    );
  };

  const createHandler = (e) => {
    props.createHandler(true, e, false);
    // setIsLineVisible(false);
    // clearLineStyle(refToGet);
    // clearLineStyle(refToGetNext);
  };

  return (
    <>
      <div
        className={cx('six_dot_wrap', {
          just_a_viewer: isViewer,
          on: isActive,
          // isCardView: !isExpandedView,
        })}
        // onMouseLeave={() => setIsLineVisible(false)}

      >
        <div
          className={cx('checkbox_balancer', {
            selected_checkbox: selectedItems[props.item.id],
            isPlaceHolder: props.isPlaceHolder,
          })}
        >
          <CheckBoxLinkPage
            itemId={props.item.id}
            isSelected={selectedItems[props.item.id]}
            isDisabled={isDisabled}
          />
        </div>
        <div className={cx('six_dot_positioning')}>
          {!isViewer && (
          <div
            data-cy="playlist-element-hover"
            ref={buttonRef}
            className={cx('six_dot_dropMenu_wrapper', { six_dot_dropMenu_wrapper_active: isActive })}
          >
            {isActive && (
            <div className={cx('batch_actions_wrapper')}>
              {createPortal(SixDotsPortal(), document.body)}
            </div>
            )}
            {!otherOwner && (
            <Tooltip
              text={t('sixDotsTooltipT')}
              direction="down"
              place="six_dots"
            >
              <UserAvatarWrapper
                draggable={!otherOwner}
                onDragStart={props.dragStartHandler}
                onDragEnd={props.onDragEndHandler}
                className={cx(
                  { six_dot_icon: !otherOwner, otherOwner: !!otherOwner, on: isActive },
                )}
                onClick={clickHandler}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              >
                <SixDotsSvg />
              </UserAvatarWrapper>
            </Tooltip>
            )}
          </div>
          )}
          {otherOwner && (
            <SixDotsAnalogIndentWrap>
              <SixDotsAnalogWrap
                onClick={clickHandler}
                className={cx('show_analog_wrap_on_hover')}
              >
                <None />
              </SixDotsAnalogWrap>
            </SixDotsAnalogIndentWrap>
          )}
          {!isTitle && (
          <div className={cx('six_dot_permanent_line')}>
            <div className={cx('line', `${itemStylesObject.style}`)} />
          </div>
          )}
        </div>
      </div>
      {!isViewer && (
      // {!isViewer && showPlus && (
      <div
        className={cx('six_dot_stripe_shell')}
      >
        <div
          // onMouseEnter={() => setIsLineVisible(true)}
          // onMouseLeave={() => setIsLineVisible(false)}
          className={cx('six_dot_stripe')}
        >
          <Tooltip text={t('plusTooltipBellowT')} direction="down" place="plus_item">
            <div
              onClick={createHandler}
              className={cx('plus', { plus_viewer: isViewer })}
            >
              <Add16Svg />
            </div>
          </Tooltip>
        </div>
      </div>
      )}
    </>
  );
};

export default SixDotsItem;
