import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import { ReactComponent as LogoImg } from '../../images/2023/svg/Logo.svg';

import { DATA_PARENTS, DEFAULT_PAGE_PATH, emptyCallback } from '../../utils/constants';

const LogoWrapper = styled.div`
  width: max-content;
  margin-top: 16px;
  margin-left: 16px;
  max-height: 34px;
  display: flex;
  align-items: center;
  gap: 14px;

  svg:first-child   {
    cursor: pointer;
  }
  
  &:hover {
    & .logoChevronWrapper {
      visibility: visible;
    }
  }

  &.active {
    background-color: #dde2f9;
  }
`;

const Logo = ({ isForSharedToWeb }) => {
  const history = useHistory();
  const goToDefaultPage = () => history.push(DEFAULT_PAGE_PATH);

  return (
    <LogoWrapper data-parent={DATA_PARENTS.PlaylistHeader}>
      <LogoImg className={styles.menuIcon} onClick={isForSharedToWeb ? emptyCallback : goToDefaultPage} />
    </LogoWrapper>
  );
};

export default Logo;
