import React from 'react';
import styled from 'styled-components/macro';

const AddLineComp = styled.div`
  background: #F8EAC9;
  border-width: 0;
  border-style: dashed;
  border-color: #DDA20E;
  left: 5px;
  height: 0;
  overflow: hidden;
  border-radius: 4px;
  transition: height 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isCut }) => (isCut ? 'calc(100vw - 765px);' : 'calc(100vw - 430px);')}
  max-width: 1200px;
`;

const AddLineShell = styled.div`
  display: flex;
  align-items: center;
  z-index: 205;
  cursor: pointer;
  &:hover ${AddLineComp} {
    height: 16px;
    border-width: 1px;
  }
  height: 32px;
`;
const AddLineShellShell = styled.div`
    display: flex;
    justify-content: center;
    height: 0;
`;

const AddLine = ({ refToSet, indexs, createLinkPage, isCut }) => {
  return (
    <AddLineShellShell>
      <AddLineShell onClick={(e) => { createLinkPage(indexs, e, true); }}>
        <AddLineComp ref={refToSet} isCut={isCut}> Add new block</AddLineComp>
      </AddLineShell>
    </AddLineShellShell>
  );
};

export default AddLine;
