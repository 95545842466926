import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import { calcNewState, calcSaveStateForShared } from './helpers';
import PlaylistsDatePicker2023 from './PlaylistsDatePicker2023';
import { actionUpdateShareState } from '../../redux/currentPage/action';


export const PlaylistSharedDatePickerDataLayer = ({
  isMultiple,
  dataParent = 'miniPage',
  setSelectedOption,
  selectedOption,
  isModify,
  setLocalState,
  localState,
}) => {
  const {
    shareState: {
      sharedAvailableFrom,
      sharedAvailableTo,
    },
  } = useSelector((state) => state.currentPage);

  const dispatch = useDispatch();


  const [selectedDateFrom, setSelectedDateFrom] = useState(calcNewState(sharedAvailableFrom));
  const [selectedDateTo, setSelectedDateTo] = useState(calcNewState(sharedAvailableTo));
  const saveState = () => {
    const state = calcSaveStateForShared(
      selectedDateFrom,
      selectedDateTo,
      selectedOption,
    );
    if (isModify) {
      setLocalState(state);
      return;
    }
    if (isMultiple) {
      dispatch(actionUpdateShareState(state));
    } else {
      dispatch(actionCreator(EditPlaylist.updateShareStatePlaylistS, state));
    }
  };

  useEffect(() => {
    if (localState?.sharedAvailableTo != null && setSelectedDateTo !== calcNewState(localState.sharedAvailableTo)) {
      setSelectedDateTo(calcNewState(localState.sharedAvailableTo));
    }
    if (localState?.sharedAvailableFrom != null && sharedAvailableFrom !== calcNewState(localState.sharedAvailableFrom)) {
      setSelectedDateFrom(calcNewState(localState.sharedAvailableFrom));
    }
  }, [localState]);
  return (
    <PlaylistsDatePicker2023
      isModify={isModify}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      dataParent={dataParent}
      saveState={saveState}
      selectedDateFrom={selectedDateFrom}
      setSelectedDateFrom={setSelectedDateFrom}
      selectedDateTo={selectedDateTo}
      setSelectedDateTo={setSelectedDateTo}
      showHeader
      isForShared
    />
  );
};
