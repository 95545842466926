import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SvgWrap,
  ViewModeSwitcherWrap,
  ViewOptionShell,
  ViewModeWrapper,
} from './sharedStyled';
import { DATA_PARENTS, NewUPVViewModes } from '../../utils/constants';
import { ReactComponent as ListSvg } from '../../images/icons/icon_18/view_list2.svg';
import { ReactComponent as GridSvg } from '../../images/icons/icon_18/view_grid2.svg';
import { ReactComponent as ExpandedSvg } from '../../images/icons/icon_18/expand2.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';


const SwitchModeAndPublishBlock = ({
  viewMode,
  setViewMode,
  forCutHeader,
  isLargeRightBar,
  isShowLibraryWidget,
}) => {
  const { t } = useTranslation();
  const moved = isShowLibraryWidget;

  return (
    <ViewModeSwitcherWrap
      forCutHeader={forCutHeader}
      moved={moved}
    >
      {!isLargeRightBar && (
        <ViewModeWrapper>
          <ViewOptionShell>
            <SvgWrap
              isActive={viewMode === NewUPVViewModes.compressed}
              isLeft
              onClick={(e) => {
                e.stopPropagation();
                setViewMode(NewUPVViewModes.compressed);
              }}
              data-parent={DATA_PARENTS.checkItem}
            >
              {' '}
              <Tooltip
                text={t('listViewT')}
                direction={forCutHeader ? 'up' : 'down'}
              >
                <ListSvg data-parent={DATA_PARENTS.checkItem} />
              </Tooltip>
            </SvgWrap>

            <SvgWrap
              isActive={viewMode === NewUPVViewModes.card}
              onClick={(e) => {
                e.stopPropagation();
                setViewMode(NewUPVViewModes.card);
              }}
              data-parent={DATA_PARENTS.checkItem}
            >
              {' '}
              <Tooltip
                text={t('cardViewT')}
                direction={forCutHeader ? 'up' : 'down'}
              >
                <GridSvg data-parent={DATA_PARENTS.checkItem} />
              </Tooltip>
            </SvgWrap>
            <SvgWrap
              isActive={viewMode === NewUPVViewModes.expanded}
              onClick={(e) => {
                e.stopPropagation();
                setViewMode(NewUPVViewModes.expanded);
              }}
              isRight
              data-parent={DATA_PARENTS.checkItem}
            >
              {' '}
              <Tooltip
                text={t('expandViewT')}
                direction={forCutHeader ? 'up' : 'down'}
              >
                <ExpandedSvg data-parent={DATA_PARENTS.checkItem} />
              </Tooltip>
            </SvgWrap>
          </ViewOptionShell>
        </ViewModeWrapper>
      )}
    </ViewModeSwitcherWrap>
  );
};

export default SwitchModeAndPublishBlock;
