import styled, { keyframes } from 'styled-components/macro';

export const tooltipFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const TooltipShell = styled.div<{
  forChannels: boolean, forUPV: boolean, moveLeft?: number
}>`
  z-index: 3;
  position: relative;
  width: auto;
  height: auto;
  ${({ forChannels }) => (forChannels ? 'top: -1px;' : '')}
  ${({ moveLeft }) => (moveLeft ? `left: ${moveLeft}px;` : '')}
`;

export const TooltipContainer = styled.div<{
  direction: string, place?: string
}>`
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  visibility: hidden;
  position: absolute;
  padding: 0 10px 1px 10px;
  height: 25px;
  background: #585858;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;

  ${({ direction }) => (direction === 'up'
    ? `
    top: 41px;
  `
    : '')}
  ${({ direction }) => (direction === 'down'
    ? `
    top: -35px;
  `
    : '')}
  ${({ place }) => (place === 'settingsHeader'
    ? `
    width: 145px;
    top: 3px;
    left: 122px;`
    : '')}
  ${({ place }) => (place === 'SharedHeader'
    ? `
    top: 31px;
  `
    : '')}
  ${({ place }) => (place === 'manyPagesHeader'
    ? `
    top: 40px;
  `
    : '')}
  ${({ place }) => (place === 'leftSideBar'
    ? `
    top: 3px;
    left: 97px;`
    : '')}
  ${({ place }) => (place === 'libraryHeaderFullscreen'
    ? `
    top: 3px;
    left: 109px;`
    : '')}
  ${({ place }) => (place === 'libraryHeaderFullscreenCardList'
    ? `
    top: 43px;
  `
    : '')}
  ${({ place }) => (place === 'PlaylistTabs'
    ? `
    top: 5px;
    right: 43px;
    left: inherit;
    transform: none;`
    : '')}
  ${({ place }) => (place === 'MakerPlaylistTabs'
    ? `
    top: 15px;
    right: 63px;
    left: inherit;
    transform: none;`
    : '')}

  ${({ place }) => (place === 'LibraryTabsSV'
    ? `
    top: 40px;
    right: -25px;
     left: inherit;
    transform: none;`
    : '')}
  ${({ place }) => (place === 'LibraryTabsTag'
    ? `
    top: 40px;
    
    right: -8px; 
    left: inherit;
    transform: none;`
    : '')}
  ${({ place }) => (place === 'PlaylistHeader'
    ? `
    top: -1px;
    right: -187px;
    left: initial;
    `
    : '')}
  ${({ place }) => (place === 'ChannelListItem'
    ? `
    left: 92px;
    top: -5px;`
    : '')}
  ${({ place }) => (place === 'newChannelItem'
    ? `
    left: 92px;
    top: -5px;`
    : '')}


  ${({ place }) => (place === 'newChannelItemInSelector_A'
    ? `
    left: -21px;
    top: -3px;`
    : '')}

  ${({ place }) => (place === 'newChannelItemInSelector_CO'
    ? `
    left: -33px;
    top: -3px;`
    : '')}


  ${({ place }) => (place === 'newChannelItemInSelector_C'
    ? `
   left: -36px;
    top: -3px;`
    : '')}

  ${({ place }) => (place === 'UpgradePlanFeatureHint'
    ? `
    left: 286px;
    top: 3px;`
    : '')}
  ${({ place }) => (place === 'ChannelListItemInNew'
    ? `
   left: 20px;
    top: -30px;`
    : '')}
  ${({ place }) => (place === 'coEditTooltip'
    ? `
    top: -41px;`
    : '')}
  ${({ place }) => (place === 'SearchInHeader'
    ? `
    left: 46px;`
    : '')}
  ${({ place }) => (place === 'SettingsRightSideBar'
    ? `
    background: #585858;`
    : '')}

  ${({ place }) => (place === 'avaUPVTable'
    ? `
      top: -28px;
      left: 50px;
      border-radius: 4px;
      border: 1px solid #FFC72C;
      background: #fff;
      color: #585858;
      `
    : '')}

  ${({ place }) => (place === 'ab_popup_edit'
    ? ` text-transform:none;
        top: -44px;
        left: -6px;`
    : '')}

  ${({ place }) => (place === 'ab_popup_delete'
    ? `     text-transform:none;
            top: -44px;
            left: 7px;`
    : '')}
  ${({ place }) => (place === 'PlaylistLastFrameClose'
    ? `     top: 17px;
    left: 92px;
`
    : '')}
  ${({ place }) => (place === 'PlaylistLastFrameReset'
    ? ` top: 15px;
      left: 101px;
`
    : '')}
  ${({ place }) => (place === 'PlaylistLastFrameConfirm'
    ? `top: 61px;
    left: 150px;
`
    : '')}
  ${({ place }) => (place === 'HeaderControls'
    ? `top: 31px;
`
    : '')}
  ${({ place }) => (place === 'DownloadModal'
    ? `
      top: -30px;
      left: 7px;
    ` : '')}
  ${({ place }) => (place === 'HeaderControlsMore'
    ? 'top: -44px;'
    : '')}
  ${({ place }) => (place === 'TOC'
    ? `
      top: 14px;
      left: 114px;
    `
    : '')}
  ${({ place }) => (place === 'ActionsTrash'
    ? `
      left: 8px;
    `
    : '')}
    ${({ place }) => (place === 'ActionsMoreExpand'
    ? `
      left: 10px;
    `
    : '')}
  ${({ place }) => (place === 'ActionsMoreList'
    ? `
      left: 9px;
    `
    : '')}
  ${({ place }) => (place === 'PinIcon'
    ? `
      left: 9px;
    `
    : '')}
  ${({ place }) => (place === 'ActionCellLock'
    ? `
      top: -43px;
      left: -19px;
    `
    : '')}
  ${({ place }) => (place === 'six_dots'
    ? `
      left: -3px;
      top: -55px;
      height: auto;
    ` : '')}
  ${({ place }) => (place === 'plus_item'
    ? `
      left: 12px;
      top: -55px;
      height: auto;
    ` : '')}
  ${({ place }) => (place === 'ai_send'
    ? `
      left: -26px;
      top: -51px;
    ` : '')}
  ${({ place }) => (place === 'ai_agent'
    ? `
      left: 14px;
      top: -45px;
      height: auto;
    ` : '')}
    ${({ place }) => (place === 'role_picker'
    ? `
      left: 55px;
      top: -45px;
      height: auto;
    ` : '')}
    ${({ place }) => (place === 'wrongEmail'
    ? `
      left: 50%;
      top: -31px;
    ` : '')}
    ${({ place }) => (place === 'AddImageToPlaylist'
    ? `
     top: 81px;
    ` : '')}
    ${({ place }) => (place === 'AddImageToPlaylistUp'
    ? `
     top: -27px;
    ` : '')}
    ${({ place }) => (place === 'UserToolTipInReactionOnTop'
    ? `
     top: -24px;
     background: #232225;
     color:  #FCFCFD;
    ` : '')}
    ${({ place }) => (place === 'UserToolTipInReactionOnBottom'
    ? `
     top: 42px;
    background: #232225;
     color:  #FCFCFD;
     
    ` : '')}
    ${({ place }) => (place === 'AllToolTipInReaction'
    ? `
     top: 29px;
    background: #232225;
     color:  #FCFCFD;
    ` : '')}
    ${({ place }) => (place === 'sidebarCollapseTooltip'
    ? `
      top: -31px;
      left: 268px;
    ` : '')}
    ${({ place }) => (place === 'sidebarPlus'
    ? `
      top: -31px;
      left: 11px;
    ` : '')}
    ${({ place }) => (place === 'sidebarBottom'
    ? `
      top: -31px;
      left: 15px;
    ` : '')}
`;

const timeFadeIn = 1.2;

export const Item = styled.div`
  display: flex;

  &:hover ${TooltipContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    animation: ${tooltipFadeIn} ${timeFadeIn}s;
    -webkit-animation: ${tooltipFadeIn} ${timeFadeIn}s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: ${tooltipFadeIn} ${timeFadeIn}s; /* Firefox < 16 */
    -o-animation: ${tooltipFadeIn} ${timeFadeIn}s; /* Opera < 12.1 */
  }
`;

export const TooltipText = styled.div<{
  place?: string
}>`
  text-align: center;
  width: auto;
  max-width: 134px;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ place }) => (place === 'avaUPVTable'
    ? 'color: #585858;' : '')};
  ${({ place }) => ((place === 'ai_input' || place === 'six_dots' || place === 'plus_item')
    ? 'max-width: none;' : '')}
  ${({ place }) => ((place === 'AddImageToPlaylist' || place === 'AddImageToPlaylistUp')
    ? 'max-width: 90px;' : '')}
  ${({ place }) => ((place === 'six_dots'
    || place === 'plus_item'
    || place === 'ai_agent'
    || place === 'role_picker')
    ? `
      max-width: none;
      white-space: pre;
    ` : '')}
    `;

export const TrianglePointer = styled.div<{
  pointingDirection?: string
}>`
  position: absolute;
  background: inherit;
  border: inherit;
  width: 10px;
  height: 10px;
  border-bottom-left-radius: 2px;

  ${({ pointingDirection }) => (pointingDirection === 'up'
    ? `
    top: -5px;
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
  `
    : '')}
  ${({ pointingDirection }) => (pointingDirection === 'down'
    ? `
    top: calc(100% - 6px);
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
  `
    : '')}
  ${({ pointingDirection }) => (pointingDirection === 'left'
    ? `
    top: calc(25px/2 - 5px);
    left: -4px;
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(45deg);
  `
    : '')}
  ${({ pointingDirection }) => (pointingDirection === 'right'
    ? `
    top: calc(25px/2 - 5px);
    right: -5px;
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(225deg);
  `
    : '')}
  ${({ pointingDirection }) => (pointingDirection === 'none'
    ? 'display: none;' : '')}
`;
