import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Controls from './Controls';
import { actionOpenModal } from '../../redux/user/action';
import { isRoleInPlaylist } from '../../utils/permissions';
import {
  PlaylistMainControlsShell,
  PlaylistMainControlsShell2,
} from './styled';
import { DATA_PARENTS, openModalType } from '../../utils/constants';

const PlaylistMainControls = ({
  setViewMode,
  currentRole,
  viewMode,
  isForSharedToWeb,
  cutMode,
  refForScroll,
  isExpand,
}) => {
  const dispatch = useDispatch();
  const { isViewer, isOwner } = isRoleInPlaylist;
  const {
    isContentEditable,
    id,
    isFavorite,
    title,
    usedInChannels,
    linkPages,
    socketId,
  } = useSelector((state) => state.currentPage);

  const enableShare = (e) => {
    e?.stopPropagation();
    dispatch(
      actionOpenModal(openModalType.Share, { itemType: 'playlist', isMakerStyles: true }),
    );
  };
  // if (cutMode) {
  //   return <></>;
  // }
  return (
    <PlaylistMainControlsShell2 data-parent={DATA_PARENTS.PlaylistHeader}>
      <PlaylistMainControlsShell isExpand={isExpand}>
        <Controls
          enableShare={enableShare}
          id={id}
          isFavorite={isFavorite}
          viewMode={viewMode}
          title={title}
          usedInChannels={usedInChannels}
          linkPages={linkPages}
          isOwner={isOwner[currentRole]}
          socketId={socketId}
          isContentEditable={isContentEditable}
          setViewMode={setViewMode}
          isViewer={isViewer[currentRole]}
          isForSharedToWeb={isForSharedToWeb}
          refForScroll={refForScroll}
          isExpand={isExpand}
        />
      </PlaylistMainControlsShell>
    </PlaylistMainControlsShell2>
  );
};

export default PlaylistMainControls;
