import React, { RefObject, useRef } from 'react';
import classNames from 'classnames/bind';
import { createPortal } from 'react-dom';

import data from '@emoji-mart/data/sets/14/google.json';
import Picker from '@emoji-mart/react';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import { ReactComponent as EmojiIcon } from '../../../images/icons/emoji_Icon.svg';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { DATA_PARENTS } from '../../../utils/constants';
import {
  ReactionsButtonBalancer,
  ReactButtonShell,
  PickerWrapper,
  PickerOverlay, ReactionsButtonShell,
} from './styles';
import { CustomButton } from '../ai_styled';

const cx = classNames.bind(rawStyles);

interface IReactionsComponent {
  reactHandler: (emoji: string, isExtra?: boolean) => void;
  children?: React.ReactNode;
  isExtra?: boolean;
  currentReaction?: string | null;
  setCurrentReaction?: (reaction: string) => void;
  toggleReactionsVisible?: () => void;
  isReactionsActive?: string | boolean;
  isActive: boolean;
  setIsActive: (isActive:boolean) => void;
  menuRef: RefObject<HTMLDivElement>;
}

export const ReactionsComponent: React.FC<IReactionsComponent> = ({
  reactHandler,
  isReactionsActive,
  children,
  isExtra,
  currentReaction,
  setCurrentReaction,
  toggleReactionsVisible,
  isActive,
  setIsActive,
  menuRef,
}) => {
  const reactButtonRef = useRef<any>(null);
  const { t } = useTranslation();


  const onEmojiSelect = (e: { native: string }) => {
    if (!isExtra && setCurrentReaction) {
      setCurrentReaction(e.native);
    }
    reactHandler(e.native, isExtra);
    setIsActive(false);
  };

  const handleClickMainReaction = () => {
    if (currentReaction && !isExtra && toggleReactionsVisible) {
      toggleReactionsVisible();
    } else {
      setTimeout(() => { setIsActive(true); }, 50);
    }
    menuRef.current?.scrollIntoView();
  };

  const PotentialReactionsPortal = () => {
    const { innerHeight: height } = window;

    const position = reactButtonRef?.current?.getBoundingClientRect();
    const topMaxByTop = Math.max(position?.top - 120, 70);
    const left = ((position?.left ?? 0) - 240);
    const top = Math.min(topMaxByTop, height - 445);
    const topReactionsActive = Math.min((height - position?.top - 460), -180);
    return (
      <PickerOverlay>
        <PickerWrapper
          ref={menuRef}
          data-parent={DATA_PARENTS.reactions}
          top={isReactionsActive ? topReactionsActive : top}
          left={isReactionsActive ? -250 : left}
        >
          <Picker
            data={data}
            onEmojiSelect={onEmojiSelect}
            theme="light"
            set="google"
            data-parent={DATA_PARENTS.reactions}
          />
        </PickerWrapper>
      </PickerOverlay>
    );
  };

  return (
    <ReactionsButtonBalancer isExtra={isExtra}>
      <ReactionsButtonShell isExtra={isExtra}>
        <ReactButtonShell
          className={cx('rising_button_flex', 'rising_button')}
          data-parent={DATA_PARENTS.checkItem}
          isVisible={isReactionsActive}
          ref={reactButtonRef}
        >

          <CustomButton
            onClick={handleClickMainReaction}
            data-parent={DATA_PARENTS.checkItem}
            customWidth={32}
            customHeight={32}
            isGhost
            isSquare
            isIconOnly
          >
            <Tooltip
              text={t('AddReactionT')}
              direction={isExtra ? 'none' : 'down'}
              place={isExtra ? 'AllToolTipInReaction' : 'ActionsTrash'}
              data-parent={DATA_PARENTS.checkItem}
            >
              <div className={cx('reaction_wrapper')}>
                {currentReaction ?? <EmojiIcon />}
              </div>
            </Tooltip>
          </CustomButton>
          {children}
          {isActive && (
            isReactionsActive ? PotentialReactionsPortal() : createPortal(PotentialReactionsPortal(), document.body)
          )}
        </ReactButtonShell>
      </ReactionsButtonShell>
    </ReactionsButtonBalancer>
  );
};
