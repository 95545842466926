import React, { RefObject } from 'react';
import { createPortal } from 'react-dom';
import data from '@emoji-mart/data/sets/14/google.json';
import Picker from '@emoji-mart/react';

import { useTranslation } from 'react-i18next';
import {
  EmojiReaction, EmojiReactionSafarifix,
  ExtraReactions,
  ExtraReactionWrapper,
  PickerOverlay,
  ReactionsWrapper,
  SingleReaction,
} from '../../MakerCardView/styles';
import { DATA_PARENTS } from '../../../../utils/constants';
import { AvatarGroupComponent } from '../Info/AvatarGroup';
import { ReactionsPopup } from './PopupForReactions';
import { ReactionsComponent } from '../../MakerCardView/ReactionsComponent';
import useComponentVisibleMouseDown from '../../../../utils/hooks/useComponentVisibleMouseDown';
import Tooltip from '../../../../shared/Tooltips/Tooltip';

interface IReactionsListProps {
    isHover?: boolean;
    menuRef: RefObject<HTMLDivElement>;
    ownerID: string;
    allReactionsCounter: number;
    allUsers: { [key: string]: { username: string; email: string; };};
    isReactionsActive: boolean | string;
    reactionsRef: RefObject<HTMLDivElement>;
    isActive: boolean | string;
    user: { id: string };
    position?: { top?: number; left?: number };
    reactionsArr: string[];
    reactHandler: (emoji: string) => void;
    item: { usersReaction: { [key: string]: string[] } };
    calcReactionUsers: (users: string[]) => object[];
    reactionsLimit: { limit?: number; extra?: number };
    setIsActive: (isActive: boolean) => void;
    setReactionsActive?: (isActive: boolean) => void;
    menuRefReactionsComponent: RefObject<HTMLDivElement>;
    isActiveReactionsComponent: boolean | string;
    setIsActiveReactionsComponent: (isActive: boolean) => void;
}

const ReactionsList: React.FC<IReactionsListProps> = ({
  isHover,
  menuRef,
  ownerID,
  allReactionsCounter,
  allUsers,
  isReactionsActive,
  reactionsRef,
  isActive,
  user,
  position,
  reactionsArr,
  reactHandler,
  item,
  calcReactionUsers,
  reactionsLimit,
  setIsActive,
  setReactionsActive,
  menuRefReactionsComponent,
  isActiveReactionsComponent,
  setIsActiveReactionsComponent,
}) => {
  const { t } = useTranslation();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <ReactionsWrapper
      isReactionsActive={isReactionsActive}
      onClick={() => setReactionsActive && setReactionsActive(true)}
      isHover={isHover}
      ref={reactionsRef}
      top={(position?.top ?? 0) + 10}
      left={(position?.left ?? 0) - 100}
    >
      {reactionsArr.slice(0, reactionsLimit.limit || 8)?.map(elem => (
        <SingleReaction
          onClick={() => reactHandler(elem)}
          isMarkedAsMy={item.usersReaction[elem].includes(user.id)}
          data-parent={DATA_PARENTS.reactions}
          key={elem}
        >
          <EmojiReaction> <EmojiReactionSafarifix isSafari={isSafari}> {elem} </EmojiReactionSafarifix> </EmojiReaction>
          {item.usersReaction[elem]?.length < 9 ? (
            <AvatarGroupComponent
              users={calcReactionUsers(item.usersReaction[elem])}
              isThirdRow={false}
              isUPV={false}
              isReactions
              yourId={user.id}
                // @ts-ignore
              reactionText={data?.emojis[data?.natives && data?.natives[elem]]?.name}
            />
          ) : (item.usersReaction[elem]?.length)}
        </SingleReaction>
      ))}
      {!!reactionsLimit.extra && (
        <Tooltip text={t('ClickSeeAllReactionsT')} direction="none" place="AllToolTipInReaction">
          <ExtraReactions onClick={() => setIsActive(true)}>
            +{reactionsLimit.extra}
          </ExtraReactions>
        </Tooltip>
      )}
      {isActive && (
        <div ref={menuRef}>
          <ReactionsPopup
            usersReaction={item.usersReaction}
            contacts={allUsers}
            yourId={user.id}
            reactHandler={reactHandler}
            ownerID={ownerID}
          />
        </div>
      )}
      <div
        style={{ display: 'none' }}
      >  <Picker
        data={data}
      />
      </div>

      {allReactionsCounter > 0 && (
        <ExtraReactionWrapper>
          <ReactionsComponent
            reactHandler={reactHandler}
            isReactionsActive={isReactionsActive}
            isExtra
            isActive={!!isActiveReactionsComponent}
            menuRef={menuRefReactionsComponent}
            setIsActive={setIsActiveReactionsComponent}
          />
        </ExtraReactionWrapper>
      )}
    </ReactionsWrapper>
  );
};

interface IReactionsListSelectorProps {
    menuRef: RefObject<HTMLDivElement>;
    ownerID: string;
    allReactionsCounter: number;
    allUsers: { [key: string]: { username: string; email: string; };};
    isReactionsActive: boolean | string;
    reactionsRef: RefObject<HTMLDivElement>;
    isActive: boolean | string;
    user: { id: string };
    position?: { top?: number; left?: number };
    reactionsArr: string[];
    reactHandler: (emoji: string) => void;
    item: { usersReaction: { [key: string]: string[] } };
    calcReactionUsers: (users: string[]) => object[];
    reactionsLimit: { limit?: number; extra?: number };
    setIsActive: (isActive: boolean) => void;
    setReactionsActive?: (isActive: boolean) => void;
}

export const ReactionsListSelector: React.FC<IReactionsListSelectorProps> = ({
  menuRef,
  ownerID,
  allReactionsCounter,
  allUsers,
  isReactionsActive,
  reactionsRef,
  isActive,
  user,
  position,
  reactionsArr,
  reactHandler,
  item,
  calcReactionUsers,
  reactionsLimit,
  setIsActive,
  setReactionsActive,
}) => {
  const [
    menuRefReactionsComponent, isActiveReactionsComponent, setIsActiveReactionsComponent,
  ] = useComponentVisibleMouseDown(false, DATA_PARENTS.reactions);

  if (isReactionsActive) {
    return (
      <>
        {createPortal(
          <PickerOverlay>
            <ReactionsList
              isReactionsActive={isReactionsActive}
              setReactionsActive={setReactionsActive}
              menuRef={menuRef}
              ownerID={ownerID}
              menuRefReactionsComponent={menuRefReactionsComponent}
              isActiveReactionsComponent={isActiveReactionsComponent}
              setIsActiveReactionsComponent={setIsActiveReactionsComponent}
              allReactionsCounter={allReactionsCounter}
              allUsers={allUsers}
              reactionsRef={reactionsRef}
              isActive={isActive}
              user={user}
              position={position}
              reactionsArr={reactionsArr}
              reactHandler={reactHandler}
              item={item}
              calcReactionUsers={calcReactionUsers}
              reactionsLimit={reactionsLimit}
              setIsActive={setIsActive}
            />
          </PickerOverlay>,
          document.body,
        )}
      </>
    );
  }
  return (
    <ReactionsList
      isReactionsActive={isReactionsActive}
      setReactionsActive={setReactionsActive}
      isHover
      menuRef={menuRef}
      ownerID={ownerID}
      menuRefReactionsComponent={menuRefReactionsComponent}
      isActiveReactionsComponent={isActiveReactionsComponent}
      setIsActiveReactionsComponent={setIsActiveReactionsComponent}
      allReactionsCounter={allReactionsCounter}
      allUsers={allUsers}
      reactionsRef={reactionsRef}
      isActive={isActive}
      user={user}
      position={position}
      reactionsArr={reactionsArr}
      reactHandler={reactHandler}
      item={item}
      calcReactionUsers={calcReactionUsers}
      reactionsLimit={reactionsLimit}
      setIsActive={setIsActive}
    />
  );
};
