import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { IUserToShare, SingleShareItem } from '../ShareUnshare_single_share_user';
import { calcSaveStateForShared } from '../../../DatePicker/helpers';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { ActionTypeRemoveUserToSharing } from '../../../DropDownOption/Share/helpers';
import EditPlaylist from '../../../../redux/playlists/types';

import styles from '../../index.module.scss';
import { actionEditPlaylistSingleStateUser } from '../../../../redux/playlists/action';
import { PublicPrivateTab } from './PublicPrivateTab';
import { ChannelsTab } from './ChannelsTab';

const cx = classNames.bind(styles);
export interface ILocalState {
  dateRangeMark: string,
  availableFrom: number,
  availableTo: number,
  selectedDateFrom?: number,
  selectedDateTo?: number,
}

interface IShareTab {
  setCurrentMode: (key: string) => void,
  localState: ILocalState,
  setLocalState: (state: ILocalState) => void,
  owner: IUserToShare,
  singleUserShareState: { [key: string]: IUserToShare }
  channelsList: { name: string, id: string }[],
  selectedDateFrom: Date,
  selectedDateTo: Date,
  id: string,
  wrapperId: string,
  setMainCalendarOpened: (val: boolean) => void,
  setCurrentCalendarItem: ({ type, id }: { type: string, id: string }) => void,
  isPublic: boolean,
  mixedSharedToUser: number,
  mixedSharedToChannel: number,
  isMultiple: boolean,
  isMixedShareToWeb: boolean,
  isAllShareToWeb: boolean,
}
export const ShareTab: React.FC<IShareTab> = ({
  setCurrentMode,
  owner,
  singleUserShareState,
  channelsList,
  setLocalState,
  localState,
  selectedDateFrom,
  selectedDateTo,
  id,
  setMainCalendarOpened,
  setCurrentCalendarItem,
  isPublic,
  wrapperId,
  isMultiple,
  mixedSharedToChannel,
  isAllShareToWeb,
  isMixedShareToWeb,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const saveState = (selectedOption: string, email: string) => {
    const state = calcSaveStateForShared(
      selectedDateFrom,
      selectedDateTo,
      selectedOption,
    );
    setLocalState(state);
    dispatch(actionCreator(EditPlaylist.updateShareStatePlaylistS, state));
    dispatch(actionEditPlaylistSingleStateUser({ ...localState, ...state, email, wrapperId }));
  };

  const removeUserToSharing = (user: IUserToShare) => {
    dispatch(
      actionCreator(ActionTypeRemoveUserToSharing.playlist, {
        pageId: id,
        user,
      }),
    );
  };

  const calcIndex = useMemo(() => {
    const currentCoEditors = (Object.values(singleUserShareState));
    return currentCoEditors
      .sort((a: IUserToShare, b: IUserToShare) => a.coEditorIndex - b.coEditorIndex)
      .reduce((acc: number, cur: IUserToShare, index: number) => {
        if ((cur.coEditorIndex !== acc || !cur.coEditorIndex) && (index + 1 <= currentCoEditors.length)) return acc;
        return acc + 1;
      }, 1);
  }, [singleUserShareState]);

  const coEditTogglerHandler = useCallback((selectedUser: IUserToShare) => {
    dispatch(actionEditPlaylistSingleStateUser({
      state: !singleUserShareState[selectedUser.email]?.isCoEdit,
      field: 'isCoEdit',
      email: selectedUser.email,
      coEditorIndex: calcIndex,
      wrapperId,
    }));
  }, [singleUserShareState, calcIndex, dispatch]);
  return (
    <div className={cx('main_wrapper')}>
      <div className={cx('section_title', 'text_unified-base')}>{t('sharedWithT')}</div>
      <div className={cx('items_wrapper')}>
        <PublicPrivateTab isPublic={isPublic || isAllShareToWeb} isMixedShareToWeb={isMixedShareToWeb} setCurrentMode={setCurrentMode} />
        <ChannelsTab
          channelsList={channelsList}
          isMultiple={isMultiple}
          mixedSharedToChannel={mixedSharedToChannel}
          setCurrentMode={setCurrentMode}
        />

        <SingleShareItem user={owner} isOwner />
        {(Object.values(singleUserShareState) as IUserToShare[]).map((user) => (
          <SingleShareItem
            user={user}
            removeUserToSharing={removeUserToSharing}
            setTime={saveState}
            switchRole={coEditTogglerHandler}
            setMainCalendarOpened={setMainCalendarOpened}
            setCurrentCalendarItem={setCurrentCalendarItem}
          />
        ))}
      </div>
    </div>
  );
};
