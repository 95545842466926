import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import rawStyles from '../MakerCardView/index.module.scss';
import { ReactComponent as Plus16Svg } from '../../../images/icons/plus_16.svg';
import { isRoleInPlaylist } from '../../../utils/permissions';
import MakerItem from './MakerItem';
import { actionRemoveAllSelected } from '../../../redux/selectedPage/action';
import { actionSelectItemInSmartfile } from '../../../redux/currentPage/action';
import useSelectAreaItems from '../../../utils/hooks/useSelectAreaItems';
import AddLine from './AddLine';


const styles = classNames.bind(rawStyles);

const AddElement = ({ hasDraggable, hasItems, isExpanded, isSidebarOpened }) => {
  return (
    <div className={styles('create_new_element',
      {
        hoverable: hasDraggable,
        card_content: hasItems && !isExpanded,
        expanded_content: hasItems,
        isCut: isSidebarOpened,
      })}
    >
      <div className={styles('icon_wrapper')}>
        <Plus16Svg />
      </div>
    </div>
  );
};

const { isViewer } = isRoleInPlaylist;

const PlaylistsBuilderUIEx = ({
  rawPages,
  setSelectedIndex,
  activeItemIndex,
  isPages,
  onEditorAreaMouseDrop,
  renderSelectionBox,
  hasDraggable,
  linkPages,
  onDragEnter,
  onDragLeave,
  createLinkPage,
  backSpaceHandler,
  indexHover,
  needToShowVioletStripe,
  refForScroll,
  ...props
}) => {
  const { selectedItems, currentRole } = useSelector(state => state.currentPage);
  const makerAreaRef = useRef(null);
  const refs = useRef([]);
  const [onMouseDown, onMouseMove] = useSelectAreaItems(
    selectedItems,
    actionRemoveAllSelected,
    makerAreaRef,
    actionSelectItemInSmartfile,
    null,
    null,
    null,
    null,
    refForScroll,
  );
  if (refs.current.length !== (rawPages.length + 1)) {
    refs.current = Array(rawPages.length + 1)
      .fill()
      .map((_, i) => refs.current[i] || React.createRef());
  }

  return (
    <div
      tabIndex="0"
      className={styles('wrapper')}
      onKeyDown={backSpaceHandler}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
    >
      <div className={styles('builder__wrapper', { full_width: !isPages })}>
        <div
          className={styles('pages__section', { full_width: !isPages })}
        >

          {linkPages && !!linkPages.length && (
            <div className={styles('pages_wrapper')}>
              <div className={styles('all_items_wrapper')} ref={makerAreaRef}>
                {rawPages.map((item, itemIndex) => (
                  <React.Fragment key={item.id}>
                    {!hasDraggable && !isViewer[currentRole] && (
                      <AddLine
                        createLinkPage={props.addNewLinkPageByIndex}
                        indexs={itemIndex}
                        refToSet={refs.current[itemIndex]}
                        isCut={props.isSidebarOpened}
                      />
                    )}
                    <MakerItem
                      key={item.id}
                      itemIndex={itemIndex}
                      refToGet={refs.current[itemIndex]}
                      refToGetNext={refs.current[itemIndex + 1]}
                      item={item}
                      rawPages={rawPages}
                      setSelectedIndex={setSelectedIndex}
                      isSelectLikeHover={activeItemIndex === itemIndex}
                      isHoverByDnd={indexHover === itemIndex}
                      isHoverByDndOnEmpty={indexHover === `${itemIndex}empty`}
                      onEditorAreaMouseDrop={onEditorAreaMouseDrop}
                      renderSelectionBox={renderSelectionBox}
                      hasDraggable={hasDraggable}
                      onDragEnter={onDragEnter}
                      onDragLeave={onDragLeave}
                      createLinkPage={createLinkPage}
                      needToShowVioletStripe={needToShowVioletStripe}
                      {...props}
                    />
                  </React.Fragment>
                ))}
                <div style={{ position: 'relative' }}>
                  <AddLine
                    createLinkPage={props.addNewLinkPageByIndex}
                    ndexs={rawPages.length}
                    refToSet={refs.current[rawPages.length]}
                    isCut={props.isSidebarOpened}
                  />
                </div>
                {!isViewer[props.currentRole] && (
                <div
                  className={styles('dragLine_wrap', { hasHoverDrag: indexHover === 'last' })}
                  onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onDrop={(e) => {
                    onEditorAreaMouseDrop(e, null, 'last');
                  }}
                  onDragEnter={(e) => {
                    e.stopPropagation();
                    onDragEnter('last');
                  }}
                  onDragLeave={onDragLeave}
                >
                  {indexHover === 'last' && (
                    <div className={styles('dragLine_line', 'last')} />
                  )}
                  <div onClick={() => createLinkPage(rawPages.length - 1)}>
                    <AddElement
                      isExpanded={props.isExpanded}
                      isSidebarOpened={props.isSidebarOpened}
                      hasItems
                    />
                  </div>
                </div>
                )}
                {isViewer[props.currentRole] && <div className={styles(rawStyles.viewer_empty_place)} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaylistsBuilderUIEx;
