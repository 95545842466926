import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIconSvg } from '../../images/2023/svg/preview/edit.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';

const tooltipStyle = {
  LibraryCard: 'LibraryCard',
  ActionsWrapper: 'ActionsWrapper',
  MakerLibraryElement: 'MakerLibraryElement',
  HeaderControls: 'HeaderControls',
  ActionsCell: 'ActionsCell',
} as const;

type EditIconSvgShellProps = {
  place: typeof tooltipStyle[keyof typeof tooltipStyle];
}
const EditIconSvgShell = styled.div<EditIconSvgShellProps>`
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  ${({ place }) => (tooltipStyle.LibraryCard === place
    ? `
    background-color: white;
    border: 0.6px solid #FFEEBF;
    &:hover{
      border: 0.6px solid #FFC72C;
      background: #FFDF85;
    }
  `
    : '')}

  ${({ place }) => (tooltipStyle.ActionsWrapper === place
    ? `
    background-color: inherit;
    width: 28px;
    height: 28px;
    border-radius: 6px;
  &:hover{
    background: #FFDF85;
  }
  `
    : '')}
  ${({ place }) => (tooltipStyle.HeaderControls === place
    ? `
    background-color: inherit;
    width: 21px;
    height: 21px;
    border-radius: 6px;
  `
    : '')}
  ${({ place }) => (tooltipStyle.ActionsCell === place
    ? `
         width: 28px;
    height: 28px;
    border-radius: 6px;
    &:hover{
      background: #FFDF85;
    }
  `
    : '')}
  ${({ place }) => (tooltipStyle.MakerLibraryElement === place
    ? `
    z-index:100;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: #F6F6F5;
    border: 0.6px solid #ECE2E2;
    filter: drop-shadow(0px 1px 2px rgba(122, 105, 105, 0.10));
    &:hover{
      border: 0.6px solid #FFC72C;
      background: #FFEEBF;
    }
  `
    : '')}
  //background: #FFF7DF;
`;

type EditIconShellProps = {
  place: typeof tooltipStyle[keyof typeof tooltipStyle];
}
const EditIconShell = styled.div<EditIconShellProps>`

  ${({ place }) => (tooltipStyle.ActionsWrapper === place
    ? `
    position:absolute;
    width: 28px;
height: 28px;
top: 5px;
    left: -19px;
  `
    : '')}
`;

type EditIconProps = {
  type: typeof tooltipStyle[keyof typeof tooltipStyle];
  id: any; // TODO typify this
  dataParent?: string;
}
const EditIcon = ({ type, id, dataParent }: EditIconProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const direction = tooltipStyle.HeaderControls !== type ? 'down' : 'up';

  const open = (e: React.MouseEvent<HTMLDivElement | SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    history.push(`/libraryPage/${id}`);
  };

  return (
    <EditIconShell
      className="actions_svg_wrapper"
      place={tooltipStyle[type]}
      onClick={open}
      data-parent={dataParent}
    >
      <Tooltip
        text={t('editT')}
        direction={direction}
        place={tooltipStyle[type]}
      >
        <EditIconSvgShell data-parent={dataParent} place={tooltipStyle[type]}>
          <EditIconSvg onClick={open} data-parent={dataParent} />
        </EditIconSvgShell>
      </Tooltip>
    </EditIconShell>
  );
};

export default EditIcon;
