import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { useHistory } from 'react-router-dom';
import { DateOption } from '../ShareUnshare_single_share_user';
import Toggler from '../../../DropDownOption/Toggler';
import { CustomButton } from '../../../../pages/Maker/ai_styled';
import { ReactComponent as SharedSvg } from '../../../../images/icons/shareModal/sf_shared.svg';
import { ReactComponent as BaseToggleSvg } from '../../../../images/icons/shareModal/toggle_base.svg';

import { emptyCallback, MessageType } from '../../../../utils/constants';

import { actionShowMessage } from '../../../../redux/support/action';
import { actionUnPublishSPlaylistS } from '../../../../redux/playlists/action';

import styles from '../../index.module.scss';
import { ContentActionType } from '../../../../redux/content/contentTypes';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { actionRemoveAllSelected } from '../../../../redux/selectedPage/action';

const cx = classNames.bind(styles);

interface ILocalShareState {
  isNeedAccess: boolean,
  accessCode?: string,
}
interface IShareTab {
  isPublic: boolean,
  setPublic: (key:boolean)=>void,
  currentPeriod: string,
  setCurrentPeriod: React.Dispatch<React.SetStateAction<string>>,
  localShareState: ILocalShareState,
  editShareStateHandler: (state: boolean | string, field: string) => void,
  isHasShare: boolean,
  isMultiple: boolean,
  isMixedShareToWeb: boolean,
  isHasShareInMultiple: boolean,
  id: string,
  wrapperId: string,
  close: () => void,
  setSelectedDateFromHandler: (date: Date) => void,
  setSelectedDateToHandler: (date: Date) => void,
  playlistsIds: { [key: string]: boolean }

}
export const SettingsTab: React.FC<IShareTab> = ({
  isPublic,
  setPublic,
  currentPeriod,
  setCurrentPeriod,
  localShareState,
  editShareStateHandler,
  isHasShare,
  id,
  wrapperId,
  close,
  isMultiple,
  isMixedShareToWeb,
  isHasShareInMultiple,
  setSelectedDateFromHandler,
  setSelectedDateToHandler,
  playlistsIds,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [isToglerHasChanges, setIsToglerHasChanges] = useState(false);
  const history = useHistory();

  const isPublishPath = history.location.pathname.includes('sharedByMe');

  const unshare = () => {
    if (!isMultiple) {
      dispatch(actionUnPublishSPlaylistS(id, wrapperId));
      dispatch(
        actionShowMessage({
          type: MessageType.Regular,
          text: t('smartFileUnsharedT'),
        }),
      );
      close();
    } else {
      Object.keys(playlistsIds).forEach(playlistId => {
        dispatch(actionUnPublishSPlaylistS(playlistId));
        if (isPublishPath) {
          dispatch(
            actionCreator(ContentActionType.updatePlaylistR, {
              idRemove: playlistId,
            }),
          );
        }
      });
      dispatch(actionRemoveAllSelected());
      dispatch(
        actionShowMessage({
          type: MessageType.Regular,
          text: t('smartFileUnsharedT'),
        }),
      );

      close();
    }
  };
  const onBaseTogglerClickHandler = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPublic(true);
    setIsToglerHasChanges(true);
  };

  return (
    <div className={cx('main_wrapper')}>
      <>
        <div className={cx('shared_option')}>
          <div className={cx('shared_option_content')}>
            <div className={cx('option_title', 'text_unified-base')}>{t('makePublicT')}</div>
            <div className={cx('option_description', 'text_unified-sm')}>{t('makePublicDescriptionT')} </div>
          </div>
          {(!isMixedShareToWeb || isToglerHasChanges) && (
          <Toggler
            dataParent=""
            togglerHeight={24}
            togglerWidth={48}
            isActive={isPublic}
            callback={() => setPublic((!isPublic))}
            isDisable={false}
            isBrand
            text=""
            top="12px"
            left="520px"
          />
          )}
          {(isMixedShareToWeb && !isToglerHasChanges) && (
            <BaseToggleSvg onClick={onBaseTogglerClickHandler} />
          )}
        </div>
        {isPublic && (
          <DateOption
            currentPeriod={currentPeriod}
            setCurrentPeriod={setCurrentPeriod}
            setSelectedDateFromHandler={setSelectedDateFromHandler}
            setSelectedDateToHandler={setSelectedDateToHandler}
          />
        )}
        {isPublic && !isMultiple && (
          <div className={cx('shared_option')}>
            <div className={cx('shared_option_content')}>
              <div className={cx('option_title', 'text_unified-base')}>{t('allowAccessCodeT')}</div>
              <div className={cx('option_description', 'text_unified-sm')}>{t('allowAccessCodeDescriptionT')}</div>
            </div>
            <Toggler
              dataParent=""
              togglerHeight={24}
              togglerWidth={48}
              isActive={!(!localShareState.isNeedAccess)}
              callback={() => {
                editShareStateHandler(
                  (!localShareState.isNeedAccess),
                  'isNeedAccess',
                );
              }}
              isDisable={false}
              isBrand
              text=""
              top="7px"
              left="520px"
            />
          </div>
        )}
        {isPublic && localShareState.isNeedAccess && (
          <input
            data-cy="input-accessCode"
            className={cx('access_code_input', 'input_user_name')}
            onChange={(event) => setCode(event.target.value)}
            value={code}
            onBlur={() => editShareStateHandler(code, 'accessCode')}
            placeholder={t('enterAccessCodeT')}
          />
        )}
        <div className={cx('shared_option')}>
          <div className={cx('shared_option_content')}>
            <div className={cx('option_title', 'text_unified-base')}>{t('allowViewersCommentT')}</div>
            <div className={cx('option_description', 'text_unified-sm')}>{t('allowViewersCommentDescriptionT')}</div>
          </div>
          <Toggler
            dataParent=""
            togglerHeight={24}
            togglerWidth={48}
            isActive={false}
            callback={emptyCallback}
            isDisable
            isBrand
            text=""
            top="11px"
            left="520px"
          />
        </div>
        {(isHasShare || isHasShareInMultiple) && <div className={cx('divider_full', 'no_margin')} />}
        {(isHasShare || isHasShareInMultiple) && (
          <div className={cx('shared_option', 'shared_state_wrapper')}>
            <SharedSvg />
            <div className={cx('shared_option_content', 'unShare_option')}>
              <div className={cx('option_title', 'text_unified-base')}>
                { t(isHasShareInMultiple ? 'sfsIsSharedT' : 'sfIsSharedT')}
              </div>
              <div className={cx('option_description', 'text_unified-sm')}>
                { t('sfIsSharedDescription1T')}
              </div>
              <div className={cx('option_description', 'text_unified-sm', 'second_row')}>
                {t('sfIsSharedDescription2T') }
              </div>
            </div>
            <CustomButton isSquare onClick={unshare} isNeutral sidePaddings={7}>
              { t(isHasShareInMultiple ? 'unshareAllT' : 'unshareT')}
            </CustomButton>
          </div>
        )}
      </>
    </div>
  );
};
