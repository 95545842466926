import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { PlayerItem, PlayerItemContentShell } from './styledComponents';
import { actionCreator } from '../../shared/redux/actionHelper';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { ServiceUser } from '../../redux/user/types';
import { PLAYER_SETTINGS } from '../../utils/constants';
import DisplayPreviewForContent from './DisplayPreviewForContent';
import { MAX_ZOOM_VALUE, MIN_ZOOM_VALUE } from '../LibraryComponent/helpers';
import mimeTypes from '../../utils/mimeTypes';
import useThrottle from '../../utils/hooks/useThrottle';
import { getNameFromState } from '../../utils/draftJsHelpers';
import { smartFileItemTypeCheck } from '../../shared/smartFile/constant';

const Item = styled.div`
  opacity: ${({ isLoad }) => (isLoad ? 1 : 0)};
  ${({ isLoad }) => (isLoad ? 'transition: 0.3s all cubic-bezier(1, -1.09, 1, 1);' : '')};
  //width: 100%;
  //height: 100%;
`;

const PlayerItemContent = ({
  item,
  isMouseMoveShowControls,
  contentScrollRef,
  isForSharedToWeb,
  ...props
}) => {
  const dispatch = useDispatch();
  // super business requirment
  const isNeedToCenterText = useMemo(() => {
    if (item?.type !== 'elementText') {
      return false;
    }
    return getNameFromState(item).length > 260;
  }, [item]);

  const {
    playerSettings: {
      isFullScreenMode,
      // isCaptionsAndApproveButtonsHidden,
      zoomPercentage,
      contentWidthMode,
    },
  } = useSelector((state) => state.user);
  const { width, height } = useWindowDimensions();
  const imageRef = useRef(null);
  const refHeight = useRef(null);
  const refHeightChildren = useRef(null);
  const refTopPosition = useRef(null);
  // const [topCompensation, setTopCompensation] = useState({});
  const isText = smartFileItemTypeCheck.isText[item.type];
  const [siblingComponentIsLoading, setSiblingComponentIsLoading] = useState(false);
  const [isHasScroll, setIsHasScroll] = useState(false);
  const [triggerUpdateSimple, setTriggerUpdateSimple] = useState(null);
  const calcStyle = { zoom: `${zoomPercentage}%` };
  // const calcStyle = { scale: `${zoomPercentage}%` };

  const [throttledAnimateIn] = useThrottle(120);

  const setScalePercentage = (newValue) => {
    dispatch(
      actionCreator(ServiceUser.Player.ChangeZoomAuto, {
        value: newValue,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      actionCreator(ServiceUser.Player.ChangeZoom, {
        value: 100,
      }),
    );
    setTimeout(() => {
      setTriggerUpdateSimple(item);
    }, 10);
  }, [item]);

  useEffect(() => {
    if (mimeTypes.image[item?.libraryComponent?.type]) {
      setTimeout(() => {
        contentScrollRef.current
          && contentScrollRef.current.scrollTo({
            top: (contentScrollRef.current?.scrollHeight - height) / 2,
            left: (contentScrollRef.current?.scrollWidth - width) / 2,
          });
      }, 10);
    }
  }, [siblingComponentIsLoading]);

  useEffect(() => {
    if (!imageRef?.current) return;

    if (contentWidthMode === PLAYER_SETTINGS.widthMode.fullheight) {
      const topHeight = 126;
      // const scrollSize = 20;
      const heightResize = ((height - topHeight)
          / imageRef.current?.getBoundingClientRect().height)
        * 100;
      // const widthResize = ((width - scrollSize) / imageRef.current?.getBoundingClientRect().width) * 100;
      const minResize = Math.max(heightResize, MIN_ZOOM_VALUE);
      const maxResize = Math.min(minResize, MAX_ZOOM_VALUE);
      setScalePercentage(Math.ceil(maxResize));
    } else if (contentWidthMode === PLAYER_SETTINGS.widthMode.fullwidth) {
      const scrollSize = 20;
      const widthResize = ((width - scrollSize)
          / imageRef.current?.getBoundingClientRect().width)
        * 100;
      const minResize = Math.max(widthResize, MIN_ZOOM_VALUE);
      const maxResize = Math.min(minResize, MAX_ZOOM_VALUE);
      setScalePercentage(Math.ceil(maxResize));
    }
    if (contentWidthMode === PLAYER_SETTINGS.widthMode.default) {
      const widthCoefficient = width / imageRef.current?.getBoundingClientRect().width;
      const heightCoefficient = height / imageRef.current?.getBoundingClientRect().height;
      const rescaleIndex = Math.min(widthCoefficient, heightCoefficient) * 100;
      if (rescaleIndex < 100) {
        setScalePercentage(Math.floor(rescaleIndex.toFixed(2)));
      }
    }
  }, [contentWidthMode, item.id]);

  useEffect(() => {
    throttledAnimateIn(() => {
      const calcIsHasScroll = contentScrollRef.current?.scrollHeight
          > contentScrollRef.current?.clientHeight
        || contentScrollRef.current?.scrollWidth
          > contentScrollRef.current?.clientWidth;
      setIsHasScroll(calcIsHasScroll);
    });
  }, [
    refHeight.current?.scrollHeight,
    refHeight.current?.scrollWidth,
    zoomPercentage,
  ]);

  return (
    <PlayerItemContentShell
      isHasScroll={isHasScroll}
      isImageHasScroll={isHasScroll}
      isFullScreenMode={isFullScreenMode}
      isNeedToCenterText={isNeedToCenterText}
      ref={refHeight}
      isText={isText}
      isPage
    >
      <PlayerItem
        ref={refHeightChildren}
        // isSimple={item?.type === 'elementText'}
        isFullScreenMode={isFullScreenMode}
        isMouseMoveShowControls={isMouseMoveShowControls}
      >
        <Item ref={refTopPosition} isLoad={triggerUpdateSimple === item}>
          <DisplayPreviewForContent
            isMouseMoveShowControls={isMouseMoveShowControls}
            isForSharedToWeb={isForSharedToWeb}
            item={item}
            refTopPosition={refTopPosition}
            imageRef={imageRef}
            calcStyle={calcStyle}
            toggleIsCaptionsAndApproveButtonsHidden={
              props.toggleIsCaptionsAndApproveButtonsHidden
            }
            isPlayer
            setSiblingComponentIsLoading={setSiblingComponentIsLoading}
          />
        </Item>
      </PlayerItem>
      {/* <PlayerCaptionComponent */}
      {/*  caption={item.caption} */}
      {/*  isShowCaption={item.isShowCaption} */}
      {/*  toggleIsCaptionsAndApproveButtonsHidden={ */}
      {/*    props.toggleIsCaptionsAndApproveButtonsHidden */}
      {/*  } */}
      {/* /> */}
    </PlayerItemContentShell>
  );
};

export default PlayerItemContent;
