import styled from 'styled-components/macro';
import { keyframes, css } from 'styled-components';

export const PlaylistAIInput = styled.div<{ isExpanded: string | boolean, isExpandedForPreview: boolean }>`
  z-index: 8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
// TODO check it
//  margin-left: 40px;
  bottom:0;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  transition: height, 0.2s;
  width: 935px;
  align-items: center;
`;
export const ExpandButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #E8E9EA;
  background: #FFF;
  box-shadow: 0 24px 48px -8px rgba(66, 66, 66, 0.12), 0 2px 8px 0 rgba(66, 66, 66, 0.04);
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const AITitle = styled.div`
  display: flex; 
  align-items: center;
  gap: 10px;
  & h3{
    font-family: Roboto, sans-serif;
  }
`;

export const ProcessingType = styled.div`
  color: #2B2C2E;
  font-family: RobotoFlex, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
`;
export const EstimatedTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 32px);
  border-top: 1px solid rgba(224, 223, 226, 1);
  margin-top: 32px;
  padding-top: 16px;
  p {
    padding-left: 16px;
    color: #4B5563;
    font-family: RobotoFlex, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.05px;
    margin: 0;
  }

  button {
    margin-right: 16px;
    width: 160px;
  }
`;

export const Summary = styled.div`
  white-space: normal;
  margin: 0 0 14px;
`;
export const FirstSource = styled.div`
  padding-bottom: 10px;
`;

export const progressKeyFrames = keyframes`
   to {
      width: 100%;
      background: #F8EAC9;
   }
`;
export const Progress = styled.div`
  animation: ${progressKeyFrames} 6s linear 1;
  animation-fill-mode: forwards;
  height: 8px;
`;

export const LoadingLine = styled.div`
  background-color: rgba(247, 247, 248, 1);
  height: 10px;
  width: 120px;
  position: absolute;
  z-index: 1;
`;
export const VerticalDivider = styled.div`
  background-color: #F2F2F3;
  width: 1px;
  height: 40px;
  margin: 0 12px;
`;

export const ScrollableItemWrapper = styled.div`
  overflow-y: scroll;
  max-height: 540px;
  white-space: normal;
  b{
      font-weight: 600;
      font-family: "Roboto", sans-serif;
  }
  p{
      margin: 15px 0;
      line-height: 15px;
  }
  ul{
      margin: 0;
      line-height: 15px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;

export const SendButton = styled.button <{ disabled?: boolean, isLoading: boolean }>`
  display: flex;
  width: 40px;
  min-width: 40px;
  position: absolute;
  bottom: -20px;
  right: 6px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: ${({ disabled }) => (disabled ? '#A2A5AA' : 'linear-gradient(98deg, #19D244 4.03%, #23A942 102.26%)')};
  border-radius: 50%;
  border: none;
  outline: none;
  margin-left: 15px;
  cursor: pointer;
  ${({ isLoading }) => (isLoading
    ? `
      width: 32px;
      min-width: 32px;
      height: 32px;
      bottom: -16px;
      right: 10px;
      background: #F8EAC9;
      display: flex;

      .ai_step path {
        fill: rgba(248, 186, 18, 1);
      }
    ` : '')};
  .ai_stop {
    display: none;
  }
    
  &:hover:not([disabled]) {
    background: linear-gradient(98deg, #40DD65 4.03%, #19D244 102.26%);
    .ai_stop {
      display: flex;
    }
    
    .ai_processing {
      visibility: hidden;
    }
    ${({ isLoading }) => (isLoading
    ? `
      background: #F8EAC9;
      .ai_step {
        display: none;
      }
    ` : '')}
  }
`;
const bounceAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(-60px);
  }
`;
const reBounceAnimation = keyframes`
  0% {
    transform: translateY(-60px);
  }
  50% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(-60px);
  }
`;
export const ExpandedFunctionalityWrapper = styled.div<{ isCanBeShow?: boolean | string, isShowMax?:boolean | string, heightIndent:number, animate?:boolean }>`
  display: flex;
  width: 600px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 12px 32px -6px  rgba(23, 25, 28, 0.08), 0px 8px 16px 2px  rgba(23, 25, 28, 0.08);
  position: absolute;
  height: auto;
  max-height: 600px;
  transition: transform, top, 0.8s ease-in-out;
 
   ${({ heightIndent }) => (`top: -${heightIndent}px;`)}

`;
export const SummaryExample = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22px;
  margin-bottom: 10px;
`;
export const CustomButton = styled.button<{
  isActive?: boolean,
  isBrand?: boolean,
  isShare?: boolean,
  isSquare?: boolean,
  isNeutral?: boolean,
  sidePaddings?: number,
  isIconOnly?: boolean,
  isLink?: boolean,
  isGhost?: boolean,
  customWidth?: number,
  customHeight?: number,
}>`
  padding: 10px 20px;
  height: 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid transparent;
  border-radius: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #514E55;
  background: #fff;
  position: relative;
  box-shadow:none;
  ${({ isActive }) => (isActive
    ? `
    color: #FFC72C;
    border: 1px solid #E7C15F;
     svg path {
      fill: #E7C15F;
    }
    ` : '')};
  &:hover:not([disabled]) {
    background: #E6AC190F;
    color: #E7C15F;

    span {
      color: #514E55;
    }
    svg path {
      fill: #E7C15F;
    }
  }

  ${({ isBrand }) => (isBrand
    ? `
    background-color: #FFC72C;
    color: #514E55;
    svg path {
      fill: #514E55;
    }
    &:hover:not([disabled]) {
      background: #E7C15F;
      color: #514E55;

      svg path {
        fill: #514E55;
      }
    }

    &:focus:not([disabled]) {
      background: #E6AC190F;
      border-color: #CA971614;
      color: #514E55;

      svg path {
        fill: #514E55;
      }
    }

    &:active:not([disabled]) {
      background: #FFC72C;
      color: #514E55;
      outline: 1.5px solid #F3DDA5;
      svg path {
        fill: #514E55;
      }
    }
    &:disabled {
      background: #FDF6E2;
      color: #F8EAC9;
      cursor: not-allowed;
      svg path {
        fill: #F8EAC9;
      }
    }
  ` : '')};
  ${({ isShare }) => (isShare ? 'width:180px; min-width:180px; justify-content: center' : '')}

  ${({ isNeutral }) => (isNeutral
    ? `
    background: rgb(255, 255, 255, 0);
    border-radius: 4px;
    border: 1px solid var(--Sheme_border_default);
    outline: 0.5px solid var(--Sheme_border_default);
    color: var(--Sheme_fg_muted);
    svg path {
      fill: var(--Sheme_fg_muted);
    }
    &:hover:not([disabled]) {
    background: #fff;
      border-color: var(--Sheme_border_muted);
      color: var(--Sheme_fg_muted);
      outline-width: 0;

      svg path {
        fill: var(--Sheme_fg_muted);
      }
    }

    &:focus:not([disabled]) {
      outline: 4px solid var(--Sheme_border_focus);
      border-color: transparent;
      color: var(--Sheme_fg_muted);

      svg path {
        fill: var(--Sheme_fg_muted);
      }
    }

    &:active:not([disabled]) {
      background: #fff;
      color: var(--Sheme_fg_muted);
      outline-width: 0;
      border-color: var(--Sheme_border_muted);

      svg path {
        fill: var(--Sheme_fg_muted);
      }
    }
    &:disabled {
      background: #fff;
      color: var(--Sheme_fg_disabled);
      border-color: var(--Sheme_border_disabled);
      cursor: not-allowed;
      svg path {
        fill: var(--Sheme_fg_disabled);
      }
    }
  ` : '')};
  ${({ isSquare }) => (isSquare ? 'border-radius: 8px;' : '')}
  
  ${({ sidePaddings }) => (sidePaddings ? `
    padding-left: ${sidePaddings}px; padding-right: ${sidePaddings}px;
    ` : '')}
  ${({ isIconOnly }) => (isIconOnly ? `
    width: 32px;
    height: 32px;
    padding: 5.5px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    ` : '')}
  ${({ isIconOnly, isSquare }) => (isIconOnly && isSquare ? `
      border-radius: 12px;
      width: fit-content;
      height: fit-content;
    ` : '')}
  ${({ isGhost }) => (isGhost ? `
    background: transparent;
    color: var(--Sheme_fg_muted);

    svg path {
      fill: var(--Sheme_fg_muted);
    }

    &:hover:not([disabled]) {
      background: var(--Sheme_bg_hover_alpha_1);
      border-color: transparent;
      color: var(--Sheme_fg_muted);
      outline-width: 0;

      svg path {
        fill: var(--Sheme_fg_muted);
      }
    }

    &:focus:not([disabled]) {
      outline: 4px solid var(--Sheme_border_focus);
      border-color: transparent;
      color: var(--Sheme_fg_muted);

      svg path {
        fill: var(--Sheme_fg_muted);
      }
    }

    &:active:not([disabled]) {
      background: #fff;
      color: var(--Sheme_fg_muted);
      outline-width: 0;
      border-color: var(--Sheme_border_muted);

      svg path {
        fill: var(--Sheme_fg_muted);
      }
    }
    &:disabled {
      background: #fff;
      color: var(--Sheme_fg_disabled);
      border-color: var(--Sheme_border_disabled);
      cursor: not-allowed;
      svg path {
        fill: var(--Sheme_fg_disabled);
      }
    }
  ` : '')}
  ${({ isLink }) => (isLink ? `
    background: transparent;
    color: var(--Sheme_fg_muted);
    border: none;
    outline: none;

    svg path {
      fill: var(--Sheme_fg_muted);
    }

    &:hover:not([disabled]) {
      border: none;
      outline: none;
      background: transparent;
      color: var(--Sheme_fg_subtle);

      svg path {
        fill: var(--Sheme_fg_subtle);
      }
    }

    &:focus:not([disabled]) {
      outline: none;
      border: none;
      color: var(--Sheme_fg_subtle);
      background: transparent;

      svg path {
        fill: var(--Sheme_fg_subtle);
      }
    }

    &:active:not([disabled]) {
      outline: none;
      border: none;
      color: var(--Sheme_fg_default);
      background: transparent;

      svg path {
        fill: var(--Sheme_fg_default);
      }
    }
    &:disabled {
      background: transparent;
      color: var(--Sheme_fg_disabled);
      border: none;
      cursor: not-allowed;
      svg path {
        fill: var(--Sheme_fg_disabled);
      }
    }
  ` : '')}
  ${({ customWidth }) => (customWidth ? `
    width: ${customWidth}px;
    ` : '')}
  ${({ customHeight }) => (customHeight ? `
    height: ${customHeight}px;
  ` : '')}
`;

export const InputAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 24px 48px -8px rgba(66, 66, 66, 0.16), 0 4px 12px 4px rgba(66, 66, 66, 0.12);
  max-width: 1200px;
  width: calc(100vw - 430px);
  background: #fff;
  padding: 12px 12px;
    z-index: 10;
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 32px;
  padding: 8px 4px 8px 24px;
  borer-radius: 50%;
  
  &:focus-within {
    background: var(--Sheme_bg_subtle);
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const SectionTitle = styled.p`
  color: #8D8A93;
  font-family: RobotoFlex, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin-top: 0;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  max-width: 147px;
`;
export const ShareButton = styled.div`
  display: flex;
  height: 40px;
  position: relative;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background-color: #FFC72C;
  cursor: pointer;
`;

export const FeatureTag = styled.div <{ height?: number }>`
  padding-bottom: 2px;
  ${({ height }) => (height ? `
    height: ${height}px;
    display: flex;
    align-items: flex-end;
    
    ` : '')}

  p {
    color: #A2A5AA;
    margin: 0;
    font-weight: 700;
    font-family: "RobotoFlex", sans-serif;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const AIButton = styled.button <{ isSearch?: boolean, isReset?: boolean, isSave?: boolean }>`
  border-radius: 8px;
  height: 40px;
  padding: 2px 24px;
  align-items: center;
  align-content: center;
  display: flex;
  gap: 8px;
  outline: none;
  border: none;
  color: #FAFAFA;
  cursor: pointer;
  ${({ isSearch }) => (isSearch
    ? `
      background: radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%, 
        rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%), 
        linear-gradient(87deg, #AA59DF 0%, #7B59DF 66.81%, #AA59DF 133.63%);

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
          radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
          rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
          linear-gradient(87deg, #AA59DF 0%, #7B59DF 66.81%, #AA59DF 133.63%);
      }
      ` : `
      background: radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
        linear-gradient(89deg, #59DFC0 0%, #356CE4 74.49%, #59DFC0 148.99%);

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
         radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
         rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
         linear-gradient(89deg, #59DFC0 0%, #356CE4 74.49%, #59DFC0 148.99%), #A2A5AA;
      }
    `)}
  ${({ isReset }) => (isReset
    ? `
      background:  transparent;
      color:#000;
      border: 1px solid #E8E9EA;
       &:hover {
            color:#484343;
            border: 0.6px solid #FFC72C;
            background: #FFEEBF;
          }
    `
    : '')}
    ${({ isSave }) => (isSave
    ? `
      border-radius: 4px;
      background: #FFC72C;
      width: 160px;
      color: rgb(81, 78, 85);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      &:hover {
        background: rgb(231, 193, 95);
        color: rgb(81, 78, 85);
        }
    `
    : '')}
`;

export const Textarea = styled.textarea`
  width: 100%;
  margin-right: 50px;
  height: 28px;
  max-height: 76px;
  resize: none;
  border: none;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-left: 8px;
  font-size: 16px;
  font-weight: 500;

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    padding-top: 2px;
    color: #C5C5C5;
  }

  &:focus {
    background: #F5F5F5;
  }

   &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;
export const keyFrames = keyframes`
   to {
      transform: rotate(360deg);
   }
`;

export const SharedIndicator = styled.div`
  background-color: #5FE77F;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 125px;
  top: 10px;
  border-radius: 50%;
`;

export const AISpinnerWrapper = styled.div`
  width: 40px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
export const ImageShell = styled.div`
  position: relative;
  transition: ease-out 0.25s;
  transition-property: width, height;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  width: 32px;
  height: 32px;
`;

const rotate = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`;

const prixClipFix = keyframes`
  0% {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25% {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
`;
export const Spinner = styled.span <{ isSmall?: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  &:before, &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 4px solid #FFC72C;
    animation: ${prixClipFix} 6s linear 1;
  }
`;
export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
`;
export const Result = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 8px;
  align-self: stretch;
  border-bottom: 1px solid #E8E9EA;
  background: #FFF;
  cursor: pointer;

  &:last-child {
    border: none;
  }
`;
export const SingleResultInfo = styled.div`
  display: flex;
  padding-left: 40px;
  gap: 8px;
  p {
    color: #8D8A93;
    margin: 4px 0;
    
    b {
      color: #232225;
    }
  }
`;

export const SelectedItemsCounter = styled.div`
  border-radius: 50%;
  background-color: #FFC72C;
  min-width: 24px;
  width: fit-content;
  height: 24px;
  display: flex;
  color: #232225;
  align-items: center;
  justify-content: center;
`;

export const PopupWrapper = styled.div<{ isAgents?: boolean, left?: number, top?: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 4px 0;
  background: #fff;
  box-shadow: 0px 24px 48px -8px rgba(66, 66, 66, 0.16), 0px 4px 12px 4px rgba(66, 66, 66, 0.12);
  min-width: 280px;
  width: fit-content;
  height: fit-content;
  bottom: 86px;
  ${({ isAgents }) => (isAgents ? 'left: 225px; z-index: 10;' : 'left: 0;')}
  ${({ left, top }) => ((left && top) ? `position: absolute; left: ${left + 20}px; top: ${top - 130}px;  z-index: 100;` : '')} 
  border-radius: 12px;
`;

export const PopupHeader = styled.div`
  display: flex;
  height: 28px;
  padding: 10px 20px;
  -webkit-box-align: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
`;

export const ModalFooter = styled.div`
  border-top: 1px solid #E0DFE2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
`;

export const LikeResultsSection = styled.div`
  display: flex;
  gap: 5px;
  color: #514E55;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 17.5px;

  p {
    margin: 0;
  }

  .save {
    font-weight: 500;
  }
`;
export const HorizontalDivider = styled.div`
  background-color: #D9D9D933;
  width: 100%;
  margin: 1px 4px;
  height: 1px;
`;

export const RefineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const PopupSectionTitle = styled.div`
  color: #8D8A93;
  font-size: 12px;
  font-family: RobotoFlex, sans-serif;
  margin: 0;
  padding: 5px 0 5px 20px;
  margin-bottom: 2px;
`;

export const ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 2px
`;

export const NoMatches = styled.p`
  margin: 8px 0;
  text-align: center;
`;

export const ItemName = styled.div<{ isDelete?: boolean, isAI?: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  padding-right: 10px;
  svg {
    width: 20px;
    height: 20px;
  }

  ${({ isDelete }) => (isDelete ? `
    svg path {
      stroke: #E7715F;
    }
    ` : '')};
`;

export const Keyword = styled.div`
  display: flex;
  height: 24px;
  padding: 0px 6px;
  align-items: center;
  border-radius: 32px;
  border: 1px solid #F3DDA5;
  background: #F8EAC9;
  color: #F8BA12;
`;

export const Note = styled.div`
  color:  #8D8A93;
  text-align: center;
  font-family: RobotoFlex, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
`;

export const FirstLine = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StepWrapper = styled.div<{ isActive: boolean }>`
  width: 48px;
  height: 48px;
  border-radius: 45px;
  background: rgba(247, 247, 248, 1);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  ${({ isActive }) => (isActive ? 'background:rgba(248, 234, 201, 1);' : '')}

  svg {
    width: 32px;
    height: 32px;
    ${({ isActive }) => (isActive ? `
      path {
        fill: rgba(248, 186, 18, 1);
      }
    ` : '')}
  }
`;

export const SingleItem = styled.button<{isAI?: boolean, isActive?:boolean}>`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 4px 20px;
  align-items: center;
  border: 4px solid #fff;
  border-radius: 8px;
  background: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  & .hidePlaylistSelector {
    display: none;
  }
  &:hover:not([disabled]) {
    background: #E6AC190F;

    ${ItemName} {
      color: #E7C15F;

      svg path {
        ${({ isAI }) => (isAI ? 'fill: #E7C15F;' : 'stroke: #E7C15F;')}
      }
    }
    .hidePlaylistSelector {
      display: block;
    } 
  }
    ${({ isActive }) => (isActive ? `
    background: #E6AC190F;
    ${ItemName} {
      color: #E7C15F;
      svg path {
       fill: #E7C15F;
      }
    }` : '')}

  &:focus:not([disabled]) {
    background: #E6AC190F;
    border-color: #CA971614;

    ${ItemName} {
      color: #E7C15F;

      svg path {
        ${({ isAI }) => (isAI ? 'fill: #E7C15F;' : 'stroke: #E7C15F;')}
      }
    }
  }

  &:active:not([disabled]) {
    background: #E6AC190F;

    ${ItemName} {
      color: #F8BA12;

      svg path {
        ${({ isAI }) => (isAI ? 'fill: #F8BA12;' : 'stroke: #F8BA12;')};
      }
    }
  }
`;


export const HotkeyWrapper = styled.div<{ isDelete?: boolean }>`
  font-size: 12px;
  text-transform: uppercase;
  font-family: RobotoFlex, sans-serif;
  border: 0.4px solid #B7B5BA;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  white-space: nowrap;
  border-color: #8D8A93;
  color: #8D8A93;
  ${({ isDelete }) => (isDelete ? `
    border-color: #F3B0A5;
    color: #F3B0A5;
    width: fit-content;
    text-transform: none;
    ` : '')};
`;


export const SmartfilesListWrapper = styled.div<{ isDelete?: boolean }>`
  position: absolute;
  left: calc(100% - 16px);
  z-index: 1;
`;

export const SvgButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
  border: 4px solid transparent;
  background: #fff;
  width: 40px;
  height: 40px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: rgba(126, 122, 133, 0.04);
  }
  &:focus {
    border: 4px solid  rgba(111, 108, 117, 0.08);
    background: rgba(126, 122, 133, 0.04);
  }
  &:active {
    border: 1.5px solid #B7B5BA;
    padding-top: 2.5px;
  }
`;

export const CloseWrapper = styled.div``;
