import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoadPlaylistData } from './MakerCommon/LogicComponents/useLoadPlaylistData';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import PlaylistSideBarsWrapper from './PlaylistSideBarsWrapper';
import Logo from '../../components/MainHeader/Logo';
import MakerTOC from './MakerToc/MakerTOC';
import { ReactComponent as CollapseIcon } from '../../images/icons/panel-left-close.svg';
import {
  DATA_PARENTS,
  emptyCallback,
  INBOX_CARD_TYPE,
  NewUPVViewModes,
  openModalType,
  MessageType,
  Group,
  sidebarModes,
} from '../../utils/constants';
import PlaylistMainContentCardView from './MakerContent/PlaylistMainContentCardView';
import { actionChangeViewModeUPV } from '../../redux/upv/actions';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import ZoomControls from './MakerCommon/ZoomControl';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import rawStyles from './MakerCommon/index.module.scss';
import MakerCutHeader from './MakerCutHeader';
import { ReactComponent as TOCSvg } from '../../images/icons/icon_50/TOCMaker.svg';
import { LeftNav, TOCSvgShell } from './sharedStyled';
import { SUBSCRIBE_SOCKET } from '../../sagas/SocketClusterHelper';
import { sendMessage } from '../../sagas/SocketCluster/action';
import { isRoleInPlaylist } from '../../utils/permissions';
import { actionCleanBlinkId, actionShowMessage } from '../../redux/support/action';
import { actionOpenModal } from '../../redux/user/action';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import EditPlaylist from '../../redux/playlists/types';
import {
  actionClearItemsSelectionInSmartfile,
  actionClearToBeScrollTo,
  actionRemoveLinkPageFromPlaylist,
} from '../../redux/currentPage/action';
import {
  MakerContentMainSection,
  Place,
  PlaylistMainShell,
  PlaylistMainShellW,
  ToCAndExpandWrapper,
  BackgroundForSidebar,
  CollapseTooltipWrapper,
} from './styled';
import useDurationChangerInMaker from '../../utils/hooks/useDurationChangerInMaker';
import useThrottle from '../../utils/hooks/useThrottle';
import NoAvailable from '../AccessPage/NoAvailable';
import { useQuery } from '../../utils/hooks/useQuery';
import { calcIfWholeSmartfileDownloadable } from '../../utils/helpers';
import { InboxAction } from '../../redux/inbox/types';
import { useHoverArrowNavigation } from './useHoverArrowNavigation';
import { MILLISECONDS_IN_MIN } from '../../utils/dateConvert';
import Tooltip from '../../shared/Tooltips/Tooltip';
import DownloadPlaylistModal from '../../components/GlobalModals/DownloadPlaylistModal';
import AIInput from './AIInput.tsx';
import { PlaylistMainHeaderShell } from './components/MainHeader/styled';
import MainLeftSideBar from '../../components/MainLeftSideBar';
import { CollapseIconWrapper } from '../../components/MainLeftSideBar/styled';

const cx = classNames.bind(rawStyles);

const ZOOM_STEP = 0.1;
const { isViewer, isOwner, isCoEdit } = isRoleInPlaylist;
const isScrollbarClick = (event, refForScroll) => {
  const element = refForScroll.current;
  if (element) {
    const { clientWidth, offsetWidth, scrollWidth } = element;
    const isScrollbarVisible = scrollWidth > clientWidth;
    const scrollbarWidth = offsetWidth - clientWidth;
    if (isScrollbarVisible) {
      const rect = element.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      if (clickX >= clientWidth && clickX <= clientWidth + scrollbarWidth) {
        return true;
      }
    }
  }
  return false;
};

const PlaylistMain = ({ isForSharedToWeb }) => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [throttleScroll] = useThrottle();
  const { location } = history;
  const windowDimensions = useWindowDimensions();
  const [refItem, open, setOpen] = useComponentVisible('');
  // ref to element to be scroll to
  const elementRef = useRef(null);
  const isPrevent = useRef(null);
  const activeItemIndex = useHoverArrowNavigation({ isForSharedToWeb });

  const [refItemHeader, isHeaderOpen, setHeaderOpen] = useComponentVisible(location.state?.isNewPlaylist, DATA_PARENTS.PlaylistHeader + DATA_PARENTS.checkItem);

  const [currentSidebarMode, setCurrentSidebarMode] = useState(sidebarModes.collapsed);
  const [hoverTimeoutID, setHoverTimeoutID] = useState();

  const collapseButtonMouseLeaveHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (currentSidebarMode === sidebarModes.visible) return;
    if (currentSidebarMode === sidebarModes.hovered) {
      setHoverTimeoutID(setTimeout(() => setCurrentSidebarMode(sidebarModes.collapsed), 2000));
    }
  };

  const collapseButtonMouseEnterHandler = () => {
    if (currentSidebarMode === sidebarModes.collapsed) {
      setCurrentSidebarMode(sidebarModes.hovered);
    }
    clearTimeout(hoverTimeoutID);
  };

  const clearSelection = (event) => {
    const isScrollButtonClick = event.button === 1;
    if (isScrollButtonClick || isScrollbarClick(event, refForScroll)) {
      return;
    }
    if (event.target
      && ((event.target)?.dataset?.parent === DATA_PARENTS.checkItem
      || (event.target)?.parentElement?.dataset?.parent === DATA_PARENTS.checkItem)) return;

    const parent = (event.target)?.parentElement;
    const isDeepNestedSVG = parent.tagName === 'g';
    if (isDeepNestedSVG) {
      if (parent.parentElement?.dataset?.parent === DATA_PARENTS.checkItem) return;
      if (parent.parentElement?.parentElement?.dataset?.parent === DATA_PARENTS.checkItem) return;
    }
    dispatch(actionClearItemsSelectionInSmartfile());
  };


  const currentPage = useSelector((state) => state.currentPage);
  const { isDownload,
    isFinished,
    wrapperId,
    linkPages = [],
    id: currentPageId,
    currentRole,
    isShowLibraryWidget,
    sideBarType,
    idForCoEdit,
    totalPlaylistDuration,
    notAvailable, shareState,
    selectedItems,
    isLargeRightBar,
    title,
  } = currentPage;
  const { id: userId, openModal } = useSelector((state) => state.user);
  // view mode - 3 diff types - card list and extended
  const { viewMode } = useSelector((state) => state.upv);
  const { backlightItemId, toBeScrolledTo } = useSelector(
    (state) => state.support,
  );

  const viewersLinkPages = useMemo(() => {
    if (isViewer[currentRole]) {
      return linkPages.filter(lp => lp.textComponent || lp.libraryComponent);
    }
    return linkPages;
  }, [linkPages, currentRole]);

  const query = useQuery();
  const [clickOutSideRefLib, isShowRightSideBar, setIsShowRightSideBar] = useComponentVisible(
    isShowLibraryWidget,
    DATA_PARENTS.PlaylistBuilderLibrary,
    null,
    isPrevent,
  );
  if (!isForSharedToWeb) {
    useLoadPlaylistData(location.state?.isNewPlaylist ? 'justCreatedEdit' : null);
  }
  useDurationChangerInMaker(linkPages, totalPlaylistDuration);

  const [isCanManyDownload, setIsCanManyDownload] = useState(false);
  const [isCanSomeDelete, setCanSomeDelete] = useState(false);


  function onReload() {
    history.replace(location.pathname, {
      ...location.state,
      isNewPlaylist: false,
    });
  }

  const itemsToDell = useRef([]);

  // cut mode - if page is overflown and scrollTop!=0
  const [cutMode, setCutMode] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const refForScroll = useRef(null);
  const refPrevent = useRef(null);
  const zoomRef = useRef(null);
  const zoomWrapRef = useRef(null);
  const zoomParam = useRef(1);

  const curryOperatePlus = (a, b) => a + b;

  const curryOperateMinus = (a, b) => a - b;

  const downloadItemClickHandler = useCallback((e, filesArray, isLinkAdded, smartfileLink) => {
    e.stopPropagation();
    if (!linkPages?.length || !isCanManyDownload) {
      return;
    }
    dispatch(
      actionShowMessage({
        type: MessageType.DownloadWholePlaylist,
        itemName: 'playlist',
        mode: Group.processing,
        currentPageId,
      }),
    );
    axiosAbortarium.generateNew(currentPageId);
    dispatch(
      actionCreator(EditPlaylist.DownloadAllDownloadablePlaylistItems, {
        playlistId: currentPageId,
        playlistTitle: title,
        filesArray,
        isLinkAdded,
        smartfileLink,
      }),
    );
  }, [linkPages?.length, isCanManyDownload, dispatch, title, currentPageId]);

  const handleDownloadAll = () => {
    if (!!linkPages?.length && isCanManyDownload) {
      dispatch(actionOpenModal(openModalType.DownloadPlaylistModal, { download: downloadItemClickHandler }));
    }
  };

  const deleteHandler = () => {
    if (!isCanSomeDelete) return;
    const splittedItems = linkPages.reduce((acc, cur) => {
      const canDeleteAsSmartFileOwner = isOwner[currentRole];
      const canDeleteAsItemOwner = isCoEdit[currentRole] && cur?.owner?.id === userId;
      if (!(canDeleteAsSmartFileOwner || canDeleteAsItemOwner)) return acc;

      if (selectedItems[cur.id]) {
        acc.textComponents.push(cur.textComponent?.type ? cur.textComponent.id : null);
        acc.linkPages.push(cur.id);
      }
      return acc;
    }, { textComponents: [], linkPages: [] });

    dispatch(
      actionRemoveLinkPageFromPlaylist(
        currentPage.id,
        splittedItems.linkPages,
        splittedItems.textComponents,
      ),
    );
    dispatch(actionClearItemsSelectionInSmartfile());
  };

  const changeZoom = (operate) => {
    if (zoomParam.current && zoomWrapRef.current) {
      zoomParam.current = Math.floor((operate(zoomParam.current * 10, 1) / 10) * 100) / 100;
      // zoomWrapRef.current.style.transform = `scale(${zoomParam.current})`;
      //  heed check next solution
      zoomWrapRef.current.style.zoom = `${zoomParam.current}`;
    }
  };

  const isContentWithinViewPort = (a) => {
    return (
      windowDimensions.width
      > zoomRef?.current?.clientWidth * ((a || zoomParam?.current) + ZOOM_STEP * 2)
    );
  };

  const onZoomOutClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    zoomParam?.current > ZOOM_STEP * 2 && changeZoom(curryOperateMinus);
  };

  const onZoomInClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    isContentWithinViewPort() && changeZoom(curryOperatePlus);
  };

  const onZoomDefaultClick = () => {
    zoomParam.current = 1;
    if (!zoomWrapRef?.current) return;
    zoomWrapRef.current.style.zoom = `${zoomParam.current}`;
  };

  const setViewMode = (mode) => {
    dispatch(actionCleanBlinkId());
    dispatch(actionChangeViewModeUPV(mode));
  };

  const zoomHandler = useCallback((event) => {
    if (event.ctrlKey && zoomWrapRef?.current) {
      event.preventDefault();
      const isZoomIn = !!(Math.sign(event.deltaY) - 1);
      if (isZoomIn) {
        zoomParam.current = isContentWithinViewPort(zoomParam.current)
          ? (zoomParam.current * 10 + event.deltaY * -0.02) / 10
          : zoomParam.current;
        zoomWrapRef.current.style.zoom = `${zoomParam.current}`;
      } else {
        zoomParam.current = (zoomParam.current * 10 + zoomParam.current * -0.03) / 10 > ZOOM_STEP * 2
          ? (zoomParam.current * 10 + event.deltaY * -0.03) / 10
          : ZOOM_STEP * 2;
        zoomWrapRef.current.style.zoom = `${zoomParam.current}`;
      }
    }
  }, []);

  const clearBacklight = () => dispatch(actionCreator(SupportAction.ClearBacklightItem, {}));

  const clearOnDismount = () => {
    itemsToDell.current.forEach((it) => dispatch(actionRemoveLinkPageFromPlaylist(currentPageId, it.id)),
    );
  };

  const listenForScroll = (e) => {
    if (isHeaderOpen && !refPrevent.current) {
      setHeaderOpen(false);
    }
    throttleScroll(() => {
      if (e.target.scrollTop < 230 && cutMode) {
        setCutMode(false);
      } else if (e.target.scrollTop > 320 && !cutMode) {
        setCutMode(true);
      }
    });
  };
  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const listenForDrag = () => {
    // refForScroll.current.scrollTo({ top: e.clientY, behavior: 'smooth' });
  };

  const scrollContainerHelpers = {
    handleDragStart,
    handleDragEnd,
    listenForDrag,
  };

  useEffect(() => {
    if (backlightItemId) {
      document.addEventListener('mousedown', clearBacklight, true);
      return () => {
        clearBacklight();
        document.removeEventListener('mousedown', clearBacklight, true);
      };
    }
  }, [backlightItemId]);


  useEffect(() => {
    if (isOwner[currentRole]) {
      setCanSomeDelete(true);
      return;
    }
    if (isCoEdit[currentRole]) {
      const hasItemsToDell = linkPages.find(i => {
        if (Object.keys(selectedItems).includes(i.id)) {
          return i?.owner?.id === userId;
        }
      });
      setCanSomeDelete(hasItemsToDell);
      return;
    }
    setCanSomeDelete(false);
  }, [selectedItems]);

  useEffect(() => {
    setIsCanManyDownload(calcIfWholeSmartfileDownloadable(linkPages));
  }, [linkPages]);

  useEffect(() => {
    if (currentPageId && !isViewer[currentRole]) {
      return clearOnDismount;
    }
    return () => {
      dispatch(actionCreator(SupportAction.CleanBlinkItemIds));
    };
  }, [currentPageId]);

  useEffect(() => {
    itemsToDell.current = linkPages?.filter(
      (it) => !it.type && it?.owner?.id === userId,
    ) || [];
  }, [linkPages]);

  useEffect(() => {
    const subId = idForCoEdit || currentPageId;
    if (subId && currentRole) {
      dispatch(
        actionCreator(SUBSCRIBE_SOCKET, {
          channelName: 'channelName',
          id: subId,
          dispatch,
          history,
        }),
      );
    }
    return () => {
      if (subId && currentRole) {
        dispatch(
          sendMessage({
            dataSend: {
              unsubscribeChannel: 'unsubscribeChannel',
            },
            unsubscribeChannel: subId,
          }),
        );
      }
    };
  }, [currentPageId, currentRole]);

  useEffect(() => {
    const queryFromPageType = query?.get('fromPageType');
    if (!currentPageId) return emptyCallback;

    const saveRead = () => {
      if (wrapperId && queryFromPageType === 'channel') {
        dispatch(actionCreator(InboxAction.CardInBoxMarkUsReed, { item: {
          isRead: false,
          id: wrapperId,
          type: INBOX_CARD_TYPE.channelPlaylist,
        } }));
      } else if (queryFromPageType === 'shared to me' || queryFromPageType === 'shared' || type === 'shared') {
        dispatch(actionCreator(InboxAction.CardInBoxMarkUsReed, { item: {
          isRead: false,
          id: wrapperId,
          type: INBOX_CARD_TYPE.sharedPlaylist,
        } }));
      } else if (isOwner) {
        dispatch(actionCreator(EditPlaylist.OpenSmartFile, { wrapperId }));
      }
    };
    dispatch(actionCreator(EditPlaylist.OpenSmartFile, { wrapperId }));

    saveRead();
    const intervalId = setInterval(saveRead, MILLISECONDS_IN_MIN / 2);
    return () => {
      clearInterval(intervalId);
      saveRead();
    };
  }, [currentPageId, wrapperId]);

  useEffect(() => {
    if (elementRef?.current && toBeScrolledTo) {
      const delay = 100; // Adjust the delay as needed (in milliseconds).
      const timeoutId = setTimeout(() => {
        elementRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        dispatch(actionClearToBeScrollTo());
      }, delay);

      // Clear the timeout if the component is unmounted before the scroll happens.
      return () => clearTimeout(timeoutId);
    }
  }, [elementRef?.current, toBeScrolledTo]);

  useEffect(() => {
    isPrevent.current = !!openModal;
  }, [openModal]);

  useEffect(() => {
    // change value of isNewPlaylist before page reloaded
    window.addEventListener('beforeunload', onReload);
    return () => window.removeEventListener('beforeunload', onReload);
  }, [currentPageId, userId]);

  useEffect(() => {
    if (openModal) return;
    zoomParam.current = 1;
    if (zoomWrapRef?.current) {
      zoomWrapRef.current.style.zoom = `${zoomParam.current}`;
    }

    document.addEventListener('wheel', zoomHandler, { passive: false });
    return () => document.removeEventListener('wheel', zoomHandler);
  }, [viewMode, openModal]);

  const collapseTooltip = useMemo(() => {
    if (currentSidebarMode === sidebarModes.collapsed) {
      return 'Show sidebar';
    }
    if (currentSidebarMode === sidebarModes.hovered) {
      return 'Keep sidebar visible';
    }
    return 'Hide sidebar';
  }, [currentSidebarMode]);

  const collapseButtonHoverHandler = () => {
    if (currentSidebarMode === sidebarModes.visible) return;
    if (currentSidebarMode === sidebarModes.collapsed) {
      setCurrentSidebarMode(sidebarModes.hovered);
    }
  };
  const collapseButtonClickHandler = () => {
    if (currentSidebarMode === sidebarModes.visible) {
      setCurrentSidebarMode(sidebarModes.collapsed);
    } else {
      setCurrentSidebarMode(sidebarModes.visible);
    }
  };
  const isExpanded = viewMode === NewUPVViewModes.expanded;
  if (notAvailable && !isDownload) {
    return (
      <NoAvailable
        type="Playlist"
        shareState={shareState}
        getPageData={emptyCallback}
        id={currentPageId}
      />
    );
  }
  const setHeaderOpenHandler = (value) => {
    setHeaderOpen(value);
    refPrevent.current = true;
    setTimeout(() => {
      refPrevent.current = false;
    }, 1000);
  };
  return (
    <PlaylistMainShellW isForSharedToWeb={isForSharedToWeb} onMouseDown={clearSelection}>
      {currentSidebarMode === sidebarModes.hovered && <BackgroundForSidebar />}
      <LeftNav data-parent={DATA_PARENTS.PlaylistHeader} isMaker>
        <Logo isMaker isForSharedToWeb={isForSharedToWeb} />
      </LeftNav>
      <CollapseTooltipWrapper
        onMouseLeave={collapseButtonMouseLeaveHandler}
        onMouseEnter={collapseButtonMouseEnterHandler}
      >
        <Tooltip
          text={collapseTooltip}
          direction="left"
          place="sidebarCollapseTooltip"
        >
          <CollapseIconWrapper
            onClick={collapseButtonClickHandler}
            isVisible={currentSidebarMode === sidebarModes.visible}
            isHovered={currentSidebarMode === sidebarModes.hovered}
            onMouseEnter={collapseButtonHoverHandler}
            isOrganizer
          >
            <CollapseIcon />
          </CollapseIconWrapper>
        </Tooltip>
      </CollapseTooltipWrapper>
      {isDownload && (
      <div className="loader-wrapper-page opacity">
        <div className="loader">{t('loadingT')}</div>
      </div>
      )}
      {openModal === openModalType.DownloadPlaylistModal && <DownloadPlaylistModal />}
      <MakerTOC
        currentRole={currentRole}
        refItem={refItem}
        setOpen={setOpen}
        open={open === 'toc'}
      />
      <ToCAndExpandWrapper>
        <TOCSvgShell isMoved={currentSidebarMode === sidebarModes.visible}>
          <Tooltip
            text={t('tableOfContentT')}
            direction="left"
            place="TOC"
          >
            {open !== 'toc' && <TOCSvg onClick={() => setOpen('toc')} />}
          </Tooltip>
        </TOCSvgShell>
      </ToCAndExpandWrapper>

      <PlaylistMainShell
        onScroll={listenForScroll}
        ref={refForScroll}
      >
        <MainLeftSideBar
          currentSidebarMode={currentSidebarMode}
          collapseButtonMouseLeaveHandler={collapseButtonMouseLeaveHandler}
          setCurrentSidebarMode={setCurrentSidebarMode}
          collapseButtonMouseEnterHandler={collapseButtonMouseEnterHandler}
          isOrganizer
        />
        <PlaylistMainHeaderShell isHeaderOpen={isHeaderOpen} isShowLibraryWidget={isShowLibraryWidget} />
        <MakerCutHeader
          refItemHeader={refItemHeader}
          isLargeRightBar={isLargeRightBar}
          isFinished={isFinished}
          wrapperId={wrapperId}
          cutMode={cutMode}
          setViewMode={setViewMode}
          isForSharedToWeb={isForSharedToWeb}
          viewMode={viewMode}
          lExpand={isShowRightSideBar && sideBarType === 'library'}
          open={open}
          isShowRightSideBar={isShowRightSideBar}
          isShowLibraryWidget={isShowLibraryWidget}
          linkPages={linkPages}
          isCanManyDownload={isCanManyDownload}
          handleDownloadAll={handleDownloadAll}
          refForScroll={refForScroll}
          currentRole={currentRole}
          isOpen={isHeaderOpen}
          setOpen={setHeaderOpenHandler}
          isSidebarOpened={currentSidebarMode === sidebarModes.visible}
        />
        {!isDownload && (
        <MakerContentMainSection ref={zoomWrapRef} isSidebarOpened={currentSidebarMode === sidebarModes.visible}>
          <div
            ref={zoomRef}
            className={cx({ content: !!linkPages.length, isCut: currentSidebarMode === sidebarModes.visible })}
          >
            <PlaylistMainContentCardView
              activeItemIndex={activeItemIndex}
              isFinished={isFinished}
              playlistType={type}
              scrollContainerHelpers={scrollContainerHelpers}
              handleDragStart={handleDragStart}
              handleDragEnd={handleDragEnd}
              currentRole={currentRole}
              currentPage={currentPage}
              isExpanded={isExpanded}
              elementRef={elementRef}
              viewersLinkPages={viewersLinkPages}
              refForScroll={refForScroll}
              isSidebarOpened={currentSidebarMode === sidebarModes.visible}
            />
          </div>
        </MakerContentMainSection>
        )}
      </PlaylistMainShell>
      <PlaylistSideBarsWrapper
        forUPV
        currentPage={currentPage}
        clickOutSideRefLib={clickOutSideRefLib}
        isShowRightSideBarLib={isShowRightSideBar}
        setIsShowRightSideBarLib={setIsShowRightSideBar}
        currentRole={currentRole}
        isPreventCloseMutableRef={isPrevent}
      />
      {!isLargeRightBar && (
      <ZoomControls
        onZoomInClick={onZoomInClick}
        onZoomOutClick={onZoomOutClick}
        moveLeftSmall={sideBarType === 'settings'}
        moveLeftBig={sideBarType === 'library'}
        onZoomDefaultClick={onZoomDefaultClick}
      />
      )}
      <Place isLargeRightBar={isLargeRightBar} isShowRightSideBar={isShowRightSideBar} />
      <AIInput deleteHandler={deleteHandler} />
    </PlaylistMainShellW>
  );
};

export default PlaylistMain;
