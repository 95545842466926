import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LockSvg } from '../../../../images/icons/shareModal/lock.svg';
import { ReactComponent as PublicSvg } from '../../../../images/icons/shareModal/public.svg';
import { ReactComponent as ChevronSVG } from '../../../../images/2023/svg/small/complete/chevron_10_6.svg';
import { ReactComponent as MixedSvg } from '../../../../images/icons/shareModal/mixed_share_state.svg';


import styles from '../../index.module.scss';

const cx = classNames.bind(styles);
interface IPublicPrivateTab {
    setCurrentMode: (key: string) => void,
    isPublic: boolean,
    isMixedShareToWeb: boolean,
}
interface ModeConfig {
    title: string;
    description: string;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}
type Mode = 'public' | 'mixed' | 'private';
const modeMap: Record<Mode, ModeConfig> = {
  public: { title: 'publicT', description: 'publicDescriptionT', Icon: PublicSvg },
  mixed: { title: 'Mixed', description: 'Access settings vary by SmartFile', Icon: MixedSvg },
  private: { title: 'privateT', description: 'privateDescriptionT', Icon: LockSvg },
};
export const PublicPrivateTab: React.FC<IPublicPrivateTab> = ({
  setCurrentMode,
  isPublic,
  isMixedShareToWeb,
}) => {
  const { t } = useTranslation();
  let mode: Mode = 'private';

  if (isPublic) {
    mode = 'public';
  } else if (isMixedShareToWeb) {
    mode = 'mixed';
  }

  const { title, description, Icon } = modeMap[mode];
  return (
    <div className={cx('shared_option')} onClick={() => setCurrentMode('settings')}>
      <Icon className={cx('shared_option_icon')} />
      <div className={cx('shared_option_content')}>
        <div className={cx('option_title', 'text_unified-base')}>{t(title)}</div>
        <div className={cx('option_description', 'text_unified-sm')}>
          {t(description)}
        </div>
      </div>
      <ChevronSVG className={cx('shared_option_chevron')} />
    </div>
  );
};
