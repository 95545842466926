import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import Slider from 'rc-slider';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  calcNewCropStyles,
  centerAspectCrop,
} from '../../utils/SettingsHelpers/cropUtils';
import rawStyles from '../../pages/SettingsSection/Profile/ProfileInfo/index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';

const cn = classNames.bind(rawStyles);

const UserAvatarCropper = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imageSize, setImageSize] = useState({});
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(50);

  const imgRef = useRef(null);

  useEffect(() => {
    if (imageSize) {
      const newCrop = calcNewCropStyles(imageSize, zoom, crop);
      setCrop(newCrop);
    }
  }, [zoom]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const newCrop = centerAspectCrop(width, height, 1);
    if (crop.x === 0 && crop.y === 0) {
      setCrop(newCrop);
    }
    setImageSize({ width, height });
    setTimeout(() => {
      if (!imgRef?.current || imgRef.current.getAttribute('crossOrigin')) return;
      imgRef.current.setAttribute('crossOrigin', 'anonymous');
    }, 200);
  };

  const cancelButtonHandler = () => {
    dispatch(actionCloseModal());
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const okButtonHandler = async () => {
    props.save(crop);
  };

  if (!props.tmpImageBlob) return <></>;
  return (
    <div>
      <ReactCrop
        crop={crop}
        onChange={(pixelCrop, percentCrop) => {
          onCropChange(percentCrop);
        }}
        className={cn('crop', { isPlaylist: false })}
        aspect={1}
        locked
        keepSelection
        circularCrop
      >
        <img
          ref={imgRef}
          src={props.tmpImageBlob}
          alt="avatar_image_file"
          onLoad={onImageLoad}
        />
      </ReactCrop>

      <Slider
        className={cn('slider')}
        value={zoom}
        onChange={onZoomChange}
        handleStyle={{
          width: 6,
          height: 14,
          background: '#FFC72C',
          borderRadius: 4,
          border: 'none',
          boxShadow: 'none',
          marginTop: -4,
        }}
        trackStyle={{
          height: 4,
          background: '#E8EBFA',
          borderRadius: 4,
          border: 'none',
          boxShadow: 'none',
        }}
        railStyle={{
          height: 6,
        }}
      />

      <div className={cn('button_group')}>
        <Button2023
          variant="primary"
          height={32}
          text={t('cancelLowT')}
          handleButtonClick={cancelButtonHandler}
        />

        <Button2023
          variant="secondary"
          height={32}
          text={t('saveLowT')}
          handleButtonClick={okButtonHandler}
        />
      </div>
    </div>
  );
};

export default UserAvatarCropper;
