import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ThreeDotsMenu from '../ThreeDotsMenu';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import {
  actionDragPage,
  actionSwitchPage,
} from '../../../../redux/currentPage/action';
import ActionDraggable from '../../../../redux/dragable/action';
import { DATA_PARENTS } from '../../../../utils/constants';
import dropSmartfileToEntity from '../../../../utils/dnd/dropSmartfileToEntity';
import { AvatarInInput } from '../../../Inputs/InputUsersWithSelector/AvatarInInput';
import {
  Counter,
  Info,
  ContactName,
  Selector,
} from '../../styled';
import styles from '../sections.module.scss';

const SingleContact = React.memo(
  function SingleContact({ contact, isActiveContact, blinkIdsArray, nextContact, contacts, activeContactId }) {
    const [isDragOver, setIsDragOver] = useState(false);
    const [isDragOverError, setIsDragOverError] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const { idDragPage } = useSelector((state) => state.currentPage);
    const selectedPage = useSelector((state) => state.selectedPage);
    const currentUserId = useSelector((state) => state.user.id);
    const contentData = useSelector((state) => state.content.contentData);
    const draggableItemsExtraParams = useSelector((state) => state.library.draggableItemsExtraParams);

    const username = contact.first_name ? `${contact.first_name} ${contact.last_name}` : contact.username;

    const onDrop = (e) => {
      dropSmartfileToEntity(e,
        dispatch,
        setIsDragOver,
        idDragPage,
        contentData,
        currentUserId,
        contact,
        draggableItemsExtraParams,
        selectedPage,
        'contact');
    };

    const [refShowMenu, isOpenMenu, setIsOpenMenu] = useComponentVisible(false);
    const onContextMenuHandler = (e) => {
      e.preventDefault();
      setIsOpenMenu(true);
    };
    const testEl = document.createElement('div');
    testEl.innerText = 'Drag Info';
    const onDragOver = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (contentData?.[idDragPage]?.itemType !== 'playlist') return;
      if (!isDragOver) setIsDragOver(true);
      if (idDragPage && currentUserId !== contentData[idDragPage]?.owner.id) {
        setIsDragOverError(true);
      }
    };

    const onDragEnd = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setIsDragOver(false);
    };

    const dragPageStart = (e) => {
      e.stopPropagation();
      const div = e.currentTarget.cloneNode('deep');
      div.style.position = 'relative';
      div.style.opacity = 0.999;
      div.style.backgroundColor = '#ffeebf';
      div.id = 'draggable_page';

      dispatch(
        ActionDraggable.DragContact({
          id: contact.contactId,
        }),
      );

      dispatch(actionSwitchPage({ idDragPage: null }));
      document.body.appendChild(div);
      setTimeout(() => {
        dispatch(actionDragPage(contact.contactId, null, true));
      }, 0);
      e.dataTransfer.setDragImage(div, 117, 20);
    };

    const dragPageEnd = () => {
      const div = document.getElementById('draggable_page');
      if (div) document.body.removeChild(div);
      dispatch(ActionDraggable.Clear());
      dispatch(actionDragPage(null, null));
    };

    const clickContactHandler = () => {
      history.push(`/content/users_smartfiles/${contact.id}`);
    };
    return (
      <Selector
        active={isActiveContact}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
        key={contact.id}
        isHasOpenMenu={isOpenMenu}
        isDragOver={isDragOver}
        isNotAllowed={isDragOverError}
        onDragOver={onDragOver}
        onDragLeave={onDragEnd}
        onDrop={onDrop}
        onDragStart={dragPageStart}
        onDragEnd={dragPageEnd}
        draggable="true"
        onClick={clickContactHandler}
        className={`${blinkIdsArray?.includes(contact.contactId) ? styles.blink_it : ''}`}
      >
        <Info onContextMenu={onContextMenuHandler}>
          <AvatarInInput item={contact} />
          <ContactName>
            {username}
          </ContactName>
          <Counter isHidden={!isActiveContact}>
            {contact.counter}
          </Counter>
          <ThreeDotsMenu
            refShowMenu={refShowMenu}
            setIsOpenMenu={setIsOpenMenu}
            isOpenMenu={isOpenMenu}
            userId={contact.id}
            itemId={contact.contactId}
            items={contacts}
            activeItemId={activeContactId}
            nextItem={nextContact}
          />
        </Info>
      </Selector>
    );
  },
);

export default SingleContact;
