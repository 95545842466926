import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIconCircle } from '../../../images/icons/icon_20/plus2.svg';
import { ReactComponent as ChevronDownSvg } from '../../../images/icons/chevron_down_12.svg';
import { ReactComponent as ChannelsIcon } from '../../../images/2023/svg/small/left-main-menu/fill_icons/channels.svg';
import styles from '../../LeftSideBar/LeftSideBar.module.scss';
import ListItemChannel from '../../LeftSideBar/ListItemChannel';
import ListDragHandler from '../../DragHandler/ChannelListDragHandler';
import { actionOpenModal, actionSwitchFolder } from '../../../redux/user/action';
import {
  SectionChevronIconWrapper,
  MajorSelector,
  SelectorWrapper,
  MajorText,
  CollapsingSectionWrapper,
  PlusWrapperCircle,
  Selector,
  Info,
  AllContactsIconWrapper,
  ContactName,
} from '../styled';
import { actionSwitchContentSortType } from '../../../redux/content/actions';
import downloadStatus from '../../../utils/dataUtil';
import { calcTimeForSpeed } from '../../../utils/helpers';
import { openModalType } from '../../../utils/constants';
import Tooltip from '../../../shared/Tooltips/Tooltip';

const itemHeight = 33;

const ChannelsSection = ({ sortOptions }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { myChannels, loadStatus: loadStatusChannels } = useSelector((redux) => redux.channels);
  const { folderId: activeChannelId } = useSelector((state) => state.user);
  const { blinkIdsArray } = useSelector((state) => state.support);

  const [isChannelsCollapsed, setChannelsCollapsed] = useState(true);
  const [navAmongFolders, setNavAmongFolders] = useState([]);
  const isChannels = history.location.pathname.startsWith('/channel');

  useEffect(() => {
    setNavAmongFolders(Object.values(myChannels).filter(channel => !channel.isUnpin)
      .sort((a, b) => a.position - b.position));
  }, [myChannels]);

  const handleChannelSortOnClick = () => {
    dispatch(actionSwitchContentSortType(sortOptions.channelPlaylists));
  };

  const onAllChannelsClick = () => {
    dispatch(actionOpenModal(openModalType.ApplicationSettings, { defaultPage: 'activity' }));
  };

  const height = Object.values(myChannels).length * itemHeight;
  const time = calcTimeForSpeed(Object.values(myChannels).length);

  return (
    <>
      <MajorSelector
        onClick={() => setChannelsCollapsed(!isChannelsCollapsed)}
        onDragEnter={() => setChannelsCollapsed(false)}
        isCollapsed={isChannelsCollapsed}
      >
        <SelectorWrapper>
          <SectionChevronIconWrapper
            isCollapsed={isChannelsCollapsed}
          >
            <ChevronDownSvg className="chevron" />
            <ChannelsIcon className="icon" />
          </SectionChevronIconWrapper>
          <MajorText>{t('channelsUpT')}</MajorText>
        </SelectorWrapper>
        <Tooltip
          text="Add"
          direction="down"
          place="sidebarPlus"
        >
          <PlusWrapperCircle onClick={() => dispatch(actionOpenModal(openModalType.CreateChannel, null))}>
            <PlusIconCircle />
          </PlusWrapperCircle>
        </Tooltip>
      </MajorSelector>
      <CollapsingSectionWrapper
        isCollapsed={isChannelsCollapsed}
        height={`${height}px`}
        time={time}
      >
        <Selector onClick={onAllChannelsClick}>
          <Info>
            <AllContactsIconWrapper>
              <ChannelsIcon />
            </AllContactsIconWrapper>
            <ContactName>
              All Channels
            </ContactName>
          </Info>
        </Selector>
        <ListDragHandler nextItem={navAmongFolders[0]} isLast />
        {navAmongFolders.map((channel, index) => (
          <ChannelSector
            key={channel.id}
            activeChannelId={activeChannelId}
            isChannels={isChannels}
            channel={channel}
            index={index}
            blinkIdsArray={blinkIdsArray}
            sortOptions={sortOptions}
            navAmongFolders={navAmongFolders}
          />
        ))}
        {!navAmongFolders.length && loadStatusChannels !== downloadStatus.pending && (
          <div
            className={styles.create_new_channel}
            onClick={() => dispatch(actionOpenModal(openModalType.CreateChannel, null))}
          >
            <div>{t('addAChannelT')}</div>
          </div>
        )}
      </CollapsingSectionWrapper>
    </>
  );
};


const ChannelSector = React.memo(
  function ChannelSector({
    activeChannelId,
    channel,
    isChannels,
    sortOptions,
    navAmongFolders,
    index,
    blinkIdsArray,
  }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const switchFolder = () => {
      dispatch(actionSwitchContentSortType(sortOptions.channelPlaylists));
      dispatch(actionSwitchFolder('folder', channel.id));
      history.push(`/channel/${channel.id}`);
    };
    return (
      <div className={styles.relative}>
        <ListItemChannel
          canDrag={false}
          blinkIdsArray={blinkIdsArray}
          activeFolderId={isChannels && activeChannelId}
          switchFolder={switchFolder}
          channel={channel}
          navAmongFolders={navAmongFolders}
        />
        <ListDragHandler
          currentItem={channel}
          nextItem={navAmongFolders[index + 1]}
          isFirst={index === 0}
        />
      </div>
    );
  },
);

export default ChannelsSection;
