import styled from 'styled-components/macro';

export const EditCoverPopupWrapper = styled.div<{ top?: number, left?: number }>`
  position: absolute;
  z-index: 11111;
  border-radius: 12px;
  background: var(--white);
  box-shadow: var(--box_shadow-md);
  width: 400px;
  padding: 4px;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ top, left }) => (top && left
    ? `
    top: ${top}px;
    left: ${left}px;
  `
    : '')}
`;

export const TabsWrapper = styled.div`
  display: flex;
`;

export const TabContent = styled.div<{ isActive: boolean, width:number }>`
  padding: 6px 16px 10px;
  display: flex;
  gap: 8px;
  cursor: pointer;
  border-bottom: 2px solid;
  color: var(--Sheme_fg_subtle, #8D8A93);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;


  ${({ width }) => (`width: ${width}px;`)}
  ${({ isActive }) => (isActive ? `
    border-color: var(--Sheme_fg_muted);
    color: var(--Sheme_fg_muted);
    svg path {
      fill: var(--Sheme_fg_muted);
    }
    ` : `
    border-color: var(--Sheme_bg_subtle);
    svg path {
      fill: var(--Sheme_fg_subtle);
    }
      `
  )};
`;

export const DropWrapper = styled.div`
  border: 1px dashed #D2D2D2;
  border-radius: 8px;
  margin: 12px 16px;
  display: flex;
  padding: 34px 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 360px;
  height: 160px;
  &.dragged_over {
    background: var(--Brand_hover_alpha_1);
  }
  p {
    margin: 0; 
    color: var(--Neutral_Scheme_fg_subtle);

    .browse {
      cursor: pointer;
      color: var(--Sheme_fg_muted);
      font-weight: 500;
    }
  }
`;
