import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import styles from './index.module.scss';
import { ReactComponent as CloseSvg } from '../../images/icons/close_12.svg';
import { ReactComponent as AvatarPlaceHolder24Svg } from '../../images/icons/avatar_placeholder_24.svg';
import { ReactComponent as AvatarWrongSvg } from '../../images/icons/avatar_wrong.svg';
import { ReactComponent as AddUserIcon16Svg } from '../../images/icons/user-round.svg';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../utils/constants';
import downloadStatus from '../../utils/dataUtil';
import { actionCreateChannel } from '../../redux/channels/action';
import { IconRole } from '../../utils/permissions';
import AvatarPlaceholder from '../Avatar';
import Tooltip from '../../shared/Tooltips/Tooltip';
import validateEmail from '../../utils/validators/validateEmail';

const cx = classNames.bind(styles);

const AvatarWrapper = ({ item, isSmall }) => (
  <div className={cx('avatar_wrapper', { channel_icon_wrapper: item.isChannel })}>
    {item.isChannel && (
      <div className={cx('icon', 'channel', `${isSmall ? 'small' : ''}`)}>
        {IconRole[item.role ?? 'admin']}
      </div>
    )}
    {!item.isChannel && (
      item.avatarUrlVerySmall ? (
        <img src={item.avatarUrlVerySmall} className={cx('avatar_preview', `${isSmall ? 'small' : ''}`)} alt="" />
      ) : (
        <AvatarPlaceholder
          width={isSmall ? 20 : 24}
          name={item.name}
          abbreviation={item.abbreviation}
        />
      )
    )}
  </div>
);

const ListItem = ({ item, index, deleteEmail, dataParent }) => {
  const { t } = useTranslation();
  if (item?.isChannel) {
    return (
      <div key={`${item}${index}`} className={cx('emailToAdd_wrapper')}>
        <AvatarWrapper item={item} isSmall />
        <div className={cx('email')}>{item.name}</div>
        <div className={cx('close_wrapper')}>
          <CloseSvg onClick={() => deleteEmail(index)} />
        </div>
      </div>
    );
  }
  if (item?.type === 'user') {
    return (
      <div
        key={`${item.id}`}
        className={cx('emailToAdd_wrapper', { pending: item.send === downloadStatus.pending,
          success: item.send === downloadStatus.success,
          failed: item.send === downloadStatus.failed,
        })}
      >
        <AvatarWrapper item={item} isSmall />
        {item.username && item.username !== item.email && (
          <>
            <div className={cx('email')}>{item.username }</div>
          </>
        ) }
        {(!item.username || item.username === item.email) && (
          <div className={cx('email')}>{item.email}</div>
        ) }
        <div className={cx('close_wrapper')} data-parent={dataParent}>
          <CloseSvg onClick={() => deleteEmail(index)} data-parent={dataParent} />
        </div>
      </div>
    );
  }
  if (item?.type === 'newUser') {
    return (
      <div
        key={`${item.id}`}
        className={cx('emailToAdd_wrapper', { pending: item.send === downloadStatus.pending,
          success: item.send === downloadStatus.success,
          failed: item.send === downloadStatus.failed,
        })}
      >
        <div className={cx('avatar')}><AvatarPlaceHolder24Svg /></div>
        <div className={cx('email')}>{item.email}</div>
        <div className={cx('close_wrapper')} data-parent={dataParent}>
          <CloseSvg onClick={() => deleteEmail(index)} data-parent={dataParent} />
        </div>
      </div>
    );
  }
  if (item?.type === 'wrongUser' || item?.type === 'exsistingUser') {
    return (
      <Tooltip
        data-parent="miniPage"
        text={item?.type === 'wrongUser' ? t('invalidEmailAddressErrorT') : t('alreadyInvitedLabelT')}
        direction="down"
        place="wrongEmail"
      >
        <div
          key={`${item.id}`}
          className={cx('emailToAdd_wrapper',
            {
              error: item?.type === 'wrongUser',
              exists: item?.type === 'exsistingUser',
            })}
        >
          <div className={cx('avatar', 'avatar_wrong')}><AvatarWrongSvg /></div>
          <div className={cx('email')}>{item.email}</div>
          <div className={cx('close_wrapper')} data-parent={dataParent}>
            <CloseSvg onClick={() => deleteEmail(item.id)} data-parent={dataParent} />
          </div>
        </div>
      </Tooltip>
    );
  }
  return (
    <div key={`${item}${index}`} className={cx('emailToAdd_wrapper')}>
      <div className={cx('avatar')}><AvatarPlaceHolder24Svg /></div>
      <div className={cx('email')}>{item}</div>
      <div className={cx('close_wrapper')}>
        <CloseSvg onClick={() => deleteEmail(index)} />
      </div>
    </div>
  );
};

const InputWithSelectUser = ({ validateError, handleUserOrEmailState, deleteEmail,
  setValidateError, inputEmail, setInputEmail, emailsAndUsersToInvite,
  dataParent, isSharePage, isMaker,
  wrongEmails, setWrongEmails }) => {
  const { t } = useTranslation();
  const contacts = useSelector(state => state.support.contactNicknameMap);
  const channels = useSelector(state => state.channels.myChannels);
  const { usedInChannels = {}, singleUserShareState = {} } = useSelector(state => state.currentPage);
  const dispatch = useDispatch();
  const [userContacts, setUserContacts] = useState(Object.values(contacts));
  const [refDropMenu, isDropMenu, setIsDropMenu] = useComponentVisible(false, DATA_PARENTS.AddMember);
  const { folderId } = useSelector(state => state.user);
  const channelsMembers = useSelector(state => state.channels?.myChannels[folderId]) || {};
  const refInput = useRef(null);

  useEffect(() => {
    const channelsMap = Object.values(channels || {})
      .map(elem => ({ ...elem, isChannel: true }))
      .filter(elem => elem.permissions?.canPostContent);

    const items = isMaker
      ? [...Object.values(contacts || {}), ...channelsMap].filter(elem => !emailsAndUsersToInvite[elem.id])
      : Object.values(contacts || {});
    setUserContacts(items);
  }, [contacts, channels, isMaker]);

  useEffect(() => {
    if (inputEmail) setIsDropMenu(true);
  }, [inputEmail]);


  const checkIsUserSuits = user => {
    return (
      !!user.name?.toLowerCase().includes(inputEmail.toLowerCase())
      || !!user.email?.toLowerCase().includes(inputEmail.toLowerCase())
      || !!user.username?.toLowerCase().includes(inputEmail.toLowerCase())
      || !!user.nameInContact?.toLowerCase().includes(inputEmail.toLowerCase())
      || !!user.organization?.toLowerCase().includes(inputEmail.toLowerCase())
    )
    && !emailsAndUsersToInvite.filter(item => item.id === user.id).length
    && !Object.values(channelsMembers.users || {}).map(item => item.email).includes(user.email);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      if (e.key === ',') {
        e.preventDefault();
      }
      if (!inputEmail.trim()) return;
      if (userContacts.filter(checkIsUserSuits).length === 1) {
        const user = userContacts[0];
        handleUserOrEmailState(e, user, inputEmail.trim());
        return;
      }
      const user = userContacts.find(i => i.name === inputEmail.trim() || i.email === inputEmail.trim());
      if (!user && wrongEmails && !validateEmail(inputEmail.trim())) {
        setWrongEmails([...wrongEmails, {
          id: uuidv4(),
          type: 'wrongUser',
          email: inputEmail,
        }]);
        setInputEmail('');
        return;
      }
      handleUserOrEmailState(e, user, inputEmail.trim());
    } else if (e.key === 'Backspace' && emailsAndUsersToInvite.length > 0 && !inputEmail.trim()) {
      deleteEmail(emailsAndUsersToInvite.length - 1);
    } else if (validateError) setValidateError('');
  };

  const userClickHandler = (event, id) => {
    event.stopPropagation();
    const user = userContacts.find(i => i.id === id);
    if (!user && wrongEmails && !validateEmail(inputEmail.trim())) {
      setWrongEmails([...wrongEmails, {
        id: uuidv4(),
        type: 'wrongUser',
        email: inputEmail,
      }]);
      setInputEmail('');
    }
    handleUserOrEmailState(event, user, inputEmail.trim());
    refInput.current.focus();
  };

  const handleReplaceWrongEmail = (id) => {
    if (wrongEmails) setWrongEmails(wrongEmails.filter(i => i.id !== id));
  };

  const createChannelAndAdd = (event) => {
    const channelID = uuidv4();
    dispatch(
      actionCreateChannel(inputEmail.trim(), channelID),
    );
    handleUserOrEmailState(event, { id: channelID, isChannel: true, name: inputEmail.trim() }, inputEmail.trim());
  };

  return (

    <div className={cx('inputEmail_wrapper')} data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}>
      {!isMaker && <div className={cx('inputEmail_description')}>{t('userNameOrEmailT')}</div>}
      <div
        data-cy="nameOrEmail"
        onClick={() => refInput.current.focus()}
        className={cx('input_area', { is_maker: isMaker })}
        data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
      >
        {emailsAndUsersToInvite
          .map((i, index) => (
            <ListItem
              key={i.id}
              item={i}
              index={index}
              deleteEmail={deleteEmail}
              dataParent={dataParent}
            />
          ))}
        {wrongEmails?.map((i, index) => (
          <ListItem
            key={i.id}
            item={i}
            index={index}
            deleteEmail={handleReplaceWrongEmail}
            dataParent={dataParent}
          />
        ))}
        <input
          onKeyDown={handleKeyPress}
          onFocus={() => setIsDropMenu(true)}
          ref={refInput}
          value={inputEmail}
          onChange={e => setInputEmail(e.target.value)}
          className={cx('title')}
          autoFocus
          type="email"
          maxLength={35}
          placeholder={isMaker && !emailsAndUsersToInvite.length ? t('channelUserEmailT') : ''}
        />
      </div>
      {(isDropMenu && !!inputEmail.trim()) && (
      <div ref={refDropMenu} className={cx('search_wrapper')}>
        {(!userContacts.filter(checkIsUserSuits).length || isMaker) && (
          <div
            className={cx('send_email_invite')}
            onClick={(event) => userClickHandler(event)}
            data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
          >
            <div className={cx('icon', 'new_user')}><AddUserIcon16Svg /></div>
            <div className={cx('quote_email')}>{inputEmail}</div>
          </div>
        )}
        {isMaker && (
          <div
            className={cx('send_email_invite', 'avatar_wrapper')}
            onClick={(event) => createChannelAndAdd(event)}
            data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
          >
            <div className={cx('avatar_wrapper')}>
              <div className={cx('icon', 'channel')}> {IconRole.admin}</div>
            </div>
            <div className={cx('name_without_ellipsis')}>{t('createNewChannelT')}</div>
            <div className={cx('quotes')}>“</div>
            <div className={cx('quote_email')}>{inputEmail}</div>
            <div className={cx('quotes')}>”</div>
          </div>
        )}
        {!!userContacts.filter(checkIsUserSuits).length
          && (
            <div className={cx('scroll_search_container')}>
              {userContacts.filter(checkIsUserSuits)
                .map((item) => (
                  <div
                    className={cx('item_wrapper')}
                    key={item.id}
                    onClick={(event) => userClickHandler(event, item.id)}
                    data-parent={`${DATA_PARENTS.AddMember} ${dataParent}`}
                  >
                    <div className={cx('item_title_container')}>
                      <AvatarWrapper item={item} />
                      {item.isChannel ? (
                        <div className={cx('name', 'is_channel')}>{item.name}</div>
                      ) : (
                        <div className={cx('name', { sharePage: isSharePage })}>
                          <span className={cx('quote_username')}>{item.username}</span>
                          <span className={cx('quote_email')}> {item.email}</span>
                        </div>
                      )}
                      {usedInChannels[item.id] && (
                        <span className={cx('shared_label', 'text-unified_base')}>{t('alreadySharedLabelT')}</span>
                      )}
                      {singleUserShareState[item.email] && (
                        <span className={cx('shared_label', 'text-unified_base')}>{t('alreadyInvitedLabelT')}</span>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
      </div>
      )}
    </div>

  );
};

export default InputWithSelectUser;
