import React from 'react';
import { useTranslation } from 'react-i18next';
import { calculateSingleUserAvatar, calculateUsername } from './helpers';
import { AvatarPopOver, AvatarWrapper } from './styled';


const SingleAvatarComponent = ({ user = {}, index, isReactions, yourId, reactionText }) => {
  const { t } = useTranslation();
  const name = yourId === user.id ? t('YouT') : calculateUsername(user);
  const text = !isReactions
    ? calculateUsername(user)
    : `${name} ${t('ReactedWithT')}  
    *${reactionText}*`;

  return (
    <AvatarWrapper key={user?.email || index} left={index * 10} isReactions={isReactions}>
      <AvatarPopOver
        imageIsFirst={index === 0}
        isReactions={isReactions}
        imageIsImage={user.displayImage === 'AvatarImage' && user.avatarUrlVerySmall}
        className="ava_popover"
      >{text}
      </AvatarPopOver>
      {calculateSingleUserAvatar(user, isReactions ? 20 : undefined)}

    </AvatarWrapper>
  );
};

export default SingleAvatarComponent;
