export default {
  RequestUserPlaylists: '[EditSmartFile] Request User SmartFiles',
  SaveRequestedUserPlaylists: '[EditSmartFile] Save Requested User SmartFiles',
  EditShareState: '[EditSmartFile] Update share state for SmartFile',
  Get: '[EditSmartFile] Get SmartFile',
  Update: '[EditSmartFile] Update SmartFile',
  Add: '[EditSmartFile] Add SmartFile',
  AddShareState: '[EditSmartFile] Add SmartFile Share state Redux',
  Delete: '[EditSmartFile] Delete SmartFile',
  updateMoveToTrash: '[EditSmartFile] update Move To Trash SmartFile',
  Create: '[EditSmartFile] Create SmartFile',
  GetPublish: '[EditSmartFile] Get all publish SmartFile',
  UpdatePlaylist: '[EditSmartFile] Update SmartFile field',
  EditTitle: '[EditSmartFile] Edit title on SmartFile',
  EditDescriptionS: '[EditSmartFile] Edit description on SmartFile SAGAS',
  EditDescriptionR: '[EditSmartFile] Edit description on SmartFile REDUX',
  EditComplementaryPlaylists: '[EditSmartFile] Edit Complementary SmartFiles',
  EditImage: '[EditSmartFile] Edit image on SmartFile',
  ToggleFavorite: '[EditSmartFile] toggle favorite SmartFile',
  ToggleIsShowDescriptionPlaylist:
    '[EditSmartFile] toggle isShowDescription SmartFile',
  AddImage: '[EditSmartFile] Add Image',
  ChangeDefaultColor: '[EditSmartFile] Change Default Color',
  EditImageREDUX: '[EditSmartFile] Edit Image LOCAL',
  RemoveChangesImageREDUX: '[EditSmartFile] Remove Changes Image LOCAL',
  DeleteImage: '[EditSmartFile] Delete Image',
  MakerDeleteImage: '[EditSmartFile-Maker] Delete Image',
  DeleteImageRedux: '[EditSmartFile] Delete Image LOCAL',
  DuplicatePlaylist: '[EditSmartFile] Duplicate SmartFiles',
  DuplicateAndSwitchPlaylist: '[EditSmartFile] Duplicate And Switch SmartFile',
  UpdateTime: '[EditSmartFile] update duration time',
  AddPlaylistToChannelR: '[EditSmartFile] Add SmartFile To Channel REDUX',
  RemovePlaylistFromChannelR:
    '[EditSmartFile] Remove SmartFile From Channel REDUX',
  PlaylistChannelPublishManagement:
    '[EditSmartFile] SmartFile Channel Publish Management',
  CreatePageAndAddToBuilder: '[EditSmartFile] Create Page And Add To Builder',
  ChangeTextElementBlockRedux: '[EditSmartFile] Change TextElement Block Redux',
  ChangeTextElementBlockReduxMaker:
    '[EditSmartFile] Change TextElement Block Maker Redux',
  Publish: '[EditSmartFile] Publish Saga',
  multiplePublish: '[EditSmartFile] multiple Publish Saga',
  DiscardS: '[EditSmartFile] Discard Saga',
  UnPublishS: '[EditSmartFile] UnPublish Saga',
  EditPublishStateR: '[EditSmartFile] Edit Publish State REDUX',
  AddPageToPlaylist: '[EditSmartFile] Add Page To SmartFile',
  AddPageToPlaylistR: '[EditSmartFile] Add Page To SmartFile REDUX',
  CreatePlaylistAndAddPageToIt:
    '[EditSmartFile] Create SmartFile And Add Page To It',
  MarkViewedS: '[EditSmartFile] Mark SmartFile as Viewed SAGAS',
  MarkViewedR: '[EditSmartFile] Mark SmartFile as Viewed REDUX',
  UpdateCardStateR: '[EditSmartFile] Update card state REDUX',
  HelpAdminPublish: '[EditSmartFile] Help Admin Publish',

  updateShareStatePlaylistS: '[EditSmartFile] update shared for SmartFile Sage',
  getShareStatePlaylistS: '[EditSmartFile] get shared for SmartFile Sage',
  updateShareToWebStateManyPlaylistSR: '[EditSmartFile] update shared to web many for SmartFile Sage, Redux',
  updateShareToWebStatePlaylistSR: '[EditSmartFile] update shared to web for SmartFile Sage, Redux',
  updatePublishStatePlaylistSR: '[EditSmartFile] update publish state SmartFile Sage, Redux',
  updateShareToUserPlaylistSR: '[EditSmartFile] update shared to USER for SmartFile Sage, Redux',
  GetSharedPlaylist: '[EditSmartFile] get shared SmartFile Sage',
  GetSharedManyPlaylist: '[EditSmartFile] get many shared SmartFile Sage',
  UpdateStateSharedPlaylistsR:
    '[LibraryPages] update state shared SmartFile REDUX',

  AddUserToSharing: '[EditSmartFile] Add User To Sharing SmartFile',
  RemoveUserToSharing: '[EditSmartFile] Remove User To Sharing SmartFile',
  SendEmailToSharingS:
    '[EditSmartFile] Start Send email to User To Sharing  SmartFile SAGE',
  SendingOnEmail:
    '[EditSmartFile] Sending email to User To Sharing SmartFile REDUX',
  MarkIsReadByUser: '[EditSmartFile] Mark IsRead By User',
  ShareMultiplePlaylistsS: '[EditSmartFile] Share Multiple SmartFiles SAGA',
  ChangePlayModeS: '[EditSmartFile] Set SmartFile Play mode SAGA',
  ChangePlayModeR: '[EditSmartFile] Set SmartFile Play mode REDUX',

  GetUniversalViewData: 'UniversalPlaylist.Init data request',
  DownloadAllDownloadablePlaylistItems:
    'UniversalPlaylist.Download All Downloadable SmartFile Elements',
  DownloadOneElement: 'UniversalPlaylist.Download One Element',
  AddAllDownloadableItemsIntoLibrary:
    'UniversalPlaylist.Add All Downloadable Items Into Library',
  AddAllDownloadableItemsIntoPlaylist:
    'UniversalPlaylist.Add All Downloadable Items Into SmartFile',
  CreatePlaylistFromLib: 'Create new SmartFile with selected lib items',
  UpdateReadStateLinkPage: 'Update Read state link page in UPV',
  UpdateApproveStateLinkPage: 'Update Approve state link page in UPV',
  removeNewInLinkPage: 'remove active by isNew field',
  editShareStatePlaylistSingleUser: '[EditSmartFile] editShareStatePlaylistSingleUser Sagas',
  getUsersToSharingPl: '[EditSmartFile] getUsersToSharingPl Sagas',
  getChannelsToPublishPl: '[EditSmartFile] getChannelsToPublishPl Sagas',
  UpdateChannel: '[EditSmartFile] UpdateChannel REDUX',
  RemoveChannel: '[EditSmartFile] Remove Channel',
  UpdateInfoLinkPage: '[EditSmartFile] Update Info Link Page',
  OpenSmartFile: '[SmartFile] Open Smart File',
  ClearSavedTextS: '[SmartFile] Clear savedState for text Saga',
  translateRTB: '[SmartFile] Translate RTB',
};
