import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudSvg } from '../../images/icons/cloud_square.svg';
import { ReactComponent as HelpSvg } from '../../images/icons/user_settings/help.svg';
import { ReactComponent as LangSvg } from '../../images/icons/user_settings/lang.svg';
import { ReactComponent as SettingsSvg } from '../../images/icons/user_settings/settings.svg';
import { ReactComponent as SignOutSvg } from '../../images/icons/user_settings/sign_out.svg';
import { ReactComponent as PlanSvg } from '../../images/icons/lightning_white.svg';
import { actionLogout, actionOpenModal } from '../../redux/user/action';
import AvatarPlaceholder from '../Avatar';
import { Avatar } from '../MainHeader/User';
import { openModalType } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import { Settings } from '../../redux/settings/types';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

const Menu = styled.div`
  position: absolute;
  width: 280px;
  right: 34px;
  top: 60px;
  background: #ffffff;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.25),
    0 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  z-index: 108;
  font-family: RobotoFlex, sans-serif;
`;

const Info = styled.div`
  display: flex;
  padding: 12px 20px;
`;

const Separator = styled.div`
  border-bottom: 1px solid rgba(29, 29, 29, 0.08);
  margin: 0 16px;
`;

const UpgradePlanButton = styled.div`
  border-radius: 8px;
  width: 240px;
  height: 32px;
  background: linear-gradient(98deg, #19D244 4.03%, #23A942 102.26%);
  box-shadow: 0px 8px 20px -4px rgba(23, 25, 28, 0.08), 0px 4px 6px 2px rgba(23, 25, 28, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  margin: 15px 0 8px 4px;
  svg {
    margin-right: 8px;
  }
`;
const NextPlan = styled.span`
text-transform: uppercase;
margin-left: 4px;
`;
const Username = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--Sheme_fg_default);
  line-height: 23px;
  padding-left: 6px;
`;

const Email = styled.div`
  font-style: normal;
  font-size: 12px;
  color: var(--Sheme_fg_subtle);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  line-height: 16px;
  margin-top: 8px;
  padding-left: 6px;
`;

const CurrentPlanWrapper = styled.div`
  border-radius: 32px;
  border: 1px solid var(--Sheme_border_muted);
  background: var(--Sheme_bg_subtle);
  position: absolute;
  right: 0;
  padding: 3px 12px;
  color: var(--Sheme_fg_default);
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${({ isSignout }) => (isSignout ? '#D32F2F' : '#242633')};
`;
const Item = styled.div`
  display: flex;
  gap: 6px;
  padding: 0 20px;
  cursor: pointer;
  height: 30px;
  align-items: center;
  ${({ isHelp }) => (isHelp ? 'padding-top: 5px' : '')};
  &:hover {
    background: #ffeebf;
    ${Text} {
      color: var(--Brand_Scheme_fg_muted);
    }

    svg path {
      fill: var(--Brand_Scheme_fg_muted);
    }
  }

  ${({ disabled }) => (disabled
    ? `
    cursor: no-drop;
    
    ${Text} {
      color: lightgrey;
    }
    
    &:hover {
      background: initial;
    }
    & svg path {
      stroke: lightgrey;
    }
  `
    : '')}
`;

const Section = styled.div`
  padding: ${({ isSettings }) => (isSettings ? ' 5px 0' : '10px 0 0')};
  ${({ isSignout }) => (isSignout ? 'padding: 4px 0 7px' : '')};
  ${({ isLang }) => (isLang ? 'padding: 4px 0 0' : '')};
  font-size: 14px;
`;

const LangsPopup = styled.div`
  position: absolute;
  top: 200px;
  left: -192px;
  padding: 4px 0;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 12px 32px -6px rgba(23, 25, 28, 0.08), 0px 8px 16px 2px rgba(23, 25, 28, 0.08);
  width: 192px;
`;

const LangOption = styled.p`
    margin: 0;
    height: 32px;
    display: flex;
    padding: 4px 20px;
    cursor: pointer;
    align-items: center;
    color: var(--Sheme_fg_muted);
    font-size: 16px;
    font-weight: 400;
    ${({ isActive }) => (isActive ? 'color: var(--Brand_Scheme_fg_default);' : '')}
    &:hover {
      background: var(--Brand_Scheme_bg_hover_alpha_1);
      color: var(--Brand_Scheme_fg_muted);
    }
  `;
const StorageSection = styled.div`
  padding: 0 16px;
`;

const StorageLine = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  margin: 2px 3px;
  position: relative;
  & svg {
    margin-right: 8px;
  }
`;
const ProgressBar = styled.div`
  width: calc(100% - 10px);
  height: 5px;
  border-radius: 7px;
  background: #e8ebfa;
  margin: 8px auto;
`;
const InfillLine = styled.div`
  background: #FFC72C;
  width: 45%;
  height: 4px;
  transition: width ease-in-out 0.3s;
`;

const ProgressBarHintWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-left: 4px;
`;
const ProgressBarHint1 = styled.div`
  font-weight: 400;
  font-size: 14px;
   color: var(--Sheme_fg_muted);
`;
const ProgressBarHint2 = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--Sheme_fg_suble);
`;

const ButtonContainer = styled.div`
  margin-top: 13px;
`;

const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const UserMenu = ({ refUserMenu, setIsComponentVisible }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const onLogoutClick = () => {
    dispatch(
      actionOpenModal(openModalType.ConfirmModal, {
        subject: t('leavingT'),
        description: t('signOutQuestionT'),
        confirm: () => {
          dispatch(actionLogout());
          history.push('/logout');
        },
        cancelText: t('cancelUpT'),
        okText: t('signOutUpT'),
        isSignOut: true,
      }),
    );
    setIsComponentVisible(false);
  };

  const [refLangMenu, isLangMenuVisible, setLangMenuVisible] = useComponentVisible(false, '');

  const onHelpClick = () => {
    setIsComponentVisible(false);
    history.push('/help');
  };
  const onUpgradePlan = () => {
    dispatch(actionOpenModal(openModalType.ApplicationSettings, { defaultPage: 'upgrade' }));
    setIsComponentVisible(false);
  };
  const onSettingsClick = () => {
    dispatch(actionOpenModal(openModalType.ApplicationSettings));
    setIsComponentVisible(false);
  };
  const setLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
    dispatch(actionCreator(Settings.Preferences.ChangeLanguage, { newLang }));
    localStorage.setItem('language', newLang);
    setLangMenuVisible(false);
  };

  const userPlan = useMemo(() => {
    if (user?.currentPlan === 'Tier1') {
      return { current: t('planTier1TitleT'), next: t('planTier2TitleT') };
    }
    if (user?.currentPlan === 'Tier2') {
      return { current: t('planTier2TitleT'), next: null };
    }
    return { current: t('planFreeTitleT'), next: t('planTier1TitleT') };
  }, [user?.currentPlan, t]);
  return (
    <Menu data-cy="user-menu" ref={refUserMenu}>
      <Info>
        <AvatarContainer>
          {!!user?.avatarUrlVerySmall
            && user?.displayImage === 'AvatarImage' && (
              <Avatar width={48} src={user?.avatarUrlVerySmall} alt="avatar" />
          )}
          {(!user?.avatarUrlVerySmall
            || user?.displayImage === 'GeneratedAvatar') && (
            <AvatarPlaceholder
              width={48}
              name={user?.first_name}
              abbreviation={`${user?.first_name[0]}${user?.last_name[0]}`}
            />
          )}
        </AvatarContainer>

        <div>
          <Username>
            {user?.showUsername && user?.username
              ? `${user?.username}`
              : `${user?.first_name} ${user?.last_name}`}
          </Username>
          <Email>{user?.email}</Email>
        </div>
      </Info>

      <Separator />

      <Section>
        <StorageSection>
          <StorageLine>
            <CloudSvg />
            <Text>{t('storageT')}</Text>
            <CurrentPlanWrapper>
              {userPlan.current}
            </CurrentPlanWrapper>
          </StorageLine>

          <ProgressBar>
            <InfillLine />
          </ProgressBar>

          <ProgressBarHintWrapper>
            <ProgressBarHint1>{t('progressBar1T')}</ProgressBarHint1>
            <ProgressBarHint2>{t('progressBar2T')}</ProgressBarHint2>
          </ProgressBarHintWrapper>

          {user.currentPlan !== 'Tier2' && (
            <ButtonContainer>
              <UpgradePlanButton
                onClick={onUpgradePlan}
              >
                <PlanSvg />
                {t('upgradePlanT')} <NextPlan>{userPlan.next}</NextPlan>
              </UpgradePlanButton>
            </ButtonContainer>
          )}
        </StorageSection>
      </Section>

      <Separator />
      <Section isLang>
        <Item
          onMouseEnter={() => setLangMenuVisible(true)}
          onMouseLeave={() => setLangMenuVisible(false)}
          ref={refLangMenu}
        >
          <LangSvg />
          <Text>{t('languageT')}</Text>
          {isLangMenuVisible && (
            <LangsPopup>
              <LangOption onClick={() => setLanguage('en')} isActive={i18n.language === 'en'}>English</LangOption>
              <LangOption onClick={() => setLanguage('fr')} isActive={i18n.language === 'fr'}>Français</LangOption>
            </LangsPopup>
          )}
        </Item>
      </Section>
      <Section isSettings>
        <Item onClick={onSettingsClick}>
          <SettingsSvg />
          <Text>{t('settingsT')}</Text>
        </Item>

        <Item onClick={onHelpClick} isHelp>
          <HelpSvg />
          <Text>{t('helpT')}</Text>
        </Item>
      </Section>
      <Separator />

      <Section isSignout>
        <Item onClick={onLogoutClick}>
          <SignOutSvg />
          <Text data-cy="logout-btn">{t('signOutT')}</Text>
        </Item>
      </Section>
    </Menu>
  );
};

export default UserMenu;
