import { convertToRaw } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import { BlockTypes, DEFAULT_DURATION } from '../constants';
import {
  innerHtmlWebSiteTemplate,
  replaceDoubleQuotes,
  sanitizeToLoad,
  sanitizeToSave,
  stringifyInnerHtml,
} from '../helpers';

const calcModifiedField = (field) => {
  return Object.entries(field).reduce((acc, [key, value]) => {
    return `${acc}${key}: ${value},
        `;
  }, '');
};

const generateQueryWithBlockStateToAddToPage = (
  component,
  currentPageId,
  userId,
  index = '',
) => {
  let addLibraryComponentToComponent = '';
  let width = '';
  const isHidden = component.isHidden ? 'isHidden: true' : '';
  if (parseInt(component.width)) width = `width: ${parseInt(component.width)}`;
  const innerHtmlField = component.innerHtml
    ? `innerHtml: "${component.innerHtml
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"')
      .replaceAll('\n', '')
      .replaceAll('\\n', '')}"`
    : '';
  let data = '';
  const type = component.type ? `type: "${component.type}"` : '';
  if (
    component.type === BlockTypes.webSite
    || component.type === BlockTypes.lineSeparator
  ) {
    data = JSON.stringify(component.state)
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"');
  } else if (
    component.type === BlockTypes.approveButton
    || component.type === BlockTypes.shortText
    || component.type === BlockTypes.actionButton
  ) {
    data = sanitizeToSave(JSON.stringify(component.state));
  } else if (
    component.type === BlockTypes.page
    || component.type === BlockTypes.component
  ) {
    // ?
  } else {
    data = component.state.getCurrentContent
      && JSON.stringify(convertToRaw(component.state.getCurrentContent()))
        .replaceAll("'", '$@quote')
        .replaceAll('"', "'")
        .replaceAll("\\'", '\\"');
  }

  const queryCreateComponent = `  
    cc${index}: CreateComponent(
    id: "${component.id}" 
    content: "${data}"
    ${width}
    ${innerHtmlField}
    ${type}
    ${isHidden}
    position: ${component.position}
    ) 
      { 
        content
        id
        type
        position
      }
    AddUserOwnerOfComponents${index}: AddUserOwnerOfComponents(
      from: {id:"${userId}"}
       to: {id:"${component.id}"}
    ){
      to{id}
    }
  `;

  const queryAddLibraryPageComponents = `
    pc${index}: AddComponentParentLibraryComponent(
      from:  { id: "${currentPageId}"}
      to:{ id: "${component.id}"}
    ) 
    { to { id } }
  `;
  if (component.type === 'page' || component.type === 'component') {
    addLibraryComponentToComponent = `
      parentLinkToLC${index}: AddComponentLibraryComponents(
        from: {id: "${component.id}"} 
        to: {id: "${component.pageId || component.nestedItemId}"}
      ) {to {id}}
      `;
  }
  if (
    component.type === BlockTypes.dropboxEmbed
    || component.type === BlockTypes.googleEmbed
  ) {
    addLibraryComponentToComponent = `
      parentLinkToLC${index}: AddComponentLibraryComponents(
        from: {id: "${component.id}"} 
        to: {id: "${component?.state?.data?.libraryComponentId}"}
      ) {to {id}}
      `;
  }
  return `${queryCreateComponent}
  ${queryAddLibraryPageComponents}
  ${addLibraryComponentToComponent}`;
};

const updateComponents = (blocks) => {
  try {
    return blocks.reduce((acc, item, index) => {
      let width = '';
      if (item?.width) {
        if (parseInt(item?.width)) width = `width : ${parseInt(item?.width)}`;
      }
      const innerHtmlField = item.innerHtml
        ? `innerHtml: "${sanitizeToSave(stringifyInnerHtml(item.innerHtml))}"`
        : '';
      let content;
      let type1 = item.type ? `type: "${item.type}"` : '';
      if (item.type === BlockTypes.webSite) {
        content = sanitizeToSave(JSON.stringify(item.state));
      } else if (
        item.type === BlockTypes.lineSeparator
        || item.type === BlockTypes.approveButton
        || item.type === BlockTypes.actionButton
        || item.type === BlockTypes.embed
        || item.type === BlockTypes.shortText
      ) {
        content = sanitizeToSave(JSON.stringify(item.state));
      } else if (item.type === BlockTypes.lineSeparator) {
        content = JSON.stringify(item.state)
          .replaceAll("'", '$@quote')
          .replaceAll('"', "'")
          .replaceAll("\\'", '\\"');
      } else {
        type1 = item.type ? `type: "${item.type}"` : '';
        content = item.state.getCurrentContent
          && JSON.stringify(convertToRaw(item.state.getCurrentContent()))
            .replaceAll("'", '$@quote')
            .replaceAll('"', "'")
            .replaceAll("\\'", '\\"');
      }

      const updateComponent = `
      updateComponent${index + 1}: updateComponents(
      where:{ id: "${item.id}"}
       update:{
        ${type1}
        position: ${item.position}
        ${innerHtmlField}
        content: "${content}"
        ${width}}
      ) 
      {
       components { id}
      }
    `;
      return acc + updateComponent;
    }, '');
  } catch (err) {
    console.error(err.message);
    return '';
  }
};

const generateComponentsFromBlocks = (blocks, userId, parentId) => {
  try {
    const images = [];
    const imageConnection = [];

    blocks.forEach((item, index) => {
      if (item.type !== BlockTypes.image) {
        return;
      }
      const nestedItemId = item.nestedItemId || item?.state?.data?.libraryComponentId;
      let width = '';
      if (item?.width) {
        if (parseInt(item?.width)) width = `width : ${parseInt(item?.width)}`;
      }
      // already sanitized (!not always: SKI-643 Some Glitches)
      const innerHtmlField = item.innerHtml
        ? `innerHtml: "${sanitizeToSave(stringifyInnerHtml(item.innerHtml))}"`
        : '';

      let content;
      let type1 = item.type ? `type: "${item.type}"` : '';
      if (item.content) {
        content = sanitizeToSave(JSON.stringify(item.content));
      } else {
        type1 = item.type ? `type: "${item.type}"` : '';
        content = item.state
          ? item.state.getCurrentContent
            && JSON.stringify(convertToRaw(item.state.getCurrentContent()))
              .replaceAll("'", '$@quote')
              .replaceAll('"', "'")
              .replaceAll("\\'", '\\"')
          : '';
      }
      const createComponent = `
      {where:{node:{id:"${item.id}"}}, onCreate: {node:{
        id: "${item.id}"
        ${type1}
        position: ${item.position}
        ${innerHtmlField}
        content: "${content}"
        ${width}
      }}}
   
  `;
      const connection = nestedItemId
        ? `
       updateComponents${index}: updateComponents (where:{id:"${item.id}"}, connect:{libraryComponents:{where:{node:{id:"${nestedItemId}"}}}}){
            info {
              bookmark
            }
          }
      `
        : '';
      if (connection) imageConnection.push(connection);
      images.push(createComponent);
    });
    const notImages = blocks.reduce((acc, item, index) => {
      if (item.type === BlockTypes.image) return;
      let width = '';
      if (item?.width) {
        if (parseInt(item?.width)) width = `width : ${parseInt(item?.width)}`;
      }
      // already sanitized (!not always: SKI-643 Some Glitches)
      let innerHtmlField = item.innerHtml
        ? `innerHtml: "${sanitizeToSave(stringifyInnerHtml(item.innerHtml))}"`
        : '';

      let content;
      let type1 = item.type ? `type: "${item.type}"` : '';
      if (item.type === BlockTypes.webSite) {
        content = sanitizeToSave(JSON.stringify(item.state));
        innerHtmlField = `innerHtml: "${sanitizeToSave(
          innerHtmlWebSiteTemplate(item.state.data),
        )}"`;
      } else if (
        item.type === BlockTypes.lineSeparator
        || item.type === BlockTypes.approveButton
        || item.type === BlockTypes.actionButton
        || item.type === BlockTypes.shortText
      ) {
        content = sanitizeToSave(JSON.stringify(item.state));
      } else if (item.type === BlockTypes.lineSeparator) {
        content = JSON.stringify(item.state)
          .replaceAll("'", '$@quote')
          .replaceAll('"', "'")
          .replaceAll("\\'", '\\"');
      } else if (item.content) {
        content = sanitizeToSave(JSON.stringify(item.content));
      } else if (
        item.type === BlockTypes.googleEmbed
        || item.type === BlockTypes.dropboxEmbed
      ) {
        content = sanitizeToSave(JSON.stringify(item.state));
      } else if (item.type === BlockTypes.page && !item?.state) {
        content = sanitizeToSave(
          JSON.stringify({ pageId: item.pageId, title: item.title }),
        );
      } else {
        type1 = item.type ? `type: "${item.type}"` : '';
        content = item.state
          ? item.state.getCurrentContent
            && JSON.stringify(convertToRaw(item.state.getCurrentContent()))
              .replaceAll("'", '$@quote')
              .replaceAll('"', "'")
              .replaceAll("\\'", '\\"')
          : '';
      }
      const nestedItemId = item.pageId
        || item.nestedItemId
        || item?.state?.data?.libraryComponentId;
      const createComponent = `
      createComponent${index + 1}: createComponents(
      input:{
        id: "${item.id}"
        ${type1}
        position: ${item.position}
        ${innerHtmlField}
        content: "${content}"
        ${width}
        owner: {connect:{where:{node:{id:"${userId}"}}}}
        parentLibraryComponent: {connect:{where:{node:{id:"${parentId}"}}}}
        ${
  nestedItemId
    ? `libraryComponents: {connect:{where:{node:{id:"${nestedItemId}"}}}}`
    : ''
}
      } 
      ) {components {id}}
  `;

      return acc + createComponent;
    }, '');
    return {
      images: images.join(','),
      notImages,
      imageConnection: imageConnection.join(' '),
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err.message);
    return '';
  }
};

export const createUpdateComponentBody = (
  item,
  pageId,
  widthInt,
  isBuilderTextElement,
  duration,
) => {
  let content;
  if (item.type === BlockTypes.webSite) {
    content = sanitizeToSave(JSON.stringify(item.state));
  } else if (item.type === BlockTypes.embed) {
    content = sanitizeToSave(JSON.stringify(item.state));
  } else if (
    item.type === BlockTypes.googleEmbed
    || item.type === BlockTypes.dropboxEmbed
  ) {
    content = sanitizeToSave(JSON.stringify(item.state));
  } else if (item.type === BlockTypes.approveButton) {
    content = sanitizeToSave(JSON.stringify(item.state));
  } else if (item.type === BlockTypes.lineSeparator) {
    content = JSON.stringify(item.state)
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"');
  } else if (item.type === BlockTypes.image) {
  } else if (item.type === BlockTypes.page) {
  } else if (item.type === BlockTypes.component) {
  } else {
    content = item.state.getCurrentContent
      && JSON.stringify(convertToRaw(item.state.getCurrentContent()))
        .replaceAll("'", '$@quote')
        .replaceAll('"', "'")
        .replaceAll("\\'", '\\"');
  }
  if (isBuilderTextElement) return '';
  const updComponentField = {
    content: `"${content}"`,
  };
  if (item.isHidden) {
    updComponentField.isHidden = true;
  }
  if (item.type) {
    updComponentField.type = `"${item.type}"`;
  }
  if (parseInt(widthInt || duration)) {
    updComponentField.width = parseInt(widthInt);
  }
  if (item?.position) {
    updComponentField.position = item?.position;
  }
  if (item.innerHtml) {
    updComponentField.innerHtml = `"${item.innerHtml
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"')
      .replaceAll('\n', '')
      .replaceAll('\\n', '')}"`;
  }
  return {
    place: 'queryUpdateComponent',
    id: pageId,
    updateNested: {
      components: [{ id: item.id, field: updComponentField }],
    },
  };
};

export const createChangeComponentTypeBody = (
  newBlock,
  pageId,
  playlistField,
) => {
  if (playlistField) return null;
  if (newBlock.type !== 'page' && newBlock.type !== BlockTypes.embed) {
    let content;
    if (
      newBlock.type === BlockTypes.approveButton
      || newBlock.type === BlockTypes.actionButton
      || newBlock.type === BlockTypes.shortText
      || newBlock.type === BlockTypes.lineSeparator
    ) {
      content = `"${sanitizeToSave(JSON.stringify(newBlock.state))}"`;
    } else if (newBlock.type === BlockTypes.image) {
      content = '""';
    } else {
      content = `"${JSON.stringify(
        convertToRaw(newBlock.state.getCurrentContent()),
      )
        .replaceAll("'", '$@quote')
        .replaceAll('"', "'")
        .replaceAll("\\'", '\\"')}"`;
    }
    return {
      place: 'queryChangeTypeComponent',
      id: pageId,
      updateNested: {
        components: [
          {
            id: newBlock.id,
            field: {
              innerHtml: `"${sanitizeToSave(newBlock.innerHtml)}"`,
              content: `${content}`,
              type: `"${newBlock.type}"`,
            },
          },
        ],
      },
    };
  }
  return null;
};

export const createUpdateContactBody = ({
  id,
  userFunction,
  first_name: firstName,
  last_name: lastName,
  email,
  organization,
  phone,
  username,
  contactId,
}) => {
  const fieldsUpdateObg = {};
  if (userFunction !== undefined) fieldsUpdateObg.function = `"${sanitizeToSave(userFunction)}"`;
  if (email !== undefined) fieldsUpdateObg.email = `"${sanitizeToSave(email)}"`;
  if (organization !== undefined) fieldsUpdateObg.organization = `"${sanitizeToSave(organization)}"`;
  if (lastName !== undefined) fieldsUpdateObg.last_name = `"${sanitizeToSave(lastName)}"`;
  if (firstName !== undefined) fieldsUpdateObg.first_name = `"${sanitizeToSave(firstName)}"`;
  if (phone !== undefined) fieldsUpdateObg.phone = `"${sanitizeToSave(phone)}"`;
  if (username !== undefined) fieldsUpdateObg.username = `"${sanitizeToSave(username)}"`;
  let fieldsCalc = '';
  let fields = '';
  for (const key in fieldsUpdateObg) {
    if (fieldsUpdateObg.hasOwnProperty(key)) {
      fieldsCalc += `${key} : ${fieldsUpdateObg[key]}, `;
    }
  } if (fieldsCalc) {
    fields = `  update:{ ${fieldsCalc}}`;
  }
  return {
    id: contactId ?? id,
    fields,
  };
};

export const createBodyAddComponentsToCurrentLibraryComponent = (
  blocksToCreate,
  blocksToDelete,
  blocksToUpdate,
  userId,
  componentId,
  name,
) => {
  const { images, notImages, imageConnection } = generateComponentsFromBlocks(
    blocksToCreate,
    userId,
    componentId,
  );
  const updatedComponents = updateComponents(blocksToUpdate);
  return {
    blocksToDelete,
    nestedItemId: componentId,
    title: sanitizeToSave(name),
    images,
    notImages,
    imageConnection,
    updatedComponents,
  };
};

export const createBodyDuplicateLinkPageText = (
  pageData,
  linkPage,
  playlistId,
  userId,
) => {
  const objState = convertToRaw(
    pageData.textComponent?.state?.getCurrentContent(),
  );
  const newState = replaceDoubleQuotes(JSON.stringify(objState));
  return { objState, newState, pageData, userId, linkPage, playlistId };
};


export const createBodyDuplicateLinkPageLexicalText = (
  pageData,
  linkPage,
  playlistId,
  userId,
) => {
  const objState = pageData.textComponent?.state;
  const newState = JSON.stringify(objState);
  return { userId, linkPages: [{ linkPage, pageData, objState, newState }], playlistId };
};

export const createDetachComponentBody = (blocksArray) => {
  return blocksArray.map((item) => {
    if (item.type === BlockTypes.webSite && item?.state?.data?.url) {
      const newState = JSON.stringify(item.state);
      return newState
        .replaceAll('"', "'")
        .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
        .replaceAll('$@quote', "'");
    }
    if (
      item.type === BlockTypes.approveButton
      || item.type === BlockTypes.actionButton
      || item.type === BlockTypes.shortText
      || item.type === BlockTypes.googleEmbed
      || item.type === BlockTypes.dropboxEmbed
    ) {
      return sanitizeToSave(JSON.stringify(item.state));
    }
    if (item.type === BlockTypes.embed) {
      const updState = {
        data: { text: sanitizeToLoad(item.state.data.text), isPreview: true },
      };
      return sanitizeToSave(JSON.stringify(updState));
    }

    if (item.type === BlockTypes.lineSeparator) {
      return sanitizeToSave(JSON.stringify(item.state));
    }
    if (item.type === BlockTypes.page || item.type === BlockTypes.pdf) {
      return item.content;
    }
    return JSON.stringify(convertToRaw(item.state.getCurrentContent()))
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"');
  });
};

export const createAddManyComponentsBody = (
  lastModifiedDate,
  currentPageId,
  userId,
  components,
) => {
  const queryWithBlockStateToAddToPage = components
    .map((component, index) => generateQueryWithBlockStateToAddToPage(
      component,
      currentPageId,
      userId,
      index,
    ),
    )
    .join(' ');
  return { currentPageId, queryWithBlockStateToAddToPage, lastModifiedDate };
};

export const createAddComponentPlaylistContent = (currentPageId, component) => {
  if (component.type === 'component') return '';
  let data = '';
  if (component.type === BlockTypes.webSite) {
    data = JSON.stringify(component.state)
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"');
  } else if (component.type === BlockTypes.lineSeparator) {
    data = JSON.stringify(component.state)
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"');
  } else if (
    component.type === BlockTypes.approveButton
    || component.type === BlockTypes.shortText
    || component.type === BlockTypes.actionButton
  ) {
    data = sanitizeToSave(JSON.stringify(component.state));
  } else if (component.type === BlockTypes.page) {
  } else {
    data = component.state.getCurrentContent
      && JSON.stringify(convertToRaw(component.state.getCurrentContent()))
        .replaceAll("'", '$@quote')
        .replaceAll('"', "'")
        .replaceAll("\\'", '\\"');
  }
  return data;
};

export const queryCreateContact = ({
  id,
  userFunction,
  last_name,
  first_name,
  email,
  organization,
  phone,
  username,
  createDate,
  position,
  userId,
  userForContact,
  oldContact,
}) => {
  if (oldContact) {
    // debugger;
  }
  const fieldsUpdateObg = {
    last_name: `"${sanitizeToSave(last_name)}"`,
    first_name: `"${sanitizeToSave(first_name)}"`,
    email: `"${sanitizeToSave(email)}"`,
    organization: `"${sanitizeToSave(organization)}"`,
    function: `"${sanitizeToSave(userFunction)}"`,
    phone: `"${sanitizeToSave(phone)}"`,
    createDate,
    position,
    lastModifiedDate: createDate,
  };
  if (userFunction) fieldsUpdateObg.function = `"${sanitizeToSave(userFunction)}"`;
  if (userFunction) fieldsUpdateObg.organization = `"${sanitizeToSave(organization)}"`;
  if (userFunction) fieldsUpdateObg.phone = `"${sanitizeToSave(phone)}"`;
  if (userFunction) fieldsUpdateObg.username = `"${sanitizeToSave(username)}"`;
  const queryParams = {
    id,
    fieldsUpdateObg: calcModifiedField(fieldsUpdateObg),
    ownerId: userId,
  };

  if (userForContact?.userType !== 'InviteUser') {
    queryParams.connectUser = { id: userForContact.id };
  } else {
    queryParams.connectOrCreateUserInvited = {
      id: `"${userForContact.id || uuidv4()}"`,
      email: `"${email.toLowerCase()}"`,
    };
  }
  return { data: queryParams };
};

export const convertStateWithDraftJs = (components) => {
  return components.map((comp) => {
    let tmpContent;
    if (
      comp.type === BlockTypes.webSite
      || comp.type === BlockTypes.lineSeparator
      || comp.type === BlockTypes.embed
    ) {
      tmpContent = sanitizeToSave(JSON.stringify(comp.state));
    } else if (
      comp.type === BlockTypes.approveButton
      || comp.type === BlockTypes.shortText
      || comp.type === BlockTypes.actionButton
    ) {
      tmpContent = sanitizeToSave(JSON.stringify(comp.state));
    } else {
      tmpContent = JSON.stringify(convertToRaw(comp.state.getCurrentContent()))
        .replaceAll("'", '$@quote')
        .replaceAll('"', "'")
        .replaceAll("\\'", '\\"');
    }

    return tmpContent;
  });
};

export const generateQueryWithBlockStateToAddToPageNew = (
  component,
  userId,
) => {
  let data = '';
  if (
    component.type === BlockTypes.webSite
    || component.type === BlockTypes.lineSeparator
  ) {
    data = JSON.stringify(component.state)
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"');
  } else if (
    component.type === BlockTypes.approveButton
    || component.type === BlockTypes.shortText
    || component.type === BlockTypes.actionButton
  ) {
    data = sanitizeToSave(JSON.stringify(component.state));
  } else if (
    component.type === BlockTypes.page
    || component.type === BlockTypes.component
  ) {
    // ?
  } else {
    data = component?.state?.getCurrentContent
      && JSON.stringify(convertToRaw(component.state.getCurrentContent()))
        .replaceAll("'", '$@quote')
        .replaceAll('"', "'")
        .replaceAll("\\'", '\\"');
  }

  const optionField = {};

  if (parseInt(component.width)) optionField.width = parseInt(component.width);
  if (component.innerHtml) {
    optionField.innerHtml = component.innerHtml
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"')
      .replaceAll('\n', '')
      .replaceAll('\\n', '');
  }
  if (component.type) optionField.type = component.type;
  if (component.isHidden) optionField.isHidden = component.isHidden;
  if (component.type === 'page' || component.type === 'component') {
    optionField.libraryComponentId = component.pageId || component.nestedItemId;
  }
  return {
    id: component.id,
    content: data,
    addOwner: userId,
    position: component.position,
    ...optionField,
  };
};

export const parseFieldsToObj = (obj) => {
  const fields = {};
  if (typeof obj !== 'object') return {};
  if (!obj.duration) {
    obj.duration = DEFAULT_DURATION;
  }
  if (!obj.createDate && obj?.libraryComponent?.createDate) {
    obj.createDate = obj.libraryComponent.createDate;
  }
  Object.entries(obj).forEach((pair) => {
    if (pair[0] === 'id') return;
    if (pair[0] === 'type' && pair[1]) fields[pair[0]] = `"${pair[1]}" `;
    if (pair[0] === 'text') fields[pair[0]] = `"${sanitizeToSave(pair[1])}" `;
    if (pair[0] === 'position') fields[pair[0]] = `${pair[1]} `;
    if (pair[0] === 'createDate') fields[pair[0]] = `${pair[1]} `;
    if (pair[0] === 'duration' && pair[1]) fields[pair[0]] = `${Math.floor(pair[1])}`;
    // return acc;
  }, '');

  return fields;
};
