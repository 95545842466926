import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/reducers';
import ShareUnshare from './ShareUnshare';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';

// @ts-ignore
const ShareUnshareDataShell = () => {
  const {
    dataPayload: { item, type, isMultiple },
  } = useSelector((redux: IRootState) => redux.user);
  const { selectedPage } = useSelector((state: IRootState) => state);

  const { contentData } = useSelector((state: IRootState) => state.content);
  const [isLoading, setIsLoading] = useState(type === 'ThreeDotsDropDown');
  const [someData, setData] = useState({});

  const dispatch = useDispatch();
  const {
    title,
    linkPages,
    id,
    wrapperId,
    singleUserShareState = [],
    usedInChannels = {},
    shareState: {
      link = '',
      sharedAvailableFrom,
      sharedAvailableTo,
      isShareToWeb,
    },
  } = useSelector((state: IRootState) => state.currentPage);

  useEffect(() => {
    if (type === 'ThreeDotsDropDown') {
      const element = contentData[item.itemID];
      dispatch(
        actionCreator(EditPlaylist.getShareStatePlaylistS, { wrapperId: element.wrapperId, itemID: item.itemID }),
      );
    }
  }, [type]);

  useEffect(() => {
    if (type === 'ThreeDotsDropDown') {
      const element = contentData[item.itemID];
      setData({ title: element.title, linkPagesCount: Object.keys(element.linkPages).length });
      return;
    }
    if (type === 'ThreeDotsDropDownEmpty') {
      const element = contentData[item.itemID];
      setData({
        title: element.title,
        linkPagesCount: Object.keys(element.linkPages).length,
        id: element.id,
        wrapperId: element.wrapperId,
        singleUserShareState: {},
        usedInChannels: {},
        link: element.wrapperId,
      });
      return;
    }
    if (type === 'ThreeDotsDropDownUpdated') {
      const element = contentData[item.itemID];
      setIsLoading(false);
      const newState = {
        title: element.title,
        linkPagesCount: Object.keys(element.linkPages).length,
        id: element.id,
        wrapperId: element.wrapperId,
        singleUserShareState,
        usedInChannels,
        link,
        sharedAvailableFrom,
        sharedAvailableTo,
        isShareToWeb,
      };
      setData(newState);
      return;
    }
    if (isMultiple) {
      const itemsLength = Object.keys(selectedPage).reduce((acc, cur) => {
        return acc + Object.keys(contentData[cur].linkPages).length;
      }, 0);
      const uniqueSharedToUserKeys = Object.keys(selectedPage).reduce((set, id) => {
        Object.keys(contentData[id].sharedToUsers).forEach(key => set.add(key));
        return set;
      }, new Set());

      const uniqueSharedToChannelKeys = Object.keys(selectedPage).reduce((set, id) => {
        Object.keys(contentData[id].usedInChannels).forEach(key => set.add(key));
        return set;
      }, new Set());

      const { isAllShareToWeb, isMixed } = Object.keys(selectedPage).reduce(
        (acc, cur) => {
          const isShare = contentData[cur].isShareToWeb;

          if (isShare) {
            acc.isAllShareToWeb = acc.isAllShareToWeb && true;
            acc.hasTrue = true;
          } else {
            acc.isAllShareToWeb = false;
            acc.hasFalse = true;
          }

          acc.isMixed = acc.hasTrue && acc.hasFalse;
          return acc;
        },
        { isAllShareToWeb: true, isMixed: false, hasTrue: false, hasFalse: false },
      );
      const mixedSharedToUser = uniqueSharedToUserKeys.size;
      const mixedSharedToChannel = uniqueSharedToChannelKeys.size;

      setData({
        title: `${Object.keys(selectedPage).length} smartfiles`,
        linkPagesCount: itemsLength,
        mixedSharedToUser,
        isAllShareToWeb,
        isMixedShareToWeb: isMixed,
        mixedSharedToChannel,
      });
      return;
    }
    setData({
      title,
      linkPagesCount: linkPages?.length,
      id,
      wrapperId,
      singleUserShareState,
      usedInChannels,
      link,
      sharedAvailableFrom,
      sharedAvailableTo,
      isShareToWeb,
    });
  }, [type, ...Object.values(usedInChannels), ...Object.values(singleUserShareState)]);

  return (
    <ShareUnshare data={someData} isLoading={isLoading} />);
};

export default ShareUnshareDataShell;
