import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactionsComponent } from '../../MakerCardView/ReactionsComponent';
import { IRootState } from '../../../../redux/reducers';
import { DATA_PARENTS } from '../../../../utils/constants';
import { actionSetLinkPageReactions } from '../../../../redux/currentPage/action';
import {
  ReactionsCounter,
} from '../../MakerCardView/styles';
import useComponentVisibleMouseDown from '../../../../utils/hooks/useComponentVisibleMouseDown';
import { ReactionsListSelector } from './ReactionsListSelector';

interface IReactions {
  item: {
    id: string,
    usersReaction: {
      [reaction: string]: string[]
    }
  }
}
export const Reactions: React.FC<IReactions> = ({ item }) => {
  const dispatch = useDispatch();

  const user = useSelector((state: IRootState) => state.user);
  const currentPage = useSelector((state: IRootState) => state.currentPage);
  const contacts = useSelector((state: IRootState) => state.support.contactNicknameMap);
  const ownerID = currentPage?.owner?.id;
  contacts[ownerID] = {
    ...currentPage?.owner,
    abbreviation: `${currentPage?.owner?.first_name?.[0]}${currentPage?.owner?.last_name?.[0]}`,
    name: `${currentPage?.owner?.first_name} ${currentPage?.owner?.last_name}`,
  };

  const reactButtonRef = useRef<HTMLDivElement | null>(null);
  const position = reactButtonRef?.current?.getBoundingClientRect();
  const reactionsArr = useMemo(() => Object.keys(item.usersReaction || {}), [item.usersReaction]);
  const [currentReaction, setCurrentReaction] = useState<string | null>(reactionsArr[0]);

  const allReactionsCounter = useMemo(() => {
    return Object.values(item.usersReaction || {}).reduce((acc, usersReaction) => {
      return usersReaction.length + acc;
    }, 0);
  }, [item.usersReaction]);

  useEffect(() => {
    const firstNonEmptyKey = Object.keys(item.usersReaction || {}).find(key => {
      return item.usersReaction[key].length > 0;
    });
    setCurrentReaction(firstNonEmptyKey || null);
  }, [item.usersReaction]);

  const allUsers = useMemo(() => {
    return {
      ...contacts,
      [user.id]: {
        ...user,
        abbreviation: `${user.firstName ? user.firstName[0] : 'a'}${user.lastName ? user.lastName[0] : 'a'}`,
      },
    };
  }, [contacts, user]);

  const [menuRef, isActive, setIsActive] = useComponentVisibleMouseDown(
    false,
    DATA_PARENTS.reactions,
  );

  const [reactionsRef, isReactionsActive, setReactionsActive] = useComponentVisibleMouseDown(
    false,
    DATA_PARENTS.reactions,
  );

  useEffect(() => {
    if (!isReactionsActive && isActive) {
      setIsActive(false);
    }
  }, [isReactionsActive]);

  const toggleReactionsVisible = () => setReactionsActive(!isReactionsActive);

  const reactionsLimit = useMemo(() => {
    return Object.values(item?.usersReaction || {}).reduce((acc, cur, index) => {
      if (acc.line >= 3.5 && !acc.limit) {
        acc.limit = index;
        acc.extra = reactionsArr.length - index;
        return acc;
      }
      if (cur.length > 3 && cur.length < 9) {
        acc.line += 1;
        return acc;
      }
      if (cur.length < 4) {
        acc.line += 0.5;
        return acc;
      }
      acc.line += 0.5;
      return acc;
    }, { limit: 0, line: 1, extra: 0 });
  }, [item.usersReaction, reactionsArr]);

  const reactHandler = (reaction: string) => {
    const currentUserReactions = reactionsArr.reduce((acc: string[], cur: string) => {
      if (item.usersReaction[cur]?.includes(user.id)) {
        acc.push(cur);
      }
      return acc;
    }, []);

    const isReactionAlreadyExists = currentUserReactions.includes(reaction);
    let reactions = currentUserReactions;
    if (isReactionAlreadyExists) {
      reactions = currentUserReactions.filter(elem => elem !== reaction);
    } else {
      currentUserReactions.push(reaction);
    }
    dispatch(
      actionSetLinkPageReactions(
        user.id,
        item.id,
        reactions,
      ),
    );
  };
  const calcReactionUsers = useCallback((ids: string[]) => {
    return ids?.map((id) => allUsers[id]);
  }, [allUsers]);

  const [
    menuRefReactionsComponent, isActiveReactionsComponent, setIsActiveReactionsComponent,
  ] = useComponentVisibleMouseDown(false, DATA_PARENTS.reactions);

  return (
    <ReactionsComponent
      reactHandler={reactHandler}
      currentReaction={currentReaction}
      setCurrentReaction={setCurrentReaction}
      toggleReactionsVisible={toggleReactionsVisible}
      isReactionsActive={isReactionsActive || !!allReactionsCounter}
      isActive={!!isActiveReactionsComponent}
      menuRef={menuRefReactionsComponent}
      setIsActive={setIsActiveReactionsComponent}
    >
      <div ref={reactButtonRef} />
      {!!allReactionsCounter && <ReactionsCounter>{allReactionsCounter}</ReactionsCounter>}

      <ReactionsListSelector
        isReactionsActive={isReactionsActive}
        setReactionsActive={setReactionsActive}
        menuRef={menuRef}
        ownerID={ownerID}
        allReactionsCounter={allReactionsCounter}
        allUsers={allUsers}
        reactionsRef={reactionsRef}
        isActive={isActive}
        user={user}
        position={position}
        reactionsArr={reactionsArr}
        reactHandler={reactHandler}
        item={item}
        calcReactionUsers={calcReactionUsers}
        reactionsLimit={reactionsLimit}
        setIsActive={setIsActive}
      />

    </ReactionsComponent>
  );
};
