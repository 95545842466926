import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { actionAddToBeScrollTo } from '../../redux/currentPage/action';
import { actionChangeViewModeUPV } from '../../redux/upv/actions';
import { emptyCallback, NewUPVViewModes } from '../../utils/constants';

const NAVIGATION = {
  [NewUPVViewModes.expanded]: { next: NewUPVViewModes.compressed, prev: NewUPVViewModes.card },
  [NewUPVViewModes.compressed]: { next: NewUPVViewModes.card, prev: NewUPVViewModes.expanded },
  [NewUPVViewModes.card]: { next: NewUPVViewModes.expanded, prev: NewUPVViewModes.compressed },
};

const calcNextActiveIndex = ({ isBack, linkPagesRef, dispatch, prevIndex }) => {
  let newIndex;
  if (isBack) {
    newIndex = prevIndex === null
      ? linkPagesRef.current.length - 1
      : (prevIndex - 1 + linkPagesRef.current.length) % linkPagesRef.current.length;
  } else {
    newIndex = prevIndex === null
      ? 0
      : (prevIndex + 1) % linkPagesRef.current.length;
  }

  const item = linkPagesRef.current[newIndex];
  dispatch(actionAddToBeScrollTo(item?.id));

  return newIndex;
};
const handleClick = (set) => (event) => {
  const dateAttr = event.target.closest('.selectedItem');
  if (!dateAttr) {
    set(null);
  }
};
export const useHoverArrowNavigation = () => {
// export const useHoverArrowNavigation = ({ isForSharedToWeb }) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  // const { type, id: sharedToWebPlaylistID } = useParams();
  // const {
  //   usedInChannels,
  //   id,
  // } = useSelector((state) => state.currentPage);
  const { viewMode } = useSelector((state) => state.upv);
  const linkPages = useSelector((state) => state.currentPage.linkPages);
  const mode = useRef(null);
  // const openPlayer = useRef(emptyCallback);
  const linkPagesRef = useRef(linkPages);
  const [activeItemIndex, setActiveItemIndex] = useState(null);

  const { openModal } = useSelector((state) => state.user);

  useEffect(() => {
    linkPagesRef.current = linkPages;
  }, [linkPages]);

  useEffect(() => {
    mode.current = viewMode;
  }, [viewMode]);

  useEffect(() => {
    if (activeItemIndex !== null && linkPagesRef.current) {
      const item = linkPagesRef.current[activeItemIndex];
      dispatch(actionAddToBeScrollTo(item?.id));
    }
  }, [viewMode]);

  // useEffect(() => {
  //   openPlayer.current = () => {
  //     if (!id) return;
  //     const isEmpty = !linkPagesRef.current
  //       || linkPagesRef.current && !linkPagesRef.current.filter((i) => i.type && i.type !== 'upload').length;
  //
  //     if (isEmpty) return;
  //     dispatch(actionPageWillDownload());
  //     const step = (activeItemIndex ?? 0) + 1;
  //
  //     if (isForSharedToWeb) {
  //       dispatch(
  //         actionAddPayloadUnifyHistory({ sharedToWebID: sharedToWebPlaylistID }),
  //       );
  //       history.push(`/shared_player/shared/${sharedToWebPlaylistID}/${step}`);
  //       return;
  //     }
  //     const defaultChannel = Object.values(usedInChannels || {})[0]?.id || 'preview';
  //     const firstChannelId = type === 'publish' ? defaultChannel : type === 'shared' ? 'shared' : 'preview';
  //
  //     const exPlaylistId = type === 'shared' ? sharedToWebPlaylistID : id;
  //     history.push(`/player/${firstChannelId}/${exPlaylistId}/${step}`);
  //   };
  // }, [isForSharedToWeb, activeItemIndex, type, sharedToWebPlaylistID, id]);


  const handleKeyDown = useCallback((event) => {
    if (!linkPagesRef.current) return;
    const elementTagFocused = document.activeElement.tagName.toLowerCase();
    const isDraftJsInputFocused = document.activeElement.role === 'textbox';
    const isTextInputFocused = elementTagFocused === 'input';
    const isTextAreaInputFocused = elementTagFocused === 'textarea';
    if (isTextAreaInputFocused || isTextInputFocused || isDraftJsInputFocused) return;

    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      dispatch(actionChangeViewModeUPV(NAVIGATION[mode.current].prev));
    } else if (event.key === 'Escape') {
      setActiveItemIndex(null);
    } else if (event.key === 'ArrowRight') {
      dispatch(actionChangeViewModeUPV(NAVIGATION[mode.current].next));
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      setActiveItemIndex((prevIndex) => calcNextActiveIndex({ isBack: false, prevIndex, linkPagesRef, dispatch }),
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setActiveItemIndex((prevIndex) => calcNextActiveIndex({ isBack: true, prevIndex, linkPagesRef, dispatch }),
      );
    }
  }, []);

  useEffect(() => {
    if (!openModal) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [openModal]);

  useEffect(() => {
    if (activeItemIndex !== null) {
      window.addEventListener('click', handleClick(setActiveItemIndex));
    }
    return () => {
      window.removeEventListener('click', handleClick(setActiveItemIndex));
    };
  }, [activeItemIndex !== null]);


  return activeItemIndex;
};
