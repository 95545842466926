import { PLAYLISTS_TRANSLATIONS } from './playlists';
import { SETTINGS_TRANSLATIONS } from './settings';
import { PLAYER_TRANSLATIONS } from './player';

export const LANG = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  nl: 'nl',
  es: 'es',
  it: 'it',
  pl: 'pl',
  ru: 'ru',
  ua: 'ua',
};
export const CURRENCY_UI = {
  usd: '$',
  eur: '€',
  gbp: '£',
};
export const CURRENCY = {
  usd: 'usd',
  eur: 'eur',
  gbp: 'gbp',
};
interface TranslationMessage {
  en: string | ((...args: any[]) => string);
}


interface SettingsTranslations {
  [key: string]: {
    [key: string]: string | ((...args: any[]) => string) | any | {
      [key: string]: string | ((...args: any[]) => string) | any
    };
  };
}

interface InformerMessages {
  [key: string]: TranslationMessage;
}

interface Translations {
  settings: SettingsTranslations;
  informer: {
    messages: InformerMessages;
  };
  player:SettingsTranslations;
  playlists:SettingsTranslations;
}

export const TRANSLATIONS:Translations = {
  settings: SETTINGS_TRANSLATIONS,
  informer: {
    messages: {
    },
  },
  playlists: PLAYLISTS_TRANSLATIONS,
  player: PLAYER_TRANSLATIONS,
};
