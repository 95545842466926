
import React from 'react';

import { ReactComponent as AISvg } from '../../images/icons/batchActions/sparkles_gradient.svg';

export const calcItemName = (linkPage, t) => {
  if (linkPage.name) return linkPage.name;
  if (linkPage.title) return linkPage.title;
  if (linkPage.libraryComponent?.title) return linkPage.libraryComponent.title;
  return t('textComponentT');
};

export const calcModalTitle = (aiType, t) => {
  const naming = {};
  switch (aiType) {
    case 'ai_task':
      naming.title = t('assignTaskForAlanAIT');
      naming.button = t('sendT');
      break;
    case 'ai_send':
      naming.title = t('aiProcessingT');
      naming.button = t('sendT');
      break;
    case 'ai_search':
      naming.title = `${t('aISearchT')}:`;
      naming.button = t('searchT');
      break;
    case 'ai_summarize_single':
      naming.title = t('alanAIResultsT');
      naming.button = t('addToSmartFileT');
      break;
    case 'ai_summarize_all':
      naming.title = t('alanAIResultsAcrossTheSmartfileT');
      naming.button = t('addT');
      break;
    case 'ai_queue':
      naming.title = t('sendToAIT');
      break;
    default:
      break;
  }
  return naming;
};

export const aIAgents = {
  // summarize: {
  //   name: 'Summarizer',
  //   command: '/sum',
  //   icon: <SummarizeSvg />,
  // },
  // translate: {
  //   name: 'Translator',
  //   command: '/translate',
  //   icon: <TranslateSvg />,
  // },
  // search: {
  //   name: 'Searcher',
  //   command: '/search',
  //   icon: <SearchSvg />,
  // },
  pro: {
    name: 'Quidzi AI Pro',
    command: '/pro',
    icon: <AISvg />,
  },
};
