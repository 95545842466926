import styled from 'styled-components/macro';

export const ReactionsPopupWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0 2px 4px 2px rgba(23, 25, 28, 0.08);
  width: 226px;
  height: 156px;
  position: absolute;
  transform: translate(-55%, -20%);
  z-index: 101;
  flex-direction: column;
  padding: 4px 12px 0 12px;
  top: 35px;
  left: 70px;
`;

export const SingleReactionWrapper = styled.div<{isSelected?: boolean}>`
  display:flex;
  align-items: center;
  padding: 2px 10px;
  color: #A1A6AF;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  white-space: nowrap;
  gap: 6px;
  ${({ isSelected }) => (isSelected ? `
    border-bottom: 1px solid #000;
    color:#212327;
    ` : '')}
  
`;

export const EmojiWrapper = styled.div<{isSelected?: boolean}>`
    font-size: 20px;
  color: unset;
  font-weight: normal;
`;

export const TabsSection = styled.div`
  display: flex;
  max-width: 202px;
  margin: 0;
  overflow: scroll;
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`;

export const SectionSeparator = styled.div`
  border-top: 1px solid var(--Sheme_border_subtle);
  width: 204px;
`;
export const UsersSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 120px;
  width: 213px;
  padding-top: 5px;
  min-height: 100px;
  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    right: -4px;
  }

  &::-webkit-scrollbar-thumb {
    position: absolute;
    display: block;
    border-radius: 4px;
    background-color: #e2e1e1;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.13);
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;

export const SingleContactWrapper = styled.div`
  color: var(--Sheme_fg_muted);
  font-family: RobotoFlex, sans-serif;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  justify-content: space-between;
  width: 198px;
  padding: 4px;
  &:hover{
    border-radius: 8px;
    background: rgba(126, 122, 133, 0.04);
  }
`;

export const SingleContactWrapperHover = styled.div`
  &:hover{
    z-index: 1000;
  }
`;

export const UserWrapper = styled.div`
    display: flex;
`;

export const TextWrapper = styled.div`
  padding-left: 6px;
  
    p {
      margin: 0;
      font-family: "RobotoFlex", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      max-width: 125px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .email {
      color: var(--Sheme_fg_subtle);
    }
`;
