import React, { useEffect, useRef, useState } from 'react';
import { CompositeDecorator, DefaultDraftBlockRenderMap } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import 'draft-js/dist/Draft.css';
import createImagePlugin from 'draft-js-image-plugin';
import createVideoPlugin from 'draft-js-video-plugin';
import { useSelector } from 'react-redux';
import {
  blockRenderMap,
  findLinkEntities, isTextType,
  myBlockStyleFn,
} from '../../utils/helpersDragt';
import styles from './index.module.css';
import { styleMap } from '../../utils/richTextBar';
import ResizeImage from '../ResizeImage';
import ErrorBoundary from '../ErrorBoundary';
import LinkPreviewBlock from '../MomentBlock/LinkPreviewBlock';
import { ApproveButton } from '../MomentBlock/CustomBlocks';
import ActionButton from '../MomentBlock/CustomBlocks/ActionButton';
import ShortText from '../MomentBlock/CustomBlocks/ShortText';
import BlockImageAdd from '../BlockImageAdd/MediaAdd';
import SeparatorLineBlock from '../MomentBlock/SeparatorLineBlock';
import PdfBlock from '../MomentBlock/CustomBlocks/pdfBlock/PdfBlock';
import PageLink from '../MomentBlock/CustomBlocks/PageLink';
import { BlockTypes, empty, UiComponentTypes, emptyCallback } from '../../utils/constants';
import EmbedBlock from '../MomentBlock/CustomBlocks/EmbedBlock';
import GoogleEmbedPreviewBlock from '../MomentBlock/GoogleEmbedPreviewBlock';
import DropboxEmbedPreviewBlock from '../MomentBlock/DropboxEmbedPreviewBlock';
import { LinkDecorator } from '../../utils/draftJsHelpers';

const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();

const SharedComponent = React.memo(function SharedComponent({
  stopPropagation,
  clear,
  index,
  parentIndex,
  setIdOpenMenuBlock,
  idOpenMenuBlock,
  isDragging,
  state,
  componentId,
  newLayers,
  position,
  innerHtml,
  relationId,
  dragHandleProps,
  addEditableBlock,
  deleteEditableBlock,
  onChangeEditableBlock,
  onChangeBlocksWithData,
  type1,
  refI,
  handlerClick,
  page,
  isLibraryCreate,
  isSharePage,
  currentPageId,
  ...props
}) {
  const [localState, setLocalState] = useState(state || empty);
  const [type] = useState(type1);
  const editorWR = useRef(null);
  const editorWrapperRef = useRef(null);
  const layersRed = useSelector(reduxState => reduxState.currentPage.layers);
  const layers = newLayers || layersRed;
  const isHasContent = localState
    && localState.getCurrentContent
    && localState.getCurrentContent().blockMap.size !== 1;

  const isMedia = type === BlockTypes.image || type === BlockTypes.video;
  const isPage = type === BlockTypes.page;
  const isPdf = type === BlockTypes.pdf;
  const isText = isTextType[type];
  const isHasPdfPreview = UiComponentTypes.ms[type] || UiComponentTypes.xls[type];
  const isNoEditable = type === BlockTypes.approveButton
    || type === BlockTypes.actionButton
    || type === BlockTypes.shortText
    || type === BlockTypes.lineSeparator;
  const isWebSite = type === BlockTypes.webSite;
  const isGoogleEmbed = type === BlockTypes.googleEmbed;
  const isDropboxEmbed = type === BlockTypes.dropboxEmbed;
  const isEmbed = type === BlockTypes.embed;
  const isOrderedList = type?.startsWith(BlockTypes.ordered_list_item);
  const isUnorderedList = type?.startsWith(BlockTypes.unordered_list_item);
  const isHasMediaContentOrNotMedia = (isMedia && isHasContent) || !isMedia;
  const isLargeRightBar = useSelector(reduxState => reduxState.currentPage.isLargeRightBar);
  const { isHidden: isBlockHidden } = useSelector(
    reduxState => reduxState.currentPage?.blocks?.find(block => block.id === componentId),
  ) || empty;

  useEffect(() => {
    setLocalState(state);
  }, [type1, state]);

  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

  // const LinkW = WrapperLink({
  //   setLinkOption: () => {},
  //   localState,
  //   isView: true,
  // });

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: LinkDecorator,
    },
  ]);

  return (
    <div className={styles.wrapper_component_balancer}>
      <div
        className={`wrapper_component_${type}
      ${
        isLibraryCreate
          ? styles.wrapper_component_in_library
          : styles.wrapper_component
      }
      ${isLargeRightBar ? styles.wrapper_component_small : ''}
      `}
      >
        <div
          onMouseUp={stopPropagation}
          onMouseMove={stopPropagation}
          onMouseDown={stopPropagation}
          className={`component_${type}
            component
            ${styles.component}
            ${props.isBuilderTextElement ? styles.component_in_builder : ''}
            ${props.isPlayerTextElement ? styles.component_in_player : ''}
            ${
              !props.isCardView && props.isPlayerTOCPreview
                ? styles.component_in_TOC_player_row
                : ''
            }
            ${
              !!props.isCardView && props.isPlayerTOCPreview
                ? styles.component_in_TOC_player_card
                : ''
            }
            ${props.isFullScreenMode ? 'colors_inversion' : ''}
          `}
        >
          <div className={` ${styles.hiddenWrapper}`}>
            <div
              ref={editorWrapperRef}
              className={` ${styles.wrapper} ${
                isBlockHidden ? styles.addOpacity : ''
              } `}
            >
              {isHasMediaContentOrNotMedia
                && !isPage
                && !isNoEditable
                && !isWebSite
                && !isGoogleEmbed
                && !isDropboxEmbed
                && !isEmbed
                && !isPdf
                && (isText || !type)
                && type !== BlockTypes.embed && (
                <>
                  <div ref={editorWR}>
                    <div
                      className={
                          isSharePage && !props.isAnyCanEdit
                            ? styles.notEditable
                            : ' '
                        }
                    >
                      {isOrderedList
                          && typeof parentIndex !== 'number'
                          && type !== 'ordered-list-item' && (
                            <p className={styles.test1}>
                              {' '}
                              {`${
                                (layers && layers[index]?.counter?.join('.'))
                                || 1
                              }.`}{' '}
                            </p>
                      )}

                      {isOrderedList
                          && typeof parentIndex === 'number'
                          && type !== 'ordered-list-item' && (
                            <p className={styles.test1}>
                              {`${
                                (layers[parentIndex]?.counter.length
                                  && layers[parentIndex]?.counter[
                                    index
                                  ]?.counter?.join('.'))
                                || 1
                              }.`}
                            </p>
                      )}

                      {isUnorderedList
                          && type !== BlockTypes.unordered_list_item && (
                            <p className={`${styles.test2}`} />
                      )}
                      <div className="customEditor">
                        <ResizeImage
                          isSharePage
                          save={() => {}}
                          index={index}
                          startWith={props.width}
                          type={type}
                          innerHtml={innerHtml}
                          localState={localState}
                          isBuilderTextElement={
                              props.isBuilderTextElement
                              || props.isPlayerTextElement
                            }
                        >
                          <ErrorBoundary>
                            <Editor
                              customStyleMap={styleMap()}
                              readOnly
                              editorState={localState}
                              onChange={emptyCallback}
                              blockStyleFn={myBlockStyleFn}
                              blockRenderMap={extendedBlockRenderMap}
                              plugins={[imagePlugin, videoPlugin]}
                              decorators={[decorator]}
                            />
                          </ErrorBoundary>
                        </ResizeImage>
                      </div>
                    </div>
                  </div>
                </>
              )}
              { type === BlockTypes.image && (
                <>
                  <div ref={editorWR}>
                    <div className="customEditor">
                      <ResizeImage
                        save={() => {}}
                        index={index}
                        isSharePage
                        startWith={props.width}
                        type={type}
                        innerHtml={innerHtml}
                        localState={localState}
                      >
                        <ErrorBoundary>
                          <div>
                            <figure>
                              <img
                                src={props.urlSmallImage || props.urlFile}
                                alt=""
                              />
                            </figure>
                          </div>
                        </ErrorBoundary>
                      </ResizeImage>
                    </div>
                  </div>
                </>
              )}
              {isWebSite && localState?.data && (
                <LinkPreviewBlock data={localState.data} editorWR={editorWR} />
              )}
              {isGoogleEmbed && localState?.data && (
                <GoogleEmbedPreviewBlock
                  data={localState.data}
                  editorWR={editorWR}
                  index={index}
                  isSharedComponent
                />
              )}
              {isDropboxEmbed && localState?.data && (
                <DropboxEmbedPreviewBlock
                  data={localState.data}
                  editorWR={editorWR}
                  index={index}
                />
              )}
              {isPage && page && (
                <PageLink
                  page={page}
                  header={page?.title}
                  isViewMode={props.isViewMode}
                />
              )}
              {type === BlockTypes.lineSeparator && localState?.data && (
                <SeparatorLineBlock
                  data={localState.data}
                  editorWR={editorWR}
                  componentId={componentId}
                  isView
                  update={() => {}}
                />
              )}
              {type === BlockTypes.approveButton && (
                <ApproveButton
                  saveState={() => {}}
                  state={localState}
                  isView
                  id={componentId}
                />
              )}
              {(isPdf || isHasPdfPreview) && (
                <PdfBlock
                  state={localState}
                  innerHtml={innerHtml}
                  relationId={relationId}
                  componentId={componentId}
                  isSharePage={isSharePage}
                />
              )}
              {type === BlockTypes.actionButton && (
                <ActionButton
                  saveState={() => {}}
                  state={localState}
                  isView
                  id={componentId}
                />
              )}
              {type === BlockTypes.embed && (
                <EmbedBlock
                  state={state}
                  innerHtml={innerHtml}
                  // saveItem={onChangeBlocksWithData}
                  isView
                />
              )}
              {type === BlockTypes.shortText && (
                <ShortText
                  saveState={() => {}}
                  state={localState}
                  isView
                  id={componentId}
                />
              )}
              {type === BlockTypes.image && !isHasContent && (
                <BlockImageAdd
                  index={index}
                  id={componentId}
                  position={position}
                  modifier={newUrl => onChangeEditableBlock(
                    imagePlugin.addImage(localState, newUrl),
                  )
                  }
                  isSharedComponent
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SharedComponent;
