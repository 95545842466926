import React, { useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import Slider from 'rc-slider';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from '../../pages/SettingsSection/Profile/ProfileInfo/index.module.scss';
import { ReactComponent as PlusIconSvg } from '../../images/icons/icon_20/plus2.svg';
import { ReactComponent as MinusIconSvg } from '../../images/icons/icon_20/minus.svg';

import { actionCloseModal } from '../../redux/user/action';
import {
  calcNewCropStyles,
  centerAspectCrop,
} from '../../utils/SettingsHelpers/cropUtils';
import { CustomButton } from '../../pages/Maker/ai_styled';

const cn = classNames.bind(rawStyles);

const PlaylistCoverCropper = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imageSize, setImageSize] = useState({});
  const [crop, setCrop] = useState();
  const [zoom, setZoom] = useState(50);

  const imgRef = useRef(null);

  useEffect(() => {
    if (imageSize) {
      const newCrop = calcNewCropStyles(imageSize, zoom, crop);
      setCrop(newCrop);
    }
  }, [zoom, imageSize]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const newCrop = centerAspectCrop(width, height, 1);
    if (crop.x === 0 && crop.y === 0) {
      setCrop(newCrop);
    }
    setImageSize({ width, height });
    // setTimeout(() => {
    //   if (!imgRef?.current || imgRef.current.getAttribute('crossOrigin')) return;
    //   imgRef.current.setAttribute('src', `${props.src}?not-from-cache-please`);
    //   imgRef.current.setAttribute('crossOrigin', 'anonymous');
    // }, 200);
  };

  const cancelButtonHandler = () => {
    dispatch(actionCloseModal());
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(Math.max(newZoom, 2));
  };

  const onPlusChange = (e) => {
    e.preventDefault();
    setZoom((zoom) => Math.min(zoom + 2, 100));
  };

  const onMinusChange = (e) => {
    e.preventDefault();
    setZoom((zoom) => Math.max(zoom - 2, 2));
  };

  const okButtonHandler = async () => {
    props.save(crop);
  };

  if (!props.src) return <></>;
  return (
    <div>
      <div className={cn('cropWrap')}>
        <ReactCrop
          crop={crop}
          onChange={(pixelCrop, percentCrop) => {
            onCropChange(percentCrop);
          }}
          className={cn('crop', { isPlaylist: true })}
          aspect={1}
          locked
          keepSelection
          circularCrop
        >
          <img
            ref={imgRef}
            src={props.src}
            loading="eager"
            fetchPriority="high"
            alt="avatar_image_file"
            onLoad={onImageLoad}
          />
        </ReactCrop>
      </div>
      <div className={cn('slider_wrapper')}>

        <div onClick={onMinusChange} className={cn('plusButton')}>
          <MinusIconSvg />
        </div>
        <Slider
          className={cn('slider')}
          value={zoom}
          onChange={onZoomChange}
          handleStyle={{
            width: 20,
            height: 20,
            background: '#f1f1f0',
            borderRadius: 14,
            border: '1px solid',
            borderColor: '#e9e9e9',
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.08)',
            marginTop: -8,
          }}
          trackStyle={{
            height: 4,
            background: 'rgba(162, 160, 167, 0.60)',
            borderRadius: 4,
            border: 'none',
            boxShadow: 'none',
          }}
        />
        <div onClick={onPlusChange} className={cn('plusButton')}>
          <PlusIconSvg />
        </div>
      </div>
      <div className={cn('separator')} />
      <div className={cn('button_group')}>
        <div onClick={cancelButtonHandler} className={cn('cancel_button')}>{t('cancelT')}</div>
        <CustomButton isActive isBrand onClick={okButtonHandler}>{t('setThumbnailT')}</CustomButton>

      </div>
    </div>
  );
};

export default PlaylistCoverCropper;
