import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-date-picker';
import classNames from 'classnames/bind';
import i18n from '../../../i18n';

import { NameAndImageAva } from '../../../pages/Maker/MakerCommon/Table/NameAndImage';
import Tooltip from '../../../shared/Tooltips/Tooltip';

import { convertDate } from '../../../utils/dateConvert';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';

import { ReactComponent as DropDownSvg } from '../../../images/icons/shareModal/chevron_double.svg';
import { ReactComponent as CalendarSvg } from '../../../images/icons/shareModal/calendar.svg';
import { ReactComponent as TimeSvg } from '../../../images/icons/shareModal/time_icon.svg';
import { ReactComponent as LifetimeSvg } from '../../../images/icons/shareModal/time_icon_endless.svg';

import styles from '../index.module.scss';
import { CustomButton } from '../../../pages/Maker/ai_styled';

const cx = classNames.bind(styles);

const dateOptions: { [key: string]: string } = {
  OPEN_TO_READ: i18n.t('LifetimeT'),
  DAILY: i18n.t('dayT'),
  WEEKLY: i18n.t('weekT'),
  CUSTOM_TIME_RANGE: i18n.t('customTimeT'),
};


export interface IUserToShare {
  sharedAvailableTo: number,
  sharedAvailableFrom: number,
  name: string,
  email: string,
  avatarUrlVerySmall: string,
  first_name: string,
  last_name: string,
  displayImage: string,
  color: string
  isCoEdit: boolean,
  coEditorIndex: number,
}

export const ShareSettingPopup: React.FC<{
  type: string,
  handler: (param?: string) => void,
  isCoEdit?: boolean,
  handleRevoke?: () => void,
  elemPosition: { time: number, role: number },
  setMainCalendarOpened?: () => void
}> = ({ type, handler, isCoEdit, handleRevoke, elemPosition, setMainCalendarOpened }) => (
  <div
    className={cx('shared_settings_popup')}
    style={{ top: type === 'time' ? elemPosition.time : elemPosition.role }}
  >
    {type === 'time' && (
      Object.keys(dateOptions).map((date) => (
        <div
          className={cx('shared_settings_popup_option')}
          onClick={() => (date === 'CUSTOM_TIME_RANGE' && setMainCalendarOpened
            ? setMainCalendarOpened() : handler(date))}
        >
          {dateOptions[date]}
        </div>
      ))
    )}
    {type === 'role' && (
      <div className={cx('shared_settings_popup_option')} onClick={() => handler()}>
        {isCoEdit ? 'Viewer' : 'Co-Editor'}
      </div>
    )}
    <div className={cx('shared_settings_popup_option', 'is_revoke')} onClick={handleRevoke}>
      {i18n.t('revokeAccessT')}
    </div>
  </div>
);

export const SingleShareItem: React.FC<{
  user: IUserToShare,
  removeUserToSharing?: (user: IUserToShare) => void,
  isOwner?: boolean,
  switchRole?: (user: IUserToShare) => void,
  setTime?: (time: string, email: string) => void,
  setMainCalendarOpened?: (val: boolean) => void,
  setCurrentCalendarItem?: ({ type, id }: { type: string, id: string }) => void,
}> = ({
  user,
  isOwner,
  removeUserToSharing,
  switchRole,
  setTime,
  setMainCalendarOpened,
  setCurrentCalendarItem,
}) => {
  const { t } = useTranslation();
  const date = user?.sharedAvailableTo
    ? `${t('dueT')} ${convertDate(user?.sharedAvailableTo)}`
    : `${t('lifetimeAccessT')}`;
  const [refDropTimeMenu, isDropTimeMenuVisible, setIsDropTimeMenu] = useComponentVisibleMouseDown(false, '');
  const [refDropRoleMenu, isDropRoleMenuVisible, setIsDropRoleMenu] = useComponentVisibleMouseDown(false, '');

  const handleRevokeAccess = () => {
    if (removeUserToSharing) {
      removeUserToSharing(user);
      setIsDropTimeMenu(false);
      setIsDropRoleMenu(false);
    }
  };

  const handleSwitchRole = () => {
    switchRole && switchRole(user);
    setIsDropRoleMenu(false);
  };

  const handleSetTime = (time?: string) => {
    setTime && time && setTime(time, user.email);
    setIsDropTimeMenu(false);
  };
  const elemPositionOptions = refDropTimeMenu.current?.getBoundingClientRect();
  const elemPosition = {
    time: ((elemPositionOptions?.top ?? 0) > 500) ? -165 : 0,
    role: ((elemPositionOptions?.top ?? 0) > 550) ? -65 : 0,
  };
  const handleOpenCalendar = () => {
    if (setMainCalendarOpened && setCurrentCalendarItem) {
      setMainCalendarOpened(true);
      setCurrentCalendarItem({ type: 'user', id: user.email });
    }
    setIsDropTimeMenu(false);
  };

  const handleOpenPopup = (isTime: boolean) => {
    setIsDropTimeMenu(isTime);
    setIsDropRoleMenu(!isTime);
  }
  return (
    <div className={cx('shared_option', 'user')}>
      <div className={cx('ava_wrapper')}>
        <NameAndImageAva
          avaURL={user?.avatarUrlVerySmall}
          first_name={user?.first_name ?? 'John'}
          last_name={user?.last_name ?? 'Doe'}
          displayImage={user.displayImage}
          upvCard
          noStick
          itemColor={user?.color ?? 'transparent'}
          isOnlyAvatarsDisplay
          calcDate={undefined}
          isShareModal
        />
      </div>
      <div className={cx('shared_option_content')}>
        <div className={cx('option_title', 'text_unified-base')}>{user?.first_name} {user?.last_name}</div>
        <div className={cx('option_description', 'text_unified-sm')}>{user?.email}</div>
      </div>
      {isOwner ? (
        <div className={cx('sender', 'text_unified-base')}>{t('senderT')}</div>
      ) : (
        <div
          className={cx('user_options_wrapper')}
          ref={(ref) => {
            refDropTimeMenu.current = ref;
            refDropRoleMenu.current = ref;
          }}
        >
          <Tooltip
            data-parent=""
            text={`${date} \n ${t('clickToUpdateT')}`}
            direction="down"
            place="ai_agent"
          >
            <CustomButton onClick={() => handleOpenPopup(true)} isIconOnly isGhost>
              {user.sharedAvailableTo ? (
                <TimeSvg />
              ) : (
                <LifetimeSvg />
              )}
            </CustomButton>
          </Tooltip>
          <Tooltip
            data-parent=""
            text={t('roleTooltipT')}
            direction="down"
            place="role_picker"
          >
            <CustomButton onClick={() => handleOpenPopup(false)} isGhost>
              <span>{user.isCoEdit ? 'Co-Editor' : 'Viewer'}</span>
              <DropDownSvg />
            </CustomButton>
          </Tooltip>
          {isDropTimeMenuVisible && (
            <ShareSettingPopup
              type="time"
              handler={handleSetTime}
              handleRevoke={handleRevokeAccess}
              elemPosition={elemPosition}
              setMainCalendarOpened={handleOpenCalendar}
            />
          )}
          {isDropRoleMenuVisible && (
            <ShareSettingPopup
              type="role"
              handler={handleSwitchRole}
              handleRevoke={handleRevokeAccess}
              isCoEdit={user?.isCoEdit}
              elemPosition={elemPosition}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const DateOption: React.FC<{
  currentPeriod: string,
  setCurrentPeriod: React.Dispatch<React.SetStateAction<string>>,
  setSelectedDateFromHandler: (date: Date) => void,
  setSelectedDateToHandler: (date: Date) => void,
}> = ({
  currentPeriod,
  setCurrentPeriod,
  setSelectedDateFromHandler,
  setSelectedDateToHandler,
}) => {
  const [value, onChange] = useState<any>();
  const [isDateOpened, setDateOpened] = useState(false);

  useEffect(() => {
    if (value && value[0] && value[1]) {
      setSelectedDateFromHandler(value[0]);
      setSelectedDateToHandler(value[1]);
    }
  }, [value])

  return (
    <div className={cx('shared_options')}>
      <div className={cx('shared_option')}>
        <div className={cx('shared_option_content')}>
          <div className={cx('option_title', 'text_unified-base')}>{i18n.t('accessPeriodT')}</div>
          <div className={cx('option_description', 'text_unified-sm')}>{i18n.t('accessPeriodDescriptionT')}</div>
        </div>
      </div>
      <div className={cx('shared_option')}>
        <div className={cx('shared_option_content', 'time_options')}>
          {Object.keys(dateOptions).slice(0, 3).map(elem => (
            <div
              className={cx('time_option', { active: currentPeriod === elem })}
              onClick={() => setCurrentPeriod(elem)}
              key={elem}
            >
              {dateOptions[elem]}
            </div>
          ))}
          <div
            className={cx('time_option', { active: currentPeriod === 'CUSTOM_TIME_RANGE' })}
            onClick={() => { setDateOpened(true); setCurrentPeriod('CUSTOM_TIME_RANGE'); }}
          >
            {(value && value[0]) ? `${value[0].toLocaleDateString()}-${value[1].toLocaleDateString()}`
              : (
                <>
                  <CalendarSvg />
                  <span>{i18n.t('customTimeT')}</span>
                </>
              )}
            {isDateOpened && (
              <div className={cx('date_picker_wrapper')}>
                <DatePicker
                  onChange={onChange}
                  value={value}
                  tileClassName="tileClass"
                  className={`playlistsDatePicker2023 isModifyUnset`}
                  showLeadingZeros
                  isOpen={isDateOpened}
                  onCalendarClose={() => setDateOpened(false)}
                  minDate={new Date()}
                  returnValue="range"
                  selectRange
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
