import React from 'react';
import { useTranslation } from 'react-i18next';
import { INBOX_CARD_TYPE, OUTBOX_CARD_TYPE } from '../../utils/constants';
import BoardColumnLabelLayer from '../../components/MiniPlaylist/NewPlaylist/CardView/BoardColumnLabelLayer';
import { convertDateListView } from '../../utils/dateConvert';
import { ReactComponent as CheckboxOnSvg } from '../../images/icons/checkbox_on.svg';
import { ReactComponent as CheckboxOffSvg } from '../../images/icons/checkbox_off.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';
import InboxPagePreview from '../Inbox/InboxPagePreview';
import styles from '../Inbox/index.module.scss';
import { calculateSingleUserAvatar } from '../Maker/components/Info/helpers';
import {
  BoardColumnWrapper,
  CardBadge,
  CardBadgeWrapper,
  CardBody,
  ImageWrap,
  ImageWrapper,
  InboxCardButtonsWrapper,
  InviteChannelName,
  Message,
  Time,
  TimeAndAvatar,
  Title,
} from '../Inbox/styled';
import OutboxRecipients from './OutboxRecipients';
import FolderIconLayout from '../Maker/FolderIconLayout';
import FolderImage from '../Maker/FolderImage';
import {
  AvailableBlockOutbox,
  CardBodyInfoOutbox,
  CardInfoChannelOutbox,
  CardInfoSizeOutbox, CardInfoWrapper, CardInfoWrapperHelpWr,
  InfoBlockOutbox,
  SelectCheckBoxWrapperOutBox,
} from './styled';
import OutboxCardButtons from './OutboxCardButtons';
import OutboxRecipientsOpened from './OutboxRecipientsOpened';
import LastUpdateDateDisplay from '../../components/LastUpdateDateDisplay/LastUpdateDateDisplay';


export const OutboxCardBody = React.memo(function InboxCardBody({
  item,
  pickCard,
  isSelected,
  setSelected,
  channels,
  user,
  pageRef,
}) {
  const { t } = useTranslation();
  const isSharedPlaylist = item.type === INBOX_CARD_TYPE.sharedPlaylist;
  const isInvite = item.type === INBOX_CARD_TYPE.invite;
  const isPage = item.type === INBOX_CARD_TYPE.page;
  const messageToDisplay = !item.recipients[0]?.sharedAvailableTo
    ? t('openToReadT') : `${t('endsT')} ${convertDateListView(item.recipients[0].sharedAvailableTo)}`;

  const isOpenToRead = messageToDisplay === t('openToReadT');
  const isCardSelected = isSelected;

  const sizeInMb = !Number.isNaN(+item.totalSizeInBytes)
    && `${(item.totalSizeInBytes / (1024 * 1024)).toFixed(2)} MB`
    || '';

  const selectItem = (e) => {
    e.stopPropagation();
    if (item.type === OUTBOX_CARD_TYPE.invite) {
      pickCard({
        id: item.id,
        type: item.type,
        isRead: item.isRead,
        inChannel: item.inChannel,
        recipients: item.recipients.map(i => ({ id: i.user.id })),
      });
    } else {
      pickCard({ id: item.id, type: item.type, isRead: item.isRead, inChannel: item.inChannel });
    }
    setSelected(!isSelected);
  };
  return (
    <CardBody>
      <CardBodyInfoOutbox>
        <div className={styles.selector_place}>

          <SelectCheckBoxWrapperOutBox isSelected={isCardSelected} onClick={selectItem}>
            {isCardSelected ? <CheckboxOnSvg /> : <CheckboxOffSvg />}
          </SelectCheckBoxWrapperOutBox>
        </div>
        {(isSharedPlaylist) && (
          <CardInfoWrapperHelpWr isHasUpdate={item.isHasUpdate}>
            <CardInfoWrapper>
              <ImageWrapper isFolderDefault={!!item.playlist.cropUrl}>
                {item.playlist.cropUrl ? (
                  <>
                    <ImageWrap src={item.playlist.cropUrl} />
                    <FolderIconLayout isOverCoverImage />
                  </>
                ) : (
                  <FolderImage isTable itemId={item.id} currentColor={item.playlist?.defaultPreviewColor} />
                )}
              </ImageWrapper>
              <Title>{item.playlist.title || t('defaultSmartFileTitleT')}</Title>
              {item.isHasUpdate && (
              <CardBadgeWrapper isPlaceHolder>
                <CardBadge isUpdate>
                  {t('updateUpT')}
                </CardBadge>
              </CardBadgeWrapper>
              )}
            </CardInfoWrapper>
            {item.isHasUpdate && (
              <CardBadgeWrapper isAbsolute>
                <CardBadge isUpdate>
                  {t('updateUpT')}
                </CardBadge>
              </CardBadgeWrapper>
            )}
          </CardInfoWrapperHelpWr>
        )}
        {isPage && <InboxPagePreview pageRef={pageRef} id={item.id} />}
        {isInvite && (
          <>
            {t('invitationToJoinT')}
            <InviteChannelName>
              {item.channelName || t('defaultChannelTitleT')}
            </InviteChannelName>
          </>
        )}
        {isPage && <Title>{item.title || t('defaultSmartPageTitleT')}</Title>}
      </CardBodyInfoOutbox>

      <CardInfoChannelOutbox>
        {item.recipients && (
        <OutboxRecipients
          isPage={isPage}
          id={item.id}
          channels={channels}
          recipients={item.recipients}
        />
        )}
      </CardInfoChannelOutbox>

      <InfoBlockOutbox>
        {!isInvite && (<> {item.opened} </>)}
        {isInvite && <OutboxRecipientsOpened opened={item.opened} recipients={item.recipients} />}

      </InfoBlockOutbox>
      <CardInfoSizeOutbox isRight>
        {!isInvite && (<> { sizeInMb } </>)}
      </CardInfoSizeOutbox>

      <AvailableBlockOutbox isRight>
        {!isInvite && (<Message isOpenToRead={isOpenToRead}>{`${messageToDisplay} `}</Message>)}
      </AvailableBlockOutbox>

      <BoardColumnWrapper>
        {(isSharedPlaylist) && (
          <Tooltip text={t('selectAColumnT')} direction="down">
            <BoardColumnLabelLayer isInboxCard item={item} />
          </Tooltip>
        )}
      </BoardColumnWrapper>

      <TimeAndAvatar>
        <Time> {item.date && <LastUpdateDateDisplay date={item.date} /> } </Time>
        <div className={styles.avatar_wrapper}>
          {calculateSingleUserAvatar(item.userWhoLastUpdate || user, 22)}
        </div>
      </TimeAndAvatar>

      <InboxCardButtonsWrapper>
        <OutboxCardButtons item={item} />
      </InboxCardButtonsWrapper>

    </CardBody>
  );
});

export default OutboxCardBody;
