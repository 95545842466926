import React from 'react';
import { ReactComponent as PageRecentSvg } from '../images/icons/emptyIcon/pageRecent.svg';
import { ReactComponent as ComponentSvg } from '../images/icons/emptyIcon/component.svg';
import { ReactComponent as LinkSvg } from '../images/icons/emptyIcon/link.svg';
import { ReactComponent as PlusSvg } from '../images/icons/add_component.svg';
import { ReactComponent as AddPlaylistSvg } from '../images/icons/addNew/addPlaylist.svg';
import { ReactComponent as UploadSvg } from '../images/icons/addNew/upload.svg';
import { ReactComponent as PlaylistsIcon } from '../images/2023/svg/small/left-main-menu/playlist_icon_16_16.svg';

import { ReactComponent as RecentIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/recent.svg';
import { ReactComponent as FavoriteIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/favorites.svg';
import { ReactComponent as DraftsIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/drafts.svg';
import { ReactComponent as SharedIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/shared.svg';

import { ReactComponent as InboxIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/inbox_icon.svg';
import { ReactComponent as OverviewIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/overview_icon.svg';
import { ReactComponent as PageIcon } from '../images/2023/svg/small/left-main-menu/page_icon_16_16.svg';
import { ReactComponent as LibraryIcon } from '../images/2023/svg/small/left-main-menu/library_icon_16_16.svg';
import { ReactComponent as ComponentIcon } from '../images/2023/svg/small/left-main-menu/component_icon_16_16.svg';
import { ReactComponent as MediaIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/media.svg';
import { ReactComponent as PdfIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/pdf.svg';
import { ReactComponent as LinksIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/links.svg';
import { ReactComponent as FilesIcon } from '../images/2023/svg/small/left-main-menu/fill_icons/other.svg';
import { ReactComponent as Files2023Icon } from '../images/2023/svg/Files_64_64.svg';
import { ReactComponent as TagIcon } from '../images/icons/tag.svg';
import { ReactComponent as SmartIcon } from '../images/icons/smartView.svg';
import { ReactComponent as Page2023Ico } from '../images/2023/svg/Page_54_66.svg';
import { ReactComponent as Playlist2023Ico } from '../images/2023/svg/Playlist_70_70.svg';
import { ReactComponent as Component2023Ico } from '../images/2023/svg/Component_60_66.svg';
import { ReactComponent as PDF2023Ico } from '../images/2023/svg/PDF_80_80.svg';
import { ReactComponent as Media2023Ico } from '../images/2023/svg/Media_60_60.svg';
import { ReactComponent as Link2023Ico } from '../images/2023/svg/Link_66_66.svg';
import { ReactComponent as File2023Ico } from '../images/2023/svg/File_54_66.svg';
import { ReactComponent as Help65Svg } from '../images/icons/help_icon_65_61.svg';

import { NextAdd, PlaylistNew } from './styledComponent';
import { createNewPlaylistAndJumpIntoIt, createPage } from '../utils/helpers';
import { actionOpenModal } from '../redux/user/action';
import { openModalType } from '../utils/constants';
import i18n from '../i18n';

const newItem = ({
  text,
  icon = <UploadSvg />,
  hasPlus = true,
  width = 170,
  height = 170,
}) => (
  <PlaylistNew width={`${width}px`} height={`${height}px`}>
    {icon}
    <NextAdd>
      {hasPlus && <PlusSvg />}
      {text}
    </NextAdd>
  </PlaylistNew>
);

export const SideBarNavigate = {
  tag: {
    name: 'Tag view',
    selectorsOrder: ['all'],
    selectorsOrderInSideBar: ['all'],
    selectors: {
      all: {
        empty: {
          main: i18n.t('noContentForTagT'),
          text: i18n.t('emptyMessage1T'),
          icon: <TagIcon style={{ width: 24, height: 24, marginTop: 50 }} />,
        },
      },
    },
  },
  dynamicCollection: {
    name: 'Smart view',
    selectorsOrder: ['all'],
    selectorsOrderInSideBar: ['all'],
    selectors: {
      all: {
        empty: {
          main: i18n.t('noContentForSmartviewT'),
          text: i18n.t('emptyMessage2T'),
          icon: <SmartIcon style={{ width: 24, height: 24, marginTop: 50 }} />,
        },
      },
    },
  },
};
//
export const ExtraTabs = {
  overview: {
    name: 'Overview',
    textID: 'overviewT',
    icon: <OverviewIcon />,
    selectorsOrder: [
      '',
    ],
    disabled: true,
  },
  inbox: {
    name: 'Inbox',
    textID: 'inboxT',
    icon: <InboxIcon />,
    selectorsOrder: [
      '',
    ],
  },
  // outbox: {
  //   name: 'Outbox',
  //   textID: 'outboxT',
  //   icon: <OutboxIcon />,
  //   selectorsOrder: [
  //     '',
  //   ],
  //   // disabled: true,
  // },
  // board: {
  //   name: 'Board',
  //   textID: 'boardT',
  //   icon: <KanbanIcon />,
  //   selectorsOrder: [
  //     '',
  //   ],
  // },
};

export const MainNavigate = {
  smartfiles: {
    name: 'SmartFiles',
    textID: 'smartfilesT',
    icon: <PlaylistsIcon />,
    actionCreateNew: ({ isAdmin, history, dispatch }) => {
      createNewPlaylistAndJumpIntoIt({ history, isAdmin, dispatch });
    },
    sidebarSelectors: {
      recently_viewed: {
        icon: <RecentIcon />,
        name: 'Recent',
        textID: 'recentT',
      },
      favorites: {
        icon: <FavoriteIcon />,
        name: 'Favorites',
        textID: 'favoritesT',
      },
      drafts: {
        icon: <DraftsIcon />,
        name: 'Drafts',
        textID: 'draftsT',
      },
      sharedByMe: {
        icon: <SharedIcon />,
        name: 'Shared',
        textID: 'sharedT',
      },
    },
    selectorsOrder: [
      'recently_viewed',
      'favorites',
      'drafts',
      'sharedByMe',
    ],
    selectorsTabsExtensions: {
      recent: {
        'Recently viewed': 'recently_viewed',
      },
      favorites: {
        favorites: 'favorites',
      },
      drafts: {
        drafts: 'drafts',
      },
      shared: {
        shared: 'sharedByMe',
        'Shared with me': 'shared',
      },
    },
    selectorsTabsOrder: {
      'Recently viewed': 'recently_viewed',
      favorites: 'favorites',
      drafts: 'drafts',
      shared: 'sharedByMe',
      'Shared with me': 'shared',
    },
    selectors: {
      all: {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage3T'),
          icon: <Playlist2023Ico />,
        },
        new: newItem({
          text: 'SmartFile',
          icon: <AddPlaylistSvg />,
          width: 271,
          height: 180,
        }),
      },
      new: {
        empty: {
          main: 'NO SMARTFILES',
          text: i18n.t('emptyMessage4T'),
          icon: <Playlist2023Ico />,
        },
      },
      drafts: {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage5T'),
          text2: i18n.t('emptyMessage6T'),
          icon: <Playlist2023Ico />,
        },
        new: newItem({
          text: 'SmartFile',
          icon: <AddPlaylistSvg />,
          width: 271,
          height: 180,
        }),
      },
      favorites: {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage7T'),
          icon: <Playlist2023Ico />,
        },
      },
      shared: {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage8T'),
          icon: <Playlist2023Ico />,
        },
      },
      sharedByMe: {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage9T'),
          icon: <Playlist2023Ico />,
        },
      },
      board: {
        empty: {
          main: i18n.t('noSharesUpT'),
          text: i18n.t('emptyMessage10T'),
          icon: <Playlist2023Ico />,
        },
      },
      recently_viewed: {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage11T'),
          icon: <Playlist2023Ico />,
        },
      },
      recently_edited: {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage12T'),
          icon: <Playlist2023Ico />,
        },
      },
    },
  },
  // pages: {
  //   name: 'Pages',
  //   textID: 'pagesT',
  //   icon: <PageIcon />,
  //   actionCreateNew: ({ history, dispatch, selectorType, tag }) => {
  //     createPage({ selectorType, dispatch, tag, history });
  //   },
  //   selectorsOrder: ['all', 'drafts', 'favorites', 'shared', 'sharedByMe'],
  //   selectorsOrderInSideBar: [
  //     'all',
  //     'drafts',
  //     'favorites',
  //     'shared',
  //     'sharedByMe',
  //     'used',
  //   ],
  //   selectors: {
  //     all: {
  //       empty: {
  //         main: i18n.t('noPagesUpT'),
  //         text: i18n.t('emptyMessage13T'),
  //         icon: <Page2023Ico />,
  //       },
  //       new: newItem({
  //         icon: <PageRecentSvg />,
  //         text: 'PAGE',
  //         height: 250,
  //       }),
  //       newSideBar: newItem({
  //         icon: <PageRecentSvg />,
  //         text: 'PAGE',
  //         height: 250,
  //       }),
  //     },
  //     drafts: {
  //       empty: {
  //         main: i18n.t('noPagesUpT'),
  //         text: i18n.t('emptyMessage14T'),
  //         icon: <Page2023Ico />,
  //       },
  //       new: newItem({
  //         icon: <PageRecentSvg />,
  //         text: 'PAGE',
  //         height: 250,
  //       }),
  //       newSidebar: newItem({
  //         icon: <PageRecentSvg />,
  //         text: 'PAGE',
  //         width: 162,
  //         height: 256,
  //       }),
  //     },
  //     favorites: {
  //       empty: {
  //         main: i18n.t('noPagesUpT'),
  //         text: i18n.t('emptyMessage15T'),
  //         icon: <Page2023Ico />,
  //       },
  //     },
  //     shared: {
  //       empty: {
  //         main: i18n.t('noPagesUpT'),
  //         text: i18n.t('emptyMessage16T'),
  //         icon: <Page2023Ico />,
  //       },
  //     },
  //     sharedByMe: {
  //       empty: {
  //         main: i18n.t('noPagesUpT'),
  //         text: i18n.t('emptyMessage17T'),
  //         icon: <Page2023Ico />,
  //       },
  //     },
  //   },
  // },
  library: {
    name: 'Library',
    textID: 'libraryT',
    icon: <LibraryIcon />,
    selectorsOrder: ['all', 'favorites'],
    selectorsOrderInSideBar: ['all', 'favorites', 'used'],
    selectors: {
      all: {
        empty: {
          main: i18n.t('noFilesUpT'),
          text: i18n.t('emptyMessage18T'),
          icon: <Files2023Icon />,
        },
      },
      favorites: {
        empty: {
          main: i18n.t('noFilesUpT'),
          text: i18n.t('emptyMessage19T'),
          icon: <Files2023Icon />,
        },
      },
    },
  },
  media: {
    name: 'Media',
    textID: 'mediaT',
    isLibraryItem: true,
    icon: <MediaIcon />,

    selectorsOrder: ['all', 'favorites', 'images', 'video', 'audio'],
    selectorsOrderInSideBar: [
      'all',
      'favorites',
      'images',
      'video',
      'audio',
      'used',
    ],
    selectors: {
      all: {
        empty: {
          main: i18n.t('noMediaUpT'),
          text: i18n.t('emptyMessage20T'),
          icon: <Media2023Ico />,
        },
        new: newItem({
          text: 'upload',
          hasPlus: false,
          height: 170,
          width: 170,
        }),
        newSideBar: newItem({
          text: 'upload',
          hasPlus: false,
          width: 160,
          height: 160,
        }),
      },
      favorites: {
        empty: {
          main: i18n.t('noMediaUpT'),
          text: i18n.t('emptyMessage21T'),
          icon: <Media2023Ico />,
        },
      },
      images: {
        empty: {
          main: i18n.t('noMediaUpT'),
          text: i18n.t('emptyMessage22T'),
          icon: <Media2023Ico />,
        },
        new: newItem({
          text: 'upload',
          hasPlus: false,
        }),
        newSideBar: newItem({
          text: 'upload',
          hasPlus: false,
          width: 160,
          height: 160,
        }),
      },
      video: {
        empty: {
          main: i18n.t('noMediaUpT'),
          text: i18n.t('emptyMessage23T'),
          icon: <Media2023Ico />,
        },
        new: newItem({
          text: 'upload',
          hasPlus: false,
        }),
        newSideBar: newItem({
          text: 'upload',
          hasPlus: false,
          width: 160,
          height: 160,
        }),
      },
      audio: {
        empty: {
          main: i18n.t('noMediaUpT'),
          text: i18n.t('emptyMessage24T'),
          icon: <Media2023Ico />,
        },
        new: newItem({
          text: 'upload',
          hasPlus: false,
        }),
        newSideBar: newItem({
          text: 'upload',
          hasPlus: false,
          width: 160,
          height: 160,
        }),
      },
    },
  },
  pdf: {
    name: 'PDF',
    textID: 'pdfT',
    isLibraryItem: true,
    icon: <PdfIcon />,
    selectorsOrder: ['all', 'favorites'],
    selectorsOrderInSideBar: ['all', 'favorites', 'used'],
    selectors: {
      all: {
        empty: {
          main: i18n.t('noPDFUpT'),
          text: i18n.t('emptyMessage25T'),
          icon: <PDF2023Ico />,
        },
        new: newItem({
          text: 'upload',
          height: 250,
          hasPlus: false,
        }),
        newSideBar: newItem({
          text: 'upload',
          height: 233,
          hasPlus: false,
          width: 160,
        }),
      },
      favorites: {
        empty: {
          main: i18n.t('noPDFUpT'),
          text: i18n.t('emptyMessage26T'),
          icon: <PDF2023Ico />,
        },
      },
    },
  },
  links: {
    name: 'Links',
    textID: 'linksT',
    isLibraryItem: true,
    icon: <LinksIcon />,
    actionCreateNew: ({ dispatch }) => {
      dispatch(actionOpenModal(openModalType.CreateLinkModal));
    },
    selectorsOrder: ['all', 'favorites', 'url', 'embed'],
    selectorsOrderInSideBar: ['all', 'favorites', 'url', 'embed', 'used'],
    selectors: {
      all: {
        empty: {
          main: i18n.t('noLinksUpT'),
          text: i18n.t('emptyMessage27T'),
          icon: <Link2023Ico />,
        },
        new: newItem({
          icon: <LinkSvg />,
          text: 'links',
        }),
        newSideBar: newItem({
          icon: <LinkSvg />,
          text: 'links',
          width: 160,
          height: 160,
        }),
      },
      favorites: {
        empty: {
          main: i18n.t('noLinksUpT'),
          text: i18n.t('emptyMessage28T'),
          icon: <Link2023Ico />,
        },
      },
      url: {
        empty: {
          main: i18n.t('noLinksUpT'),
          text: i18n.t('emptyMessage29T'),
          icon: <Link2023Ico />,
        },
        new: newItem({
          icon: <LinkSvg />,
          text: 'links',
        }),
        newSideBar: newItem({
          icon: <LinkSvg />,
          text: 'links',
          width: 160,
          height: 160,
        }),
      },
      embed: {
        empty: {
          main: i18n.t('noLinksUpT'),
          text: i18n.t('emptyMessage30T'),
          icon: <Link2023Ico />,
        },
        new: newItem({ icon: <LinkSvg />, text: 'links' }),
        newSideBar: newItem({
          icon: <LinkSvg />,
          text: 'links',
          width: 160,
          height: 160,
        }),
      },
    },
  },
  // components: {
  //   name: 'Components',
  //   textID: 'componentsT',
  //   icon: <ComponentIcon />,
  //   isLibraryItem: true,
  //   actionCreateNew: ({ dispatch }) => {
  //     dispatch(actionOpenCreateComponent());
  //   },
  //   selectorsOrder: ['all', 'favorites'],
  //   selectors: {
  //     all: {
  //       empty: {
  //         main: i18n.t('noComponentsUpT'),
  //         text: i18n.t('emptyMessage31T'),
  //         icon: <Component2023Ico />,
  //       },
  //       new: newItem({
  //         icon: <ComponentSvg />,
  //         text: 'component',
  //       }),
  //       newSideBar: newItem({
  //         icon: <ComponentSvg />,
  //         text: 'component',
  //         width: 160,
  //         height: 160,
  //       }),
  //     },
  //     favorites: {
  //       empty: {
  //         main: i18n.t('noComponentsUpT'),
  //         text: i18n.t('emptyMessage32T'),
  //         icon: <Component2023Ico />,
  //       },
  //     },
  //   },
  //   selectorsOrderInSideBar: ['all', 'favorites', 'used'],
  // },
  files: {
    name: 'Files',
    textID: 'filesT',
    isCanHide: true,
    isLibraryItem: true,
    icon: <FilesIcon />,
    selectorsOrder: ['all', 'favorites'],
    selectorsOrderInSideBar: ['all', 'favorites', 'used'],
    selectors: {
      all: {
        empty: {
          main: i18n.t('noFilesUpT'),
          text: i18n.t('emptyMessage33T'),
          icon: <File2023Ico />,
        },
        new: newItem({
          text: 'upload',
          hasPlus: false,
        }),
        newSideBar: newItem({
          text: 'upload',
          hasPlus: false,
          width: 160,
          height: 160,
        }),
      },
      favorites: {
        empty: {
          main: i18n.t('noFilesUpT'),
          text: i18n.t('emptyMessage34T'),
          icon: <File2023Ico />,
        },
      },
    },
  },
  '[Channels]All': {
    isCanHide: true,
    selectorsOrder: ['all', 'favorites'],
    selectors: {
      '[Channels]All': {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage35T'),
          icon: <Playlist2023Ico />,
        },
      },
    },
  },
  '[Channels]Favorite': {
    isCanHide: true,
    selectorsOrder: ['all', 'favorites'],
    selectors: {
      '[Channels]Favorite': {
        empty: {
          main: i18n.t('noSmartfilesUpT'),
          text: i18n.t('emptyMessage36T'),
          icon: <Playlist2023Ico />,
        },
      },
    },
  },

  // upload: {
  //   name: 'Upload',
  // },
};

export const HelpNavigate = {
  name: 'Help',
  textID: 'helpT',
  icon: <Help65Svg width={20} height={20} />,
};
