import { Library, LibraryCollections, LibraryComponents } from './types';
import { ServiceUser } from '../user/types';
import { LibCompSieveTypes, PLACE, typesOptions } from '../../utils/constants';
import { updateLibraryStateWithComponents } from '../../utils/helpers';
import { CurrentPage, EDIT_TITLE } from '../currentPage/types';
import downloadStatus from '../../utils/dataUtil';

const defaultState = {
  selector: 'all',
  activeSieveOption: LibCompSieveTypes.Pages,
  isOpenCreateComponent: false,
  sieveOptions: typesOptions,
  components: {},
  collections: {},
  dragState: {},
  draggableItemsExtraParams: {},
  draggableItems: [],
  shared: { status: downloadStatus.init },
  isUploadingFiles: false,
};

export const libraryReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    // GeneralLibrary
    case Library.ChangeSieveValue: {
      if (payload.place === PLACE.Library) return { ...state, activeSieveOption: payload.value };
      return state;
    }
    case Library.ChangeSideBarSelector: {
      return { ...state, selector: payload };
    }
    // LibraryComponents
    // CREATE

    case LibraryComponents.AddComponentToCollectionREDUX: {
      const newState = {
        ...state,
        collections: {
          ...state.collections,
          [payload.collectionId]: {
            ...state.collections[payload.collectionId],
            nestedPage: {
              ...state.collections[payload.collectionId].nestedPage,
              [payload.id]: { ...payload, itemType: 'component' },
            },
          },
        },
      };
      delete newState.collections[payload.collectionId].nestedPage[payload.id]
        .componentId;
      return newState;
    }
    case LibraryComponents.BulkAddComponent: {
      return { ...state, components: { ...state.components, ...payload } };
    }
    case LibraryComponents.AddComponentToDragState: {
      const newState = { ...state };
      if (payload.collectionId) {
        newState.dragState = {
          ...newState.dragState,
          [payload.collectionId]: {
            ...newState.dragState[payload.collectionId],
            [payload.componentId]: true,
          },
        };
      } else newState.dragState[payload.componentId] = true;

      if (payload.wrapperId) {
        newState.draggableItemsExtraParams = {
          [payload.wrapperId]: true,
        };
      }
      return { ...newState };
    }
    case LibraryComponents.AddLinkPagesToDragState: {
      const newState = { ...state };
      newState.draggableItems = payload.linkPages;
      return { ...newState };
    }
    case LibraryComponents.ClearDragState: {
      return { ...state, dragState: {} };
    }

    case LibraryComponents.UpdateLibraryComponentInCollectionRedux: {
      const now = Math.round(new Date().getTime() / 1000);
      const { id } = payload;
      const newState = { ...state };

      return {
        ...newState,
        components: {
          ...newState.components,
          [id]: {
            ...newState.components[id],
            ...payload,
            lastModifiedDate: now,
          },
        },
      };
    }
    case LibraryComponents.UpdateLibraryComponent: {
      const now = Math.round(new Date().getTime() / 1000);
      const { id, value, field, collectionId } = payload;
      if (collectionId) {
        state.collections[collectionId].nestedPage[id] = {
          ...state.collections[collectionId].nestedPage[id],
          [field]: value,
          lastModifiedDate: now,
        };
        return { ...state, collections: { ...state.collections } };
      }
      return {
        ...state,
        components: {
          ...state.components,
          [id]: {
            ...state.components[id],
            [field]: value,
            lastModifiedDate: now,
          },
        },
      };
    }
    case EDIT_TITLE: {
      const { title, componentId, inCollectionId } = payload;
      const lastModifiedDate = Math.floor(Date.now() / 1000);

      if (!componentId) return state;

      const reduxComponent = inCollectionId
        ? state.collections[inCollectionId].nestedPage[componentId]
        : state.components[componentId];
      if (!inCollectionId) {
        return {
          ...state,
          components: {
            ...state.components,
            [componentId]: {
              ...reduxComponent,
              lastModifiedDate,
              title,
            },
          },
        };
      }
      return {
        ...state,
        collections: {
          ...state.collections,
          [inCollectionId]: {
            ...state.collections[inCollectionId],
            nestedPage: {
              ...state.collections[inCollectionId].nestedPage,
              [componentId]: {
                ...reduxComponent,
                lastModifiedDate,
                title,
              },
            },
          },
        },
      };
    }
    case LibraryComponents.MoveComponentToTrash: {
      const { componentId, folderId } = payload;
      if (folderId) {
        state.components[componentId] = {
          ...state.collections[folderId].nestedPage[componentId],
          movedToTrash: true,
        };
        delete state.collections[folderId].nestedPage[componentId];
        return { ...state, collections: { ...state.collections } };
      }
      return {
        ...state,
        components: {
          ...state.components,
          [componentId]: {
            ...state.components[componentId],
            movedToTrash: true,
          },
        },
      };
    }
    case LibraryComponents.RestoreComponentFromTrash: {
      const { componentId, folderId } = payload;
      if (folderId) {
        state.components[componentId] = {
          ...state.collections[folderId].nestedPage[componentId],
          movedToTrash: false,
        };
        delete state.collections[folderId].nestedPage[componentId];
        return { ...state, collections: { ...state.collections } };
      }
      return {
        ...state,
        components: {
          ...state.components,
          [componentId]: {
            ...state.components[componentId],
            movedToTrash: false,
          },
        },
      };
    }
    // DELETE
    case LibraryComponents.DeleteComponentREDUX: {
      const result = { ...state, components: { ...state.components } };
      delete result.components[payload.id];
      return result;
    }
    case LibraryComponents.DeleteComponentInCollectionREDUX: {
      const result = { ...state, collections: { ...state.collections } };
      delete result.collections[payload.folderId].nestedPage[payload.id];
      return result;
    }

    // LibraryCollections
    // CREATE
    case LibraryCollections.CreateCollection: {
      return {
        ...state,
        collections: {
          ...state.collections,
          [payload.id]: {
            ...payload,
            nestedPages: [],
            itemType: 'collection',
          },
        },
      };
    }

    case LibraryCollections.BulkAddCollections: {
      return { ...state, collections: { ...state.collections, ...payload } };
    }
    case LibraryComponents.UpdatePdf: {
      const { currentPageId, relationId, fieldsArr, valuesArr } = action.payload;
      const reduxComponent = state.components[currentPageId];
      const index = reduxComponent?.components?.findIndex(
        (item) => item.id === relationId,
      );

      const currentBlock = reduxComponent?.components && reduxComponent.components[index];
      if (currentBlock) {
        const needUpdateKeys = [];
        const needUpdateValues = [];
        if (fieldsArr.length) {
          fieldsArr.forEach((item, itemIndex) => {
            if (
              currentBlock?.state?.data
              && currentBlock?.state.data[item] !== valuesArr[itemIndex]
            ) {
              needUpdateKeys.push(item);
              needUpdateValues.push(valuesArr[itemIndex]);
            }
          });
        }

        delete currentBlock.isNew;
        needUpdateKeys.forEach((item, itemIndex) => {
          currentBlock.state.data[item] = needUpdateValues[itemIndex];
        });
        return { ...state };
      }
      return state;
    }
    case LibraryCollections.ChangeCollectionIsNewReduxOnly: {
      if (payload.isNewOnButton) {
        return {
          ...state,
          collections: {
            ...state.collections,
            [payload.id]: {
              ...state.collections[payload.id],
              isNewOnButton: false,
            },
          },
        };
      }
      return {
        ...state,
        collections: {
          ...state.collections,
          [payload.id]: {
            ...state.collections[payload.id],
            isNew: false,
          },
        },
      };
    }

    case LibraryCollections.AddComponentToCollection: {
      const { collection, component } = action.payload;
      return {
        ...state,
        collections: {
          ...state.collections,
          [collection.id]: {
            ...state.collections[collection.id],
            nestedPage: {
              ...state.collections[collection.id].nestedPage,
              [component.id]: { ...component },
            },
          },
        },
      };
    }
    // UPDATE
    case LibraryCollections.RenameLibraryCollectionRedux: {
      const { id, name } = payload;
      return {
        ...state,
        collections: {
          ...state.collections,
          [id]: { ...state.collections[id], title: name },
        },
      };
    }
    // DELETE
    case LibraryCollections.MoveCollectionToTrash: {
      const { collectionId } = payload;
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...state.collections[collectionId],
            movedToTrash: true,
          },
        },
      };
    }
    case LibraryCollections.RemoveComponentREDUX: {
      const { collectionId, componentId } = payload;
      const newNested = { ...state.collections[collectionId].nestedPage };
      delete newNested[componentId];
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...state.collections[collectionId],
            nestedPage: newNested,
          },
        },
      };
    }
    case LibraryCollections.UpdateCollectionsREDUX: {
      return { ...state, collections: { ...state.collections, ...payload } };
    }
    case LibraryCollections.AddCollectionREDUX: {
      return {
        ...state,
        collections: { ...state.collections, [payload.id]: { ...payload } },
      };
    }
    // DELETE
    case LibraryCollections.DeleteCollectionREDUX: {
      const result = { ...state, collections: { ...state.collections } };
      delete result.collections[payload.id];
      return result;
    }
    case ServiceUser.switchFolder: {
      return { ...state, selector: 'all' };
    }
    // case Tags.DeleteTag: {
    //   const { tag, type: localType, parent } = payload;
    //   if (localType !== 'LibraryComponent' || !state.components[parent.id]) return state;
    //   state.components[parent.id].tags = state.components[parent.id]?.tags.filter((item) => item.id !== tag.id);
    //   state.components[parent.id].lastModifiedDate = parent.lastModifiedDate;
    //   return { ...state, components: { ...state.components }, collections: { ...state.collections } };
    // }
    // case Tags.actionAttachTagParentRedux: {
    //   const { tag, type: localType, parent, lastModifiedDate } = payload;
    //   if (localType !== 'LibraryComponent') return state;
    //   const newState = { ...state };
    //   newState.components[parent.id].tags = [...(newState.components[parent.id].tags || []), tag];
    //   newState.components[parent.id].lastModifiedDate = lastModifiedDate;
    //   return newState;
    // }
    //
    // case Tags.attachTagParentAndCurrentPageRedux: {
    //   const { tag, type: localType, parent, lastModifiedDate } = payload;
    //   if (localType !== 'LibraryComponent') return state;
    //   state.components[parent.id].tags = [...(state.components[parent.id].tags || []), tag];
    //   state.components[parent.id].lastModifiedDate = lastModifiedDate;
    //   return { ...state, components: { ...state.components }, collections: { ...state.collections } };
    // }
    // case Tags._TagsBulkUpdate: {
    //   const { tags, type: localType, componentId, folderId, lastModifiedDate } = payload;
    //   if (localType !== 'LibraryComponent') return state;
    //   if (folderId) {
    //     state.collections[folderId]
    //       .nestedPage[componentId].tags = tags;
    //     state.collections[folderId].nestedPage[componentId].lastModifiedDate = lastModifiedDate;
    //   } else {
    //     state.components[componentId].tags = tags;
    //     state.components[componentId].lastModifiedDate = lastModifiedDate;
    //   }
    //   return { ...state, components: { ...state.components }, collections: { ...state.collections } };
    // }
    // case Tags.ChangeTagTitle: {
    //   const { id, newTitle, type: localType, parent, libraryComponentId } = payload;
    //
    //   if (localType !== 'LibraryComponent' && !libraryComponentId) return state;
    //   if (parent && parent.folderId) {
    //     state.collections[parent.folderId]
    //       .nestedPage[parent.componentId].tags = state.collections[parent.folderId]
    //         .nestedPage[parent.componentId].tags.map(item => {
    //           if (item.id === id) {
    //             return { ...item, title: newTitle };
    //           }
    //           return item;
    //         });
    //   } else if (libraryComponentId && state.components[libraryComponentId]) {
    //     state.components[libraryComponentId].tags = state.components[libraryComponentId].tags.map(item => {
    //       if (item.id === id) {
    //         return { ...item, title: newTitle };
    //       }
    //       return item;
    //     });
    //   }
    //   return { ...state, components: { ...state.components }, collections: { ...state.collections } };
    // }
    // case Tags.ChangeTagColor: {
    //   const { id, newColor, type: localType, parent, newBackgroundColor, libraryComponentId } = payload;
    //   if (localType !== 'LibraryComponent' && !libraryComponentId) return state;
    //   if (parent && parent?.folderId) {
    //     state.collections[parent.folderId]
    //       .nestedPage[parent.componentId].tags = state.collections[parent.folderId]
    //         .nestedPage[parent.componentId].tags.map(item => {
    //           if (item.id === id) {
    //             const newItem = { ...item };
    //             if (newColor) newItem.color = newColor;
    //             if (newBackgroundColor) newItem.backgroundColor = newBackgroundColor;
    //             return newItem;
    //           }
    //           return item;
    //         });
    //   } else if (libraryComponentId && state.components[libraryComponentId]) {
    //     state.components[libraryComponentId].tags = state.components[libraryComponentId].tags.map(item => {
    //       if (item.id === id) {
    //         const newItem = { ...item };
    //         if (newColor) newItem.color = newColor;
    //         if (newBackgroundColor) newItem.backgroundColor = newBackgroundColor;
    //         return newItem;
    //       }
    //       return item;
    //     });
    //   }
    //   return { ...state, components: { ...state.components }, collections: { ...state.collections } };
    // }

    case LibraryComponents.GetComponent: {
      const { id, folderId } = payload;
      if (folderId) {
        // state.collections[folderId].nestedPage[id].pending = true;
        return { ...state, collections: { ...state.collections } };
      }
      return {
        ...state,
        components: {
          ...state.components,
          [id]: { ...state.components[id] },
        },
      };
    }
    case LibraryComponents.SaveComponent: {
      const { component } = payload;

      if (component.folderId) {
        state.collections[component.folderId].nestedPage[component.id] = {
          ...component,
        };
        return { ...state, collections: { ...state.collections } };
      }
      return {
        ...state,
        components: {
          ...state.components,
          [component.id]: { ...component },
        },
      };
    }
    case LibraryComponents.GetComponentsClear: {
      return { ...state, collections: { ...state.collections } };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    case LibraryComponents.Pages.DeleteLibraryPageComponentR: {
      const { id, deleteComponentId, inCollectionId } = payload;

      const reduxComponent = inCollectionId
        ? state.collections[inCollectionId].nestedPage[id]
        : state.components[id];

      const changedComponents = [...(reduxComponent?.components || [])].filter(
        (item) => item.id !== deleteComponentId,
      );

      const lastModifiedDate = Math.floor(Date.now() / 1000);

      return updateLibraryStateWithComponents(
        state,
        id,
        inCollectionId,
        changedComponents,
        lastModifiedDate,
      );
    }
    case LibraryComponents.Pages.DeleteManyLibraryPageComponentR: {
      const { currentPageId: id, pageComponents, inCollectionId } = payload;

      const reduxComponent = inCollectionId
        ? state.collections[inCollectionId].nestedPage[id]
        : state.components[id];

      const deleteIds = pageComponents.map((innerItem) => innerItem.id);

      const changedComponents = [...(reduxComponent?.components || [])].filter(
        (item) => {
          return !deleteIds.includes(item.id);
        },
      );

      const lastModifiedDate = Math.floor(Date.now() / 1000);

      return updateLibraryStateWithComponents(
        state,
        id,
        inCollectionId,
        changedComponents,
        lastModifiedDate,
      );
    }
    case LibraryComponents.Pages.ChangeManyLibraryPageComponentPositionR: {
      const { currentPageId, pageComponents, inCollectionId } = payload;

      const reduxComponent = inCollectionId
        ? state.collections[inCollectionId].nestedPage[currentPageId]
        : state.components[currentPageId];

      const items = pageComponents.reduce((acc, pageItem) => {
        acc[pageItem.id] = pageItem;
        return acc;
      }, {});
      const changedComponents = [...(reduxComponent?.components || [])].map(
        (item) => {
          if (items[item.id]) {
            return { ...item, position: items[item.id].position };
          }
          return item;
        },
      );

      const lastModifiedDate = Math.floor(Date.now() / 1000);

      return updateLibraryStateWithComponents(
        state,
        currentPageId,
        inCollectionId,
        changedComponents,
        lastModifiedDate,
      );
    }
    // case LibraryComponents.Pages.AddLibraryPageComponentR: {
    //   const { id, newComponent } = payload;
    //
    //   const reduxComponent = state.components[id];
    //
    //   let changedComponents = [];
    //   if (!reduxComponent?.components) {
    //     changedComponents = [newComponent];
    //   } else if (
    //     !reduxComponent.components.includes(
    //       (item) => item.id === newComponent.id,
    //     )
    //   ) {
    //     changedComponents = [...reduxComponent.components, newComponent];
    //   }
    //
    //   const lastModifiedDate = Math.floor(Date.now() / 1000);
    //
    //   return updateLibraryStateWithComponents(
    //     state,
    //     id,
    //     null,
    //     changedComponents,
    //     lastModifiedDate,
    //   );
    // }

    case LibraryComponents.Pages.AddLibraryPageManyComponentR: {
      const { id, newComponents } = payload;

      const reduxComponent = state.components[id];

      let changedComponents = [];
      if (!reduxComponent?.components) {
        changedComponents = newComponents;
      } else {
        changedComponents = [...reduxComponent.components, ...newComponents];
      }

      const lastModifiedDate = Math.floor(Date.now() / 1000);

      return updateLibraryStateWithComponents(
        state,
        id,
        null,
        changedComponents,
        lastModifiedDate,
      );
    }
    case LibraryComponents.Pages.MoveToTrashLibraryPageRS: {
      if (
        payload.folderId
        && state.collections[payload.folderId]?.nestedPage[
          payload.idPageMovedToTrash
        ]
      ) {
        state.collections[payload.idPageMovedToTrash] = {
          ...state.collections[payload.folderId].nestedPage[
            payload.idPageMovedToTrash
          ],
          movedToTrash: true,
        };

        delete state.collections[payload.folderId].nestedPage[
          payload.idPageMovedToTrash
        ];
      } else if (state.components[payload.idPageMovedToTrash]) {
        state.components[payload.idPageMovedToTrash].movedToTrash = true;
      }
      return { ...state };
    }

    case LibraryComponents.Pages.MoveFromTrashLibraryPageRS: {
      state.components[payload.idPageMovedToTrash].position = payload.position;
      state.components[payload.idPageMovedToTrash].movedToTrash = false;
      return { ...state };
    }
    case LibraryComponents.UpdateState: {
      return { ...payload };
    }
    case LibraryComponents.Pages.updateLibraryPageDescription: {
      const {
        libraryPage: { field, value, id },
      } = payload;
      if (field === 'title') {
        return {
          ...state,
          components: {
            ...state.components,
            [id]: { ...state.components[id], title: value },
          },
        };
      }
      return { ...state };
    }
    case LibraryComponents.Pages.UpdatePageLastModified: {
      const { id, lastModifiedDate } = payload;

      if (state.components[id]) {
        const newState = { ...state, components: { ...state.components } };
        newState.components[id].lastModifiedDate = lastModifiedDate;
        return { ...newState };
      }
      return state;
    }
    // case Tags.AttachMultipleTagsToLibraryComponent: {
    //   const { tags, libraryComponent, lastModifiedDate } = payload;
    //
    //   return {
    //     ...state,
    //     components: {
    //       ...state.components,
    //       [libraryComponent.id]: {
    //         ...state.components[libraryComponent.id],
    //         tags: [
    //           ...state.components[libraryComponent.id].tags,
    //           ...tags,
    //         ],
    //         lastModifiedDate,
    //       } } };
    // }
    // case Tags.ReplaceTagsInLibraryComponent: {
    //   const { tags, libraryComponent, lastModifiedDate } = payload;
    //
    //   return {
    //     ...state,
    //     components: {
    //       ...state.components,
    //       [libraryComponent.id]: {
    //         ...state.components[libraryComponent.id],
    //         tags: [
    //           ...tags,
    //         ],
    //         lastModifiedDate,
    //       } } };
    // }
    // case Tags.DeleteTagFromSystem: {
    //   const { tagId } = payload;
    //   const updComponents = {};
    //   Object.keys(state.components).forEach(keyComp => {
    //     if (!state.components[keyComp]?.tags?.find(tag => tag.id === tagId)) {
    //       updComponents[keyComp] = state.components[keyComp];
    //     } else {
    //       updComponents[keyComp] = {
    //         ...state.components[keyComp],
    //         tags: state.components[keyComp]?.tags?.filter(tagInComp => tagInComp.id !== tagId),
    //       };
    //     }
    //   });
    //   return { ...state, components: updComponents };
    // }
    case LibraryComponents.Pages.UpdateStateSharedPagesR: {
      const { sharedState } = payload;
      return { ...state, shared: sharedState };
    }
    case LibraryComponents.Shared.UpdateSharedLibraryComponentInRedux: {
      const { component } = payload;
      return {
        ...state,
        shared: {
          ...state.shared,
          components: {
            ...state.shared.components,
            [component.id]: {
              ...state.shared.components[component.id],
              ...component,
            },
          },
        },
      };
    }
    case CurrentPage.UpdateSingleBlock: {
      const { id, foreignLastModifiedDate, foreignLastModifiedUserName } = payload;
      return {
        ...state,
        components: {
          ...state.components,
          [id]: {
            ...state.components[id],
            foreignLastModifiedDate,
            foreignLastModifiedUserName,
          },
        },
      };
    }
    case LibraryComponents.ToggleUploadingFilesState: {
      const { isUploading } = payload;
      return {
        ...state,
        isUploadingFiles: isUploading,
      };
    }
    case LibraryComponents.AddBlockToLibraryComponent: {
      const { block, parentLibraryComponent } = payload;

      const newComponents = state.components[parentLibraryComponent]?.components
        ? [...state.components[parentLibraryComponent]?.components, block]
        : [block];

      return {
        ...state,
        components: {
          ...state.components,
          [parentLibraryComponent]: {
            ...state.components[parentLibraryComponent],
            components: newComponents,
          },
        },
      };
    }
    default:
      return state;
  }
};
