import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuid, v4 as uuidv4 } from 'uuid';
import classNames from 'classnames/bind';
import { EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import PreloadIcon from '../PreloadIcon';
import { ReactComponent as UploadSvg } from '../../images/icons/upload_32.svg';
import { ReactComponent as CollapseIcon } from '../../images/icons/panel-left-close.svg';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import moduleStyles from '../../pages/Library/Library.module.scss';

import Logo from '../MainHeader/Logo';
import {
  actionPressModKey,
  actionReleaseModKey,
} from '../../redux/shortcuts/action';
import {
  actionPageWillDownload,
  actionShowLibraryWidget,
  actionToggleIsCardView,
} from '../../redux/currentPage/action';
import {
  actionCreateLinkLibraryComponent,
  CopyBlockImageToPage,
} from '../../redux/library/actions';
import {
  createNodeFromHtml,
  pastedHandlerHelper,
  validateURL,
} from '../../utils/helpers';
import {
  actionAddEditableBlockRedux,
  actionAddManyEditableBlock,
} from '../../redux/pages/action';
import {
  actionSaveComponentDescriptionData,
  actionSettingsSideBarOff,
  actionShowMessage,
  actionToggleComponentDescription,
} from '../../redux/support/action';
import { actionCloseModal, actionOpenModal } from '../../redux/user/action';
import {
  allowedFormatsImage,
  BlockTypes,
  DEFAULT_PAGE_PATH, DEFAULT_TITLE,
  dragType,
  emptyCallback,
  MessageType,
  sidebarModes,
} from '../../utils/constants';
import { actionClearHistoryUNDO } from '../../undo';
import { maxFileSize } from '../../GLOBAL_CONST';
import SideBarsWrapper from './SideBarsWrapper';
import DashboardColumns from '../../redux/dashboardColumns/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import MainHeader from '../MainHeader/MainHeader';
import MainLeftSideBar from '../MainLeftSideBar';
import { CurrentPage } from '../../redux/currentPage/types';
import LibPageHeader from '../HeadingMenu/LibPageHeader';
import PlaylistViewMode from '../HeadingMenu/PlaylistViewMode';
import { MainNavigate } from '../../navigate/navigate';
import { SUBSCRIBE_SOCKET } from '../../sagas/SocketClusterHelper';
import filterFilesByMaxSize from '../../utils/validators/filterFilesByMaxSize';
import HighLevelHistoryListener from '../UnifiedNavigation/HighLevelHistoryListener';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';
import { ContentActionType } from '../../redux/content/contentTypes';
import SupportAction from '../../redux/support/types';
import { MILLISECONDS_IN_MIN } from '../../utils/dateConvert';
import ModalSelector from '../GlobalModals/ModalSelector';
import AIModalSelector from '../GlobalModals/ModalAI';
import { BackgroundForSidebar } from '../../pages/Maker/styled';
import { CollapseIconWrapper, HeaderPosWrapper, HeaderWrapper } from '../MainLeftSideBar/styled';
import Tooltip from '../../shared/Tooltips/Tooltip';

const styles = classNames.bind(moduleStyles);

// export const ScrollContext = createContext('ScrollContext');

const Page = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-color: ${({ isPlaylist }) => (isPlaylist ? '#F5F6FF' : 'transparent')};
  overflow: hidden;
`;

const MainPage = styled.div`
  display: flex;
  position: absolute;
  left: 240px;
  width: calc(100vw - 240px);
  top: 66px;
  background-color: #f6f6f5;
  transition: all 0.2s;
  ${({ isExpanded }) => (isExpanded ? `
    width: 100vw; z-index: 101; left: 0;
    ` : 'width: calc(100vw - 240px);')};
`;

const DnDZone = styled.div``;

const MainLayout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [currentSidebarMode, setCurrentSidebarMode] = useState(sidebarModes.visible);

  const user = useSelector((state) => state.user);
  // === Playlist builder === Pages
  const { isShowLibraryWidget, tryPublish, owner, id } = useSelector(
    (state) => state.currentPage,
  );

  const editableBlocks = useSelector((state) => state.currentPage.blocks) || [];
  const awaitedNotifications = useSelector((state) => state.inbox.awaitedNotifications);

  const { componentDescription, contentLoader } = useSelector(
    (state) => state.support,
  );
  // ===
  const { isOpenCreateComponent } = useSelector((state) => state.library);
  const { isDownload, id: playlistId } = useSelector(
    (state) => state.currentPage,
  );
  const {
    isDownload: isUserDownload,
    isAdmin,
    folderId,
    selectorType,
    openModal,
    id: userId,
  } = useSelector((state) => state.user);
  const uploads = useSelector((state) => state.uploads.components);
  const { isUnloadingBlocked } = useSelector((state) => state.user);
  const isDragFolder = useSelector((state) => state.currentPage.isDragFolder);
  const isCardView = useSelector((state) => state.currentPage.isCardView);
  const { anchorId } = useSelector((state) => state.support);

  const scrollRef = useRef();
  const awaitedNotificationsRef = useRef();
  const pasteRef = useRef(null);
  const ps = useRef();
  const timeout = useRef(0);
  // const [dropDepth, setDropDepth] = useState(0);

  const dropDepth = useRef(0);
  const setDropDepth = (state) => {
    dropDepth.current = state;
  };
  const [isDndZoneActive, setIsDndZoneActive] = useState(false);
  const [isRouteScrollDisabled, setIsRouteScrollDisabled] = useState(false);
  const [lastUrl, setLastUrl] = useState('');
  const [allowRedirect, setAllowRedirect] = useState(true);
  const [prevUrl, setPrevUrl] = useState('');

  const dragableDiv = document.getElementById('draggable_page');
  const draggableBoard = document.getElementById(dragType.board);

  const isPlaylist = history.location.pathname.startsWith('/playlist/')
    || history.location.pathname.startsWith('/shared_playlist_co_edit/');

  const isEditPage = history.location.pathname.startsWith('/edit_page');

  const isPage = history.location.pathname.startsWith('/libraryPage')
    || history.location.pathname.startsWith('/edit_page');
  const isPlaylistViewMode = history.location.pathname.includes('/view_playlist');

  const isShowSideBars = !(isEditPage && owner?.id !== userId)
    && !history.location.pathname.includes('/contacts')
    && !history.location.pathname.includes('/maker')
    && !history.location.pathname.includes('/board')
    && !history.location.pathname.includes('/inbox')
    && !history.location.pathname.includes('/player');

  const isShowMainBarAndMainMenu = history.location.pathname.startsWith('/content/')
    || history.location.pathname.startsWith('/channel/')
    || history.location.pathname.startsWith('/universal/')
    || history.location.pathname.startsWith('/inbox/')
    || history.location.pathname === '/contacts'
    || history.location.pathname === '/help'
    || history.location.pathname === '/inbox'
    || history.location.pathname === '/outbox/'
    || history.location.pathname === '/board/';
  const setShowLibraryWidget = (isShow) => {
    dispatch(actionShowLibraryWidget(isShow));
  };

  const pasteHandler = (event) => {
    if (event.target.tagName !== 'BODY' && event.target.tagName !== 'DIV') return;
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedDataHtml = clipboardData.getData('text/html');
    const pastedDataText = clipboardData.getData('text/plain');
    const newId = uuidv4();
    const result = pastedHandlerHelper(
      null,
      'new',
      newId,
      pastedDataText,
      pastedDataHtml,
    );
    const { type, payload } = result;
    switch (type) {
      case 'ADD_BLOCKS': {
        dispatch(actionAddManyEditableBlock(newId, payload, playlistId));
        break;
      }
      default:
        break;
    }
  };

  const handleDragEnter = (e) => {
    if (
      !history.location.pathname.startsWith('/content')
      || dragableDiv
      || isDragFolder
      || draggableBoard
    ) return;
    if (history.location.pathname === '/content/playlists/board') return;
    e.preventDefault();
    e.stopPropagation();
    setDropDepth(dropDepth.current + 1);
    setIsDndZoneActive(true);
  };
  const handleDragEnd = () => {
    if (currentSidebarMode === sidebarModes.hovered) {
      setCurrentSidebarMode(sidebarModes.collapsed);
    }
  };
  const handleDragLeave = (e) => {
    if (
      !history.location.pathname.startsWith('/content')
      || dragableDiv
      || isDragFolder
      || draggableBoard
    ) return;
    if (history.location.pathname === '/content/playlists/board') return;
    e.preventDefault();
    e.stopPropagation();
    setDropDepth(dropDepth.current - 1);
    if (dropDepth.current > 0) return;
    setIsDndZoneActive(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentSidebarMode === sidebarModes.collapsed) {
      setCurrentSidebarMode(sidebarModes.hovered);
    }
    if (
      !history.location.pathname.startsWith('/content')
      || dragableDiv
      || isDragFolder
      || draggableBoard
    ) return;
    if (history.location.pathname === '/content/playlists/board') return;

    e.dataTransfer.dropEffect = 'copy';
    setIsDndZoneActive(true);
  };

  const handleDrop = (e) => {
    // debugger;
    if (
      !history.location.pathname.startsWith('/content')
      || dragableDiv
      || isDragFolder
      || draggableBoard
    ) return;
    if (history.location.pathname === '/content/playlists/board') return;
    e.preventDefault();
    e.stopPropagation();
    if (!isCardView) dispatch(actionToggleIsCardView());
    setDropDepth(0);
    setIsDndZoneActive(() => false);
    const sortedUploads = Object.values(uploads || {}).sort((a, b) => b.position - a.position)
      || [];

    if (
      e.dataTransfer.types.filter(
        (i) => i === 'text/plain' || i === 'text/uri-list',
      ).length
    ) {
      const dropDataText = e.dataTransfer.getData('text/plain');
      const dropDataHtml = e.dataTransfer.getData('text/html');
      let hasOneImage;
      let urlTextToCreate;
      const isUrl = validateURL(dropDataText);
      let imageDataTitle = '';
      if (dropDataHtml) {
        const nodes = createNodeFromHtml(dropDataHtml);
        const images = nodes.content.querySelectorAll('img');
        const paragraphs = nodes.content.querySelectorAll('p');
        imageDataTitle = images[0]?.alt;
        if (images.length === 1 && !paragraphs.length) {
          hasOneImage = images?.length === 1;
          urlTextToCreate = images[0]?.src;
        }
      }

      if (hasOneImage || isUrl) {
        if (selectorType === 'folder') {
          dispatch(
            actionCreateLinkLibraryComponent(
              hasOneImage ? urlTextToCreate : dropDataText,
              imageDataTitle,
            ),
          );
        } else {
          dispatch(
            actionCreateLinkLibraryComponent(
              hasOneImage ? urlTextToCreate : dropDataText,
              imageDataTitle,
            ),
          );
        }
      } else if (dropDataText) {
        const newId = uuidv4();
        const newState = EditorState.createEmpty();
        const result = pastedHandlerHelper(
          newState,
          'new',
          newId,
          dropDataText,
          dropDataHtml,
        );

        const { type, payload } = result;
        switch (type) {
          case 'ADD_BLOCKS': {
            dispatch(actionAddManyEditableBlock(newId, payload, null));
            break;
          }
          case 'CREATE_AS_TEXT': {
            dispatch(
              actionAddEditableBlockRedux(
                newId,
                'text',
                null,
                null,
                dropDataText,
              ),
            );
            break;
          }
          case 'CREATE_AS_LINK':
          default:
        }
      }
    } else if (e.dataTransfer.types.filter((i) => i === 'Files').length) {
      const files = Array.from(e.dataTransfer.files);
      const filteredFilesArr = filterFilesByMaxSize([...e.dataTransfer.files]);
      if (files.length !== filteredFilesArr.length) {
        dispatch(
          actionShowMessage({
            type: MessageType.CannotBeUploaded,
            itemName: t('defaultSmartPageTitleT'),
          }),
        );
      }

      filteredFilesArr.forEach((item, index) => {
        const newId = uuidv4();
        if (item.size > maxFileSize.bytes) {
          dispatch(
            actionCreateUpload({
              newId,
              file: item,
              title: item.name,
              position: sortedUploads.length
                ? sortedUploads[0]?.position + 1
                : index + 1,
              type: item.type || 'unknown_type',
              errorMsg: `${t('fileSizeMoreThanT')} ${maxFileSize.text}`,
              history,
            }),
          );
        } else {
          dispatch(
            actionCreateUpload({
              newId,
              file: item,
              title: item.name,
              position: sortedUploads[0]?.position + 1,
              type: item.type || 'unknown_type',
              history,
            }),
          );
        }
      });
    }
  };

  const onPasteHandler = (e) => {
    if (
      e.target.tagName === 'INPUT'
      || e.target.tagName === 'SELECT'
      || e.target.tagName === 'TEXTAREA'
      || e.target.tagName === 'BR'
      || e.target.tagName === 'SPAN'
    ) return;

    const isEditLibraryPage = history.location.pathname.startsWith('/libraryPage');
    if (isEditLibraryPage) {
      const newBlock = [];
      // const startIndex = editableBlocks[editableBlocks.length - 1]
      const startIndex = editableBlocks.length;
      const files = [...e.clipboardData.files];

      files
        .filter((i) => allowedFormatsImage[i.type])
        .forEach((item, fileIndex) => {
          if (fileIndex > 6) return;
          // const emptyEd = EditorState.createEmpty();
          const blockId = uuid();
          const libCompId = uuid();
          dispatch(
            actionCreateUpload({
              newId: libCompId,
              blockId,
              file: item,
              title: item.name,
              type: item.type,
              history,
              callback: () => CopyBlockImageToPage(
                blockId,
                startIndex + fileIndex,
                libCompId,
              ),
            }),
          );
          newBlock.push({
            id: blockId,
            libCompId,
            type: BlockTypes.image,
          });
        });

      dispatch(actionAddManyEditableBlock('componentId', newBlock, id));
    }
  };

  const onGlobalKeyDown = (e) => {
    if (
      e.target.tagName === 'INPUT'
      || e.target.tagName === 'SELECT'
      || e.target.tagName === 'TEXTAREA'
      || e.target.tagName === 'SPAN'
    ) return;
    switch (e.code) {
      case 'MetaLeft': {
        dispatch(actionPressModKey('MetaLeft'));
        break;
      }
      case 'ShiftLeft': {
        dispatch(actionPressModKey('ShiftLeft'));
        break;
      }
      case 'AltLeft': {
        dispatch(actionPressModKey('AltLeft'));
        break;
      }
      case 'CtrlLeft': {
        dispatch(actionPressModKey('CtrlLeft'));
        break;
      }
      default: {
        break;
      }
    }
  };

  const onGlobalKeyUp = (e) => {
    if (
      e.target.tagName === 'INPUT'
      || e.target.tagName === 'SELECT'
      || e.target.tagName === 'TEXTAREA'
      || e.target.tagName === 'SPAN'
    ) return;

    if (e.key === '-') {
      if (!timeout.current) {
        timeout.current = setTimeout(() => (timeout.current = 0), 500);
      } else {
        timeout.current = 0;
        dispatch(actionCreator(CurrentPage.ToggleIsCardView));
      }
    }
    switch (e.code) {
      case 'MetaLeft': {
        dispatch(actionReleaseModKey('MetaLeft'));
        break;
      }
      case 'ShiftLeft': {
        dispatch(actionReleaseModKey('ShiftLeft'));
        break;
      }
      case 'AltLeft': {
        dispatch(actionReleaseModKey('AltLeft'));
        break;
      }
      case 'CtrlLeft': {
        dispatch(actionReleaseModKey('CtrlLeft'));
        break;
      }
      default: {
        break;
      }
    }
  };

  function scrollTop() {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  }

  useEffect(() => {
    const allUploadsReady = Object.values(uploads || {}).every(
      (i) => i.status === 'READY' || i.status === 'ERROR',
    );
    if (!allUploadsReady) {
      setAllowRedirect(false);
    } else {
      setAllowRedirect(true);
    }
  }, [uploads]);

  useEffect(() => {}, [allowRedirect]);

  useEffect(() => {
    if (!allowRedirect) {
      window.onbeforeunload = (ev) => {
        ev.preventDefault();
        ev.returnValue = '';
      };
    } else {
      window.onbeforeunload = null;
    }
  }, [allowRedirect]);

  useEffect(() => {
    if (isShowLibraryWidget) setShowLibraryWidget(false);
    if (componentDescription) dispatch(actionToggleComponentDescription(false));
  }, [history.location]);


  useEffect(() => {
    awaitedNotificationsRef.current = awaitedNotifications;
  }, [awaitedNotifications]);

  useEffect(() => {
    if (user.id) {
      const showUnread = () => {
        if (Object.values(awaitedNotificationsRef.current).length) {
          dispatch(actionCreator(SupportAction.ShowUnread, { history }));
        }
      };
      const intervalId = setInterval(showUnread, MILLISECONDS_IN_MIN);
      return () => clearInterval(intervalId);
    }
    return emptyCallback;
  }, [user.id]);

  useEffect(() => {
    scrollTop();
  }, [isDownload, selectorType, folderId]);

  useEffect(() => {
    if (history.location.pathname === DEFAULT_PAGE_PATH) return;
    if (history.location.pathname === '/contacts') return;
    if (history.location.pathname.includes('/inbox/')) return;
    if (history.location.pathname.includes('/outbox')) return;
    if (history.location.pathname.includes('/pages')) return;
    if (history.location.pathname === '/board/') return;
    if (history.location.pathname.includes('/maker/')) return;
    if (history.location.pathname.includes('/users_smartfiles/')) return;
    if (history.location.pathname.includes('/users_smartfiles_favorite/')) return;
    if (history.location.pathname.includes('/shared_playlist_co_edit')) return;
    if (history.location.pathname.includes('/tag/')) return;
    if (history.location.pathname.includes('/dynamicCollection/')) return;
    if (history.location.pathname.includes('/player')) return;
    if (history.location.pathname.includes('/confirm_invite')) return;
    if (history.location.pathname.includes('/browse_playlists')) return;
    if (history.location.pathname.includes('/view_playlist')) return;
    if (history.location.pathname.includes('/edit_page')) return;
    if (history.location.pathname.includes('/preview_playlist')) return;
    if (history.location.pathname.includes('/preview_page')) return;
    if (history.location.pathname.includes('/libraryComponent')) return;
    if (history.location.pathname.includes('/sharedPlaylistItem')) return;
    if (history.location.pathname.includes('/libraryPage')) return;
    if (history.location.pathname.includes('/shared_libraryComponent')) return;
    if (history.location.pathname.includes('/views')) return;
    if (history.location.pathname.includes('/universal')) return; // todo delete
    if (history.location.pathname.includes('/help')) return;
    if (history.location.pathname.includes('/protected_confirm_new_email')) return;
    if (history.location.pathname.includes('/channel/')) return;
    if (history.location.pathname.includes('/mail_redirect')) return;
    const isInMainNav = Object.entries(MainNavigate).some((i) => {
      const [activeNavSlider, vall] = i;
      return Object.keys(vall.selectors).some((selector) => {
        return (
          history.location.pathname
          === `/content/${activeNavSlider}/${selector}`
        );
      });
    });
    if (isInMainNav) return;

    history.push(DEFAULT_PAGE_PATH);
  }, [user.id]);

  useEffect(() => {
    if (isPage && playlistId) document.addEventListener('paste', pasteHandler);
    return () => document.removeEventListener('paste', pasteHandler);
  }, [isPage, playlistId]);

  useEffect(() => {
    if (!dropDepth.current || dragableDiv || draggableBoard) setIsDndZoneActive(false);
  }, [dropDepth.current]);

  useEffect(() => {
    if (
      !isRouteScrollDisabled
      && history.location.pathname.includes('playlist_builder')
    ) {
      setIsRouteScrollDisabled(true);
    } else setIsRouteScrollDisabled(false);
    dispatch(actionSaveComponentDescriptionData());
  }, [history.location]);

  useEffect(() => {
    const historyArray = history.location.pathname.split('/');
    if (historyArray[1] !== 'playlist') dispatch(actionSettingsSideBarOff());
    if (openModal) dispatch(actionOpenModal(''));
    if (componentDescription) dispatch(actionToggleComponentDescription(true));
    dispatch(actionClearHistoryUNDO());

    const isUserLiveNotMainPage = prevUrl && !prevUrl.includes('/content');
    if (
      history.location.pathname.includes('/content')
      && isUserLiveNotMainPage
    ) {
      dispatch(actionCreator(ContentActionType.updateCounterS, {}));
    }
    setPrevUrl(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    if (isUnloadingBlocked) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [isUnloadingBlocked]);

  useEffect(() => {
    userId && dispatch(actionCreator(DashboardColumns.uploadColumns));

    // subscribe by userId
    userId
      && dispatch(
        actionCreator(SUBSCRIBE_SOCKET, {
          channelName: 'channelName',
          id: userId,
          dispatch,
          history,
        }),
      );
  }, [dispatch, userId]);

  useEffect(() => {
    if (history.location.pathname.startsWith('/view_playlist')) {
      dispatch(actionPageWillDownload(true));
    }
    setLastUrl(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    if (tryPublish) {
      scrollRef.current?.scrollTo(0, 300);
    }
  }, [tryPublish]);

  useEffect(() => {
    if (anchorId === 'pageTitleInput') {
      scrollRef.current?.scrollTo(0, 0);
    }
  }, [anchorId]);

  const close = useCallback(() => {
    dispatch(actionRemoveAllSelected());
    dispatch(actionCloseModal());
  }, [dispatch]);

  const [hoverTimeoutID, setHoverTimeoutID] = useState();

  const collapseButtonMouseLeaveHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (currentSidebarMode === sidebarModes.visible) return;
    if (currentSidebarMode === sidebarModes.hovered) {
      setHoverTimeoutID(setTimeout(() => setCurrentSidebarMode(sidebarModes.collapsed), 2000));
    }
  };

  const collapseButtonMouseEnterHandler = () => {
    if (currentSidebarMode === sidebarModes.collapsed) {
      setCurrentSidebarMode(sidebarModes.hovered);
    }
    clearTimeout(hoverTimeoutID);
  };


  const collapseButtonHoverHandler = () => {
    if (currentSidebarMode === sidebarModes.visible) return;
    if (currentSidebarMode === sidebarModes.collapsed) {
      setCurrentSidebarMode(sidebarModes.hovered);
    }
  };
  const collapseButtonClickHandler = () => {
    if (currentSidebarMode === sidebarModes.visible) {
      setCurrentSidebarMode(sidebarModes.collapsed);
    } else {
      setCurrentSidebarMode(sidebarModes.visible);
    }
  };
  const collapseTooltip = useMemo(() => {
    if (currentSidebarMode === sidebarModes.collapsed) {
      return 'Show sidebar';
    }
    if (currentSidebarMode === sidebarModes.hovered) {
      return 'Keep sidebar visible';
    }
    return 'Hide sidebar';
  }, [currentSidebarMode]);

  if (isUserDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t('loadingT')}</div>
      </div>
    );
  }

  return (
    <div onPaste={onPasteHandler}>
      <div className={styles('hidden')} />
      {currentSidebarMode === sidebarModes.hovered && <BackgroundForSidebar isMain />}
      <HighLevelHistoryListener />
      {isAdmin && <div className={styles('admin_label')}>{t('helperAdminT')}</div>}

      {contentLoader && (
        <div className={styles('loader_overlay')}>
          <div className={styles('loader-wrapper-page')}>
            <div className="loader menuWrapperCenter">{t('loadingT')}</div>
          </div>
        </div>
      )}
      <Page
        tabIndex="0"
        onKeyDown={onGlobalKeyDown}
        onKeyUp={onGlobalKeyUp}
        isPlaylist={isPlaylist}
        ref={pasteRef}
      >
        <ModalSelector />
        <AIModalSelector />
        <PreloadIcon />
        <div
          onDragOver={(e) => handleDragOver(e)}
          onDragEnter={(e) => handleDragEnter(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onDragEnd={handleDragEnd}
          className={styles('wrapper_drop_zone')}
        >
          <DnDZone
            className={styles('drop_zone', { active: isDndZoneActive })}
            onDrop={(e) => handleDrop(e)}
          >
            <div className={styles('dnd_hint', { active: isDndZoneActive })}>
              <div className={styles('icon')}>
                <UploadSvg />
              </div>
              <div className={styles('text')}>
                {t('dropFilesToUploadLibraryMessageT')}
              </div>
            </div>
          </DnDZone>
          {isShowMainBarAndMainMenu && (
            <>
              {' '}
              <HeaderPosWrapper
                onMouseLeave={collapseButtonMouseLeaveHandler}
                onMouseEnter={collapseButtonMouseEnterHandler}
              >
                <HeaderWrapper
                  isExpanded={currentSidebarMode === sidebarModes.visible}
                >
                  <Logo />
                  <Tooltip
                    text={collapseTooltip}
                    direction="left"
                    place={'sidebarCollapseTooltip'}
                  >
                    <CollapseIconWrapper
                      onClick={collapseButtonClickHandler}
                      isVisible={currentSidebarMode === sidebarModes.visible}
                      onMouseEnter={collapseButtonHoverHandler}
                    >
                      <CollapseIcon />
                    </CollapseIconWrapper>
                  </Tooltip>
                </HeaderWrapper>
              </HeaderPosWrapper>
              <MainHeader
                currentSidebarMode={currentSidebarMode}
                setCurrentSidebarMode={setCurrentSidebarMode}
                collapseButtonMouseLeaveHandler={collapseButtonMouseLeaveHandler}
                collapseButtonMouseEnterHandler={collapseButtonMouseEnterHandler}
              />
              <MainLeftSideBar
                currentSidebarMode={currentSidebarMode}
                collapseButtonMouseLeaveHandler={collapseButtonMouseLeaveHandler}
                setCurrentSidebarMode={setCurrentSidebarMode}
                collapseButtonMouseEnterHandler={collapseButtonMouseEnterHandler}
              />
              <MainPage isExpanded={currentSidebarMode !== sidebarModes.visible}>{props.children}</MainPage>
            </>
          )}
          {isPage && <LibPageHeader />}
          {isPlaylistViewMode && <PlaylistViewMode />}
          {!isShowMainBarAndMainMenu && <> {props.children} </>}
        </div>

        {isShowSideBars && <SideBarsWrapper />}

        {/* {isShowUploader && <UploadPanel />} */}
      </Page>
    </div>
  );
};

export default MainLayout;
