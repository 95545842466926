import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as DropSvg } from '../../../images/icons/icon_250/upload.svg';
import { ReactComponent as TitleSvg } from '../../../images/icons/icon_20/title_sf_option.svg';
import { ReactComponent as TextSvg } from '../../../images/icons/icon_20/text_sf_option.svg';
import { ReactComponent as LibrarySvg } from '../../../images/icons/icon_20/gallery_sf_option.svg';
import { ReactComponent as CloudSvg } from '../../../images/icons/icon_20/cloud_sf_option.svg';
import { ReactComponent as UploadSvg } from '../../../images/icons/icon_20/upload_sf_option.svg';
import {
  actionChangeLinkPageType,
  actionShowLibraryWidget,
} from '../../../redux/currentPage/action';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../redux/content/contentTypes';
import { actionChangeSieveValue } from '../../../redux/library/actions';
import { placeSelector } from '../../../utils/helpers';
import { smartFileItemType } from '../../../shared/smartFile/constant';
import { actionOpenModal } from '../../../redux/user/action';
import { openModalType, stopPropagation } from '../../../utils/constants';
import { sortedFunctions } from '../../../utils/dateConvert';

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;
const TitleText = styled.div`
    font-size: 30px;
    line-height: 40px;
    padding-left: 15px;
    padding-top: 2px;
`;
const Block = styled.div`
    display: flex;
    padding: 4px 16px 4px 15px;
    gap: 5px;
    width: 272px;
    height: 32px;
    align-items: center;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;
    &:hover {
      background: #E6AC190F;
        color: #FFC72C;
        & svg{
            & path {
                fill: #FFC72C;
            }
        }
    }
`;

const ItemShell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const OptionShell = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    height: 253px;
    justify-content: space-between;
    width: 350px;
    white-space: nowrap;
    overflow: visible;
`;
const EmptyShell = styled.div`
    width: 100vw;
    height: calc(100vh - 61px);
  display: flex;
  justify-content: center;
    ${({ hasDrag }) => (hasDrag
    ? `
    align-items: center;
      background: #FFEEBF;
  `
    : '')}
`;
const defaultSortType = Object.keys(sortedFunctions)[2];

const Empty = ({
  indexHover,
  onDragEnter,
  onDragLeave,
  item,
  index,
  onEditorAreaMouseDrop,
  submitForm,
  create,
}) => {
  const dispatch = useDispatch();

  const inputFile = useRef(null);
  const { t } = useTranslation();
  const { sortType = defaultSortType } = useSelector((state) => state.content);
  const currentPage = useSelector((state) => state.currentPage);


  const openLibraryHandler = () => {
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: 'pages',
          selectorType: 'all',
          sortType,
        },
        'PlaceHolderAll',
      ),
    );
    dispatch(actionChangeSieveValue(placeSelector('library'), 'library'));
    dispatch(actionShowLibraryWidget(true, 'library'));
  };

  const setText = () => {
    const createdItem = create(0);
    dispatch(actionChangeLinkPageType(createdItem.id, smartFileItemType.richText, currentPage.id));
  };

  const setTitle = () => {
    const createdItem = create(0);
    dispatch(actionChangeLinkPageType(createdItem?.id, smartFileItemType.title, currentPage?.id));
  };

  const openUploadModal = () => {
    dispatch(actionOpenModal(openModalType.UploadModal2023, { item, index }));
  };


  const onClick = () => {
    inputFile.current.click();
  };
  return (
    <EmptyShell
      onDragEnter={() => onDragEnter('empty')}
      onDragLeave={onDragLeave}
      hasDrag={indexHover === 'empty'}
      onDrop={(e) => {
        onEditorAreaMouseDrop(e, null, 'last');
      }}
    >
      {indexHover === 'empty' && <TitleText onDragLeave={stopPropagation}>{t('dropYourFileAnywhereT')}</TitleText>}
      {indexHover !== 'empty' && (
      <ItemShell>
        <div>
          <DropSvg />
        </div>

        <OptionShell>
          <TitleText className="text_unified-3xl">{t('dragOrStartHereT')}</TitleText>
          <Block className="text_unified-base" onClick={onClick}>
            <IconWrapper>
              <UploadSvg />
            </IconWrapper>
            {t('uploadT')}
          </Block>
          <Block className="text_unified-base" onClick={openLibraryHandler}>
            <IconWrapper>
              <LibrarySvg />
            </IconWrapper>
            {t('addFromLibraryT')}
          </Block>
          <Block className="text_unified-base" onClick={openUploadModal}>
            <IconWrapper>
              <CloudSvg />
            </IconWrapper>
            {t('importFromT')}
          </Block>
          <Block className="text_unified-base" onClick={setTitle} isFirst>
            <IconWrapper>
              <TitleSvg />
            </IconWrapper>
            {t('titleT')}
          </Block>
          <Block className="text_unified-base" onClick={setText} isFirst>
            <IconWrapper>
              <TextSvg />
            </IconWrapper>
            {t('richTextT')}
          </Block>
        </OptionShell>
        <input
          onChange={submitForm}
          type="file"
          name="file"
          webkitdirectory
          // eslint-disable-next-line react/no-unknown-property
          mozdirectory
          // eslint-disable-next-line react/no-unknown-property
          directory
          multiple
          style={{ display: 'none' }}
          ref={inputFile}
        />
      </ItemShell>
      )}
    </EmptyShell>
  );
};

export default Empty;
