import styled from 'styled-components/macro';


export const DeleteButtonContainer = styled.div<{ isPageAndCompress?: boolean }>`
    width: 32px;
    height: 32px;
    //border: 0.6px solid #ece2e2;
    border-radius: 12px;
    opacity: 0.5;
    padding-left: 7px;
    padding-top: 5px;
    ${({ isPageAndCompress }) => (isPageAndCompress ? `
     position: absolute;
     left: 48px;
     top: 0px;` : '')}
    &:hover {
        opacity: 1;
        cursor: pointer;
        background: #7E7A850A;
        }
    }
`;
export const DeleteButtonShell2 = styled.div`
    right: -8px;
    top: 22px;
    position: absolute;
`;
export const ReactionsButtonShell = styled.div<{ isExtra?: boolean }>`
    ${({ isExtra }) => (isExtra ? `
    margin-top: 2px;
    ` : `
    right: -59px;
    top: 22px;
    position: absolute;
    `)}
`;
export const DeleteButtonShell = styled.div < { isVisible?: string | boolean }>`
    position: absolute;
    align-items: flex-end;
    justify-content: center;
    display: none;
    gap: 14px;
    ${({ isVisible }) => (isVisible ? `
        display: flex !important;
    ` : '')}
`;
export const ReactButtonShell = styled.div < { isVisible?: string | boolean }>`
    position: absolute;
    align-items: flex-end;
    justify-content: center;
    display: flex;
    visibility: hidden;
    gap: 14px;
    & button{
        z-index: 1;
    }
    ${({ isVisible }) => (isVisible ? `
        visibility: visible !important;
        display: flex !important;
    ` : '')}
`;
export const PickerOverlay = styled.div`
    height: 100vh;
    width: 100vw;
    z-index: 200;
    position: absolute;
    top: 0;
    left: 0;
`;
export const PickerWrapper = styled.div<{ top?: number | null, left?: number | null }>`
    position: absolute;
    left: -250px;
    top: -180px; 
    z-ndex: 100001;
    ${({ top, left }) => (top && left ? `
        top: ${top}px;
        left: ${left}px;
    ` : '')}
`;

export const DeleteButtonBalancer = styled.div`
  transform: translateY(-50%);
  height: 32px;
  position: sticky;
  z-index: 100;
  top: 91px;
  margin-bottom: 16px;
`;

export const SixDotBalancer = styled.div`
    padding-top: 32px;
    & ${DeleteButtonShell} {
        display: none;
    }
    & ${ReactButtonShell} {
        display: flex;
        visibility: hidden;
    }
    &:hover {
        z-index: 1000;
        position: relative;
        & ${DeleteButtonShell} {
            display: flex;
        }
         & ${ReactButtonShell} {
            visibility: visible;
            display: flex;
        }
    }
`;

export const ExtraReactions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: var(--Neutral_Scheme_bg_default);
    height: 24px;
    min-width: 24px;
    border-radius: 30px;
    cursor: pointer;
`;

export const ReactionsWrapper = styled.div<{ top?: number, left?: number, isHover?:boolean, isReactionsActive?:boolean | string}>`
    display: flex;
    position: absolute; 
    top: 40px;
    gap: 8px;
    background: rgb(246, 246, 245);
    flex-wrap: wrap;
    align-items: center;
    width: 160px;
    z-index: 100000;
    left: -50px;
    padding-bottom: 15px;
    ${({ top, left, isHover }) => (top && left && !isHover ? `
        top: ${top}px;
        left: ${left}px;
    ` : '')}
    ${({ isHover, isReactionsActive }) => (isHover ? `
    ${!isReactionsActive ? 'display: none;' : ''}
    padding-top: 18px;
    top: 24px;
    left: -54px;
    z-index: 0;
    ` : '')}
`;

export const ReactionsCounter = styled.div`
    border-radius: 30px;
    width: 20px;
    height: 20px;
    position: absolute;
    background: var(--Brand_Scheme_bg_default);
    z-index: 3;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    left: 20px;
    top: 20px;
    cursor: default;
`;

export const SingleReaction = styled.div<{ isMarkedAsMy?: boolean }>`
    border-radius: 32px;
    border: 1px solid var(--Sheme_border_muted);
    background: #F7F7F8;
    padding: 3px 6px 0 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    max-height: 28px;

`;
export const EmojiReaction = styled.div<{ isMarkedAsMy?: boolean }>`
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    width: 14px;
    height: 20px;
    position: relative;
`;

export const EmojiReactionSafarifix = styled.div<{ isSafari?: boolean }>`
    ${({ isSafari }) => (isSafari ? `
    position: absolute;
    top: -1px;
    left: -2px;
    font-size: 12px;
    ` : '')}
`;


export const ReactionsButtonBalancer = styled.div<{ isExtra?: boolean }>`
  transform: translateY(-50%);
  height: 32px;
  z-index: 100;
  top: 91px;
  position: sticky;
  ${({ isExtra }) => (isExtra ? `
  position: static;
  width: 32px;
  right: -90px;
  z-index: 100;
  top: 23px;
    ` : 'margin-bottom: 16px;')}
  &:hover ${ReactionsWrapper} {
      display: flex;
  }
`;
export const ExtraReactionWrapper = styled.div`
    display: flex;
    cursor: pointer;
    ${ReactionsButtonBalancer} {
        position: static;
        margin-top: -5px;
        transform: none;
    }
`;

export const AvatarWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const AvatarPreview = styled.img`
 border-radius: 30px;
 width: 20px;
height: 20px;
`;
