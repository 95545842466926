import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RadarSvg } from '../../../images/icons/icon_60/radar_empty_sf.svg';

const TitleText = styled.div`
  padding-top: 9px;
  color:#8D8A93;
`;
const Block = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  color: #514E55;
  padding-top: 4px;
`;

const ItemShell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const EmptyShell = styled.div`
  width: 100vw;
  height: calc(100vh - 61px);
  display: flex;
  justify-content: center;
`;

const EmptyForViewer = () => {
  const { t } = useTranslation();
  return (
    <EmptyShell>
      <ItemShell>
        <div>
          <RadarSvg />
        </div>
        <TitleText className="text_unified-xs">{t('thereNothingHereYetT')}</TitleText>
        <Block className="text_unified-sm">
          {t('pleaseRefreshOrCheckBackLaterForUpdates')}
        </Block>
      </ItemShell>
    </EmptyShell>
  );
};

export default EmptyForViewer;
