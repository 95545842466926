import React from 'react';
import { useSelector } from 'react-redux';
import HeaderWrapper from './HeaderWrapper';
import PreviewSelector from './PreviewSelector';
import {
  MakerInfoContent,
  MakerInfoDivider,
  MakerInfoImageAndMainInfo, MakerInfoShell,
  MakerInfoTextAreaBlock, MakerInfoTextPlaceholder,
} from './styled';
import MakerInfoRow from './components/Info/MakerInfoRow';
import { DATA_PARENTS } from '../../utils/constants';


const PlaylistMainInfo = ({
  isRightSideBarExpanded,
  currentRole,
  handleDownload,
  isCanManyDownload,
  refForScroll,
  cutMode,
  open,
  setOpen,
  lExpand,
  setViewMode,
  viewMode,
  isForSharedToWeb,
  onDrop,
  isDrag,
  setDrag,
  isImageUploading,
  setImageUploading,
  isDragFromDevice,
  setDragFromDevice,
  setUploadId,
  openCoverSettings,
  coverRef,
}) => {
  const currentPage = useSelector((it) => it.currentPage);

  const {
    title,
    description,
    owner,
    totalPlaylistDuration,
    co_autors,
    id,
    linkPages,
    lastModifiedDate,
    createDate,
    isShowLibraryWidget,
    content,
  } = currentPage;
  const userName = owner?.showUsername && owner?.username
    ? `${owner?.username || ''}`
    : `${owner?.first_name || ''} ${owner?.last_name || ''}`;

  return (
    <MakerInfoShell data-parent={DATA_PARENTS.PlaylistHeader}>
      <MakerInfoDivider theFirst />
      <MakerInfoContent isRightSideBarExpanded={isRightSideBarExpanded}>
        <MakerInfoImageAndMainInfo data-parent={DATA_PARENTS.PlaylistHeader}>
          <PreviewSelector
            refForScroll={refForScroll}
            onDrop={onDrop}
            isDrag={isDrag}
            setDrag={setDrag}
            isImageUploading={isImageUploading}
            setImageUploading={setImageUploading}
            isDragFromDevice={isDragFromDevice}
            setDragFromDevice={setDragFromDevice}
            setUploadId={setUploadId}
            openCoverSettings={openCoverSettings}
            coverRef={coverRef}
          />
          {owner && (
          <MakerInfoTextAreaBlock>
            <HeaderWrapper
              type="title"
              text={title}
              playlistID={id}
              currentRole={currentRole}
              isShowLibraryWidget={isShowLibraryWidget}
            />
            <HeaderWrapper
              currentRole={currentRole}
              playlistID={id}
              type="description"
              text={description || content.description}
            />
            <MakerInfoRow
              users={co_autors}
              linkPages={linkPages}
              totalPlaylistDuration={totalPlaylistDuration || 0}
              lastModifiedDate={lastModifiedDate}
              userName={userName}
              owner={owner}
              createDate={createDate}
              handleDownload={handleDownload}
              isCanManyDownload={isCanManyDownload}
              refForScroll={refForScroll}
              cutMode={cutMode}
              open={open}
              setOpen={setOpen}
              smExpand
              lExpand={lExpand}
              setViewMode={setViewMode}
              viewMode={viewMode}
              isForSharedToWeb={isForSharedToWeb}
              isDrag={isDrag}
              setDrag={setDrag}
              isImageUploading={isImageUploading}
              setImageUploading={setImageUploading}
              isDragFromDevice={isDragFromDevice}
              setDragFromDevice={setDragFromDevice}
              isExpand
            />
          </MakerInfoTextAreaBlock>
          )}{!owner && (
          <MakerInfoTextPlaceholder />
          )}
        </MakerInfoImageAndMainInfo>
      </MakerInfoContent>
    </MakerInfoShell>
  );
};

export default PlaylistMainInfo;
