import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { IconRole } from '../../../utils/permissions';
import { ShareSettingPopup } from './ShareUnshare_single_share_user';
import { ReactComponent as TimeSvg } from '../../../images/icons/shareModal/time_icon.svg';
import { ReactComponent as LifetimeSvg } from '../../../images/icons/shareModal/time_icon_endless.svg';

import { actionCreator } from '../../../shared/redux/actionHelper';
import EditPlaylist from '../../../redux/playlists/types';

import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';

import styles from '../index.module.scss';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { convertDate } from '../../../utils/dateConvert';
import { CustomButton } from '../../../pages/Maker/ai_styled';

const cx = classNames.bind(styles);

export interface IChannel {
  channel: {
    name: string,
    role: 'Viewer' | 'viewer' | 'admin' | 'co_admin' | 'contributor',
    playlistsCounter: number,
    id: string,
    availableTo?: number,
    node: {
      members?: number,
    }
  },
  setTime: (id: string, time: string) => void,
  setMainCalendarOpened: (val: boolean) => void
  setCurrentCalendarItem: ({ type, id }: { type: string, id: string }) => void
}

export const SingleChannel: React.FC<IChannel> = ({
  channel,
  setTime,
  setMainCalendarOpened,
  setCurrentCalendarItem,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [refDropTimeMenu, isDropTimeMenuVisible, setIsDropTimeMenu] = useComponentVisibleMouseDown(false, '');

  const handleSetTime = (time?: string) => {
    setTime && time && setTime(channel.id, time);
    setIsDropTimeMenu(false);
  };
  const removeChannel = () => {
    dispatch(actionCreator(EditPlaylist.RemoveChannel, { channelId: channel.id }));
  };
  const date = channel?.availableTo
    ? `${t('dueT')} ${convertDate(channel.availableTo)}`
    : `${t('lifetimeAccessT')}`;

  const elemPosition = { time: 0, role: 0 };

  const handleOpenCalendar = () => {
    setMainCalendarOpened(true);
    setCurrentCalendarItem({ type: 'channel', id: channel.id });
    setIsDropTimeMenu(false);
  }
  return (
    <div className={cx('shared_option')} ref={refDropTimeMenu}>
      <div className={cx('shared_option_icon')}>{(IconRole)[channel.role]}</div>
      <div className={cx('shared_option_content')}>
        <div className={cx('option_title', 'text_unified-base')}>{channel.name}</div>
        <div className={cx('option_description', 'text_unified-sm')}>
          {channel.node.members} {channel.node.members === 1 ? t('userT') : t('usersT')}
        </div>
      </div>
      <Tooltip
        data-parent=""
        text={`${date} \n ${t('clickToUpdateT')}`}
        direction="down"
        place="ai_agent"
      >
        <CustomButton onClick={() => setIsDropTimeMenu(true)} isIconOnly isGhost>
          {channel.availableTo ? (
            <TimeSvg />
          ) : (
            <LifetimeSvg />
          )}
        </CustomButton>
      </Tooltip>
      {isDropTimeMenuVisible && (
        <ShareSettingPopup
          type="time"
          handler={handleSetTime}
          handleRevoke={removeChannel}
          elemPosition={elemPosition}
          setMainCalendarOpened={handleOpenCalendar}
        />
      )}
    </div>
  )
};
