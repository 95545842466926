import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Union } from '../../images/2023/svg/Union.svg';
import { ReactComponent as ChevronLeft20Svg } from '../../images/icons/chevron_left_7_14.svg';
import {
  ActiveContent,
  Controls,
  HeaderInfo,
  RoleDISPLAYCoedit,
  RoleDISPLAYOwner,
  RoleDISPLAYView,
} from './sharedStyled';
import { isRoleInPlaylist } from '../../utils/permissions';
import User from '../../components/MainHeader/User';
import UserMenu from '../../components/UserMenu';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { DATA_PARENTS, emptyArr, MessageType, openModalType, allowedFormatsImage, empty, sidebarModes } from '../../utils/constants';
import { actionPageWillDownload } from '../../redux/currentPage/action';
import SwitchModeAndPublishBlock from './SwitchModeAndPublishBlock';
import { actionOpenModal } from '../../redux/user/action';
import {
  actionShowMessage,
} from '../../redux/support/action';
import ImageAndName from './ImageAndName';
import {
  AllContentWrapper,
  AvaModuleWrapper,
  HeaderWrapper,
  MakerCutHeaderWrapper,
  UnionWrapper,
  ChevronWrapper,
  ContentWrapper,
  UnionPlaceholder,
} from './styled';
import { actionAddPayloadUnifyHistory } from '../../redux/history/actions';
import { actionEditImagePlaylistREDUX, actionUnPublishSPlaylistS } from '../../redux/playlists/action';
import { universalPlaylistSize } from '../../utils/helpers';
import { maxFileSize } from '../../GLOBAL_CONST';
import mimeTypes from '../../utils/mimeTypes';
import PlaylistMainInfo from './PlaylistMainInfo';
import PlaylistMainControls from './PlaylistMainControls';
import EditCoverPopup from './EditCoverPopup';
import { actionCreateUpload } from '../../redux/filesUpload/action';

const MakerCutHeader = ({
  lExpand,
  setViewMode,
  viewMode,
  cutMode,
  isForSharedToWeb,
  isFinished,
  wrapperId,
  isLargeRightBar,
  isShowLibraryWidget,
  linkPages,
  handleDownloadAll,
  isCanManyDownload,
  isShowRightSideBar,
  open,
  refForScroll,
  isOpen,
  setOpen,
  refItemHeader,
  isSidebarOpened,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isDrag, setDrag] = useState(false);
  const [isImageUploading, setImageUploading] = useState(false);
  const [isDragFromDevice, setDragFromDevice] = useState(false);
  const [uploadId, setUploadId] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [refMenu, isActive, setIsActive] = useComponentVisible(
    false,
    'miniPage',
  );
  const coverRef = useRef(null);
  const { isCoEdit, isOwner, isViewer } = isRoleInPlaylist;
  const { requestSpinner } = useSelector((state) => state.support);
  const {
    currentRole,
    id,
    title,
    idDragPage,
    lastModifiedDate,
    usedInChannels,
    isPublish,
    shareState: { isShareToWeb, usersToSharing = emptyArr },
    img,
    cropUrl,
    singleUserShareState,
    defaultPreviewColor,
    type: itemType,
  } = useSelector((state) => state.currentPage);

  const contentData = useSelector((state) => state.content.contentData);

  const calcPlaylistSize = useMemo(() => {
    return universalPlaylistSize(linkPages, true);
  }, [linkPages]);

  const { type, id: sharedToWebPlaylistID } = useParams();
  const [refUserMenu, isComponentVisible, setIsComponentVisible] = useComponentVisible(false, DATA_PARENTS.UserMenu);
  const isButtonsBlocked = !!requestSpinner && requestSpinner !== 'init' && requestSpinner === id;
  const isHasShare = !!Object.values(singleUserShareState).length || !!usersToSharing?.length || isShareToWeb || isPublish;
  const toggleShowUserMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const coEditors = useMemo(() => {
    const usersList = usersToSharing.length ? usersToSharing : Object.values(singleUserShareState);
    return usersList.filter(user => user.isCoEdit);
  }, [usersToSharing, singleUserShareState]);

  const enableShare = (e) => {
    e.stopPropagation();
    dispatch(
      actionOpenModal(openModalType.Share, { itemType: 'playlist', isMakerStyles: true }),
    );
  };

  const goToPlayer = () => {
    dispatch(actionPageWillDownload());
    if (isForSharedToWeb) {
      dispatch(
        actionAddPayloadUnifyHistory({ sharedToWebID: sharedToWebPlaylistID }),
      );
      history.push(`/shared_player/shared/${sharedToWebPlaylistID}/1`);
      return;
    }
    const attributes = {};
    const defaultChannel = Object.values(usedInChannels)[0]?.id || 'preview';
    const firstChannelId = type === 'publish' ? defaultChannel : 'preview';
    attributes.from = history.location.pathname;
    attributes.channelId = firstChannelId;
    history.push(`/player/${firstChannelId}/${id}/1`);
  };

  const isImage = mimeTypes.image[itemType];
  const canDrop = idDragPage && isImage;

  const onDrop = (e, isSelectFromPC) => {
    e.stopPropagation();
    e.preventDefault();
    setImageUploading(true);
    setDrag(false);
    const files = isSelectFromPC ? [e.target.files[0]] : Array.from(e.dataTransfer?.files);
    if (files.length > 0 || isSelectFromPC) {
      setDragFromDevice(false);
      const firstFile = files[0];
      const newId = uuidv4();

      if (firstFile.size < maxFileSize.bytes && allowedFormatsImage[firstFile.type]) {
        dispatch(
          actionCreateUpload({
            newId,
            file: firstFile,
            title: firstFile.name,
            type: firstFile.type,
            callback: (url) => {
              actionEditImagePlaylistREDUX(newId, null, url);
              setImageUploading(false);
            },
            history,
          }),
        );
        setUploadId(newId);
      }
    }

    if (!canDrop) return;
    const item = contentData[idDragPage];
    dispatch(
      actionOpenModal(openModalType.CropPlaylistCover, {
        componentId: item.id,
        imagePath: item.urlFile,
      }),
    );
    setImageUploading(false);
  };

  const unshare = useCallback(() => {
    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: t('unshareUpT'),
        subject: t('unshareThisSmartFileQuestionT'),
        description: t('unshareDescriptionT'),
        confirm: () => {
          dispatch(actionUnPublishSPlaylistS(id, wrapperId));
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: t('smartFileUnsharedT'),
            }),
          );
        },
        cancelText: t('cancelUpT'),
        okText: t('unshareUpT'),
        type: 'unshare',
      }),
    );
  }, [dispatch, id, wrapperId]);

  const uploadData = useSelector((state) => state.uploads.components[uploadId]) || empty;
  useEffect(() => {
    if (uploadId && uploadData?.imagePath) {
      dispatch(
        actionOpenModal(openModalType.CropPlaylistCover, {
          ...uploadData,
          componentId: uploadData.newId,
        }),
      );
    }
  }, [dispatch, uploadData, uploadId]);

  useEffect(() => {
    let text = isHasShare ? t('unshareUpT') : t('shareUpT');
    if (
      !!requestSpinner
      && requestSpinner !== 'init'
      && requestSpinner === id
    ) {
      text = t('sharingUpT');
    }
    setButtonText(text);
  }, [requestSpinner, isHasShare]);

  const EditCover = () => {
    const position = coverRef.current?.getBoundingClientRect();
    const top = position?.top + (isOpen ? 180 : 50);
    const left = position?.left;
    return (
      <EditCoverPopup
        refMenu={refMenu}
        isActive={isActive}
        setIsActive={setIsActive}
        top={top}
        left={left}
        refForScroll={refForScroll}
        onDrop={onDrop}
        setImageUploading={setImageUploading}
        isDrag={isDrag}
        setDrag={setDrag}
        isDragFromDevice={isDragFromDevice}
        setDragFromDevice={setDragFromDevice}
      />
    );
  };

  return (
    <MakerCutHeaderWrapper
      ref={refItemHeader}
      isLargeRightBar={isLargeRightBar}
      lExpand={lExpand}
      expandVertical={cutMode}
      data-parent={DATA_PARENTS.PlaylistHeader}
      isShowLibraryWidget={isShowLibraryWidget}
    >
      <ContentWrapper data-parent={DATA_PARENTS.PlaylistHeader} isExpand={isOpen}>
        <HeaderWrapper data-parent={DATA_PARENTS.PlaylistHeader} isExpand={isOpen}>
          {isOpen ? (
            <PlaylistMainInfo
              currentRole={currentRole}
              isRightSideBarExpanded={isShowRightSideBar || open}
              handleDownload={handleDownloadAll}
              isCanManyDownload={isCanManyDownload}
              refForScroll={refForScroll}
              cutMode={cutMode}
              open={open}
              setOpen={setOpen}
              smExpand
              lExpand={isShowRightSideBar}
              setViewMode={setViewMode}
              viewMode={viewMode}
              isForSharedToWeb={isForSharedToWeb}
              onDrop={onDrop}
              isDrag={isDrag}
              setDrag={setDrag}
              isImageUploading={isImageUploading}
              setImageUploading={setImageUploading}
              isDragFromDevice={isDragFromDevice}
              setDragFromDevice={setDragFromDevice}
              setUploadId={setUploadId}
              openCoverSettings={setIsActive}
              coverRef={coverRef}
            />
          ) : (
            <>
              <AllContentWrapper onClick={setOpen}>
                {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                  <>
                    {isCoEdit[currentRole] && (
                      <RoleDISPLAYCoedit>{t('coeditLowT')}</RoleDISPLAYCoedit>
                    )}
                    {isOwner[currentRole] && <RoleDISPLAYOwner>{t('ownerLowT')}</RoleDISPLAYOwner>}
                    {isViewer[currentRole] && <RoleDISPLAYView>{t('viewerLowT')}</RoleDISPLAYView>}
                  </>
                )}
                <ActiveContent isSidebarOpened={isSidebarOpened}>
                  {!isLargeRightBar
                    && (
                      <HeaderInfo>
                        <ImageAndName
                          itemId={id}
                          small={lExpand}
                          src={cropUrl || img?.src}
                          title={title}
                          isWithProgressBar={!isOwner[currentRole] && !isForSharedToWeb}
                          color={defaultPreviewColor}
                          isShowLibraryWidget={isShowLibraryWidget}
                          size={calcPlaylistSize}
                          coeditors={coEditors}
                          lastModified={lastModifiedDate}
                          setIsActive={setIsActive}
                          coverRef={coverRef}
                          elemsCount={Object.values(linkPages).length}
                          isSmall
                        />
                        <PlaylistMainControls
                          cutMode={cutMode}
                          open={open}
                          setOpen={setOpen}
                          smExpand
                          lExpand={isShowRightSideBar}
                          setViewMode={setViewMode}
                          viewMode={viewMode}
                          currentRole={currentRole}
                          isForSharedToWeb={isForSharedToWeb}
                          refForScroll={refForScroll}
                        />
                      </HeaderInfo>
                    )
                  }
                  <Controls>
                    <SwitchModeAndPublishBlock
                      isLargeRightBar={isLargeRightBar}
                      isOwner={isOwner[currentRole]}
                      isButtonsBlocked={isButtonsBlocked}
                      buttonText={buttonText}
                      isHasShare={isHasShare}
                      viewMode={viewMode}
                      setViewMode={setViewMode}
                      isFinished={isFinished}
                      wrapperId={wrapperId}
                      goToPlayer={goToPlayer}
                      enableShare={enableShare}
                      unshare={unshare}
                      isShowLibraryWidget={isShowLibraryWidget}
                      forCutHeader
                    />
                  </Controls>
                </ActiveContent>
              </AllContentWrapper>
            </>
          )}
        </HeaderWrapper>
        <UnionWrapper data-parent={DATA_PARENTS.PlaylistHeader} isOpen={isOpen} onClick={() => setOpen(!isOpen)}>
          <UnionPlaceholder />
          <Union />
          <UnionPlaceholder />
        </UnionWrapper>
        <ChevronWrapper data-parent={DATA_PARENTS.PlaylistHeader} onClick={() => setOpen(!isOpen)} isOpen={isOpen}>
          <ChevronLeft20Svg />
        </ChevronWrapper>
      </ContentWrapper>
      {!isForSharedToWeb && !isLargeRightBar && (
        <AvaModuleWrapper>
          <User
            isNew
            toggleUserMenu={toggleShowUserMenu}
            isActive={isComponentVisible}
            isMaker
          />
          {isComponentVisible && (
            <UserMenu
              refUserMenu={refUserMenu}
              setIsComponentVisible={setIsComponentVisible}
            />
          )}
        </AvaModuleWrapper>
      )}
      {createPortal(EditCover(), document.body)}
    </MakerCutHeaderWrapper>
  );
};

export default MakerCutHeader;
