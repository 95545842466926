import React, { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HelpIcon } from '../../images/icons/help_Icon_circle.svg';
import { ReactComponent as WhitePlusIcon } from '../../images/icons/icon_16/plus_16.svg';
import Trash from '../LeftSideBar/Trash';
import { actionCreator } from '../../shared/redux/actionHelper';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import { MainNavigate, ExtraTabs } from '../../navigate/navigate';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';
import {
  Bottom,
  ImageWrap,
  MainLeftSideBarShell,
  SelectorBoard,
  TrashWrapper,
  NewSFButton,
  CollapseWrapper,
  VersionWrapper,
  ButtonsWrapper,
  BackGroundWrapper
} from './styled';
import { CurrentPage } from '../../redux/currentPage/types';
import { createNewPlaylistAndJumpIntoIt } from '../../utils/helpers';
import { sortOptionChooser } from '../../utils/sort/sortTypeChooser';
import { actionSwitchContentSortType } from '../../redux/content/actions';
import WorkflowSection from './sidebar-sections/WorkflowSection';
import SmartfilesSection from './sidebar-sections/SmartfilesSection';
import ContactsSection from './sidebar-sections/ContactsSection';
import ChannelsSection from './sidebar-sections/ChannelsSection';
import LibrarySection from './sidebar-sections/LibrarySection';
import { sidebarModes } from '../../utils/constants';
import Tooltip from '../../shared/Tooltips/Tooltip';

const MainLeftSideBar = ({
  currentSidebarMode,
  collapseButtonMouseLeaveHandler,
  collapseButtonMouseEnterHandler,
  isOrganizer,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addFileInputRef = useRef();
  const {
    selectorType,
  } = useSelector((state) => state.content);
  const sortOptions = useSelector((state) => state.settings.sortOptions);
  const {
    tags,
    folderId,
    isAdmin,
  } = useSelector((state) => state.user);

  const createNewPlaylist = useCallback(() => {
    createNewPlaylistAndJumpIntoIt({ history, isAdmin, dispatch });
  }, [isAdmin]);


  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    [...e.target.files].forEach((item) => {
      const localTags = [];
      if (selectorType === 'tag' && folderId) {
        localTags.push({ ...tags[folderId] });
      }

      dispatch(
        actionCreateUpload({
          newId: v4(),
          file: item,
          title: item.name,
          type: item.type,
          history,
          isFavorite: selectorType === 'favorites',
          tags: localTags,
        }),
      );
    });
    addFileInputRef.current.value = '';
  };

  const onSelectorClick = (key) => {
    if (ExtraTabs[key]?.disabled) {
      return;
    }
    dispatch(actionRemoveAllSelected());
    dispatch(actionCreator(CurrentPage.clearId, {}));
    const firstSelector = ExtraTabs[key] ? ExtraTabs[key]?.selectorsOrder[0] : MainNavigate[key]?.selectorsOrder[0];
    const sortName = sortOptionChooser(key, firstSelector);
    if (sortName && sortOptions[sortName]) {
      dispatch(actionSwitchContentSortType(sortOptions[sortName]));
    }
    if (ExtraTabs[key]) {
      history.push(`/${key}/${firstSelector}`);
      return;
    }
    history.push(`/content/${key}/${firstSelector}`);
  };

  return (
    <MainLeftSideBarShell
      place="MainLeftSideBar"
      isCollapsed={currentSidebarMode === sidebarModes.collapsed || currentSidebarMode === sidebarModes.hovered}
      isHovered={currentSidebarMode === sidebarModes.hovered}
      onMouseLeave={collapseButtonMouseLeaveHandler}
      onMouseEnter={collapseButtonMouseEnterHandler}
      isOrganizer={isOrganizer}
    >
      <BackGroundWrapper isExpanded={currentSidebarMode === sidebarModes.visible}>
        <CollapseWrapper
          isCollapsed={currentSidebarMode === sidebarModes.collapsed}
          isHovered={currentSidebarMode === sidebarModes.hovered}
        >
          <NewSFButton
            onClick={createNewPlaylist}
          >
            <div data-cy="createEntity"><WhitePlusIcon />{t('createSmartfileT')} </div>
          </NewSFButton>
          <SelectorBoard isHovered={currentSidebarMode === sidebarModes.hovered}>
            <WorkflowSection
              onSelectorClick={onSelectorClick}
            />
            <SmartfilesSection
              createNewPlaylist={createNewPlaylist}
              sortOptions={sortOptions}
              isQuickAccess
            />
            <SmartfilesSection
              createNewPlaylist={createNewPlaylist}
              sortOptions={sortOptions}
            />
            <LibrarySection
              onSelectorClick={onSelectorClick}
              addFileInputRef={addFileInputRef}
              tags={tags}
              folderId={folderId}
            />
            <ContactsSection />
            <ChannelsSection sortOptions={sortOptions} />
          </SelectorBoard>
          <Bottom>
            <VersionWrapper>
              V.2.0.1
            </VersionWrapper>
            <ButtonsWrapper>
              <Tooltip
                text="Trash"
                direction="down"
                place="sidebarBottom"
              >
                <TrashWrapper data-cy="trash">
                  <Trash
                    isPlaylist={history.location.pathname.includes('/smartfiles')}
                  />
                </TrashWrapper>
              </Tooltip>
              <Tooltip
                text="Help"
                direction="down"
                place="sidebarBottom"
              >
                <ImageWrap
                  data-cy="help"
                  active={history.location.pathname === '/help'}
                  onClick={() => history.push('/help')}
                  place="help"
                >
                  <HelpIcon />
                </ImageWrap>
              </Tooltip>
            </ButtonsWrapper>
          </Bottom>
          <input
            type="file"
            name="file"
            multiple
            ref={addFileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => {
              submitForm(e);
            }}
          />
        </CollapseWrapper>
      </BackGroundWrapper>
    </MainLeftSideBarShell>
  );
};

export default MainLeftSideBar;
