import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SingleContactWrapper,
  UserWrapper, TextWrapper, EmojiWrapper, SingleContactWrapperHover,
} from './styles';
import ContactAvatar from '../../../Channels/MemberRow/CalculatedContactAvatar';
import Tooltip from '../../../../shared/Tooltips/Tooltip';


const useVisibility = (
  sectionRef: RefObject<HTMLDivElement>,
  elementRef: RefObject<HTMLDivElement>,
): boolean => {
  const [isTopPosition, setIsTopPosition] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current && elementRef.current) {
        const sectionRect = sectionRef.current.getBoundingClientRect();
        const elementRect = elementRef.current.getBoundingClientRect();
        const sectionMiddle = sectionRect.top + sectionRect.height / 2;
        setIsTopPosition(elementRect.top < sectionMiddle);
      }
    };

    const sectionElement = sectionRef.current;
    if (sectionElement) {
      sectionElement.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (sectionElement) {
        sectionElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [sectionRef, elementRef]);

  return isTopPosition;
};

interface IReactionsPopup {
    contacts: {[key:string]: {username: string, email: string}},
    yourId: string,
    reactHandler: (id:string, reaction: string) => void,
    currentEmoji: string,
    idUserReacted: string,
    sectionRef:React.RefObject<HTMLDivElement>
}
export const ReactionUserRow: React.FC<IReactionsPopup> = ({
  contacts,
  yourId,
  reactHandler,
  currentEmoji,
  idUserReacted,
  sectionRef,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const isTopPosition = useVisibility(sectionRef, elementRef);
  const { t } = useTranslation();

  if (yourId === idUserReacted) {
    return (
      <SingleContactWrapperHover>
        <Tooltip
          text={t('ClickToRemoveT')}
          direction="none"
          place={!isTopPosition ? 'UserToolTipInReactionOnTop' : 'UserToolTipInReactionOnBottom'}
        >
          <SingleContactWrapper
            ref={elementRef}
            className="text-unified-xs"
            onClick={() => reactHandler(idUserReacted, currentEmoji)}
            key={idUserReacted + currentEmoji}
          >
            <UserWrapper>
              <ContactAvatar item={contacts[idUserReacted]} isPopup={false} />
              <TextWrapper>
                <p>You</p>
                <p className="email">{contacts[idUserReacted]?.email}</p>
              </TextWrapper>
            </UserWrapper>
            <EmojiWrapper>
              {currentEmoji}
            </EmojiWrapper>
          </SingleContactWrapper>
        </Tooltip>
      </SingleContactWrapperHover>
    );
  }

  return (
    <SingleContactWrapper
      ref={elementRef}
      className="text-unified-xs"
      onClick={() => reactHandler(idUserReacted, currentEmoji)}
      key={idUserReacted + currentEmoji}
    >
      <UserWrapper>
        <ContactAvatar item={contacts[idUserReacted]} isPopup={false} />
        <TextWrapper>
          <p>{yourId === idUserReacted ? 'You' : contacts[idUserReacted]?.username}</p>
          <p className="email">{contacts[idUserReacted]?.email}</p>
        </TextWrapper>
      </UserWrapper>
      <EmojiWrapper>
        {currentEmoji}
      </EmojiWrapper>
    </SingleContactWrapper>
  );
};
