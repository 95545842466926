import { ContentActionType } from './contentTypes';
import downloadStatus from '../../utils/dataUtil';
import { LibraryComponents } from '../library/types';
import EditPlaylist from '../playlists/types';
import { Tags } from '../tags/types';
import SupportAction from '../support/types';
import { existFilesInTab, LIMIT_ITEMS_PAGINATION } from '../../utils/constants';
import { Channels } from '../channels/types';
import { MiniPlaylists } from '../miniPlaylists/types';
import Contacts from '../contacts/types';
import { dateGroupEnum } from '../../utils/libraryContentSelector/filterNames';
import { sanitizeToLoad } from '../../utils/helpers';
import draftDataConvertToLoad from '../../utils/draftDataConvert';

const defaultState = {
  contentIds: [],
  loadStatus: downloadStatus.init,
  contentData: {},
  counters: {},
  prevSortType: '',
};

function calcState(state, type) {
  try {
    if (!state) return state;
    if (typeof state === 'object') return state;
    if (type === 'embed') return JSON.parse(sanitizeToLoad(state));
    return draftDataConvertToLoad(state);
  } catch (e) {
    return state;
  }
}

const contentReducer = (state = defaultState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SupportAction.InitialDataFetch: {
      if (state.sortType) {
        return {
          ...defaultState,
          sortType: state.sortType,
          counters: payload.counters,
        };
      }
      return { ...defaultState, counters: payload.counters };
    }
    case ContentActionType.updateCounter: {
      return {
        ...defaultState,
        ...state,
        counters: { ...state.counters, ...payload },
      };
    }
    case EditPlaylist.UnPublishS: {
      return {
        ...defaultState,
        ...state,
        counters: { ...state.counters, ...{ 'smartfiles/published': state.counters['smartfiles/published'] - 1 } },
      };
    }
    case ContentActionType.incrementCounters: {
      const newCounterState = { ...state.counters };
      Object.keys(state.counters).forEach((key) => {
        if (payload[key]) {
          newCounterState[key] += payload[key];
        }
      });
      return {
        ...defaultState,
        ...state,
        counters: { ...state.counters, ...newCounterState },
      };
    }
    case Channels.UpdateChannelChangeUserR: {
      const { user } = payload;
      return {
        ...state,
        contentData: {
          ...state.contentData,
          [user.id]: {
            ...state.contentData[user.id],
            ...user,
          },
        },
      };
    }
    case Channels.ChangeChannelUserStatus: {
      const { userId, newStatus } = payload;
      if (newStatus === 'Deleted') {
        const newContentData = { ...state.contentData };
        delete newContentData[userId];
        return {
          ...state,
          contentData: newContentData,
        };
      }
      return state;
    }
    case ContentActionType.startUpload: {
      const { noNeedUpdate = false } = payload;
      const isDynamicCollectionNew = payload.activeNavSlider === 'dynamicCollectionNew';
      const hasChangeInSelectors = state.selectorType !== payload.selectorType
        || state.sortType !== payload.sortType
        || state.activeNavSlider !== payload.activeNavSlider
        || state.filter !== payload.filter;

      const isNewState = !isDynamicCollectionNew && hasChangeInSelectors && !noNeedUpdate;
      if (isNewState || payload.isNeedUpdate) {
        return {
          ...defaultState,
          sortType: state.sortType,
          counters: state.counters,
          loadStatus: downloadStatus.pending,
          prevSortType: state.prevSortType,
          ...payload,
        };
      }
      return {
        ...state,
        loadStatus: downloadStatus.pending,
        sortType: state.sortType,
        ...payload,
      };
    }

    case ContentActionType.previousSortType: {
      return { ...state, prevSortType: payload };
    }
    case SupportAction.updateTag.updateContentTags: {
      return { ...state };
    }
    case ContentActionType.finishUpload: {
      if (payload.isCleaning) {
        return {
          ...state,
          contentData: payload.content,
          contentIds: [...new Set(Object.keys(payload.content || {}))],
          loadStatus: downloadStatus.success,
        };
      }
      return {
        ...state,
        contentData: payload.content,
        contentIds: [...new Set(Object.keys(payload.content || {}))],
        counters: {
          ...state.counters,
          [`${state.activeNavSlider}/${state.selectorType}`]: Object.keys(
            payload.content || {},
          ).length,
        },
        loadStatus: downloadStatus.success,
      };
    }
    case ContentActionType.finishUploadCount: {
      const contentData = { ...state.contentData, ...payload.content };
      Object.values(contentData).forEach(({ components }) => {
        components?.forEach((component) => {
          component.state = calcState(component.state, component.type);
        });
      });
      const newContentIds = [
        ...new Set([
          ...state.contentIds,
          ...Object.keys(payload.content || {}),
        ]),
      ];
      const calcIsHasMore = Object.keys(payload.content || {}).length === LIMIT_ITEMS_PAGINATION;
      return {
        ...state,
        contentData,
        calcMap: payload.calcMap,
        contentIds: newContentIds,
        loadStatus: downloadStatus.success,
        existMore: calcIsHasMore,
      };
    }
    case LibraryComponents.UpdateLibraryComponent: {
      const now = Math.round(new Date().getTime() / 1000);
      const { id, value, field } = payload;

      return {
        ...state,
        contentData: {
          ...state.contentData,
          [id]: {
            ...state.contentData[id],
            [field]: value,
            lastModifiedDate: now,
          },
        },
      };
    }
    case LibraryComponents.AddComponent: {
      const { id, type } = action.payload;

      const isFileExitInCurrentTab = existFilesInTab[`${state.activeNavSlider}/${state.selectorType}`]
        && existFilesInTab[`${state.activeNavSlider}/${state.selectorType}`][type];

      if (!isFileExitInCurrentTab) return state;

      const newOrder = [id, ...state.contentIds];

      return {
        ...state,
        contentIds: newOrder,
        contentData: {
          [id]: {
            ...payload,
            belongsToGroup: dateGroupEnum.TodayPeriod,
          },
          ...state.contentData,
        },
      };
    }
    case EditPlaylist.Add: {
      const [{ id }] = Object.values(action.payload);
      const newOrder = [id, ...state.contentIds];

      return {
        ...state,
        contentIds: newOrder,
        contentData: {
          ...state.contentData,
          ...action.payload,
        },
      };
    }

    case EditPlaylist.Update: {
      const { id, title } = action.payload;
      return {
        ...state,
        contentData: {
          ...state.contentData,
          [id]: {
            ...state.contentData[id],
            title,
          },
        },
      };
    }

    case LibraryComponents.ToggleFavorite: {
      const { componentId, isFavorite } = action.payload;
      if (state.selectorType !== 'favorites') {
        return {
          ...state,
          contentData: {
            ...state.contentData,
            [componentId]: {
              ...state.contentData[componentId],
              isFavorite,
            },
          },
        };
      }
      if (!isFavorite) {
        const index = state.contentIds.findIndex((id) => componentId === id);
        const newOrder = [
          ...state.contentIds.slice(0, index),
          ...state.contentIds.slice(index + 1),
        ];
        const newState = {
          ...state,
          contentIds: newOrder,
          contentData: {
            ...state.contentData,
          },
        };
        delete newState.contentData[componentId];
        return newState;
      }

      const { block, index } = action.payload;
      const newOrder = [
        ...state.contentIds.slice(0, index),
        componentId,
        ...state.contentIds.slice(index),
      ];
      return {
        ...state,
        contentIds: newOrder,
        contentData: {
          ...state.contentData,
          [componentId]: block,
        },
      };
    }
    case EditPlaylist.ToggleFavorite: {
      const { id, isFavorite } = action.payload;
      const isFavoriteInChannel = state.activeNavSlider === 'channelFavoritePlaylists';

      if (state.selectorType !== 'favorites' && !isFavoriteInChannel) {
        const contentData = {
          ...state.contentData,
          [id]: {
            ...state.contentData[id],
            isFavorite,
          },
        };

        return {
          ...state,
          contentData,
        };
      }

      if (!isFavorite) {
        const index = state.contentIds.findIndex((itemId) => itemId === id);
        const newOrder = [
          ...state.contentIds.slice(0, index),
          ...state.contentIds.slice(index + 1),
        ];
        const newState = {
          ...state,
          contentIds: newOrder,
          contentData: {
            ...state.contentData,
          },
        };
        delete newState.contentData[id];
        return newState;
      }

      const { block, index = 0 } = action.payload;

      const newOrder = [
        ...state.contentIds.slice(0, index),
        id,
        ...state.contentIds.slice(index),
      ];

      return {
        ...state,
        contentIds: newOrder,
        contentData: {
          ...state.contentData,
          [id]: block,
        },
      };
    }
    case ContentActionType.removeUnsubscribeLibraryEntity: {
      const { unsubscribeIdsObj } = action.payload;
      const newOrder = state.contentIds.reduce((acc, cur) => {
        if (unsubscribeIdsObj[cur]) {
          return acc;
        }
        return [...acc, cur];
      }, []);
      const newState = {
        ...state,
        contentIds: newOrder,
      };
      Object.keys(unsubscribeIdsObj).map(id => delete newState.contentData[id]);
      return newState;
    }

    case LibraryComponents.MoveComponentToTrash: {
      const { componentId } = action.payload;
      const index = state.contentIds.findIndex((id) => componentId === id);
      const newOrder = [
        ...state.contentIds.slice(0, index),
        ...state.contentIds.slice(index + 1),
      ];
      const newState = {
        ...state,
        contentData: { ...state.contentData },
        contentIds: newOrder,
      };
      delete newState.contentData[componentId];
      return newState;
    }
    case LibraryComponents.RestoreComponentFromTrash: {
      const { componentId, block, index } = action.payload;

      const newOrder = [
        ...state.contentIds.slice(0, index),
        componentId,
        ...state.contentIds.slice(index),
      ];

      return {
        ...state,
        contentIds: newOrder,
        contentData: {
          ...state.contentData,
          [componentId]: block,
        },
      };
    }
    case EditPlaylist.DuplicatePlaylist: {
      return state;
    }
    case LibraryComponents.UpdateLibraryComponentInRedux: {
      const { id, isNotNeedUpdateDateGroup } = action.payload;
      const componentType = action.payload.type || state.contentData[id]?.type;
      const currentTab = `${state.activeNavSlider}/${state.selectorType}`;
      const isFileExitInCurrentTab = existFilesInTab[currentTab] && existFilesInTab[currentTab][componentType];
      if (!isFileExitInCurrentTab) return state;
      const subUpdate = {};
      if (!isNotNeedUpdateDateGroup) {
        subUpdate.belongsToGroup = dateGroupEnum.TodayPeriod;
      }

      return {
        ...state,
        contentData: {
          ...state.contentData,
          [id]: {
            ...state.contentData[id],
            ...action.payload,
            ...subUpdate,
          },
        },
      };
    }
    case EditPlaylist.updateMoveToTrash: {
      const { id: componentId } = action.payload;
      if (payload.state) {
        const index = state.contentIds.findIndex((id) => componentId === id);
        let newOrder;
        if (index + 1) {
          newOrder = [
            ...state.contentIds.slice(0, index),
            ...state.contentIds.slice(index + 1),
          ];
        } else {
          newOrder = state.contentIds;
        }

        return {
          ...state,
          contentIds: newOrder,
          contentData: {
            ...state.contentData,
            [payload.id]: {
              ...state.contentData[payload.id],
              moveToTrash: true,
            },
          },
        };
      }
      const { block, index } = action.payload;

      const newOrder = [
        ...state.contentIds.slice(0, index),
        componentId,
        ...state.contentIds.slice(index),
      ];

      return {
        ...state,
        contentIds: newOrder,
        contentData: {
          ...state.contentData,
          [componentId]: block,
        },
      };
    }

    case Tags.AttachMultipleTagsToLibraryComponent: {
      const { tags, libraryComponent, lastModifiedDate } = payload;
      if (!state.contentData[libraryComponent.id]) return state;
      return {
        ...state,
        contentData: {
          ...state.contentData,
          [libraryComponent.id]: {
            ...state.contentData[libraryComponent.id],
            tags: [...state.contentData[libraryComponent.id]?.tags, ...tags],
            lastModifiedDate,
          },
        },
      };
    }
    case Tags.ReplaceTagsInLibraryComponent: {
      const { tags, libraryComponent, lastModifiedDate } = payload;

      return {
        ...state,
        contentData: {
          ...state.contentData,
          [libraryComponent.id]: {
            ...state.contentData[libraryComponent.id],
            tags: [...tags],
            lastModifiedDate,
          },
        },
      };
    }
    case Tags.DeleteTagFromSystem: {
      const { tagId } = payload;
      const updComponents = {};
      Object.keys(state.contentData).forEach((keyComp) => {
        if (
          !state.contentData[keyComp]?.tags?.find((tag) => tag.id === tagId)
        ) {
          updComponents[keyComp] = state.contentData[keyComp];
        } else {
          updComponents[keyComp] = {
            ...state.contentData[keyComp],
            tags: state.contentData[keyComp]?.tags?.filter(
              (tagInComp) => tagInComp.id !== tagId,
            ),
          };
        }
      });
      return { ...state, contentData: updComponents };
    }
    case Tags.DeleteTag: {
      const { tag, type: localType, parent } = payload;
      if (localType !== 'LibraryComponent' || !state.contentData[parent.id]) return state;
      state.contentData[parent.id].tags = [
        ...state.contentData[parent.id]?.tags.filter(
          (item) => item.id !== tag.id,
        ),
      ];
      state.contentData[parent.id].lastModifiedDate = parent.lastModifiedDate;
      return { ...state, contentData: { ...state.contentData } };
    }
    case Tags.MultipleDeleteTag: {
      const { tag, type: localType, parents } = payload;
      if (localType !== 'LibraryComponent') return state;

      parents.forEach((parent) => {
        if (!state.contentData[parent.id]) return;
        state.contentData[parent.id].tags = [
          ...state.contentData[parent.id]?.tags.filter(
            (item) => item.id !== tag.id,
          ),
        ];
        state.contentData[parent.id].lastModifiedDate = parent.lastModifiedDate;
      });
      return { ...state, contentData: { ...state.contentData } };
    }
    case SupportAction.updateTag.DeleteLocal: {
      const { tag, type: localType, parent } = payload;
      if (localType !== 'LibraryComponent' || !state.contentData[parent.id]) return state;
      state.contentData[parent.id].tags = [
        ...state.contentData[parent.id]?.tags.filter(
          (item) => item.id !== tag.id,
        ),
      ];
      state.contentData[parent.id].lastModifiedDate = parent.lastModifiedDate;
      return { ...state, contentData: { ...state.contentData } };
    }
    case Tags.actionAttachTagParentRedux: {
      const { tag, type: localType, parent, lastModifiedDate } = payload;
      if (localType !== 'LibraryComponent') return state;
      const newState = { ...state };
      newState.contentData[parent.id].tags = [
        ...(newState.contentData[parent.id].tags || []),
        tag,
      ];
      newState.contentData[parent.id].lastModifiedDate = lastModifiedDate;
      return newState;
    }
    case Tags.actionAttachTagToParentsRedux: {
      const { tag, type: localType, parents, lastModifiedDate } = payload;
      if (localType !== 'LibraryComponent') return state;
      const newState = { ...state };
      parents.forEach((parent) => {
        newState.contentData[parent.id].tags = [
          ...(newState.contentData[parent.id].tags || []),
          tag,
        ];
        newState.contentData[parent.id].lastModifiedDate = lastModifiedDate;
      });
      return newState;
    }
    case Tags.actionAttachTagsToParentRedux: {
      const { tags, selectedPage, lastModifiedDate } = payload;
      const newContentData = {};
      Object.keys(selectedPage).forEach((id) => {
        newContentData[id] = {
          ...state.contentData[id],
          tags: [
            ...state.contentData[id].tags,
            ...tags.reduce((acc, tag) => {
              if (state.contentData[id].tags.some((t) => t.id === tag.id)) return acc;
              acc.push(tag);
              return acc;
            }, []),
          ],
          lastModifiedDate,
        };
      });
      return {
        ...state,
        contentData: { ...state.contentData, ...newContentData },
      };
    }

    case Tags.attachTagParentAndCurrentPageRedux: {
      const { tag, type: localType, parent, lastModifiedDate } = payload;
      if (localType !== 'LibraryComponent') return state;
      state.contentData[parent.id].tags = [
        ...(state.contentData[parent.id].tags || []),
        tag,
      ];
      state.contentData[parent.id].lastModifiedDate = lastModifiedDate;
      return { ...state, contentData: { ...state.contentData } };
    }
    case Tags._TagsBulkUpdate: {
      const { tags, type: localType, componentId, lastModifiedDate } = payload;
      if (localType !== 'LibraryComponent') return state;
      state.contentData[componentId].tags = tags;
      state.contentData[componentId].lastModifiedDate = lastModifiedDate;
      return { ...state, contentData: { ...state.contentData } };
    }
    case Tags.ChangeTagTitle: {
      const { id, newTitle, type: localType, libraryComponentId } = payload;

      if (localType !== 'LibraryComponent' && !libraryComponentId) return state;
      if (libraryComponentId && state.contentData[libraryComponentId]) {
        state.contentData[libraryComponentId].tags = state.contentData[
          libraryComponentId
        ].tags.map((item) => {
          if (item.id === id) {
            return { ...item, title: newTitle };
          }
          return item;
        });
      }
      return { ...state, contentData: { ...state.contentData } };
    }
    case Tags.ChangeTagColor: {
      const {
        id,
        newColor,
        type: localType,
        newBackgroundColor,
        libraryComponentId,
      } = payload;
      if (localType !== 'LibraryComponent' && !libraryComponentId) return state;
      if (libraryComponentId && state.contentData[libraryComponentId]) {
        state.contentData[libraryComponentId].tags = state.contentData[
          libraryComponentId
        ].tags.map((item) => {
          if (item.id === id) {
            const newItem = { ...item };
            if (newColor) newItem.color = newColor;
            if (newBackgroundColor) newItem.backgroundColor = newBackgroundColor;
            return newItem;
          }
          return item;
        });
      }
      return { ...state, contentData: { ...state.contentData } };
    }
    case LibraryComponents.actionAddLibraryComponentREDUX: {
      const componentType = action.payload.type || state.contentData[payload.id]?.type;
      const currentTab = `${state.activeNavSlider}/${state.selectorType}`;
      const isFileExitInCurrentTab = existFilesInTab[currentTab] && existFilesInTab[currentTab][componentType];
      if (!isFileExitInCurrentTab) return state;

      return {
        ...state,
        contentData: {
          ...state.contentData,
          [payload.id]: {
            ...payload,
            itemType: 'component',
            belongsToGroup: dateGroupEnum.TodayPeriod,
          },
        },
      };
    }

    case LibraryComponents.Pages.AddPageInLibraryRS: {
      return {
        ...state,
        contentData: {
          ...payload.page,
          ...state.contentData,
        },
        contentIds: [...Object.keys(payload.page), ...state.contentIds],
      };
    }
    case LibraryComponents.Pages.UpdatePageInLibraryField: {
      const {
        page: { id, value, field },
      } = payload;

      return {
        ...state,
        contentData: {
          ...state.contentData,
          [id]: { ...state.contentData[id], [field]: value },
        },
      };
    }
    case ContentActionType.clearContentDataR: {
      return { ...defaultState };
    }
    case ContentActionType.updateSpecificElementR: {
      const { content, id } = payload;
      return {
        ...state,
        contentData: {
          ...state.contentData,
          [id]: { ...state.contentData[id], ...content[id] },
        },
      };
    }
    case EditPlaylist.UpdatePlaylist: {
      const { id, field, value } = payload;
      if (state.contentData[id]) {
        return {
          ...state,
          contentData: {
            ...state.contentData,
            [id]: { ...state.contentData[id], [field]: value },
          },
        };
      }
      return state;
    }
    case ContentActionType.updatePlaylistR: {
      const { content, idRemove: playlistManagerId } = payload;
      const newItem = Object.values(content || {})[0];
      const oldItem = Object.values(state.contentData).find(
        (i) => i.playlistManagerId === playlistManagerId
          || i.wrapperId === playlistManagerId,
      ) || state.contentData[playlistManagerId];
      const contentData = { ...state.contentData };
      let contentIds = state.contentIds;
      // const oldCounterAllPlaylist = contentIds.length;
      if (oldItem) {
        contentIds = state.contentIds.filter((i) => i !== oldItem.id);
        delete contentData[oldItem.id];
      }
      if (newItem) {
        contentData[newItem.id] = newItem;
        contentIds = [newItem.id, ...contentIds];
      }

      // const needUpdCounter = oldCounterAllPlaylist !== contentIds.length;
      // if (needUpdCounter) {
      //   const isDecrement = oldCounterAllPlaylist > contentIds.length;
      //   if (isDecrement) {
      //     // --playlistsAll;
      //     // --playlistsNew;
      //     if (type === 'shared') {
      //       --playlistsShared;
      //     }
      //   } else {
      //     // ++playlistsAll;
      //     // ++playlistsNew;
      //     if (type === 'shared') {
      //       ++playlistsShared;
      //     }
      //   }
      // }
      return {
        ...state,
        contentData,
        contentIds,
      };
    }
    case ContentActionType.updatePageR: {
      const { content } = payload;
      const newItem = Object.values(content || {})[0];
      const libCompId = newItem.libraryComponentId;
      const oldItem = Object.values(state.contentData).find(
        (i) => i.libraryComponentId === libCompId,
      );

      const contentData = { ...state.contentData };
      let contentIds = state.contentIds;
      if (oldItem) {
        contentIds = state.contentIds.filter((i) => i !== oldItem.id);

        delete contentData[oldItem.id];
      }
      if (newItem) {
        contentData[newItem.id] = newItem;
        contentIds = [newItem.id, ...contentIds];
      }
      return {
        ...state,
        contentData,
        contentIds,
        counters: {
          ...state.counters,
          'pages/shared': contentIds.length,
        },
      };
    }
    case ContentActionType.removePlaylistAfterSageR: {
      const { wrapperId, type } = payload;
      let playlistsShared = state.counters['smartfiles/shared'];

      const oldItem = Object.values(state.contentData).find(
        (i) => i.playlistManagerId === wrapperId || i.wrapperId === wrapperId,
      );
      const contentData = { ...state.contentData };
      let contentIds = state.contentIds;

      if (oldItem) {
        contentIds = state.contentIds.filter((i) => i !== oldItem.id);
        delete contentData[oldItem.id];

        // --playlistsAll;
        // --playlistsNew;
        if (type === 'shared') {
          --playlistsShared;
        }
      }
      return {
        ...state,
        contentData,
        contentIds,
        counters: {
          ...state.counters,
          'smartfiles/shared': playlistsShared,
        },
      };
    }
    case ContentActionType.removeSharedPage: {
      const { pageId } = payload;
      const isInSHared = state.activeNavSlider === 'pages' && state.selectorType === 'shared';
      if (!isInSHared) return state;

      const oldItem = Object.values(state.contentData).find(
        (i) => i.libraryComponentId === pageId,
      );
      const contentData = { ...state.contentData };
      let contentIds = state.contentIds;

      if (oldItem) {
        contentIds = state.contentIds.filter((i) => i !== oldItem.id);
        delete contentData[oldItem.id];
      }
      return {
        ...state,
        contentData,
        contentIds,
        counters: {
          ...state.counters,
          'pages/shared': contentIds.length,
        },
      };
    }
    // case LibraryComponents.Pages.ReplaceLibraryPageComponentsR: {
    //   const { id, newComponent } = payload;
    //
    //   const reduxComponent = { ...state.contentData[id] };
    //
    //   if (!reduxComponent || !reduxComponent?.components) return state;
    //   const componentId = newComponent.id;
    //   const componentToChange = reduxComponent.components.find(
    //     (item) => item.id === componentId,
    //   );
    //   let changedComponents = [];
    //   if (!componentToChange) {
    //     changedComponents = [...reduxComponent.components, newComponent];
    //   } else {
    //     changedComponents = [
    //       ...reduxComponent.components.filter(
    //         (item) => item.id !== componentId,
    //       ),
    //       newComponent,
    //     ];
    //   }
    //
    //   const lastModifiedDate = Math.floor(Date.now() / 1000);
    //
    //   return {
    //     ...state,
    //     contentData: {
    //       ...state.contentData,
    //       [id]: {
    //         ...state.contentData[id],
    //         lastModifiedDate,
    //         components: changedComponents,
    //       },
    //     },
    //   };
    // }
    case EditPlaylist.UpdateCardStateR: {
      const changePlaylist = Object.values(state.contentData || {}).find(
        (playlist) => playlist.wrapperId === payload.wrapperId,
      );
      if (!changePlaylist) return state;

      const newChangePlaylist = { ...changePlaylist };
      return {
        ...state,
        contentData: {
          ...state.contentData,
          [newChangePlaylist.id]: {
            ...newChangePlaylist,
            inColumn: payload.addToColumn,
          },
        },
      };
    }
    case Channels.SetPlaylistButtonsStatesR: {
      const { playlistId, channelId, buttonsState } = payload;
      if (!playlistId || !channelId || !state.contentData[playlistId]) return state;

      const updatedPlaylist = {
        ...state.contentData[playlistId],
        usedInChannels: {
          ...state.contentData[playlistId].usedInChannels,
          [channelId]: {
            ...state.contentData[playlistId].usedInChannels[channelId],
            ...buttonsState,
            id: channelId,
            isLoaded: true,
          },
        },
      };

      return {
        ...state,
        contentData: { ...state.contentData, [playlistId]: updatedPlaylist },
      };
    }
    case MiniPlaylists.ToggleHide: {
      const { playlistId, isHide } = payload;

      if (playlistId && state.contentData[playlistId]) {
        return {
          ...state,
          contentData: {
            ...state.contentData,
            [playlistId]: {
              ...state.contentData[playlistId],
              isHide,
            },
          },
        };
      }
      return { ...state };
    }
    case Channels.AddSubscribersR: {
      const { channelId, users } = payload;

      if (
        channelId === state.selectorType
        && state.activeNavSlider === 'channelUsers'
      ) {
        return { ...state, contentData: { ...users, ...state.contentData } };
      }

      return state;
    }
    case Contacts.AddContactR: {
      const { item } = payload;
      return {
        ...state,
        contentData: {
          [item.id]: { ...item },
          ...state.contentData,
        },
        counters: { ...state.counters, contact: state.counters.contact + 1 },
      };
    }
    case Contacts.ChangeContactR: {
      const { item } = payload;
      const newStateDate = { ...state.contentData };
      if (!newStateDate[item.contactId]) return state;
      if (item.memberId) {
        newStateDate[item.memberId] = { ...newStateDate[item.memberId],
          inContact: { ...newStateDate[item.memberId].inContact, ...item, function: item.userFunction } };
      } else {
        newStateDate[item.contactId ?? item.id] = { ...newStateDate[item.contactId ?? item.id], ...item };
      }
      return { ...state, contentData: newStateDate };
    }
    case Contacts.ChangeContactStatus: {
      const { id, status } = payload;
      const newItem = { ...state.contentData[id] };
      newItem.status = status;
      return {
        ...state,
        contentData: { [newItem.id]: { ...newItem }, ...state.contentData },
      };
    }
    case Contacts.RemoveContact: {
      const { contactId } = payload;
      const newContentData = { ...state.contentData };
      delete newContentData[contactId];

      return {
        ...state,
        contentData: newContentData,
        counters: { ...state.counters, contact: state.counters.contact - 1 },
      };
    }
    case SupportAction.RemoveItemInRedux: {
      const { id } = payload;
      const contentIds = state.contentIds.filter((i) => i !== id);
      return {
        ...state,
        contentIds,
      };
    }
    case SupportAction.RemoveItemContentInRedux: {
      const { id } = payload;
      const contentIds = state.contentIds.filter((i) => i !== id);
      const updateContentData = { ...state.contentData };
      delete updateContentData[id];
      return {
        ...state,
        contentIds,
        contentData: updateContentData,
      };
    }
    case SupportAction.AddItemInRedux: {
      const { id } = payload;
      if (state.contentData[id] && !state.contentIds.includes(id)) {
        const contentIds = [id, ...state.contentIds];
        return {
          ...state,
          contentIds,
        };
      }
      return state;
    }
    case SupportAction.updateTag.Update: {
      const { id, contentTags } = payload;
      const updateComp = { ...state.contentData[id], contentTags };
      return {
        ...state,
        contentData: { ...state.contentData, [id]: updateComp },
      };
    }
    case SupportAction.updateTag.Clear: {
      const selectedPage = payload?.selectedPage;
      if (!selectedPage) return state;
      const newContentData = { ...state.contentData };
      Object.keys(selectedPage).forEach((id) => {
        newContentData[id].tags = newContentData[id].contentTags;
      });
      return {
        ...state,
        contentData: { ...newContentData },
      };
    }
    case ContentActionType.ShareMultipleR: {
      const { ids, shareState } = payload;

      const newContentData = { ...state.contentData };
      ids.forEach((i) => {
        newContentData[i] = { ...newContentData[i], ...shareState };
      });
      return {
        ...state,
        contentData: newContentData,
      };
    }
    case ContentActionType.SwitchContentSortType: {
      return {
        ...state,
        sortType: payload,
      };
    }
    case ContentActionType.MovePlaylistToShare: {
      const { playlistsIds } = payload;
      const isDraftPlaylist = state.activeNavSlider === 'smartfiles' && state.selectorType === 'drafts';
      if (!isDraftPlaylist) return state;
      const newContentData = { ...state.contentData };
      playlistsIds.forEach(id => {
        delete newContentData[id];
      });
      return {
        ...state,
        contentIds: state.contentIds.filter(id => !playlistsIds.includes(id)),
        contentData: newContentData,
      };
    }
    case Contacts.UpdatePinState: {
      const { id } = payload;
      if (!state.contentData[id]) return state;
      return {
        ...state,
        contentData: {
          ...state.contentData,
          [id]: {
            ...state.contentData[id],
            isUnpin: !state.contentData[id].isUnpin,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default contentReducer;
