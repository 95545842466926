import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as LockedSVG } from '../../images/2023/svg/preview/lock_24_24.svg';
import { ReactComponent as MoreSVG } from '../../images/2023/svg/preview/more_24_24.svg';
import { ReactComponent as CloseSVG } from '../../images/2023/svg/preview/close_24_24.svg';
import { actionCloseModal } from '../../redux/user/action';
import ThreeDotsDropDownUPV from '../../pages/Maker/MakerCommon/Table/ThreeDotsDropDown';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { actionJumpToWithConfirmation } from '../../redux/support/action';
import { actionPageWillDownload } from '../../redux/currentPage/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import { HeaderControlsSVGWrapper, HeaderControlsWrapper } from './styled';
import EditIcon from '../EditIcon';
import ZoomControls from '../../shared/ZoomControls';


const HeaderControls = ({ isLocked, item, activeN, isForLib, goNext, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [refMenu, isActive, setIsActive] = useComponentVisible(
    false,
    'miniPage',
  );

  const currentPage = useSelector((state) => state.currentPage);
  const { id: userId } = useSelector((state) => state.user);
  const lc = item?.libraryComponent || item;

  const isComponent = lc.type === 'component';
  const isLink = lc.type === 'text/html';
  const isSharedToMePage = lc.type === 'sharedPage';
  const isLinkOrComponent = isComponent || isLink;
  const isForSharedModeMaker = history.location.pathname.startsWith('/shared_playlist');

  const { isContentEditable, id: playlistId, socketId } = currentPage;
  const isOwner = userId === item?.owner?.id
    || (item?.users?.length && userId === item?.users[0].id);

  const close = () => {
    props.beforeCloseHandler();
  };

  const { unseenInChannel, unseenPlaylistManagerMapInAll } = useSelector(
    (state) => state.support,
  );
  const { channelId = null } = useParams();
  const isChannels = !!channelId;
  const newGoToPlaylist = (event, index) => {
    event.stopPropagation();
    dispatch(actionPageWillDownload());
    const isShared = currentPage.status === 'shared';
    if (!isChannels && unseenPlaylistManagerMapInAll[currentPage.wrapperId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistR, {
          playlistManagerId: currentPage.wrapperId,
        }),
      );
    }
    if (
      isChannels
      && unseenInChannel[channelId]
      && unseenInChannel[channelId][currentPage.playlistManagerId]
    ) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistInChannel, {
          channelId,
          playlistId: currentPage.id,
          playlistManagerId: currentPage.playlistManagerId,
        }),
      );
    }

    // if (specifiedAction === GO_TO_PLAYER_VIEW) {
    if (isShared) {
      history.push(`/player/shared/${currentPage.id}/${index}`);
      return;
    }
    const firstChannelId = channelId
      || (isChannels
        && currentPage?.usedInChannels
        && Object.values(currentPage?.usedInChannels)[0]?.id)
      || 'preview';

    history.push(`/player/${firstChannelId}/${currentPage.id}/${index}`);
    // }
  };
  const goToItem = (e) => {
    if (item?.libraryComponent?.type === 'page' && !isForLib) {
      history.push(`/libraryPage/${item?.libraryComponent?.id}`);
      close();
      return;
    }
    e.stopPropagation();
    dispatch(actionJumpToWithConfirmation(item.id));
    dispatch(actionPageWillDownload());
    history.push(`/maker/${playlistId}/edit`);
  };
  const isEditable = lc?.type === 'page' || lc?.type === 'component';

  useEffect(() => {
    setIsActive(false);
  }, [activeN]);

  return (
    <HeaderControlsWrapper>
      {props.isShowResizeControls && <ZoomControls {...props} tooltipDirection="up" />}
      {isLocked && (
      <HeaderControlsSVGWrapper isLock isLocked={isLocked}>
        <LockedSVG />
      </HeaderControlsSVGWrapper>
      )}
      {isEditable && isOwner && (
        <EditIcon type="HeaderControls" id={lc.id} />
      )}
      <HeaderControlsSVGWrapper
        isActive={isActive}
        onClick={() => setIsActive(!isActive)}
      >
        <MoreSVG />
      </HeaderControlsSVGWrapper>
      {isActive && (
        <ThreeDotsDropDownUPV
          isForSharedModeMaker={isForSharedModeMaker}
          forPreview
          isForLib={isForLib}
          show={isActive}
          refMenu={refMenu}
          isActive={isActive}
          setIsActive={setIsActive}
          playlistId={playlistId}
          isContentEditable={isContentEditable}
          socketId={socketId}
          isOwner={isOwner}
          item={item}
          goToItem={goToItem}
          currentPage={currentPage}
          play={(e) => newGoToPlaylist(e, activeN + 1)}
          isLinkOrComponent={isLinkOrComponent}
          isSharedToMePage={isSharedToMePage}
          goNext={goNext}
        />
      )}
      <HeaderControlsSVGWrapper onClick={close}>
        <CloseSVG />
      </HeaderControlsSVGWrapper>
    </HeaderControlsWrapper>
  );
};

export default HeaderControls;
