import React from 'react';
import cn from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import styles from './elements.module.scss';
import SixDotsItem from './SixDotsItem';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';
import { progressBarStatus } from '../../../utils/UIHelpers/PlaylistBuilderElements/libraryComponent';
import DeleteContentButton from './DeleteContentButton';
import { isRoleInPlaylist } from '../../../utils/permissions';
import { actionRemoveLinkPageFromPlaylist } from '../../../redux/currentPage/action';
import { DATA_PARENTS } from '../../../utils/constants';
import { SixDotBalancer } from './styles';

const GoogleUploadElement = ({
  item,
  playlistId,
  isDragHover,
  refForVis,
  itemIndex,
  itemStylesObject,
  ...props
}) => {
  const { id: userId } = useSelector((state) => state.user);
  const currentPage = useSelector((state) => state.currentPage);
  const ownerID = currentPage?.owner?.id;
  const isAdmin = userId === ownerID;
  const dispatch = useDispatch();

  const [menuRef, isActive, setIsActive] = useComponentVisibleMouseDown(
    false,
    DATA_PARENTS.checkItem,
  );
  const { isViewer } = isRoleInPlaylist;
  const deleteHandler = () => {
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };

  return (
    <SixDotBalancer
      ref={refForVis}
      className={cn(styles.six_dot_balancer, {
        isCompressView: props.isCompressView,
        [styles.hoverable]: !isDragHover,
        [styles.active]: isActive,
      })}
    >
      <div
        className={`${cn(styles.upload_element, {
          [styles.dragginOver]: isDragHover,
          [styles.viewMode]: isViewer[props.currentRole],
        })} highlightedPlace`}
        onDragEnd={props.onDragEndHandler}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onDragStart={props.dragStartHandler}
        draggable
      >
        <SixDotsItem
          clear={props.clear}
          createHandler={props.createHandler}
          onDragStartHandler={props.onDragStartHandler}
          onDragEndHandler={props.onDragEndHandler}
          title={item?.title || 'Untitled item '}
          playlistId={playlistId}
          menuRef={menuRef}
          isActive={isActive}
          setIsActive={setIsActive}
          isFirst={props.isItemFirst}
          isLast={props.isItemLast}
          itemIndex={itemIndex}
          isSingleElement={props.isSingleElement}
          item={item}
          isItemPreLast={props.isItemPreLast}
          isLastElementTextType={props.isLastElementTextType}
          itemStylesObject={itemStylesObject}
          otherOwner={userId !== item?.owner?.id && item.owner}
          currentRole={props.currentRole}
          isCompressView={props.isCompressView}
        />

        <div
          data-parent={item.id}
          className={cn(styles.upload_container, {
            [styles.noEdit]: userId !== item?.owner?.id,
          })}
        >
          <div className={cn(styles.icon_left)}>
            {progressBarStatus(1, undefined, 'Processing')}
          </div>
          <div className={cn(styles.main_right, styles.middle)}>
            <div className={cn(styles.text)}>
              <div className={cn(styles.title)}>{item.title}</div>
            </div>
            <div className={cn(styles.hint)}>{item.url}</div>
          </div>
        </div>
      </div>
      {!isViewer[props.currentRole]
        && ((item.owner && userId === item?.owner?.id) || isAdmin) && (
        <DeleteContentButton
          deleteHandler={deleteHandler}
          isCompressView={props.isCompressView}
        />
      )}
    </SixDotBalancer>
  );
};

export default GoogleUploadElement;
