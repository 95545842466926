import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal, actionOpenModal } from '../../redux/user/action';
import { ReactComponent as NoFolderSvg } from '../../images/icons/no_folder_72.svg';
import { ReactComponent as NoImageSvg } from '../../images/icons/no_image_72.svg';
import { actionEditImagePlaylistREDUX } from '../../redux/playlists/action';
import { ImagePickerSieveTypes, openModalType } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ContentActionType } from '../../redux/content/contentTypes';
import downloadStatus from '../../utils/dataUtil';
import Tooltip from '../../shared/Tooltips/Tooltip';

import i18n from '../../i18n';

const emptyText = {
  recent: {
    header: i18n.t('noRecentImagesT'),
    description: i18n.t('emptyTextDescRecentT'),
  },
  all: {
    header: i18n.t('noImagesT'),
    description:
      i18n.t('emptyTextDescAllT'),
  },
  filter: {
    header: i18n.t('noImagesFoundT'),
    description:
      i18n.t('emptyTextDescFilter1T'),
  },
  collection: {
    header: i18n.t('noCollectionsT'),
    description: i18n.t('emptyTextDescCollectionT'),
  },
  in_folder: {
    header: i18n.t('noImagesT'),
    description: i18n.t('emptyTextDescInfolderT'),
  },
};

const ScrollWrapper = styled.div`
  height: 262px;
  margin-top: 15px;
`;


const LoaderWrapper = styled.div`
  position: relative;
  height: 280px;
  width: 100%;
`;

const cx = classNames.bind(styles);

const ImageContainer = ({ comp, containerRef }) => {
  const [tooltipDirectionUp, setTooltipDirectionUp] = useState(false);
  const elementRef = useRef(null);

  const checkPosition = () => {
    if (!containerRef.current || !elementRef.current) return;

    const container = containerRef.current;
    const element = elementRef.current;
    const containerRect = container.getBoundingClientRect();
    const containerMiddle = containerRect.top + containerRect.height / 2;
    const elRect = element.getBoundingClientRect();
    if (elRect.top > containerMiddle) {
      setTooltipDirectionUp(false);
    } else {
      setTooltipDirectionUp(true);
    }
  };


  return (
    <Tooltip
      text={comp.title}
      direction="none"
      place={tooltipDirectionUp ? 'AddImageToPlaylist' : 'AddImageToPlaylistUp'}
    >
      <div onMouseEnter={checkPosition} ref={elementRef} className={cx('image_preview')}>
        <img
          src={comp.urlVerySmallImage || comp.urlSmallImage || comp.urlFile}
          alt=""
        />
      </div>
    </Tooltip>
  );
};
const Empty = ({ type }) => {
  const svg = () => (type === 'collection' ? <NoFolderSvg /> : <NoImageSvg />);
  return (
    <div className={cx('empty')}>
      {svg()}
      <div className={cx('empty_header')}>{emptyText[type]?.header}</div>
      <div className={cx('empty_description')}>
        {emptyText[type]?.description}
      </div>
    </div>
  );
};

const AddImageToPlaylist = ({ setIsActive }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { contentData: components, loadStatus } = useSelector(
    (state) => state.content,
  );
  const { contentIds, existMore } = useSelector((state) => state.content);
  const scroller = useRef(null);

  const [filter, setFilter] = useState('');
  const [hasMore, setHasMore] = useState(false);

  const close = () => {
    dispatch(actionCloseModal());
  };
  const isMaker = history.location.pathname.includes('/maker');


  const select = (id) => {
    setIsActive(false);
    const url = components[id].urlFile;
    const urlSmallImage = components[id].urlSmallImage;
    if (isMaker) {
      const img = new Image();
      img.src = urlSmallImage;
      setTimeout(() => {
        dispatch(
          actionOpenModal(openModalType.CropPlaylistCover, {
            imagePath: url,
            urlSmallImage,
            componentId: id,
          }),
        );
      }, 300);
    } else {
      dispatch(actionEditImagePlaylistREDUX(id, null, url));
      dispatch(actionCloseModal());
    }
  };

  const next = () => {
    if (hasMore) {
      setHasMore(false);
      dispatch(
        actionCreator(
          ContentActionType.startUpload,
          {
            activeNavSlider: 'image',
            selectorType: 'image',
            sortType: 'lastModifiedDate',
            noNeedUpdate: true,
            filter,
          },
          'AddImageToPlaylist next',
        ),
      );
    }
  };

  useEffect(() => {
    setHasMore(existMore);
  }, [contentIds]);

  useEffect(() => {
    setHasMore(false);
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: 'image',
          selectorType: 'image',
          sortType: 'lastModifiedDate',
          filter,
          isNeedUpdate: true,
        },
        'AddImageToPlaylist',
      ),
    );
  }, [dispatch, filter]);

  return (
    <div onClick={close} className={cx('image_selector_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx('modal', 'AddImageToPlaylist')}
      >
        <div className={cx('search')}>

          <input
            placeholder={t('SearchImageT')}
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
        </div>
        {loadStatus === downloadStatus.pending
          && !Object.values(components).length && (
            <LoaderWrapper>
              <div className="loader-wrapper-page white opacity">
                <div className="loader white loader_mini centered">{t('loadingT')}</div>
              </div>
            </LoaderWrapper>
        )}
        {(loadStatus !== downloadStatus.pending
          || !!Object.values(components).length) && (
          <ScrollWrapper ref={scroller} className={cx('custom_scroll')}>
            <InfiniteScroll
              pageStart={0}
              loadMore={next}
              useWindow={false}
              hasMore={hasMore}
              loader={<div key={0}>{t('loadingT')}</div>}
              getScrollParent={() => scroller.current}
            >
              <div
                // onScroll={getMany}]
                className={cx(
                  'AddImageToPlaylist_content_image',
                  'custom_scroll_hidden-scroll',
                )}
              >
                {!!Object.values(components).length
                  && Object.values(components).map((comp) => (
                    <div
                      key={comp.id}
                      onClick={() => select(comp.id)}
                      className={cx('component', {
                        // hidden: comp.isNotDownload && !comp.pending,
                        pending: comp.pending,
                      })}
                    >
                      <ImageContainer containerRef={scroller} comp={comp} />
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
            {!Object.values(components).length && !filter && (
              <Empty type={ImagePickerSieveTypes.Recent} />
            )}
            {!Object.values(components).length && !!filter && (
              <Empty type="filter" />
            )}
            <div className={cx('wrapper')} />
          </ScrollWrapper>
        )}
      </div>
    </div>
  );
};

export default AddImageToPlaylist;
