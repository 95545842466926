import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DisplayPreview } from './DisplayPreview';
import { empty } from '../../utils/constants';

const DEFAULT_TOP_PX = 55;

const DisplayPreviewForContent = ({
  item,
  isMouseMoveShowControls,
  refTopPosition,
  isForSharedToWeb,
  ...props
}) => {
  const {
    playerSettings: {
      zoomPercentage,
    },
  } = useSelector((state) => state.user);
  const [style, setStyle] = useState(empty);

  useEffect(() => {
    if (refTopPosition?.current) {
      if (refTopPosition?.current?.getBoundingClientRect().y > DEFAULT_TOP_PX) {
        if (
          props.imageRef.current?.getBoundingClientRect().height
          > DEFAULT_TOP_PX
        ) {
          setStyle({
            top:
              refTopPosition?.current?.getBoundingClientRect().y
              - DEFAULT_TOP_PX,
          });
        } else {
          setStyle({
            top:
              refTopPosition?.current?.getBoundingClientRect().y
              - DEFAULT_TOP_PX
              - props.imageRef.current?.getBoundingClientRect().height,
          });
        }
      } else {
        setStyle(empty);
      }
    }
  }, [refTopPosition?.current?.getBoundingClientRect().top, zoomPercentage]);
  const libraryComponent = Array.isArray(item?.libraryComponent)
    ? item?.libraryComponent[0]
    : item?.libraryComponent;
  return (
    <>
      <DisplayPreview
        isForSharedToWeb={isForSharedToWeb}
        libraryComponent={libraryComponent}
        imageRef={props.imageRef}
        calcStyle={props.calcStyle}
        linkPage={item}
        isPlayer
        isMouseMoveShowControls={isMouseMoveShowControls}
        setSiblingComponentIsLoading={props.setSiblingComponentIsLoading}
      />
    </>
  );
};

export default DisplayPreviewForContent;
