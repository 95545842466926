import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash_Icon.svg';
import Tooltip from '../../../shared/Tooltips/Tooltip';
import { DATA_PARENTS } from '../../../utils/constants';
import { DeleteButtonBalancer, DeleteButtonContainer, DeleteButtonShell, DeleteButtonShell2 } from './styles';

const cx = classNames.bind(rawStyles);


const DeleteContentButton = ({ deleteHandler, children, isCompressView, isPage }) => {
  const { t } = useTranslation();

  return (
    <DeleteButtonBalancer>
      <DeleteButtonShell2>
        <DeleteButtonShell
          className={cx('rising_button_flex', 'rising_button')}
          data-parent={DATA_PARENTS.checkItem}
        >

          <DeleteButtonContainer
            isPageAndCompress={isPage && isCompressView}
            onClick={deleteHandler}
            data-parent={DATA_PARENTS.checkItem}
          >
            <Tooltip
              text={t('removeT')}
              direction="down"
              place="ActionsTrash"
              data-parent={DATA_PARENTS.checkItem}
            >
              <TrashIcon />
            </Tooltip>
          </DeleteButtonContainer>
          {children}

        </DeleteButtonShell>
      </DeleteButtonShell2>
    </DeleteButtonBalancer>
  );
};

export default DeleteContentButton;
