import CustomEvent from './types';
import { ServiceUser } from '../user/types';

const defaultState = {};

const customEventReducer = (state = defaultState, action) => {
  const { type } = action;

  switch (type) {
    case CustomEvent.OpenLibSearch: {
      return { ...defaultState, openLibSearch: Date.now() };
    }
    case CustomEvent.Clear: {
      return { ...defaultState };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    default: {
      return state;
    }
  }
};

export default customEventReducer;
