import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { actionCloseModal } from '../../redux/user/action';
import Contacts from '../../redux/contacts/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import { EditPage } from '../../redux/pages/types';
import { SHARED_PLAYLIST_URL, DATA_PARENTS } from '../../utils/constants';
import { ReactComponent as Close13Svg } from '../../images/icons/player/cross_close_13.svg';
import { ReactComponent as CopySvg } from '../../images/icons/shareModal/copy.svg';
import { ReactComponent as BackSvg } from '../../images/icons/shareModal/back.svg';
import { ReactComponent as ChannelsSvg } from '../../images/icons/shareModal/channels.svg';
import { CustomButton } from '../../pages/Maker/ai_styled';
import { actionToggleRequestSpinner } from '../../redux/support/action';
import { IRootState } from '../../redux/reducers';
import { calcUserState } from '../DropDownOption/Share/helpers';
import validateEmail from '../../utils/validators/validateEmail';
import InputWithSelectUser from './InputWithSelectUser';
import Toggler from '../DropDownOption/Toggler';
import {
  actionEditPlaylistSingleStateUser,
  actionGetUsersToSharingPl,
  actionPublishPlaylistS,
} from '../../redux/playlists/action';
import { copyInBuffer } from '../../utils/helpers';
import { calcNewState, calcSaveStateForShared } from '../DatePicker/helpers';
import { DateOption, IUserToShare } from './Modals_components/ShareUnshare_single_share_user';
import { ILocalState, ShareTab } from './Modals_components/ShareUnshare_tabs/Share_tab';
import { SettingsTab } from './Modals_components/ShareUnshare_tabs/Settings_tab';

import styles from './index.module.scss';
import { SingleChannel } from './Modals_components/Single_channel';
import { ContentActionType } from '../../redux/content/contentTypes';
import { actionRemoveAllSelected } from '../../redux/selectedPage/action';

const cx = classNames.bind(styles);


interface IWrongEmail {
  id: string,
  type: string,
  email: string,
}

interface ILocalShareState {
  isNeedAccess: boolean,
  accessCode: string,
  isCoEdit: boolean,
}

// @ts-ignore
const ShareUnshare = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataPayload: { isMultiple, item, isPage } } = useSelector((state: IRootState) => state.user);

  const itemWrapperID = item?.itemWrapperID;
  const [currentPeriod, setCurrentPeriod] = useState('OPEN_TO_READ');
  const [currentMode, setCurrentMode] = useState('share');
  const close = () => {
    dispatch(actionCloseModal());
  };
  const user = useSelector((state: IRootState) => state.user);
  const {
    title,
    linkPagesCount,
    id,
    wrapperId,
    singleUserShareState = [],
    usedInChannels = {},
    link = '',
    sharedAvailableFrom,
    sharedAvailableTo,
    isShareToWeb,
    mixedSharedToUser = 0,
    mixedSharedToChannel = 0,
    isAllShareToWeb,
    isMixedShareToWeb,
  } = data;
  const history = useHistory();
  const contacts = useSelector((state: IRootState) => state.contacts);
  const { myChannels } = useSelector((state: IRootState) => state.channels);
  const selectedPage: { [key: string]: boolean } = useSelector((state: IRootState) => state.selectedPage as { [key: string]: boolean });

  const [emailsAndUsersToInvite, setEmailsAndUsersToInvite] = useState(
    calcUserState(contacts),
  );
  const deleteEmail = (indexToDel: number) => {
    setEmailsAndUsersToInvite(
      emailsAndUsersToInvite.filter((_, index) => index !== indexToDel),
    );
  };
  const type = 'playlist';
  const [selectedDateFrom, setSelectedDateFrom] = useState(calcNewState(sharedAvailableFrom));
  const [selectedDateTo, setSelectedDateTo] = useState(calcNewState(sharedAvailableTo));
  const isHasShare = !!Object.values(singleUserShareState).length || isShareToWeb || !!Object.values(usedInChannels).length;
  const isHasShareInMultiple = !!mixedSharedToChannel || !!mixedSharedToUser || isMixedShareToWeb || isAllShareToWeb;
  const [isNeedUpdateShareState, setIsNeedUpdateShareState] = useState(true);
  const [localState, setLocalState] = useState<ILocalState>({} as ILocalState);
  const [inputEmail, setInputEmail] = useState('');
  const [validateError, setValidateError] = useState('');

  const isDraftPath = history.location.pathname.includes('drafts');
  const [localShareState, setLocalShareState] = useState<ILocalShareState>(
    { isNeedAccess: false, accessCode: '', isCoEdit: false },
  );

  const [isPublic, setPublic] = useState<boolean>(isShareToWeb);
  const [value, onChange] = useState<any>();
  const [isMainCalendarOpened, setMainCalendarOpened] = useState(false);
  const [currentCalendarItem, setCurrentCalendarItem] = useState({ type: '', id: '' });

  const [wrongEmails, setWrongEmails] = useState<IWrongEmail[]>([] as IWrongEmail[]);

  const channelsList = useMemo(() => {
    return Object.keys(usedInChannels).map((channelId: string) => (
      { ...usedInChannels[channelId], ...myChannels[channelId] }));
  }, [myChannels, usedInChannels]);

  const senderEmail = user?.email;
  const handleUserOrEmailState = useCallback((e: React.MouseEvent, user: IUserToShare, email: string) => {
    const isAddOwner = email && email.trim().toLowerCase() === senderEmail.trim().toLowerCase()
      || user?.email && user?.email.trim().toLowerCase() === senderEmail.trim().toLowerCase();
    if (isAddOwner) {
      setValidateError(t('enterAnotherRecipientMessageT'));
      setWrongEmails([...wrongEmails, {
        id: uuidv4(),
        type: 'exsistingUser',
        email,
      }]);
      setInputEmail('');
      setCurrentMode('details');
      return;
    }
    if (emailsAndUsersToInvite.find((elem) => elem.email === email)
      || singleUserShareState[email]) {
      setWrongEmails([...wrongEmails, {
        id: uuidv4(),
        type: 'exsistingUser',
        email,
      }]);
      setInputEmail('');
      setCurrentMode('details');
      return;
    }
    if (user) {
      setEmailsAndUsersToInvite([
        ...emailsAndUsersToInvite,
        { ...user, type: 'user' },
      ]);
    } else if (email && !validateEmail(email) && !user) {
      const name = email.slice(0, 40);
      setValidateError(
        `${name}${name.length > 39 ? '...' : ''
        } ${t('notFoundInContactsT')}`,
      );
    } else if (email && validateEmail(email)) {
      setEmailsAndUsersToInvite([
        ...emailsAndUsersToInvite,
        { email, name: email, type: 'user' },
      ]);
      dispatch(
        actionCreator(Contacts.CreateAndAddSingleToContactMap, { email }),
      );
    } else {
      setValidateError(t('enterEmailOrUsernameT'));
      e.preventDefault();
    }
    setInputEmail('');
    setCurrentMode('details');
  }, [emailsAndUsersToInvite]);

  const editShareStateHandler = (state: boolean | string, field: string) => {
    const newState: any = { ...localShareState };
    newState[field] = state;
    setLocalShareState(newState);
  };

  const shareInChannelHandler = useCallback((channels: object) => {
    if (Object.values(channels).length) {
      if (isMultiple) {
        dispatch(actionCreator(EditPlaylist.multiplePublish, {
          addedNewChannel: Object.values(channels).map(i => ({ id: i.id })),
          playlistsIds: Object.keys(selectedPage),
          dateState: localState,
        }));
      } else {
        const playlist = {
          id,
          title,
          wrapperId,
        };
        dispatch(actionToggleRequestSpinner(id));
        dispatch(actionPublishPlaylistS(channels, {}, playlist, localState));
        dispatch(actionCloseModal());
      }
    }
  }, [id, localState, title, dispatch]);

  const shareLink = isPage
    ? `${document.location.origin}/sharedwebpage/${link}`
    : `${document.location.origin}/${SHARED_PLAYLIST_URL}/${link || wrapperId}`;

  const copyHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    copyInBuffer({ current: { value: shareLink } }, dispatch);
  };

  const shareToWebHandler = useCallback(() => {
    if (isNeedUpdateShareState) {
      if (isMultiple) {
        const isNeedAccessValid = localShareState.isNeedAccess && !!localShareState.accessCode;
        Object.keys(selectedPage).forEach(wrapperId => {
          dispatch(actionCreator(EditPlaylist.updateShareToWebStatePlaylistSR, {
            wrapperId,
            isShareToWeb: true,
            updateTime: { ...localState },
            ...localShareState,
            isNeedAccess: isNeedAccessValid,
          }));
          if (isDraftPath) {
            dispatch(
              actionCreator(ContentActionType.updatePlaylistR, {
                idRemove: wrapperId,
              }),
            );
            dispatch(actionRemoveAllSelected());
          }
        });
        return;
      }
      if (isPage) {
        dispatch(actionCreator(EditPage.editShareStateToWeb,
          { isShareToWeb: true, updateTime: { ...localState }, ...localShareState, wrapperId, playlistId: id }));
      } else {
        const isNeedAccessValid = localShareState.isNeedAccess && !!localShareState.accessCode;
        dispatch(actionCreator(EditPlaylist.updateShareToWebStatePlaylistSR, {
          wrapperId,
          isShareToWeb: true,
          updateTime: { ...localState },
          ...localShareState,
          isNeedAccess: isNeedAccessValid,
        }));
      }
      setIsNeedUpdateShareState(false);
    } else {
      close && close();
      dispatch(actionCloseModal());
    }
  }, [close, isMultiple, isNeedUpdateShareState, isPage, localShareState, localState]);

  const shareHandler = useCallback(() => {
    const items = emailsAndUsersToInvite;
    const channels = items.reduce((acc, cur) => {
      if (cur.isChannel && myChannels[cur.id]) {
        acc[cur.id] = myChannels[cur.id];
      }
      return acc;
    }, {});
    if (isPublic && !isShareToWeb) {
      shareToWebHandler();
    }
    if (!isPublic && isShareToWeb) {
      dispatch(actionCreator(EditPlaylist.updateShareToWebStatePlaylistSR, {
        isShareToWeb: false, ...localShareState,
      }));
    }
    let users = emailsAndUsersToInvite.filter(elem => !elem.isChannel);
    if (Object.values(channels).length) {
      shareInChannelHandler(channels);
      if (!users.length) {
        close && close();
        return;
      }
    }
    if (inputEmail.trim() && validateEmail(inputEmail)) {
      const notAddedUser = {
        email: inputEmail.trim(),
        isCoEdit: !!localShareState.isCoEdit,
        name: inputEmail.trim().slice(0, 40),
        type: 'user',
      };
      users = [...users, notAddedUser];
      dispatch(
        actionCreator(Contacts.CreateAndAddSingleToContactMap, { email: inputEmail.trim(), wrapperId }),
      );
    }
    if (!users.length) {
      setValidateError(t('pleaseEnterValidEmailT'));
      close && close();
      return;
    }
    if (!isMultiple) {
      // single playlist
      dispatch(actionToggleRequestSpinner(id));
      const state = calcSaveStateForShared(
        selectedDateFrom,
        selectedDateTo,
        currentPeriod,
      );
      dispatch(actionCreator(EditPlaylist.updateShareToUserPlaylistSR,
        {
          update: { ...localState, isCoEdit: !!localShareState.isCoEdit, ...state, wrapperId },
          users,
          wrapperIdFromOptions: itemWrapperID,
          idFromOptions: id,
        }));
    } else {
      dispatch(actionCreator(EditPlaylist.ShareMultiplePlaylistsS, {
        update: { ...localState, isCoEdit: !!localShareState.isCoEdit },
        users,
      }));
    }
    close && close();
    dispatch(actionCloseModal());
  }, [close, emailsAndUsersToInvite, id, inputEmail, isMultiple,
    localShareState.isCoEdit, localState]);

  useEffect(() => {
    setIsNeedUpdateShareState(true);
  }, [localShareState, localState]);

  useEffect(() => {
    setPublic(isShareToWeb);
  }, [isShareToWeb]);

  useEffect(() => {
    if (history.location.pathname.includes(`/${SHARED_PLAYLIST_URL}`)) return;
    dispatch(actionGetUsersToSharingPl({ type: 'playlist' }));
    if (type === 'playlist') {
      dispatch(actionCreator(EditPlaylist.getChannelsToPublishPl));
    }
  }, []);

  const setSelectedDateToHandler = (newSelectedDateTo: Date) => {
    newSelectedDateTo.setHours(23, 59, 59, 999);
    setSelectedDateTo(newSelectedDateTo);
  };
  const setSelectedDateFromHandler = (newSelectedDateFrom: Date) => {
    newSelectedDateFrom.setHours(23, 59, 59, 999);
    setSelectedDateFrom(newSelectedDateFrom);
  };
  const submitBtnName = useMemo(() => (
    currentMode === 'details' ? t('shareT') : t('updateCloseT')
  ), [currentMode]);

  const updateChannelTimeHandler = (channelId: string, selectedOption: string) => {
    const state = calcSaveStateForShared(
      selectedDateFrom,
      selectedDateTo,
      selectedOption,
    );
    dispatch(actionCreator(EditPlaylist.updatePublishStatePlaylistSR, { channelId, updateTime: { ...state }, wrapperId }));
  };

  const handleCloseCalendar = () => {
    const state = calcSaveStateForShared(
      selectedDateFrom,
      selectedDateTo,
      'CUSTOM_TIME_RANGE',
    );
    if (selectedDateFrom && selectedDateTo) {
      if (currentCalendarItem.type === 'channel') {
        dispatch(actionCreator(EditPlaylist.updatePublishStatePlaylistSR,
          { channelId: currentCalendarItem.id, updateTime: { ...state }, wrapperId }));
      }
      if (currentCalendarItem.type === 'user') {
        dispatch(actionCreator(EditPlaylist.updateShareStatePlaylistS, { ...state, wrapperId }));
        dispatch(actionEditPlaylistSingleStateUser({ ...localState, ...state, email: currentCalendarItem.id, wrapperId }));
      }
    }
    onChange(null);
    setCurrentCalendarItem({ type: '', id: '' });
    setMainCalendarOpened(false);
  };

  useEffect(() => {
    if (value && value[0] && value[1]) {
      setSelectedDateFromHandler(value[0]);
      setSelectedDateToHandler(value[1]);
    }
  }, [value]);

  const handleBack = () => {
    setEmailsAndUsersToInvite([]);
    setWrongEmails([]);
    setCurrentMode('share');
  };
  if (isLoading) {
    return (
      <div onClick={close} style={{ overflowY: 'hidden' }} className={cx('modal_wrapper')}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={cx('modal', 'AddToSFModal', 'shareUnshareModal', 'shareUnshareModalLoading')}
        >
          <div className={cx('title_wrapper')}>
            <div className={cx('title')}>
              {(currentMode === 'share' || currentMode === 'details') && (
                <>
                  <p>{t('shareT')} {title || t('unnamedSmartFileT')}</p>
                  <div className={cx('items_count', 'text_unified-sm')}>
                    {`${linkPagesCount} ${linkPagesCount === 1 ? t('itemLowT') : t('itemsLowT')}`}
                  </div>
                </>
              )}
            </div>
            <CustomButton isGhost isIconOnly onClick={close} data-parent={DATA_PARENTS.checkItem}>
              <Close13Svg data-parent={DATA_PARENTS.checkItem} />
            </CustomButton>
          </div>
          <div className={cx('shareUnshareModal_loader')}>
            <div className="loader-wrapper_page white_new_library">
              <div className="loader_mini white">{t('loadingT')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={close} style={{ overflowY: 'hidden' }} className={cx('modal_wrapper')}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx(
          { shareUnshareModalPublishedInChannels: currentMode === 'channels' },
          'modal', 'AddToSFModal',
          'shareUnshareModal')}
      >
        <div className={cx('title_wrapper')}>
          <div className={cx('title')}>
            {currentMode !== 'share' && (
              <div className={cx('back_icon')}>
                <CustomButton isGhost isIconOnly onClick={handleBack}>
                  <BackSvg />
                </CustomButton>
              </div>
            )}
            {(currentMode === 'share' || currentMode === 'details') && (
              <>
                <p>{t('shareT')} {title || t('unnamedSmartFileT')}</p>
                <div className={cx('items_count', 'text_unified-sm')}>
                  {`${linkPagesCount} ${linkPagesCount === 1 ? t('itemLowT') : t('itemsLowT')}`}
                </div>
              </>
            )}
            {currentMode === 'settings' && t('shareSettingsT')}
            {currentMode === 'channels' && t('publishedInChannelsT')}
          </div>
          <CustomButton isGhost isIconOnly onClick={close} data-parent={DATA_PARENTS.checkItem}>
            <Close13Svg data-parent={DATA_PARENTS.checkItem} />
          </CustomButton>
        </div>
        {(currentMode === 'share' || currentMode === 'details') && (
          <InputWithSelectUser
            inputEmail={inputEmail}
            deleteEmail={deleteEmail}
            handleUserOrEmailState={handleUserOrEmailState}
            setInputEmail={setInputEmail}
            emailsAndUsersToInvite={emailsAndUsersToInvite}
            setValidateError={setValidateError}
            validateError={validateError}
            isSharePage
            dataParent=""
            wrongEmails={wrongEmails}
            setWrongEmails={setWrongEmails}
            isMaker
          />
        )}
        {currentMode === 'share' && (
          <ShareTab
            setCurrentMode={setCurrentMode}
            owner={user}
            singleUserShareState={singleUserShareState}
            channelsList={channelsList}
            setLocalState={setLocalState}
            localState={localState}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            id={id}
            setMainCalendarOpened={setMainCalendarOpened}
            setCurrentCalendarItem={setCurrentCalendarItem}
            isPublic={isPublic}
            mixedSharedToChannel={mixedSharedToChannel}
            mixedSharedToUser={mixedSharedToUser}
            isAllShareToWeb={isAllShareToWeb}
            isMixedShareToWeb={isMixedShareToWeb}
            isMultiple={isMultiple}
            wrapperId={wrapperId}
          />
        )}
        {isMainCalendarOpened && (
          <div className={cx('date_picker_wrapper', 'is_absolute')}>
            <DatePicker
              onChange={onChange}
              value={value}
              tileClassName="tileClass"
              className="playlistsDatePicker2023 isModifyUnset"
              showLeadingZeros
              isOpen={isMainCalendarOpened}
              onCalendarClose={handleCloseCalendar}
              minDate={new Date()}
              returnValue="range"
              selectRange
            />
          </div>
        )}
        {currentMode === 'details' && (
          <>
            <textarea
              className={cx('input_message')}
              placeholder={t('messagePlaceholderT')}
            />
            <div className={cx('shared_option')}>
              <div className={cx('shared_option_content')}>
                <div className={cx('option_title', 'text_unified-base')}>{t('allowCoEditingT')}</div>
                <div className={cx('option_description', 'text_unified-sm')}>{t('allowCoEditingDescriptionT')}</div>
              </div>
              <Toggler
                dataParent=""
                togglerHeight={24}
                togglerWidth={48}
                isActive={localShareState.isCoEdit}
                callback={() => {
                  editShareStateHandler(!localShareState.isCoEdit, 'isCoEdit');
                }}
                isDisable={false}
                isBrand
                text=""
                top="12px"
                left="520px"
              />
            </div>
            <DateOption
              currentPeriod={currentPeriod}
              setCurrentPeriod={setCurrentPeriod}
              setSelectedDateFromHandler={setSelectedDateFromHandler}
              setSelectedDateToHandler={setSelectedDateToHandler}
            />
          </>
        )}
        {currentMode === 'settings' && (
          <SettingsTab
            isPublic={isPublic}
            setPublic={setPublic}
            currentPeriod={currentPeriod}
            setCurrentPeriod={setCurrentPeriod}
            localShareState={localShareState}
            editShareStateHandler={editShareStateHandler}
            isHasShare={isHasShare}
            id={id}
            wrapperId={wrapperId}
            close={close}
            isMultiple={isMultiple}
            isMixedShareToWeb={isMixedShareToWeb}
            isHasShareInMultiple={isHasShareInMultiple}
            setSelectedDateFromHandler={setSelectedDateFromHandler}
            setSelectedDateToHandler={setSelectedDateToHandler}
            playlistsIds={selectedPage}

          />
        )}
        {currentMode === 'channels' && (
          channelsList.length ? (
            <div className={cx('channels_wrapper')}>
              {channelsList.map((channel: any) => (
                <SingleChannel
                  key={channel.id}
                  channel={channel}
                  setTime={updateChannelTimeHandler}
                  setMainCalendarOpened={setMainCalendarOpened}
                  setCurrentCalendarItem={setCurrentCalendarItem}
                />
              ))}
            </div>
          ) : (
            <div className={cx('shared_options', 'empty_state')}>
              <ChannelsSvg className={cx('shared_option_icon')} />
              <div style={{ color: '#A2A5AA' }}>{t('noChannelsModalT')}</div>
              <div className={cx('no_channels_description')}>{t('noChannelsDescriptionModalT')}</div>
            </div>
          )
        )}
        {currentMode !== 'channels' && <div className={cx('divider_full')} />}
        {currentMode !== 'channels' && (
        <div className={cx('footer')}>
          {!isMultiple && (
          <CustomButton className={cx('footer_left', 'text_unified-base')} onClick={copyHandler} isLink>
            <CopySvg />
            {t('copyLinkT')}
          </CustomButton>
          )}
          {isMultiple && <div className={cx('footer_left')} />}

          <div className={cx('footer_left')}>
            <CustomButton
              onClick={close}
              className={cx('cancel_button')}
              isGhost
            >{t('cancelT')}
            </CustomButton>
            <CustomButton isActive isBrand onClick={shareHandler}>{submitBtnName}</CustomButton>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default ShareUnshare;
