export const counterMap = {
  // playlistsINCounter: 'playlistsINCounter',
  playlistOutNotificationCount: 'playlistOutNotificationCount',
  pagesOutNotificationCount: 'pagesOutNotificationCount',
  channelHasMyInviteCounter: 'channelHasMyInviteCounter',
  sharedPageNotificationCounter: 'sharedPageNotificationCounter',
  sharedPlaylistNotificationCounter: 'sharedPlaylistNotificationCounter',
  inviteInChannelsNotificationCounter: 'inviteInChannelsNotificationCounter',
  'smartfiles/published': 'counterMyPlaylistPublish',
  'smartfiles/recently_viewed': 'allOpenedSmartFileCounter',
  'smartfiles/drafts': 'counterMyPlaylistDraft',
  'smartfiles/favorites': 'counterMyPlaylistFavorite',
  'smartfiles/shared': 'playlistToSharingCount',
  'smartfiles/sharedByMe': 'playlistsToSharingByMeCount',
  'library/all': 'counterLibrary',
  'library/favorites': 'counterLibraryFavorite',
  'pages/drafts': 'counterMyPages',
  'pages/favorites': 'counterMyPagesFavorite',
  'pages/shared': 'pagesToSharingCount',
  'pages/sharedByMe': 'pagesToSharingByMeCount',
  'pdf/all': 'counterPDF',
  'pdf/favorites': 'counterPDFFavorite',
  'media/all': 'counterMedia',
  'media/favorites': 'counterMediaFavorite',
  'media/images': 'counterMediaImage',
  'media/video': 'counterMediaVideo',
  'media/audio': 'counterMediaAudio',
  'links/all': 'counterLinks',
  'links/favorites': 'counterLinksFavorite',
  'links/url': 'counterLinksUrl',
  'links/embed': 'counterLinksEmbed',
  'files/all': 'counterFile',
  'files/favorites': 'counterFileFavorite',
  contact: 'counterContact',
} as const;
