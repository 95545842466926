import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
  ReactionsPopupWrapper,
  TabsSection, UsersSection,
  SingleReactionWrapper,
  EmojiWrapper, SectionSeparator,
} from './styles';
import { ReactionUserRow } from './ReactionUserRow';

interface IReactionsPopup {
  usersReaction: { [key: string]: string[] },
  contacts: {[key:string]: {username: string, email: string}},
  yourId: string,
  reactHandler: (reaction: string) => void,
  ownerID: string,
}
export const ReactionsPopup: React.FC<IReactionsPopup> = ({
  usersReaction,
  contacts,
  yourId,
  reactHandler,
  ownerID,
}) => {
  const [currentTab, setCurrentTab] = useState('All');
  const allUsersReactions = useMemo(() => (Object.keys(usersReaction)
    .reduce((acc: { [reaction: string]: string }[], cur: string) => {
      usersReaction[cur].forEach(userId => {
        if (userId === ownerID) {
          acc.unshift({ [userId]: cur });
        } else {
          acc.push({ [userId]: cur });
        }
      });
      return acc;
    }, [])
  ), [usersReaction, ownerID]);

  const tabs: { [key: string]: string[] } = usersReaction;
  const handleClickOnMe = (id: string, reaction: string) => {
    if (yourId === id) {
      reactHandler(reaction);
    }
  };
  const scrollRef: RefObject<HTMLDivElement> = useRef(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleWheel = (event: React.WheelEvent) => {
    if (scrollRef.current) {
      if (event.deltaY) {
        scrollRef.current.scrollLeft += event.deltaY;
      }
    }
  };

  useEffect(() => {
    if (currentTab !== 'All' && !tabs[currentTab]) {
      setCurrentTab('All');
    }
  }, [tabs[currentTab]]);

  return (
    <ReactionsPopupWrapper>
      <TabsSection onWheel={handleWheel} ref={scrollRef}>
        <SingleReactionWrapper key="all" isSelected={currentTab === 'All'} onClick={() => setCurrentTab('All')}>
          All {allUsersReactions.length}
        </SingleReactionWrapper>
        {Object.keys(tabs).map(elem => (
          <SingleReactionWrapper key={elem} isSelected={currentTab === elem} onClick={() => setCurrentTab(elem)}>
            <EmojiWrapper>
              {elem}
            </EmojiWrapper>
            {tabs[elem].length}
          </SingleReactionWrapper>
        ))}
      </TabsSection>
      <SectionSeparator />
      <UsersSection ref={sectionRef}>
        {currentTab === 'All' ? (
          allUsersReactions.map((elem = {}) => (
            <ReactionUserRow
              sectionRef={sectionRef}
              contacts={contacts}
              yourId={yourId}
              reactHandler={handleClickOnMe}
              currentEmoji={Object.values(elem)[0]}
              idUserReacted={Object.keys(elem)[0]}
            />
          ))
        ) : (
          tabs[currentTab]?.map((elem) => (
            <ReactionUserRow
              sectionRef={sectionRef}
              contacts={contacts}
              yourId={yourId}
              reactHandler={handleClickOnMe}
              currentEmoji={currentTab}
              idUserReacted={elem}
            />
          ))
        )}
      </UsersSection>
    </ReactionsPopupWrapper>
  );
};
