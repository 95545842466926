import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDownSvg } from '../../../images/icons/chevron_down_12.svg';
import { ReactComponent as ContactsIcon } from '../../../images/2023/svg/small/left-main-menu/fill_icons/contacts.svg';
import { actionOpenModal } from '../../../redux/user/action';
import {
  SectionChevronIconWrapper,
  MajorSelector,
  SelectorWrapper,
  MajorText,
  CollapsingSectionWrapper,
  LoaderWrapper,
  PlusWrapperCircle,
  ContactWrapper,
  Selector,
  Info,
  ContactName,
  AllContactsIconWrapper,
} from '../styled';
import downloadStatus from '../../../utils/dataUtil';
import ListDragHandler from '../../DragHandler/ChannelListDragHandler.tsx';
import SingleContact from './single-item/SingleContact';
import styles from '../../LeftSideBar/LeftSideBar.module.scss';
import { calcTimeForSpeed } from '../../../utils/helpers';
import { ReactComponent as PlusIconCircle } from '../../../images/icons/icon_20/plus2.svg';
import { openModalType } from '../../../utils/constants.tsx';
import Tooltip from '../../../shared/Tooltips/Tooltip';

const itemHeight = 33;

const ContactsSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { selectorType: selectedUserId } = useSelector((state) => state.content);
  const [isContactsCollapsed, setContactsCollapsed] = useState(true);
  const [showedContacts, setShowedContacts] = useState([]);
  const { contactNicknameMap, loadStatusContact, blinkIdsArray } = useSelector((state) => state.support);

  const isHasActiveContact = history.location.pathname.includes('/content/users_smartfiles/');
  const height = (Object.values(showedContacts).length || 1) * itemHeight;
  const time = calcTimeForSpeed(Object.values(showedContacts).length);

  useEffect(() => {
    setShowedContacts(Object.values(contactNicknameMap).filter(i => !i.isUnpin)
      .sort((a, b) => a.position - b.position));
  }, [contactNicknameMap]);

  return (
    <>
      <MajorSelector
        onClick={() => setContactsCollapsed(!isContactsCollapsed)}
        isCollapsed={isContactsCollapsed}
        onDragEnter={() => setContactsCollapsed(false)}
      >
        <SelectorWrapper>
          <SectionChevronIconWrapper
            isCollapsed={isContactsCollapsed}
          >
            <ChevronDownSvg className="chevron" />
            <ContactsIcon className="icon" />
          </SectionChevronIconWrapper>
          <MajorText>{t('contactsT')}</MajorText>
        </SelectorWrapper>
        <Tooltip
          text="Add"
          direction="down"
          place="sidebarPlus"
        >
          <PlusWrapperCircle onClick={() => dispatch(actionOpenModal(openModalType.EditContact))}>
            <PlusIconCircle />
          </PlusWrapperCircle>
        </Tooltip>
      </MajorSelector>
      <CollapsingSectionWrapper
        isCollapsed={isContactsCollapsed}
        time={time}
      >
        {!showedContacts.length && loadStatusContact !== downloadStatus.pending && (
          <div
            className={styles.create_new_channel}
            onClick={() => dispatch(actionOpenModal(openModalType.EditContact))}
          >
            <div>{t('addAContactT')}</div>
          </div>
        )}
        <ListDragHandler nextItem={showedContacts[0]} isLast isContacts />
        <Selector onClick={() => history.push('/contacts')}>
          <Info>
            <AllContactsIconWrapper>
              <ContactsIcon />
            </AllContactsIconWrapper>
            <ContactName>
              All Contacts
            </ContactName>
          </Info>
        </Selector>
        {showedContacts.map((contact, index) => (
          <ContactWrapper key={contact.id}>
            <SingleContact
              key={contact.id}
              contact={contact}
              isActiveContact={isHasActiveContact && contact.id === selectedUserId}
              blinkIdsArray={blinkIdsArray}
              contacts={showedContacts}
              activeContactId={selectedUserId}
            />
            <ListDragHandler
              currentItem={contact}
              nextItem={showedContacts[index + 1]}
              isFirst={index === 0}
              isContacts
            />
          </ContactWrapper>
        ))}
      </CollapsingSectionWrapper>
    </>
  );
};

export default ContactsSection;
